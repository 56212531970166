import { TimeAgo } from "@cp/theme";
import { Divider, Stack, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import { PageColumn } from "../../../components/column/PageColumn";
import { useSelectedEntityId } from "../../../components/panels/atoms";
import { useCurationEntityByIdQuery } from "../../../generated/graphql";
import { deserializeCurationEntity } from "../../curation/state/serialize";
import { useCurationActions } from "../../curation/state/useCurationActions";
import { useCurationState } from "../../curation/state/useCurationState";
import { CurationEntityLayout } from "../EntityLayout/CurationEntityLayout";
import { entityTypeIdToIcon } from "../icons";
import { EntityTagSelector } from "./EntityTagSelector";
import { SaveEntityButton } from "./SaveEntityButton";

export const EditEntityPanel: React.VFC = () => {
  const [entityId] = useSelectedEntityId();
  const actions = useCurationActions();
  const [displayTextTemplate, setDisplayTextTemplate] = useState("");
  const [supportedTags, setSupportedTags] = useState<string[]>([]);
  const [supportedApplicationTags, setSupportedApplicationTags] = useState<string[]>([]);
  const { getTitle } = useCurationState();

  const { data: curationEntityData } = useCurationEntityByIdQuery({
    variables: { id: entityId! },
    skip: !entityId,
    onCompleted: (data) => {
      actions.setState(deserializeCurationEntity(data.curationEntityById));
    },
  });
  const curationEntity = curationEntityData?.curationEntityById;

  // NOTE - the state was not updating correctly when in onSuccess, so moved to useEffect
  React.useEffect(() => {
    if (curationEntity) {
      setDisplayTextTemplate(curationEntity.displayTextTemplate);
      setSupportedTags(curationEntity.supportedTags);
      setSupportedApplicationTags(curationEntity.supportedApplicationTags);
    }
  }, [curationEntity]);

  // Convert `${uuid.code}, ${uuid}, ${uuid}` to `Foo, Bar, Baz`
  const translatedLabel = displayTextTemplate.replaceAll(/\${([^}]+)}/g, (_match, template: string) => {
    const [uuid, subProperty] = template.split(".", 2);

    return subProperty ? `<${getTitle(uuid)}.${subProperty}>` : `<${getTitle(uuid)}>`;
  });

  return (
    <PageColumn
      flex={3}
      title={
        <Typography variant="h3" alignItems="center" display="flex" gap={2} m={0}>
          {curationEntity && entityTypeIdToIcon[curationEntity.type]}
          {curationEntity?.type}
        </Typography>
      }
      rightToolbar={
        <>
          <Typography variant="body2">
            Last updated:{" "}
            <b>
              <TimeAgo date={curationEntity?.updatedAt} />
            </b>
          </Typography>
          <SaveEntityButton
            displayTextTemplate={displayTextTemplate}
            supportedTags={supportedTags}
            supportedApplicationTags={supportedApplicationTags}
          />
        </>
      }
    >
      <TextField
        label="Display Text Template"
        value={displayTextTemplate}
        InputProps={{
          sx: { fontFamily: "monospace" },
        }}
        onChange={(e) => setDisplayTextTemplate(e.target.value)}
        fullWidth={true}
        helperText={
          <>
            <Stack>
              Template will render as: <b>{translatedLabel}</b>
              {`Use \${} to reference a question's values by the question UUID. For example: \${43b9dd54-0514-4f93-98bc-a7707bc0af7e}`}
            </Stack>
          </>
        }
      />
      <Divider sx={{ my: 3 }} />

      <EntityTagSelector
        supportedTags={supportedTags}
        setSupportedTags={setSupportedTags}
        supportedApplicationTags={supportedApplicationTags}
        setSupportedApplicationTags={setSupportedApplicationTags}
      />

      <Divider sx={{ my: 3 }} />

      <CurationEntityLayout />
    </PageColumn>
  );
};
