import { Drawer, Typography } from "@mui/material";
import { PropsWithChildren } from "react";
import { useSelectedCurationApplicationId, useSelectedCurationNodeId } from "../../../../components/panels/atoms";
import { Panels } from "../../../../components/panels/Panels";
import { useCurationApplicationByIdQuery } from "../../../../generated/graphql";
import ApplicationLayout from "../../../curation/ApplicationLayout/ApplicationLayout";
import { DetailsEditor } from "../../../curation/DetailsEditor/DetailsEditor";
import { DraggableUncuratedQuestion } from "../../../curation/Dragables/DraggableUncuratedQuestion";
import { CurationQuestionList } from "../../../curation/Questions/CurationQuestionList";
import { CurationFeatureContext } from "../../../curation/state/CurationContext";
import { RealtimeCurationProvider } from "../../../curation/state/RealtimeCurationProvider";
import { deserializeCurationApplication } from "../../../curation/state/serialize";
import { CurationContextFeature } from "../../../curation/state/types";
import { useCurationActions } from "../../../curation/state/useCurationActions";

interface Props {
  lockedCurationApplicationId?: string;
  disableCurationQuestionList?: boolean;
  curationContext?: CurationContextFeature;
}

export const CurationControls = ({
  lockedCurationApplicationId,
  disableCurationQuestionList = true, // Disabling because curation page can't handle rendering this large list
  curationContext = CurationContextFeature.DEFAULT,
  children,
}: PropsWithChildren<Props>) => {
  const { setState } = useCurationActions();
  const [selectedId, setSelectedId] = useSelectedCurationNodeId();
  const [selectedCurationApp] = useSelectedCurationApplicationId();
  const curationAppId = lockedCurationApplicationId || selectedCurationApp;

  // Fetch to initialize state
  useCurationApplicationByIdQuery({
    variables: { id: curationAppId },
    onCompleted: (data) => {
      setState(deserializeCurationApplication(data.curationApplicationById));
    },
  });

  if (!curationAppId) {
    return null;
  }

  return (
    <CurationFeatureContext.Provider value={curationContext}>
      <RealtimeCurationProvider id={curationAppId}>
        <Panels
          id="curation-controls"
          config={[
            {
              title: "Application",
              defaultSize: 60,
              content: <ApplicationLayout disableCurationApplicationFilters={!!lockedCurationApplicationId} />,
            },
            ...(disableCurationQuestionList
              ? []
              : [
                  {
                    title: "Questions",
                    defaultSize: 40,
                    header: (
                      <Typography variant="h4" px={2}>
                        Curation questions not in the application
                      </Typography>
                    ),
                    content: <CurationQuestionList CurationComponent={DraggableUncuratedQuestion} />,
                  },
                ]),
          ]}
        />
        <Drawer
          anchor="right"
          open={!!selectedId}
          PaperProps={{ sx: { p: 4 } }}
          onClose={() => setSelectedId(undefined)}
          sx={{ width: "50%" }}
        >
          <DetailsEditor />
        </Drawer>
        {children}
      </RealtimeCurationProvider>
    </CurationFeatureContext.Provider>
  );
};
