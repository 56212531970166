import { logNever, Objects, Strings } from "@cp/toolkit";
import {
  ApplicationConditionBooleanInput,
  ApplicationConditionFragment,
  ApplicationConditionInput,
  ApplicationConditionMatchAllInput,
  ApplicationConditionMatchAnyInput,
  ApplicationConditionNumberInput,
  ApplicationConditionStringInput,
  ApplicationConditionStringSetInput,
  ClobQuestionFragment,
  CurationQuestionFragment,
} from "../../generated/graphql";

export function formatConditions(
  conditions: ApplicationConditionFragment[],
  getQuestion: (id: string) => CurationQuestionFragment | null
): string | undefined {
  if (!conditions || conditions.length === 0) {
    return undefined;
  }

  return conditions
    .map((condition: ApplicationConditionFragment) => {
      const question = "subject" in condition ? getQuestion(condition.subject) : undefined;

      switch (condition.__typename) {
        case "ApplicationConditionBoolean":
          return `"${question?.text}" \n\t${Strings.titleCase(condition.booleanOperator)}`;
        case "ApplicationConditionString":
          return `"${question?.text}" \n\t${Strings.titleCase(condition.stringOperator)} \n"${condition.stringValue}"`;
        case "ApplicationConditionStringSet":
          return `"${question?.text}" \n\t${Strings.titleCase(condition.stringSetOperator)} \n${
            condition.stringSetValue
          }`;
        case "ApplicationConditionNumber":
          return `"${question?.text}" \n\t${Strings.titleCase(condition.numberOperator)} \n${condition.numberValue}`;
        case "ApplicationConditionMatchAll":
          return `(${condition.matchAllConditions
            .map((condition) => formatConditions([condition as ApplicationConditionFragment], getQuestion))
            .join(" \n\tAND\n ")})`;
        case "ApplicationConditionMatchAny":
          return `(${condition.matchAnyConditions
            .map((condition) => formatConditions([condition as ApplicationConditionFragment], getQuestion))
            .join(" \n\tOR\n ")})`;
        default:
          logNever(condition);
          return "";
      }
    })
    .join("\n    AND\n");
}

export function formatConditionsInput(
  conditions: ApplicationConditionInput[],
  getQuestion: (id: string) => ClobQuestionFragment | null
): string | undefined {
  if (!conditions || conditions.length === 0) {
    return undefined;
  }

  return conditions
    .map((condition: ApplicationConditionInput) => {
      const key = Objects.keys(Objects.removeUndefined(condition))[0];

      switch (key) {
        case "booleanCondition": {
          const booleanCondition = condition.booleanCondition as ApplicationConditionBooleanInput;
          const question = "subject" in booleanCondition ? getQuestion(booleanCondition?.subject ?? "") : undefined;
          return `"${question?.text}" \n\t${Strings.titleCase(booleanCondition.booleanOperator)}`;
        }
        case "stringCondition": {
          const stringCondition = condition.stringCondition as ApplicationConditionStringInput;
          const question = "subject" in stringCondition ? getQuestion(stringCondition?.subject ?? "") : undefined;
          return `"${question?.text}" \n\t${Strings.titleCase(stringCondition.stringOperator)} \n"${
            stringCondition.stringValue
          }"`;
        }
        case "stringSetCondition": {
          const stringSetCondition = condition.stringSetCondition as ApplicationConditionStringSetInput;
          const question = "subject" in stringSetCondition ? getQuestion(stringSetCondition?.subject ?? "") : undefined;
          return `"${question?.text}" \n\t${Strings.titleCase(stringSetCondition.stringSetOperator)} \n${
            stringSetCondition.stringSetValue
          }`;
        }
        case "numberCondition": {
          const numberCondition = condition.numberCondition as ApplicationConditionNumberInput;
          const question = "subject" in numberCondition ? getQuestion(numberCondition?.subject ?? "") : undefined;
          return `"${question?.text}" \n\t${Strings.titleCase(numberCondition.numberOperator)} \n${
            numberCondition.numberValue
          }`;
        }
        case "matchAllCondition": {
          const matchAllCondition = condition.matchAllCondition as ApplicationConditionMatchAllInput;
          return `(${matchAllCondition.matchAllConditions
            .map((condition) => formatConditionsInput([condition], getQuestion))
            .join(" \n\tAND\n ")})`;
        }
        case "matchAnyCondition": {
          const matchAnyCondition = condition.matchAnyCondition as ApplicationConditionMatchAnyInput;
          return `(${matchAnyCondition.matchAnyConditions
            .map((condition) => formatConditionsInput([condition], getQuestion))
            .join(" \n\tOR\n ")})`;
        }
        default:
          logNever(key);
          return "";
      }
    })
    .join("\n    AND\n");
}

export function getAllSubjects(conditions: ApplicationConditionInput[]): string[] {
  if (!conditions || conditions.length === 0) {
    return [];
  }

  return conditions.flatMap((condition: ApplicationConditionInput) => {
    const key = Objects.keys(Objects.removeUndefined(condition))[0];

    switch (key) {
      case "booleanCondition": {
        const booleanCondition = condition.booleanCondition as ApplicationConditionBooleanInput;
        return [booleanCondition.subject];
      }
      case "stringCondition": {
        const stringCondition = condition.stringCondition as ApplicationConditionStringInput;
        return [stringCondition.subject];
      }
      case "stringSetCondition": {
        const stringSetCondition = condition.stringSetCondition as ApplicationConditionStringSetInput;
        return [stringSetCondition.subject];
      }
      case "numberCondition": {
        const numberCondition = condition.numberCondition as ApplicationConditionNumberInput;
        return [numberCondition.subject];
      }
      case "matchAllCondition": {
        const matchAllCondition = condition.matchAllCondition as ApplicationConditionMatchAllInput;
        return matchAllCondition.matchAllConditions.flatMap((cond) => getAllSubjects([cond]));
      }
      case "matchAnyCondition": {
        const matchAnyCondition = condition.matchAnyCondition as ApplicationConditionMatchAnyInput;
        return matchAnyCondition.matchAnyConditions.flatMap((cond) => getAllSubjects([cond]));
      }
      default:
        return [];
    }
  });
}
