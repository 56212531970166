import { Theme, ThemeOptions } from "@mui/material";

const maxFormWidth = 800;
const contentSidebarWidth = 272;
const carrierNavWidth = 320;
const height = 65;

const gap = {
  xs: 24,
  md: 32,
  lg: 48,
  xl: 64,
};

export function createMixins(theme: Theme): ThemeOptions {
  return {
    mixins: {
      carrierNavWidth: {
        width: `${carrierNavWidth}px`,
        maxWidth: `${carrierNavWidth}px`,
        minWidth: `${carrierNavWidth}px`,
      },
      maxFormWidth: {
        maxWidth: `${maxFormWidth}px`,
        width: "100%",
      },
      contentWidth: {
        maxWidth: `${maxFormWidth}px`,
        width: `calc(100vw - ${contentSidebarWidth}px - ${carrierNavWidth}px - ${gap.xs * 3}px)`,
        [theme.breakpoints.up("md")]: {
          width: `calc(100vw - ${contentSidebarWidth}px - ${carrierNavWidth}px - ${gap.md * 3}px)`,
        },
        [theme.breakpoints.up("lg")]: {
          width: `calc(100vw - ${contentSidebarWidth}px - ${carrierNavWidth}px - ${gap.lg * 3}px)`,
        },
        [theme.breakpoints.up("xl")]: {
          width: `calc(100vw - ${contentSidebarWidth}px - ${carrierNavWidth}px - ${gap.xl * 3}px)`,
        },
      },
      contentSidebarWidth: {
        width: `${contentSidebarWidth}px`,
      },
      contentContainerWidth: {
        flex: `0 1 ${maxFormWidth + gap.lg + contentSidebarWidth}px`,
      },
      contentContainerShim: {
        pr: {
          xs: `${contentSidebarWidth + gap.xs}px`,
          md: `${contentSidebarWidth + gap.md}px`,
          lg: `${contentSidebarWidth + gap.lg}px`,
          xl: `${contentSidebarWidth + gap.xl}px`,
        },
      },
      responsivePx: {
        padding: `0 ${gap.xs}px`,
        [theme.breakpoints.up("md")]: {
          padding: `0 ${gap.md}px`,
        },
        [theme.breakpoints.up("lg")]: {
          padding: `0 ${gap.lg}px`,
        },
        [theme.breakpoints.up("xl")]: {
          padding: `0 ${gap.xl}px`,
        },
      },
      responsiveColumns: {
        gap: `${gap.xs}px`,
        padding: `0 ${gap.xs}px`,
        [theme.breakpoints.up("md")]: {
          gap: `${gap.md}px`,
          padding: `0 ${gap.md}px`,
        },
        [theme.breakpoints.up("lg")]: {
          gap: `${gap.lg}px`,
          padding: `0 ${gap.lg}px`,
        },
        [theme.breakpoints.up("xl")]: {
          gap: `${gap.xl}px`,
          padding: `0 ${gap.xl}px`,
        },
      },
      entityWidth: {
        left: `${carrierNavWidth}px`,
        top: `${height}px`,
      },
    },
  };
}

declare module "@mui/material/styles/createMixins" {
  interface Mixins {
    carrierNavWidth?: CSSProperties;
    contentWidth?: CSSProperties;
    contentSidebarWidth?: CSSProperties;
    contentContainerWidth?: CSSProperties;
    contentContainerShim?: CSSProperties;
    maxFormWidth?: CSSProperties;
    responsiveColumns?: CSSProperties;
    responsivePx?: CSSProperties;
    entityWidth?: CSSProperties;
  }
}
