import { Divider, Stack } from "@mui/material";
import React from "react";
import { ClobQuestionFragment } from "../../../generated/graphql";
import { PropertyList } from "../../properties/PropertyList";
import { acordLabel } from "./acordLabel";
import { MatchingCurationQuestionsCard } from "./MatchingCurationQuestionsCard";

interface Props {
  clobQuestion: Omit<ClobQuestionFragment, "visibility">;
  visibility?: string;
  search?: string;
  matchingCurationQuestions?: boolean;
}

/**
 * Re-usable card component that shows the details of a CLOB question.
 */
export const ClobQuestionDetailsCard: React.FC<Props> = ({
  clobQuestion,
  visibility,
  search,
  matchingCurationQuestions = true,
}) => {
  const {
    id,
    text,
    conditions,
    componentType,
    helperText,
    curationQuestion,
    options,
    mappingNotes,
    acordELabelId,
    acordELabelCounter,
    defaultValue,
    infoText,
    isRequired,
    missingQuestion,
  } = clobQuestion;

  return (
    <Stack>
      <PropertyList
        searchQuery={search}
        pairs={[
          ["Text", text],
          ["Options", options?.join(", ")],
          ["ACORD Mapping", acordLabel(acordELabelId, acordELabelCounter)],
          ["Component Type", componentType],
          ["Helper Text", helperText],
          ["Conditions", conditions],
          ["Mapping Notes", mappingNotes],
          ["Popover Text", infoText],
          ["Default value", defaultValue],
          ["Required", isRequired.toString()],
          ["Conditions", visibility],
          ["Carrier", missingQuestion?.carrierSlug],
          ["LoB", missingQuestion?.lineOfBusiness],
          ["NAICS", missingQuestion?.naics],
          ["Additional Info", missingQuestion?.additionalInfo],
        ]}
      />
      <Divider sx={{ pt: 2, mb: 2 }} />
      {curationQuestion && (
        <PropertyList
          searchQuery={search}
          pairs={[
            ["Mapped to", curationQuestion.text],
            ["Key", curationQuestion.key],
          ]}
        />
      )}
      {!curationQuestion && matchingCurationQuestions && (
        <MatchingCurationQuestionsCard matchBy={{ type: "clobQuestionId", clobQuestionId: id }} />
      )}
    </Stack>
  );
};
