import { useToaster } from "@cp/theme";
import { Button, Stack, Tooltip } from "@mui/material";
import React from "react";
import { getActions } from "../evaluate";
import { ItemType } from "../types";

interface Props {
  items: Record<ItemType, string[]>;
}

/**
 * Render a group of buttons that are dynamically generated based on the items.
 */
export const DynamicActionButtonGroup: React.FC<Props> = (props) => {
  const actions = getActions(props.items);
  const { toast } = useToaster();

  if (actions.length === 0) {
    return null;
  }

  return (
    <Stack direction="row" spacing={1}>
      {actions.map((action) => {
        return (
          <Tooltip title={action.description} key={action.id}>
            <Button
              key={action.id}
              onClick={() =>
                action.run(props.items).then((result) => {
                  if (result) {
                    toast(`${result.actionName} ${result.count} question(s)`);
                  }
                })
              }
              variant="contained"
              color={action.intent || "primary"}
              size="small"
              sx={{ mr: 1 }}
            >
              {action.label}
            </Button>
          </Tooltip>
        );
      })}
    </Stack>
  );
};
