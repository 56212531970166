import { Button } from "@mui/material";
import { useGoldenQuestionDrawer, useShowGoldenQuestionDrawerButtonInActionBar } from "../panels/atoms";

export const GoldenQuestionDrawerButton = () => {
  const [openDrawer, setOpenDrawer] = useGoldenQuestionDrawer();
  const [showbutton] = useShowGoldenQuestionDrawerButtonInActionBar();
  return (
    <>
      {showbutton && (
        <Button
          size="small"
          variant="contained"
          color="info"
          onClick={() => {
            setOpenDrawer(!openDrawer);
          }}
        >
          {openDrawer ? "Close Drawer" : "Map Golden Questions"}
        </Button>
      )}
    </>
  );
};
