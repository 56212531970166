import { PaletteMode } from "@mui/material";
import React, { PropsWithChildren } from "react";

const ColorMode = React.createContext({
  mode: "light" as PaletteMode,
  toggleColorMode: () => {
    return;
  },
  setColorMode: (mode: PaletteMode) => {
    return;
  },
});

export const useColorMode = () => React.useContext(ColorMode);

export const ColorModeProvider: React.FC<PropsWithChildren<{}>> = ({ children }) => {
  const [mode, setMode] = React.useState<PaletteMode>("light");

  const colorMode = {
    mode,
    toggleColorMode: () => setMode((mode) => (mode === "light" ? "dark" : "light")),
    setColorMode: setMode,
  };

  return <ColorMode.Provider value={colorMode}>{children}</ColorMode.Provider>;
};
