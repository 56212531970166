import { LoadState } from "@cp/theme";
import { Box, Drawer, Stack, Typography } from "@mui/material";
import { useMatch } from "@tanstack/react-location";
import React, { useEffect } from "react";
import { LocationGenerics } from "../../App";
import { Card } from "../../components/Card";
import { MatchingGoldenQuestionsCard } from "../../components/cards/clob-questions/MatchingGoldenQuestionsCard";
import { CurationNodeSearch } from "../../components/CurationNodeSearch";
import { useGoldenQuestionDrawer, useSelectedRecordingId } from "../../components/panels/atoms";
import { Panels } from "../../components/panels/Panels";
import { ScreenCaptureViewer } from "../../components/recordings/ScreenCaptureViewer";
import { GOLDEN_CURATION_APPLICATION_ID } from "../../constants";
import { useCarrierProjectQuery } from "../../generated/graphql";
import { CurationControls } from "../clobs/MapClob/components/CurationControls";
import { CurationProvider } from "../curation/state/CurationProvider";
import { CurationContextFeature } from "../curation/state/types";
import { useSetCurationApplicationToCarrierLineOfBusiness } from "../hooks/useSetCurationApplicationToCarrierLineOfBusiness";
import { AnnotationsPanel } from "./annotations/AnnotationsPanel";
import { RecordingsNavbar } from "./recordings/RecordingsNavbar";

export const AnnotateProjectPage: React.FC = () => {
  const [selectedRecording, setSelectedRecording] = useSelectedRecordingId();
  const {
    params: { projectId },
  } = useMatch<LocationGenerics>();

  const [openDrawer, setOpenDrawer] = useGoldenQuestionDrawer();

  const { data, error, loading } = useCarrierProjectQuery({
    variables: { id: projectId },
  });
  useSetCurationApplicationToCarrierLineOfBusiness(data?.carrierProject ?? {});

  useEffect(() => {
    !selectedRecording && setSelectedRecording(recordings[0]?.id);
  }, [data, selectedRecording]);

  const [viewerMode, setViewerMode] = React.useState<"view" | "edit">("edit");

  if (!data) {
    return <LoadState error={error} loading={loading} />;
  }
  const recordings = data.carrierProject.carrierRecordings;

  return (
    <>
      <Box sx={{ padding: "15px", backgroundColor: "grey.200" }}>
        <Typography>
          Select question and answer text in the interactive portal screen below to transcribe it - copying it into
          Ontolio. You’ll be guided in entering details about each question and answer, then mapping it into the
          Question Library.
        </Typography>
      </Box>
      <Panels
        id="projects"
        config={[
          {
            title: "Recording",
            content: (
              <Card loading={loading} sx={{ justifyContent: "flex-start", overflow: "auto", display: "flex", flex: 1 }}>
                {selectedRecording && (
                  <>
                    <RecordingsNavbar recordings={recordings} viewerMode={viewerMode} setViewerMode={setViewerMode} />
                    <ScreenCaptureViewer key={selectedRecording} recordingId={selectedRecording} mode={viewerMode} />
                  </>
                )}
              </Card>
            ),
          },
          {
            title: "This Screen's Transcriptions",
            content: <AnnotationsPanel />,
          },
          {
            title: "Application",
            noPadding: true,
            content: (
              <Stack gap={4} sx={{ display: "flex", maxHeight: "100%" }}>
                <Stack sx={{ flex: 1, overflow: "auto" }}>
                  <CurationNodeSearch />
                  <CurationControls curationContext={CurationContextFeature.ANNOTATION_MAPPING}>
                    <Drawer
                      anchor="right"
                      open={openDrawer}
                      PaperProps={{ sx: { p: 2, width: "40%" } }}
                      onClose={() => setOpenDrawer(!openDrawer)}
                    >
                      <CurationProvider>
                        <Stack py={1}>
                          <MatchingGoldenQuestionsCard />
                        </Stack>
                        <CurationNodeSearch />
                        <CurationControls
                          lockedCurationApplicationId={GOLDEN_CURATION_APPLICATION_ID}
                          curationContext={CurationContextFeature.GOLDEN_QUESTION_LIBRARY}
                        />
                      </CurationProvider>
                    </Drawer>
                  </CurationControls>
                </Stack>
              </Stack>
            ),
          },
        ]}
      />
    </>
  );
};
