import React from "react";
import { SAPortalRecordingTable } from "../../../components/recordings/SACapturesRecordingTable";
import { useRecordingSessionsQuery } from "../../../generated/graphql";

export const SAActivityRecordingsPage: React.FC = () => {
  const { data, loading, error } = useRecordingSessionsQuery({ variables: { input: { fromSubmission: true } } });

  const sessions = data?.recordingSessions ?? [];
  return <SAPortalRecordingTable sessions={sessions} loading={loading} error={error} />;
};
