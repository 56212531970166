import { FormBuilder, FormyForm } from "@cp/forms";
import { TimeAgo, useToaster } from "@cp/theme";
import { Functions } from "@cp/toolkit";
import { UniqueIdentifier } from "@dnd-kit/core";
import { Download, Redo, Save, Undo, UnfoldLess, UnfoldMore, Upload } from "@mui/icons-material";
import { Box, Button, ButtonGroup, Chip, FormControlLabel, Stack, Switch } from "@mui/material";
import React from "react";
import { ClobFragment, useUpdateClobApplicationMutation } from "../../../generated/graphql";
import { useClobContext } from "../../../model/clobs/context";
import {
  deserializeClob,
  deserializeClobFromString,
  serializeClobForUpdate,
  stringifyClob,
} from "../../../model/clobs/deserialize";
import { ClobTreeItem } from "../../../model/clobs/types";
import { download, imperativeUpload } from "../../../util/download";

interface Props {
  clob: ClobFragment;

  draggable: boolean;
  onDraggableChange: (value: boolean) => void;
}

interface FormValues {
  name: string;
  lineOfBusiness: string;
}

const FORM = FormBuilder.objectOf<FormValues>({
  name: FormBuilder.string().options({ label: "Name" }),
  lineOfBusiness: FormBuilder.string().options({ label: "Line of Business" }),
}).options({ rowFlex: [1, 1] });

export const ClobDetailsHeader: React.VFC<Props> = ({ clob, draggable, onDraggableChange }) => {
  const {
    actions: { undo, redo, canUndo, canRedo, setState, expandAll, collapseAll, updateState },
    state,
  } = useClobContext();
  const { toast } = useToaster();

  const [saveApplication] = useUpdateClobApplicationMutation();

  return (
    <Stack direction="row" pb={1} px={2} pt={2} sx={{ backgroundColor: "white" }} boxShadow={1} zIndex={2}>
      <FormyForm
        id="clob-details"
        form={FORM}
        defaultValues={{ name: clob.name, lineOfBusiness: clob.lineOfBusiness }}
        onSubmit={Functions.NOOP}
        onChange={(newState) => {
          updateState(newState);
        }}
      />
      <Stack px={2} justifyContent="space-evenly">
        <Stack direction="row" divider={<span>•</span>} alignItems="center" spacing={2}>
          <span>
            Last updated:{" "}
            <b>
              <TimeAgo date={clob?.updatedAt} />
            </b>
          </span>
          <span>{clob?.carrierSlug}</span>
          <span>{clob?.lineOfBusiness}</span>
        </Stack>
        <Stack direction="row" spacing={2} alignItems="center">
          <Chip size="small" color="primary" label={`${state.sections.size} sections`} />
          <Chip size="small" color="primary" label={`${state.questions.size} questions`} />
        </Stack>
      </Stack>
      <Box flex={2} />
      <Stack direction="row" gap={2} alignItems="center">
        <Button
          size="small"
          color="success"
          startIcon={<Save />}
          onClick={async () => {
            await saveApplication({
              variables: { input: serializeClobForUpdate(state) },
              onCompleted: (data) => {
                toast("Successfully saved CLOB application");
                setState(deserializeClob(data.updateClobApplication, mapCollapseState(state.tree)));
              },
            });
          }}
        >
          Save
        </Button>
        <ButtonGroup size="small">
          <Button startIcon={<UnfoldLess />} onClick={collapseAll}>
            Collapse
          </Button>
          <Button startIcon={<UnfoldMore />} onClick={expandAll}>
            Expand
          </Button>
        </ButtonGroup>
        <ButtonGroup size="small">
          <Button
            startIcon={<Upload />}
            onClick={async () => {
              const contents = await imperativeUpload();
              setState(deserializeClobFromString(contents));
            }}
          >
            Import
          </Button>
          <Button
            startIcon={<Download />}
            onClick={() => {
              download(`${clob.name}.json`, stringifyClob(state));
            }}
          >
            Export
          </Button>
        </ButtonGroup>
        <ButtonGroup size="small">
          <Button startIcon={<Undo />} disabled={!canUndo} onClick={undo}>
            Undo
          </Button>
          <Button startIcon={<Redo />} disabled={!canRedo} onClick={redo}>
            Redo
          </Button>
        </ButtonGroup>
        <FormControlLabel
          control={<Switch size="small" checked={draggable} onClick={() => onDraggableChange(!draggable)} />}
          sx={{ userSelect: "none" }}
          componentsProps={{
            typography: {
              variant: "caption",
            },
          }}
          label="Draggable"
        />
      </Stack>
    </Stack>
  );
};

const mapCollapseState = (tree: ClobTreeItem[] = [], collapseState: Map<UniqueIdentifier, boolean> = new Map()) => {
  for (const item of tree) {
    if (item.type === "question") {
      continue;
    } // questions don't have a collapse state, or children

    collapseState.set(item.id, item.collapsed ?? false);
    mapCollapseState(item.children, collapseState);
  }

  return collapseState;
};
