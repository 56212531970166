import { Stack, Typography } from "@mui/material";
import { Column, createTableConfig, Table } from "@qw/table";
import React, { PropsWithChildren, useContext, useMemo } from "react";
import { InfoTextPopover } from "../../form-fields/common/InfoTextPopover";
import { ApplicationCondition, ApplicationConditions } from "../../form-fields/conditions/ApplicationConditions";
import { FormContainerFragment, FormQuestionFragment, FormTreeItemFragment } from "../../generated/operations";
import { DisabledCell } from "../common/DisabledCell";
import { formatCellValue } from "../common/formatCell";
import { TableWrapper } from "../common/TableWrapper";
import { getDataTypeForComponentType } from "../common/utils";
import {
  FormContainerId,
  FormTreeItemId,
  PivotSectionTableManager,
  PivotSectionTableManagerContext,
} from "./PivotSectionTableManager";
import { useFormTableProps } from "./useFormTableProps";

interface Props {
  title: string;
  containers: FormContainerFragment[];
  questions: FormQuestionFragment[];
  treeItems: FormTreeItemFragment[];
  readonly: boolean;
  useConditionalRows: boolean;
  helperText?: string | null;
}

/**
 * A table that displays the data by pivoting the sections as rows and the questions as columns.
 * This will group common questions together and display them in the same column.
 */
export const PivotSectionTable: React.FC<Props> = ({
  title,
  containers,
  questions,
  treeItems,
  readonly = false,
  useConditionalRows = false,
  helperText,
}) => {
  const { getValues, onSaveValue } = useFormTableProps({ readonly });

  const manager = useMemo(() => {
    return new PivotSectionTableManager({
      formContainers: containers,
      formQuestions: questions,
      formTreeItems: treeItems,
    });
  }, [questions, containers, treeItems]);

  /**
   * Small helper to get the value of a field for a given cell.
   */
  const getValue = (fieldId: string | undefined) => {
    if (!fieldId) {
      return "";
    }

    const columnValues = getValues(fieldId);
    if (Array.isArray(columnValues)) {
      console.error("Cannot get value for a field that is an array", fieldId);
      return columnValues[0];
    }
    return columnValues;
  };

  const { columns } = useMemo(() => {
    const columnNames = manager.getColumnNames();

    let config = createTableConfig<FormContainerId>();
    // add first column
    config = config.addColumn({
      id: "_headers_",
      header: () => <></>, // empty header
      flex: 1,
      minWidth: 150,
      render: ({ data: rowId }) => {
        const label = manager.getRowLabel(rowId);
        return (
          <Typography px={2} fontSize={12} fontWeight={600} lineHeight="32px" color="text.primary">
            {label}
          </Typography>
        );
      },
    });

    for (const columnName of columnNames) {
      const question = manager.findFirstQuestion(columnName);
      const dataType = getDataTypeForComponentType(question.componentType);

      const editableConfig: Column<FormTreeItemId>["editable"] = {
        required: question.isRequired,
        dataType: dataType,
        options: {
          optionType: "PerCell",
          items: manager.getColumnOptions(columnName),
        },
        getInitialValue: (rowId) => {
          const questionId = manager.getCell(rowId, columnName)?.id;
          return getValue(questionId);
        },
        onEdit: ({ data: rowId, value }) => {
          const questionId = manager.getCell(rowId, columnName)?.id;
          if (!questionId) {
            return;
          }
          onSaveValue?.(questionId, value);
        },
        cellNotEditable: (rowId) => {
          return !manager.getCell(rowId, columnName);
        },
      };
      config = config.addColumn({
        id: question.id,
        header: () => {
          return (
            <Stack direction="row" px={2}>
              <b>{question.label}</b>
              {question.isRequired && "*"}
              {question.infoText && <InfoTextPopover infoText={question.infoText} />}
            </Stack>
          );
        },
        flex: 1,
        minWidth: 150,
        render: ({ data: rowId }) => {
          const question = manager.getCell(rowId, columnName);
          if (!question) {
            return <DisabledCell />;
          }
          const value = getValue(question.id);
          if (readonly) {
            return <>{formatCellValue(value, dataType)}</>;
          }

          return (
            <ApplicationConditions conditions={question.visibility} fallback={<DisabledCell />}>
              {formatCellValue(value, dataType)}
            </ApplicationConditions>
          );
        },
        backgroundColor: ({ data: rowId }) => {
          return manager.getCell(rowId, columnName) ? "transparent" : "grey.200";
        },
        editable: readonly ? undefined : editableConfig,
      });
    }
    return config.build();
  }, [manager]);

  const table = (
    <TableWrapper title={title} helperText={helperText}>
      <Table
        RowWrapper={useConditionalRows ? TableRowConditions : undefined}
        columns={columns}
        data={manager.getRowIds()}
        loading={false}
        error={undefined}
        onAdd={undefined}
      />
    </TableWrapper>
  );

  return useConditionalRows ? (
    <PivotSectionTableManagerContext.Provider value={manager}>
      <TableConditions manager={manager}>{table}</TableConditions>
    </PivotSectionTableManagerContext.Provider>
  ) : (
    table
  );
};

const TableRowConditions: React.FC<PropsWithChildren<{ rowId: string }>> = ({ children, rowId }) => {
  const manager = useContext(PivotSectionTableManagerContext);
  const conditions = manager.getRowVisibility(rowId) || [];
  return <ApplicationConditions conditions={conditions}>{children}</ApplicationConditions>;
};

const TableConditions: React.FC<
  PropsWithChildren<{
    manager: PivotSectionTableManager;
  }>
> = ({ children, manager }) => {
  // We want to show a table when any of the row is visible. Hence we need a disjunction of each row condition.
  const rowConditions: ApplicationCondition[] = manager
    .getRowIds()
    .map((r) => manager.getRowVisibility(r) || [])
    .map((conditions) => {
      return {
        // Conjunction of conditions
        __typename: "ApplicationConditionMatchAll",
        matchAllConditions: conditions,
      };
    });

  const anyRowCondition: ApplicationCondition = {
    __typename: "ApplicationConditionMatchAny",
    matchAnyConditions: rowConditions,
  };

  return <ApplicationConditions conditions={[anyRowCondition]}>{children}</ApplicationConditions>;
};
