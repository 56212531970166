import React from "react";
import { useCurationQuestionQuery } from "../../../generated/graphql";
import { SectionBreadcrumbs } from "../../../pages/curation/ApplicationLayout/SectionBreadcrumbs";
import { Card } from "../../Card";
import { PropertyList } from "../../properties/PropertyList";

interface Props {
  curationQuestionId: string;
}

export const CurationQuestionPropertiesCard: React.FC<Props> = ({ curationQuestionId }) => {
  const { data } = useCurationQuestionQuery({
    variables: { id: curationQuestionId },
  });

  if (!data) {
    return <Card loading={true} />;
  }

  const { text, key, componentType, options, id } = data.curationQuestion;

  return (
    <Card>
      <SectionBreadcrumbs nodeId={id} />
      <PropertyList
        pairs={[
          ["Question", text],
          ["Key", key],
          ["Component Type", componentType],
          ["Options", options.join(", ")],
          ["ID", id],
        ]}
      />
    </Card>
  );
};
