import { SxProps, Theme } from "@mui/material";
import React from "react";
import { ControllerProps } from "react-hook-form";

export interface FormFieldProps {
  // Used for autofocus
  id?: string;
  required?: boolean;
  label: React.ReactNode;
  name: string;
  sx?: SxProps<Theme>;
  ControllerProps?: Omit<ControllerProps, "render" | "name" | "control">;
  options?: Array<{
    label: React.ReactNode;
    value: string;
  }>;
  /**
   * Only update the form value when the field is blurred.
   */
  updateOnBlur?: boolean;
  /**
   * Helper text to display below the field.
   */
  helperText?: React.ReactNode;
  /**
   * Info text to display in a popover next to the field.
   */
  infoText?: string;
  disabled?: boolean;
  defaultValue?: string;
}

export interface FormFieldDateProps extends FormFieldProps {
  clampMin?: boolean;
  clampMax?: boolean;
}

export function processFormFieldProps<TProps extends FormFieldProps>({
  id,
  label,
  name,
  required,
  ControllerProps = {},
  ...rest
}: TProps) {
  return {
    id,
    label,
    ControllerProps: {
      rules: { required },
      ...ControllerProps,
      name,
    },
    fullWidth: true,
    name,
    required,
    ...rest,
  };
}
