import { Alert, AlertTitle, Box, BoxProps, CircularProgress, Table as MuiTable } from "@mui/material";
import { Column } from "../core/Column";
import { Row } from "../core/Row";
import { StyledTableContainer } from "./components";
import { EmptyTable } from "./EmptyTable";
import { LoadingTableBody } from "./LoadingTableBody";
import { PaginationFooter } from "./pagination/PaginationFooter";
import { PaginationProps } from "./pagination/props";
import { SortingProps } from "./sorting/props";
import { TableBody } from "./TableBody";
import { TableHeader } from "./TableHeader";
import { useScrollShadow } from "./useScrollShadow";

type Maybe<T> = T | null;

export interface TableProps<T> {
  row?: Row<T>;
  RowWrapper?: React.FC<
    React.PropsWithChildren<{
      rowId: T;
    }>
  >;
  columns: Array<Column<T>>;
  flex?: boolean;

  data: Maybe<readonly T[]> | undefined;
  loading: boolean;
  error: Error | undefined;

  /**
   * @default 'No data'
   */
  emptyText?: string;
  emptyAction?: React.ReactNode;

  pagination?: PaginationProps;
  sorting?: SortingProps;

  sx?: BoxProps["sx"];

  /**
   * Will add a button at the bottom of the table to add a new row.
   */
  onAdd?: () => void;
}

const PAGE_SIZE = 10;

const LoadingOverlay = () => (
  <Box
    zIndex={1}
    display="flex"
    alignItems="center"
    justifyContent="center"
    position="absolute"
    top={0}
    left={0}
    right={0}
    bottom="60%"
  >
    <CircularProgress />
  </Box>
);

export function Table<T>({
  row = { getRowStyles: () => ({}) },
  RowWrapper,
  columns,
  flex,
  data,
  loading,
  error,
  emptyText = "No Data",
  emptyAction,
  pagination,
  sorting,
  onAdd,
  sx,
}: TableProps<T>) {
  const reloading = loading && Boolean(data);
  const { shadowStyles, onScroll } = useScrollShadow();

  if (error) {
    return (
      <Alert severity="error">
        <AlertTitle>Error</AlertTitle>
        {error.message}
      </Alert>
    );
  }

  const empty = (!data || data.length === 0) && !loading;

  if (empty) {
    return <EmptyTable emptyText={emptyText} emptyAction={emptyAction} />;
  }

  return (
    <Box sx={{ display: "flex", position: "relative", width: "100%", flex: 1, ...sx }}>
      <Box sx={shadowStyles} />
      <StyledTableContainer onScroll={onScroll}>
        {reloading && <LoadingOverlay />}
        <MuiTable sx={{ opacity: reloading ? 0.6 : 1 }}>
          <TableHeader columns={columns} {...sorting} flex={flex} />
          {data && (
            <TableBody data={data} row={row} columns={columns} flex={flex} onAdd={onAdd} RowWrapper={RowWrapper} />
          )}
          {loading && !reloading && <LoadingTableBody columns={columns} pageSize={PAGE_SIZE} flex={flex} />}
        </MuiTable>
        {pagination && <PaginationFooter {...pagination} />}
      </StyledTableContainer>
    </Box>
  );
}
