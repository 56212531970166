import type { Components, Theme } from "@mui/material/styles";

export function Tabs(theme: Theme): Components<Theme> {
  return {
    MuiTabs: {
      defaultProps: {
        className: "size-default",
      },
      styleOverrides: {
        root: {
          alignSelf: "stretch",
          marginBottom: "-1px",
          minHeight: "32px",
        },
        scroller: {
          alignItems: "stretch",
          display: "flex",
        },
        flexContainer: {
          alignSelf: "stretch",
          gap: "24px",
          ".MuiTabs-root.size-large &": {
            gap: "48px",
          },
        },
      },
    },
    MuiTab: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          flex: "auto",
          letterSpacing: "-0.02em",
          margin: "0 !important",
          minHeight: "32px",
          minWidth: 0,
          overflow: "visible",
          padding: "0",
          textTransform: "none",
          ".MuiTab-iconWrapper.birdseed": {
            color: theme.palette.text.disabled,
            pointerEvents: "none",
            position: "absolute",
            right: "100%",
          },
          ".MuiTabs-root.size-default &": {
            fontSize: "11px",
            fontWeight: 700,
            ".MuiTab-iconWrapper.birdseed": {
              fontSize: "8px",
              marginRight: "8px",
            },
          },
          ".MuiTabs-root.size-large &": {
            fontSize: "16px",
            fontWeight: 500,
            ".MuiTab-iconWrapper.birdseed": {
              fontSize: "16px",
              marginRight: "16px",
            },
          },
        },
      },
    },
  };
}
