import { Components, Theme } from "@mui/material";
import { Alert } from "./Alert";
import { Avatar } from "./Avatar";
import { Button } from "./Button";
import { CssBaseline } from "./CssBaseline";
import { Dialog } from "./Dialog";
import { Drawer } from "./Drawer";
import { FormInput } from "./FormInput";
import { FormLabel } from "./FormLabel";
import { List } from "./List";
import { Menu } from "./Menu";
import { Pagination } from "./Pagination";
import { Paper } from "./Paper";
import { Table } from "./Table";
import { Tabs } from "./Tabs";

export function componentsOverrides(theme: Theme): Components<Theme> {
  return {
    ...Alert(theme),
    ...Avatar(theme),
    ...Button(theme),
    ...CssBaseline(theme),
    ...Dialog(theme),
    ...Drawer(theme),
    ...FormInput(theme),
    ...FormLabel(theme),
    ...List(theme),
    ...Menu(theme),
    ...Pagination(theme),
    ...Paper(theme),
    ...Table(theme),
    ...Tabs(theme),
  };
}
