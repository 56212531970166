import { Box, styled, TableCell, TableHead, TableRow, TableSortLabel, Typography } from "@mui/material";
import { visuallyHidden } from "@mui/utils";
import React from "react";
import { Column } from "../core/Column";
import { SortingProps, SortOrder } from "./sorting/props";

interface Props extends SortingProps {
  columns: Array<Column<any>>;
  flex?: boolean;
}

export const TableHeader: React.VFC<Props> = ({
  columns,
  flex,
  orderBy: sortOrderBy,
  order: sortDirection,
  onSortChange,
}) => {
  const handleSortChange = (columnId: string) => {
    if (!onSortChange) {
      return;
    }

    if (sortOrderBy === columnId) {
      onSortChange(columnId, sortDirection === SortOrder.Asc ? SortOrder.Desc : SortOrder.Asc);
    } else {
      onSortChange(columnId, SortOrder.Asc);
    }
  };

  const renderHeader = (column: Column<any>) => {
    if (typeof column.header === "function") {
      return column.header({
        id: column.id,
        sortable: !!column.sortable,
      });
    }
    return <HeaderTypography>{column.header}</HeaderTypography>;
  };

  return (
    <TableHead>
      <TableRow sx={{ display: flex ? "flex" : undefined }}>
        {columns.map((column) => (
          <TableCell
            key={column.id}
            align={column.align}
            variant="head"
            sx={{
              backgroundColor: "transparent",
              maxWidth: column.maxWidth,
              width: column.maxWidth,
              minWidth: column.minWidth,
              flex: column.flex ?? 1,
            }}
          >
            {column.sortable ? (
              <TableSortLabel
                active={sortOrderBy === column.id}
                direction={sortOrderBy === column.id ? sortDirection : "asc"}
                onClick={() => handleSortChange(column.id)}
              >
                {renderHeader(column)}
                {sortOrderBy === column.id && (
                  <Box component="span" sx={visuallyHidden}>
                    {sortDirection === "desc" ? "sorted descending" : "sorted ascending"}
                  </Box>
                )}
              </TableSortLabel>
            ) : (
              renderHeader(column)
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

const HeaderTypography = styled(Typography)({
  fontWeight: 700,
  fontSize: "10px",
  lineHeight: "12px",
  letterSpacing: "-0.02em",
  textTransform: "uppercase",
  color: "text.secondary",
});
