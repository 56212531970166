import { InfoOutlined } from "@mui/icons-material";
import { Stack, SxProps, Typography } from "@mui/material";
import PopupState, { bindHover, bindPopover } from "material-ui-popup-state";
import HoverPopover from "material-ui-popup-state/HoverPopover";
import MuiMarkdown from "mui-markdown";
import React, { useState } from "react";

interface Props {
  center?: boolean;
  infoText: string;
}

export const InfoTextPopover: React.VFC<Props> = ({ center = true, infoText }) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget);
  const handlePopoverClose = () => setAnchorEl(null);

  infoText = infoText.replaceAll("\n", "  \n");
  return (
    <PopupState variant="popover" popupId="demo-popup-popover">
      {(popupState) => (
        <div>
          <Stack
            alignItems="center"
            height="24px"
            justifyContent="center"
            width="24px"
            sx={BoxSx(center)}
            {...bindHover(popupState)}
          >
            <InfoOutlined sx={{ color: "text.disabled", fontSize: "16px" }} />
          </Stack>
          <HoverPopover
            {...bindPopover(popupState)}
            sx={{ pointerEvents: "none" }}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <Typography sx={{ p: 2 }}>
              <MuiMarkdown>{infoText}</MuiMarkdown>
            </Typography>
          </HoverPopover>
        </div>
      )}
    </PopupState>
  );
};

const BoxSx = (center: boolean): SxProps => ({
  bottom: center ? 0 : undefined,
  margin: "auto 8px auto 0",
  position: "absolute",
  right: "100%",
  top: 0,
});
