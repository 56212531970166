import { Box } from "@mui/material";
import React, { PropsWithChildren } from "react";

interface Props {
  isOver: boolean;
  direction: "top" | "bottom" | undefined;
}

export const DraggableWrapper = React.memo(
  React.forwardRef<HTMLDivElement, PropsWithChildren<Props>>(({ isOver, direction, children }, ref) => {
    return (
      <Box
        ref={ref}
        sx={{
          position: "relative",
          zIndex: isOver ? 2 : 1,
          ":before": isOver
            ? {
                zIndex: 3,
                content: "''",
                display: "block",
                ...(direction === "top" ? { top: "-4px" } : { bottom: "-4px" }),
                left: "-4px",
                right: 0,
                height: "8px",
                position: "absolute",
                borderColor: "#2389ff",
                backgroundColor: "#56a1f8",
                borderRadius: "2px",
              }
            : undefined,
          ":after": isOver
            ? {
                zIndex: 4,
                content: '""',
                position: "absolute",
                left: "-8px",
                ...(direction === "top" ? { top: "-6px" } : { bottom: "-6px" }),
                display: "block",
                width: "12px",
                height: "12px",
                borderRadius: "50%",
                border: "1px solid #2389ff",
                backgroundColor: "#fff",
              }
            : undefined,
        }}
      >
        {children}
      </Box>
    );
  })
);

DraggableWrapper.displayName = "DraggableWrapper";
