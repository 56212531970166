import { FormBuilder, FormyForm, SaveFormProvider } from "@cp/forms";
import { Functions } from "@cp/toolkit";
import React from "react";
import {
  ApplicationComponentType,
  ClobQuestionFragment,
  useUpdateClobQuestionMutation,
} from "../../../generated/graphql";

interface Props {
  clobQuestion: ClobQuestionFragment;
}

const FORM = FormBuilder.objectOf({
  questionText: FormBuilder.string().options({ label: "Question Text" }),
  isRequired: FormBuilder.boolean().options({ label: "Is Required", defaultValue: false }).optional(),
  componentType: FormBuilder.enum(Object.values(ApplicationComponentType))
    .options({
      label: "Component Type",
      defaultValue: ApplicationComponentType.Text,
    })
    .optional(),
  options: FormBuilder.string().options({ textArea: true, label: "Options", updateOnBlur: true }).optional(),
  mappingNotes: FormBuilder.string().options({ textArea: true, label: "Mapping Notes", updateOnBlur: true }).optional(),
});

/**
 * Re-usable card component to edit fields of a ClobQuestion.
 */
export const ClobQuestionEditableFieldsCard: React.FC<Props> = ({ clobQuestion }) => {
  const [update] = useUpdateClobQuestionMutation();

  const { id, isRequired, options, componentType, text, mappingNotes } = clobQuestion;

  // special case to handle checkbox
  const imperativeSave = (key: string, val: unknown) => {
    if (key === "isRequired") {
      void update({
        variables: {
          clobQuestion: {
            id: id.toString(),
            isRequired: val as boolean,
          },
        },
      });
    }
  };

  return (
    <SaveFormProvider value={imperativeSave}>
      <FormyForm
        key={clobQuestion.id}
        form={FORM}
        onChange={(nextState) => {
          // Don't save if the question text is empty
          if (!nextState.questionText) {
            return;
          }

          void update({
            variables: {
              clobQuestion: {
                id: id.toString(),
                text: nextState.questionText,
                isRequired: nextState.isRequired,
                options: nextState.options?.split("\n").filter(Boolean) ?? [],
                componentType: nextState.componentType || undefined, // convert empty string to undefined
                mappingNotes: nextState.mappingNotes,
              },
            },
          });
        }}
        id={id.toString()}
        defaultValues={{
          questionText: text,
          isRequired: isRequired,
          options: options?.join("\n") ?? "",
          componentType: componentType,
          mappingNotes: mappingNotes ?? undefined,
        }}
        onSubmit={Functions.NOOP}
      />
    </SaveFormProvider>
  );
};
