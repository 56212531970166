import { useToaster } from "@cp/theme";
import { Box, Tooltip } from "@mui/material";
import { useRef } from "react";
import { useDrag } from "react-dnd";
import slugify from "slugify";
import {
  CarrierProjectDocument,
  CurationQuestionFragment,
  PortalAnnotationsDocument,
  useCreateCurationQuestionMutation,
  useUpdateClobQuestionMutation,
} from "../../generated/graphql";
import { useClobContext } from "../../model/clobs/context";
import { EditableClobQuestion } from "../../model/clobs/types";
import { conditionToInput } from "../../model/conditions/conditionToInput";
import {
  curationQuestionFragmentToInput,
  UpsertCurationQuestionInput,
} from "../../model/curation-questions/deserialize";
import { Dragable, DragTypes, DropResult } from "../../pages/curation/Dragables/DragTypes";
import { Handle } from "../sortables/components/Handle/Handle";

interface Props {
  clobQuestion: Pick<EditableClobQuestion, "id" | "text" | "options" | "componentType" | "clob">;
  renderDragHandle?: (ref: React.RefObject<HTMLButtonElement>) => React.ReactNode;
}

/**
 * This is a drag-handle that will create a curation question from a clob question when dropped,
 * and then insert it into the section it was dropped into.
 */
export function DirectCurationQuestionDragAndDrop({ clobQuestion, renderDragHandle }: Props) {
  const { actions } = useClobContext();

  const { toast } = useToaster();

  const [updateClobQuestion] = useUpdateClobQuestionMutation({
    // Refetch for updated counts
    refetchQueries: [PortalAnnotationsDocument, CarrierProjectDocument],
  });

  const [mutate] = useCreateCurationQuestionMutation({
    // Refetch for updated counts
    refetchQueries: [PortalAnnotationsDocument, CarrierProjectDocument],
  });

  const submit = (data: UpsertCurationQuestionInput) => {
    return mutate({
      variables: { input: data, force: true },
      refetchQueries: [],
      onCompleted: () => {
        toast("Curation question created successfully");
      },
    });
  };

  const ref = useRef<HTMLButtonElement>(null);

  const [, drag] = useDrag<Dragable, DropResult, { isDragging: boolean }>(
    {
      type: DragTypes.CLOB_QUESTION,
      item: {
        id: clobQuestion.id,
        type: DragTypes.CLOB_QUESTION,
      },
      end: async (item, monitor) => {
        if (monitor.didDrop()) {
          const dropResult = monitor.getDropResult();
          if (dropResult?.type === "create-curation-question") {
            const curationQuestion = await submit(
              curationQuestionFragmentToInput({
                key: toKey(clobQuestion),
                text: clobQuestion.text,
                options: clobQuestion.options ?? [],
                componentType: clobQuestion.componentType || undefined, // convert empty string to undefined
              })
            );
            const curationQuestionId = curationQuestion.data?.createCurationQuestion.id;
            if (curationQuestionId) {
              // Insert the curation question into the section
              dropResult.onInsert(curationQuestionId);
              // Map the curation question to the PDF question
              await handleCreateCurationMapping(curationQuestionId);
            }
          }
        }
      },
    },
    [clobQuestion]
  );

  drag(ref);

  const handleCreateCurationMapping = (curationQuestionId: CurationQuestionFragment["id"]) => {
    return updateClobQuestion({
      variables: { clobQuestion: { id: clobQuestion.id, curationQuestionId: curationQuestionId } },
      onCompleted: (data) => {
        actions.updateQuestion({
          ...data.updateClobQuestion,
          visibility: data.updateClobQuestion.visibility.map(conditionToInput),
        });
      },
    });
  };

  if (renderDragHandle) {
    return <>{renderDragHandle(ref)}</>;
  }

  return (
    <Tooltip title="Drag to a section to create a new curation question and link it.">
      <Box sx={{ backgroundColor: "rgba(201, 242, 155,0.5)", borderRadius: 2 }}>
        <Handle ref={ref} />
      </Box>
    </Tooltip>
  );
}

const toKey = (clobQuestion: Props["clobQuestion"]) => {
  const { carrierSlug, lineOfBusiness } = clobQuestion.clob || {};
  const carrier = carrierSlug ? `${carrierSlug.trim()}_` : "";
  const lob = lineOfBusiness ? `${lineOfBusiness.trim()}_` : "";
  return `${carrier}${lob}${slugify(clobQuestion.text)}`;
};
