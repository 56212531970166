import { FormBuilder, FormyForm, SaveFormProvider } from "@cp/forms";
import { useOnScreen } from "@cp/react-hooks";
import { Functions } from "@cp/toolkit";
import React, { useMemo } from "react";
import { CONDITIONS_FORM, QUESTIONS_OPTIONS_KEY } from "../../../components/curation-questions/ConditionsEditor";
import { ApplicationComponentType } from "../../../generated/graphql";
import { useClobContext } from "../../../model/clobs/context";
import { EditableClobQuestion } from "../../../model/clobs/types";

const FORM = FormBuilder.objectOf({
  rowOne: FormBuilder.objectOf({
    componentType: FormBuilder.enum(Object.values(ApplicationComponentType)).options({
      label: "Component Type",
    }),
    helperText: FormBuilder.string().options({ label: "Helper Text" }).optional(),
    infoText: FormBuilder.string().options({ label: "Popover Text" }).optional(),

    options: FormBuilder.string().options({ textArea: true, label: "Options", updateOnBlur: true }).optional(),
  }).options({ rowFlex: [1, 1, 1, 1] }),
  rowTwo: FormBuilder.objectOf({
    isRequired: FormBuilder.boolean().options({ label: "Is Required", defaultValue: false }),
    defaultValue: FormBuilder.string().options({ label: "Default Value" }).optional(),
    visibility: CONDITIONS_FORM.options({ label: "Conditions", itemName: "condition" }).optional(),
  }).options({ rowFlex: [1, 1, 2] }),
});

/**
 * Add react-memo to prevent re-rendering. Each update to the form should just re-render 1 ClobForm.
 */
const ClobForm = React.memo(
  ({
    id,
    question,
    onChange,
    nonce,
  }: {
    id: string | number;
    nonce: number;
    question: EditableClobQuestion | undefined;
    onChange: (opts: Partial<EditableClobQuestion> & { id: string }) => void;
  }) => {
    const [isVisible, ref] = useOnScreen<HTMLDivElement>("400px");
    const {
      state: { questions },
    } = useClobContext();
    const optionSets = useMemo(() => {
      return {
        [QUESTIONS_OPTIONS_KEY]: [...questions.values()],
      };
    }, [questions]);

    const imperativeSave = (key: string, val: unknown) => {
      const property = key.match(/(row(One|Two))\.(.*)/)?.[3];
      if (property) {
        onChange({
          id: id.toString(),
          [property]: val,
        });
      }
    };

    return (
      <SaveFormProvider value={imperativeSave}>
        <div ref={ref} style={{ minHeight: "50px" }}>
          {isVisible && (
            <FormyForm
              key={nonce}
              optionSets={optionSets}
              onChange={(nextState) => {
                onChange({
                  id: id.toString(),
                  ...nextState.rowOne,
                  ...nextState.rowTwo,
                  options: nextState.rowOne.options?.split("\n"),
                });
              }}
              id={id.toString()}
              form={FORM}
              defaultValues={{
                rowOne: {
                  componentType: question?.componentType,
                  helperText: question?.helperText ?? "",
                  infoText: question?.infoText ?? "",
                  options: question?.options?.join("\n") ?? "",
                },
                rowTwo: {
                  isRequired: question?.isRequired ?? false,
                  defaultValue: question?.defaultValue ?? "",
                  visibility: question?.visibility ?? [],
                },
              }}
              onSubmit={Functions.NOOP}
            />
          )}
        </div>
      </SaveFormProvider>
    );
  }
);

ClobForm.displayName = "ClobForm";

/**
 * Add react-memo to prevent re-rendering of the entire form.
 * This component will update when there is _any_ state change because of `useClobContext`,
 * however, we keep the props pure that are passed to <ClobForm/> and memoize that component.
 */
export const ConnectedClobForm = React.memo(({ id }: { id: string }) => {
  const {
    state,
    actions: { updateQuestion, nonce },
  } = useClobContext();
  const question = state.questions.get(id);

  return <ClobForm nonce={nonce} id={id} onChange={updateQuestion} question={question} />;
});

ConnectedClobForm.displayName = "ConnectedClobForm";
