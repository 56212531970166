import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import React from "react";
import { MappedClobQuestionsOnCurationQuestionQuery } from "../../generated/graphql";
import { ViewScreenshot } from "./ViewScreenshot";

interface Props {
  mappedMissingQuestions: MappedClobQuestionsOnCurationQuestionQuery["curationQuestion"]["mappedClobQuestions"];
}

export const RelatedMissingQuestionList: React.FC<Props> = ({ mappedMissingQuestions }) => {
  return (
    <>
      <Typography variant="body2" color="text.secondary" fontWeight={700}>
        Related Missing Questions
      </Typography>
      <TableContainer component={Paper} sx={{ backgroundColor: "action.hover" }}>
        <Table sx={{ minWidth: 350 }}>
          <TableHead>
            <TableRow>
              <TableCell>Question</TableCell>
              <TableCell align="right">Carrier</TableCell>
              <TableCell align="right">LOB</TableCell>
              <TableCell align="right">NAICS</TableCell>
              <TableCell align="right">Additional Info</TableCell>
              <TableCell align="right">Screenshot</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {mappedMissingQuestions.map(({ id, text, missingQuestion }) => (
              <TableRow key={id} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                <TableCell component="th" scope="row">
                  {text}
                </TableCell>
                <TableCell>{missingQuestion?.carrierSlug}</TableCell>
                <TableCell>{missingQuestion?.lineOfBusiness}</TableCell>
                <TableCell>{missingQuestion?.naics}</TableCell>
                <TableCell>{missingQuestion?.additionalInfo}</TableCell>
                <TableCell>
                  <ViewScreenshot fileUrl={missingQuestion?.fileUrl} />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};
