import { useToaster } from "@cp/theme";
import { Save } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { EntityApplicationTag, EntityTag, useUpdateCurationEntityMutation } from "../../../generated/graphql";
import { fromStringArrayToEnumArray } from "../../../util/utils";
import { deserializeCurationEntity } from "../../curation/state/serialize";
import { useCurationActions } from "../../curation/state/useCurationActions";
import { useCurationState } from "../../curation/state/useCurationState";

interface Props {
  displayTextTemplate: string;
  supportedTags: string[];
  supportedApplicationTags: string[];
}

export function SaveEntityButton({ displayTextTemplate, supportedTags, supportedApplicationTags }: Props) {
  const actions = useCurationActions();
  const state = useCurationState();
  const { toast } = useToaster();
  const [mutate, { loading }] = useUpdateCurationEntityMutation();

  return (
    <LoadingButton
      color="success"
      variant="contained"
      startIcon={<Save />}
      size="small"
      loading={loading}
      onClick={() => {
        void mutate({
          variables: {
            input: {
              id: state.id,
              nodes: [...state.curationNodeMap.values()],
              rootIds: state.rootIds,
              expectedVersion: state.version,
              supportedTags: fromStringArrayToEnumArray<EntityTag>(supportedTags),
              supportedApplicationTags: fromStringArrayToEnumArray<EntityApplicationTag>(supportedApplicationTags),
              displayTextTemplate,
            },
          },
          onCompleted: (data) => {
            toast("Successfully saved entity");
            actions.setState(deserializeCurationEntity(data.updateCurationEntity));
          },
        });
      }}
    >
      Save
    </LoadingButton>
  );
}
