import { useState } from "react";

export function useScrollShadow() {
  const [scrollPos, setScrollPos] = useState({ scrollLeft: 0, scrollRight: 0 });

  const onScroll = (event: React.UIEvent<HTMLDivElement>) => {
    const { scrollLeft, scrollWidth, clientWidth } = event.currentTarget;
    setScrollPos({
      scrollLeft: scrollLeft,
      scrollRight: scrollWidth - clientWidth - scrollLeft,
    });
  };

  return {
    onScroll,
    shadowStyles: {
      position: "absolute",
      left: 0,
      right: 0,
      bottom: 0,
      top: 0,
      zIndex: 1,
      pointerEvents: "none",

      boxShadow:
        scrollPos.scrollLeft > 0 && scrollPos.scrollRight > 0
          ? "inset 8px 0px 8px -8px rgb(0 0 0 / 40%), inset -8px 0px 8px -8px rgb(0 0 0 / 40%)"
          : scrollPos.scrollLeft > 0
          ? "inset 8px 0px 8px -8px rgb(0 0 0 / 40%)"
          : scrollPos.scrollRight > 0
          ? "inset -8px 0px 8px -8px rgb(0 0 0 / 40%)"
          : "none",
      transition: "box-shadow 0.3s",
    },
  };
}
