import React, { memo, PropsWithChildren, useRef } from "react";

import { useCurationQuestionDropzoneForInsert } from "../ApplicationLayout/useCurationQuestionDropzoneForInsert";
import { DraggableWrapper } from "../Dragables/DragableWapper";

interface Props {
  curationId: string;
  parentId: string | undefined;
}

/**
 * This component is used to show the dropzone for inserting a question above another question.
 * Only to be used across sections.
 */
export const InsertCurationQuestionIndicator: React.FC<PropsWithChildren<Props>> = memo(
  ({ curationId, parentId, children }) => {
    const ref = useRef<HTMLDivElement>(null);

    const [{ isOver }, dropRef] = useCurationQuestionDropzoneForInsert(
      curationId,
      parentId ? { type: "section", sectionId: parentId } : { type: "uncurated" }
    );

    dropRef(ref);
    return (
      <DraggableWrapper ref={ref} isOver={isOver} direction="top">
        {children}
      </DraggableWrapper>
    );
  }
);

InsertCurationQuestionIndicator.displayName = "InsertCurationQuestionIndicator";
