import { logNever } from "@cp/toolkit";
import { Box, Typography } from "@mui/material";
import { MuiMarkdown } from "mui-markdown";
import React from "react";
import { useFormContext } from "../context/FormContextProvider";
import { ApplicationComponentType, FormQuestionFragment } from "../generated/operations";
import { FileUploadDrawerOpener } from "./ApplicationDocumentsDrawerOpener";
import { FileUpload } from "./ApplicationDocumentsUpload";
import { InfoTextPopover } from "./common/InfoTextPopover";
import { ApplicationConditions } from "./conditions/ApplicationConditions";
import { FormFieldBooleanRadio } from "./FormFieldBooleanRadio";
import { FormFieldBusinessClassification } from "./FormFieldBusinessClassification";
import { FormFieldCheckbox } from "./FormFieldCheckbox";
import { FormFieldCheckboxGroup } from "./FormFieldCheckboxGroup";
import { FormFieldContactSelect } from "./FormFieldContactSelect";
import { FormFieldCurrency } from "./FormFieldCurrency";
import { FormFieldDateSelector } from "./FormFieldDateSelector";
import { FormFieldDynamicBusinessClassification } from "./FormFieldDynamicBusinessClassification";
import { FormFieldEmail } from "./FormFieldEmail";
import { FormFieldFEIN } from "./FormFieldFEIN";
import { FormFieldLocationSelect } from "./FormFieldLocationSelect";
import { FormFieldPercentage } from "./FormFieldPercentage";
import { FormFieldPhone } from "./FormFieldPhone";
import { FormFieldProps } from "./FormFieldProps";
import { FormFieldRadioGroup } from "./FormFieldRadioGroup";
import { FormFieldSelect } from "./FormFieldSelect";
import { FormFieldStateSelect } from "./FormFieldStateSelect";
import { FormFieldText } from "./FormFieldText";
import { FormFieldTextArea } from "./FormFieldTextArea";
import { FormFieldWorkersCompClassification } from "./FormFieldWorkersCompClassification";
import { FormFieldYearSelector } from "./FormFieldYearSelector";
import { FormFieldZip } from "./FormFieldZip";

export const formFieldWrapperId = (fieldId: string) => `${fieldId}-wrapper`;
interface Props {
  id: string;
  field: Omit<FormQuestionFragment, "__typename" | "id">;
  disabled?: boolean;
}

export const FormFieldRenderer: React.FC<Props> = React.memo(({ id, field, disabled = false }) => {
  const { label, visibility, isRequired, infoText, helperText, options, componentType } = field;

  const { applicationId } = useFormContext();

  const baseFieldProps: FormFieldProps & { name: string } = {
    id,
    label: <MuiMarkdown overrides={{ component: Typography }}>{label}</MuiMarkdown>,
    name: id,
    options: options || undefined,
    required: Boolean(isRequired),
    infoText: infoText || undefined,
    helperText: <MuiMarkdown overrides={{ component: Typography }}>{helperText}</MuiMarkdown> || undefined,
    disabled,
  };

  const wrapperId = formFieldWrapperId(id);

  let fieldComponent = null;
  let center = true;

  switch (componentType) {
    case ApplicationComponentType.RadioBoolean:
      fieldComponent = <FormFieldBooleanRadio {...baseFieldProps} />;
      center = false;
      break;
    case ApplicationComponentType.Date:
      fieldComponent = <FormFieldDateSelector {...baseFieldProps} />;
      break;
    case ApplicationComponentType.Year:
      fieldComponent = <FormFieldYearSelector {...baseFieldProps} />;
      break;
    case ApplicationComponentType.Email:
      fieldComponent = <FormFieldEmail {...baseFieldProps} />;
      break;
    case ApplicationComponentType.Radio:
      fieldComponent = <FormFieldRadioGroup {...baseFieldProps} />;
      center = false;
      break;
    case ApplicationComponentType.Fein:
      fieldComponent = <FormFieldFEIN {...baseFieldProps} />;
      break;
    case ApplicationComponentType.Phone:
      fieldComponent = <FormFieldPhone {...baseFieldProps} />;
      break;
    case ApplicationComponentType.Currency:
      fieldComponent = <FormFieldCurrency {...baseFieldProps} />;
      break;
    case ApplicationComponentType.TextArea:
      fieldComponent = <FormFieldTextArea {...baseFieldProps} />;
      break;
    case ApplicationComponentType.Checkbox:
      fieldComponent = <FormFieldCheckbox {...baseFieldProps} />;
      center = false;
      break;
    case ApplicationComponentType.CheckboxGroup:
      fieldComponent = <FormFieldCheckboxGroup {...baseFieldProps} />;
      center = false;
      break;
    case ApplicationComponentType.Select:
      fieldComponent = <FormFieldSelect {...baseFieldProps} />;
      break;
    case ApplicationComponentType.StateSelect:
      fieldComponent = <FormFieldStateSelect {...baseFieldProps} />;
      break;
    case ApplicationComponentType.LocationSelect:
      fieldComponent = applicationId ? (
        <FormFieldLocationSelect {...baseFieldProps} applicationId={applicationId} />
      ) : (
        <FormFieldText {...baseFieldProps} />
      );
      break;
    case ApplicationComponentType.ContactSelect:
      fieldComponent = applicationId ? (
        <FormFieldContactSelect {...baseFieldProps} applicationId={applicationId} />
      ) : (
        <FormFieldText {...baseFieldProps} />
      );
      break;
    case ApplicationComponentType.Number:
      fieldComponent = <FormFieldText {...baseFieldProps} type={"number"} />;
      break;
    case ApplicationComponentType.Zip:
      fieldComponent = <FormFieldZip {...baseFieldProps} />;
      break;
    case ApplicationComponentType.Percentage:
      fieldComponent = <FormFieldPercentage {...baseFieldProps} />;
      break;
    case ApplicationComponentType.BusinessClassification:
      fieldComponent = <FormFieldBusinessClassification {...baseFieldProps} />;
      break;
    case ApplicationComponentType.DynamicBusinessClassification:
      fieldComponent = <FormFieldDynamicBusinessClassification {...baseFieldProps} />;
      break;
    case ApplicationComponentType.WorkersCompClassification:
      fieldComponent = <FormFieldWorkersCompClassification {...baseFieldProps} />;
      break;
    case ApplicationComponentType.Hidden:
      return null;
    case ApplicationComponentType.Text:
      fieldComponent = <FormFieldText {...baseFieldProps} />;
      break;
    case ApplicationComponentType.FileUploadDrawerOpener:
      fieldComponent = <FileUploadDrawerOpener {...baseFieldProps} />;
      break;
    case ApplicationComponentType.FileUpload:
      fieldComponent = applicationId ? <FileUpload {...baseFieldProps} applicationId={applicationId} /> : null;
      break;
    default:
      logNever(componentType);
  }

  fieldComponent = (
    <Box id={wrapperId} position="relative">
      {fieldComponent}
      {!!infoText && <InfoTextPopover center={center} infoText={infoText} />}
    </Box>
  );

  if (visibility && visibility.length > 0) {
    return <ApplicationConditions conditions={visibility}>{fieldComponent}</ApplicationConditions>;
  }

  return fieldComponent;
});

FormFieldRenderer.displayName = "FormFieldRenderer";
