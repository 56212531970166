import React from "react";
import { useFormValues } from "../context/FormDataProvider";
import { FormQuestionFragment } from "../generated/operations";
import { FormTable } from "./FormTable";

interface Props {
  title: string;
  allChildrenIds: string[];
  visibleFields: Array<Omit<FormQuestionFragment, "__typename">>;
  fixedColumns?: number;
  helperText?: string | null;
  defaultValues?: string;
}

export const ReadonlyFormTable: React.FC<Props> = ({
  title,
  allChildrenIds,
  visibleFields,
  fixedColumns,
  helperText,
}) => {
  const ids = visibleFields.map((field) => field.id);
  const values = useFormValues(ids);

  return (
    <FormTable
      title={title}
      allChildrenIds={allChildrenIds}
      visibleFields={visibleFields}
      helperText={helperText}
      canAddRows={false}
      fixedColumns={fixedColumns}
      readonly={true}
      getValues={(id) => values.get(id) ?? []}
    />
  );
};
