import { Chip, colors } from "@mui/material";
import { startCase } from "lodash";
import React from "react";
import { EntityType } from "../../../generated/graphql";
import { entityTypeIdToIcon } from "../../entities/icons";

interface Props {
  entityType?: EntityType | null;
}

export const EntityChip: React.FC<Props> = ({ entityType }) => {
  if (!entityType) {
    return null;
  }

  return (
    <Chip
      icon={entityTypeIdToIcon[entityType]}
      sx={{
        borderRadius: 1,
        backgroundColor: colors.purple[100],
      }}
      size="small"
      label={`${startCase(entityType || "")} entity`}
    />
  );
};
