import { TimeAgo } from "@cp/theme";
import { Typography } from "@mui/material";
import React from "react";
import { CurationLint } from "../../components/curation-lint/CurationLint";
import { CurationNodeSearch } from "../../components/CurationNodeSearch";
import { MultiplayerAvatars } from "../../components/multiplayer/MultiplayerAvatars";
import { useSelectedCurationApplicationId } from "../../components/panels/atoms";
import { Panels } from "../../components/panels/Panels";
import { TopBar } from "../../components/TopBar";
import { useCurationApplicationByIdQuery } from "../../generated/graphql";
import ApplicationLayout from "./ApplicationLayout/ApplicationLayout";
import { DetailsEditor } from "./DetailsEditor/DetailsEditor";
import { CurationQuestionList } from "./Questions/CurationQuestionList";
import { deserializeCurationApplication } from "./state/serialize";
import { useCurationActions } from "./state/useCurationActions";

export const CurationApplicationBuilder: React.VFC = () => {
  const { setState } = useCurationActions();
  const [curationApplicationId] = useSelectedCurationApplicationId();

  const { data: applicationData } = useCurationApplicationByIdQuery({
    variables: { id: curationApplicationId },
    onCompleted: (data) => {
      setState(deserializeCurationApplication(data.curationApplicationById));
    },
  });
  const application = applicationData?.curationApplicationById;

  return (
    <Panels
      id="curation-application"
      config={[
        {
          title: "Search questions",
          defaultSize: 10,
          header: <TopBar leftToolbar={<Typography variant="h4">Questions</Typography>} />,
          content: <CurationQuestionList />,
        },
        {
          title: "Application",
          header: (
            <TopBar
              leftToolbar={<CurationNodeSearch />}
              rightToolbar={
                <>
                  <Typography variant="body2">
                    Last updated:{" "}
                    <b>
                      <TimeAgo date={application?.updatedAt} />
                    </b>
                  </Typography>
                  •
                  <Typography variant="body2">
                    Version: <b>{application?.version}</b>
                  </Typography>
                  <MultiplayerAvatars />
                </>
              }
            />
          ),
          content: <ApplicationLayout />,
        },
        {
          title: "Details",
          defaultSize: 20,
          header: <TopBar rightToolbar={<CurationLint />} />,
          content: <DetailsEditor />,
        },
      ]}
    />
  );
};
