import React from "react";
import { DropArea } from "../Dragables/DropArea";
import { CurationQuestionId } from "../state/types";
import { useCurationQuestionDropzone } from "./useCurationQuestionDropzone";

interface Props {
  curationQuestionId: CurationQuestionId;
}

export const CurationQuestionDropArea: React.FC<Props> = ({ curationQuestionId }) => {
  const [{ isOver, canDrop }, drop] = useCurationQuestionDropzone(curationQuestionId);

  return (
    <DropArea drop={drop} canDrop={canDrop} isOver={isOver} dropText="Drop to link to curation question" color="green">
      <div />
    </DropArea>
  );
};
