import { Stack, StackProps } from "@mui/material";
import React from "react";

interface Props extends Pick<StackProps, "onDoubleClick"> {
  left?: React.ReactNode;
  right?: React.ReactNode;
}

export const Toolbar: React.FC<Props> = ({ left, right, ...rest }) => {
  return (
    <Stack flex={1} direction="row" justifyContent="space-between" alignItems="center" {...rest}>
      <Stack direction="row" gap={1} alignItems="center">
        {left}
      </Stack>
      <Stack direction="row" flexShrink={0} gap={1} alignItems="center">
        {right}
      </Stack>
    </Stack>
  );
};
