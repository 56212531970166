import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@mui/material";
import React, { useState } from "react";
import { ModalPromptPayload } from "../atoms";

interface Props extends ModalPromptPayload {
  onClose: (value: string | undefined) => void;
}

export const PromptDialog: React.FC<Props> = ({ onClose, title, confirmText = "Confirm", defaultValue, message }) => {
  const [value, setValue] = useState(defaultValue);

  return (
    <Dialog open={true} onClose={() => onClose(undefined)}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent sx={{ display: "flex", alignItems: "center", justifyContent: "center", pb: 0 }}>
        <TextField
          label={message}
          autoFocus={true}
          fullWidth={true}
          value={value}
          helperText="Press enter to confirm"
          onChange={(e) => setValue(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              onClose(value);
              e.preventDefault();
              e.stopPropagation();
            }
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose(value)}>{confirmText}</Button>
      </DialogActions>
    </Dialog>
  );
};
