import { Stack, Typography } from "@mui/material";
import { useSearch } from "@tanstack/react-location";
import React from "react";
import { LocationGenerics } from "../../../../App";
import { Highlighter } from "../../../../components/Highlighter";
import { ClobActionItems } from "./ClobActionItems";

interface Props {
  id: string;
  title: string;
  showScreenshotIcon?: boolean;
  expanded?: boolean;
  body: React.ReactNode;
}

export const ClobQuestionTitle: React.FC<Props> = React.memo(({ id, title, showScreenshotIcon, expanded, body }) => {
  const { clobSearchQuery } = useSearch<LocationGenerics>();

  const highlight = (text: string) => {
    const query = clobSearchQuery;
    if (!query) {
      return text;
    }
    return <Highlighter searchWords={[query]} autoEscape={true} textToHighlight={text} />;
  };

  return (
    <Stack>
      <Stack gap={1} direction="row" alignItems="center" className="hide-action-items" minHeight="40px">
        <Typography fontWeight={700} color="text.disabled">
          Q
        </Typography>
        <Typography title={title} flex={1} sx={{ whiteSpace: "normal" }}>
          {highlight(title)}
        </Typography>
        <ClobActionItems id={id} showScreenshotIcon={showScreenshotIcon} />
      </Stack>
      {expanded && body}
    </Stack>
  );
});

ClobQuestionTitle.displayName = "ClobQuestionTitle";
