import type { Identifier } from "dnd-core";
import { isEqual } from "lodash";
import React, { useState } from "react";
import { useDrop } from "react-dnd";
import { Dragable, DragSource, DragTypes, MoveHandler } from "./DragTypes";

export type SortableDragable = Extract<Dragable, { type: DragTypes.QUESTION } | { type: DragTypes.SECTION }>;

export function useListItemDrop<T extends SortableDragable>({
  id,
  ref,
  index,
  move,
  accept,
  source,
}: {
  id: string;
  ref: React.RefObject<HTMLDivElement>;
  index: number;
  move: MoveHandler;
  accept: DragTypes | DragTypes[];
  source: DragSource;
}) {
  const [direction, setDirection] = useState<"bottom" | "top">();

  const dropProps = useDrop<T, void, { handlerId: Identifier | null; isOver: boolean }>(
    {
      accept,
      collect(monitor) {
        return {
          handlerId: monitor.getHandlerId(),
          isOver: monitor.canDrop() && monitor.isOver({ shallow: true }),
        };
      },
      hover(item, monitor) {
        // Don't replace an item with itself
        if (!ref.current || item.id === id) {
          return;
        }

        // Determine rectangle on screen
        const hoverBoundingRect = ref.current.getBoundingClientRect();

        // Get vertical middle
        const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

        // Determine mouse position
        const clientOffset = monitor.getClientOffset();
        if (!clientOffset) {
          return;
        }

        // Get pixels to the top
        const hoverClientY = clientOffset.y - hoverBoundingRect.top;
        setDirection(hoverClientY > hoverMiddleY ? "bottom" : "top");
      },
      canDrop(item: T) {
        // We only allow reordering within the same list.
        return isEqual(source, item.source);
      },
      drop(item: T, monitor) {
        if (!ref.current) {
          return;
        }
        // Stop if we can't drop.
        if (!monitor.canDrop()) {
          return;
        }
        const dragIndex = item.index;
        let dropIndex = index;

        // Don't replace items with themselves
        if (dragIndex === dropIndex) {
          return;
        }

        // Determine rectangle on screen
        const hoverBoundingRect = ref.current.getBoundingClientRect();

        // Get vertical middle
        const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

        // Determine mouse position
        const clientOffset = monitor.getClientOffset();
        if (!clientOffset) {
          return;
        }

        // Get pixels to the top
        const hoverClientY = clientOffset.y - hoverBoundingRect.top;

        // Dragging downwards
        // When dragging downwards and not reached bottom of hover element, decrease index by 1
        if (dragIndex < dropIndex && hoverClientY < hoverMiddleY) {
          dropIndex -= 1;
        }

        // Dragging upwards
        // When dragging upwards and not reached top of hover element, increase index by 1
        if (dragIndex > dropIndex && hoverClientY > hoverMiddleY) {
          dropIndex += 1;
        }

        // Time to actually perform the action
        move({ dragItemId: item.id, moveIndex: dropIndex });
      },
    },
    [id, index, JSON.stringify(source)]
  );

  return {
    ...dropProps[0],
    dropRef: dropProps[1],
    direction,
  };
}
