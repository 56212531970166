import { ApolloProvider } from "@apollo/client";
import { Boundary } from "@cp/async";
import { ModalProvider } from "@cp/modals";
import { useToaster } from "@cp/theme";
import { GlobalStyles, Stack } from "@mui/material";
import {
  createBrowserHistory,
  MakeGenerics,
  Navigate,
  Outlet,
  ReactLocation,
  Route,
  Router,
} from "@tanstack/react-location";
import { useEffect } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { QueryClient, QueryClientProvider } from "react-query";
import { ActionBar } from "./actions/components/ActionBar";
import { AppSidebar } from "./components/sidebar/AppSidebar";
import { Scalars } from "./generated/graphql";
import { client, createErrorLink } from "./graphql/client";
import { RouteGuard } from "./pages/auth/RouteGuard";
import { ClobPage } from "./pages/clobs/EditClob/ClobPage";
import ClobDetails from "./pages/clobs/MapClob/ClobDetails";
import CurationPage from "./pages/curation/CurationPage";
import { CurationProvider } from "./pages/curation/state/CurationProvider";
import { EntityPage } from "./pages/entities/ListEntities/EntityPage";
import { DataHealthPage } from "./pages/health/DataHealthPage";
import { QuestionGraveyardPage } from "./pages/health/tabs/questions-graveyard/QuestionGraveyardPage";
import Home from "./pages/home/Home";
import { PreviewPage } from "./pages/preview/PreviewPage";
import { AnnotateProjectPage } from "./pages/recordings/AnnotateProjectPage";
import { MappingPage } from "./pages/recordings/MappingPage";
import { ProjectPage } from "./pages/recordings/ProjectPage";
import { AllProjectsPage } from "./pages/recordings/projects/AllProjectsPage";
import { NewRecordingsPage } from "./pages/recordings/projects/NewRecordingsPage";
import { ProjectPreviewPage } from "./pages/recordings/projects/ProjectPreviewPage";
import { SAActivityRecordingsPage } from "./pages/recordings/projects/SAActivityRecordingPage";
import { ProjectsPage } from "./pages/recordings/ProjectsPage";
import { RecordingsPage } from "./pages/recordings/RecordingsPage";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      suspense: true,
      useErrorBoundary: true,
    },
  },
});

const history = createBrowserHistory();
const location = new ReactLocation<LocationGenerics>({ history });

export type LocationGenerics = MakeGenerics<{
  Params: {
    curationId: string;
    clobId: string;
    projectId: Scalars["CarrierProjectId"];
  };
  Search: {
    name: string;
    id: string;
    locator: string;
    questionId: string;
    sectionId: string;
    parentClass: string;
    shape: string;
    shapePath: string[];
    navigateToClobQuestion: string;
    clobSearchQuery: string;
  };
}>;

const routes: Array<Route<LocationGenerics>> = [
  { path: "/", element: <Home /> },
  {
    path: "clobs",
    children: [
      {
        path: "map/:clobId",
        element: <ClobDetails />,
      },
      {
        path: "edit/:clobId",
        element: <ClobPage />,
      },
    ],
  },
  {
    path: "curations",
    children: [
      {
        element: <CurationPage />,
      },
    ],
  },
  {
    path: "entities",
    children: [
      {
        path: "/",
        element: <EntityPage />,
      },
      {
        path: ":entityId",
        element: <EntityPage />,
      },
    ],
  },
  {
    path: "preview",
    element: <PreviewPage />,
  },
  {
    path: "projects",
    element: <ProjectsPage />,
    children: [
      {
        path: "/",
        element: <AllProjectsPage />,
      },
      {
        path: "new-captures",
        element: <NewRecordingsPage />,
      },
      {
        path: "sa-captures",
        element: <SAActivityRecordingsPage />,
      },
    ],
  },
  {
    path: "project/:projectId",
    element: <ProjectPage />,
    children: [
      {
        path: "/",
        element: <RecordingsPage />,
      },
      {
        path: "transcribe",
        element: <AnnotateProjectPage />,
        meta: {
          allowGoldenDrawer: true,
        },
      },
      {
        path: "map",
        element: <MappingPage />,
      },
      {
        path: "preview",
        element: <ProjectPreviewPage />,
      },
    ],
  },
  {
    path: "data-health",
    element: <DataHealthPage />,
    children: [
      {
        path: "/",
        element: <Navigate to="/data-health/graveyard" replace={true} />,
      },
      {
        path: "graveyard",
        element: <QuestionGraveyardPage />,
      },
    ],
  },
];

const inputGlobalStyles = (
  <GlobalStyles
    styles={{
      // We can use CSS to hide/show nested items on hover.
      // This will happen automatically if the parent has the class "hide-action-items".
      // and the child has the class "show-action-items".
      // This is useful to power conditional visibility of action items using only CSS and no JS,
      // which is important for performance.
      ".hide-action-items .show-action-items": {
        visibility: "hidden",
        display: "contents",
      },
      ".hide-action-items:hover .show-action-items": {
        visibility: "visible",
        display: "contents",
      },
    }}
  />
);

const originalLinks = client.link;

const App = () => {
  const { toastError } = useToaster();

  // Set global error handler
  // We need to do this here because the client is created outside of the React tree, but our toaster is in the React tree.
  useEffect(() => {
    // eslint-disable-next-line unicorn/prefer-spread
    client.setLink(createErrorLink(toastError).concat(originalLinks));
  }, []);

  return (
    <div style={{ height: "100vh", overflow: "hidden", width: "100vw" }}>
      {inputGlobalStyles}
      <ApolloProvider client={client}>
        <QueryClientProvider client={queryClient}>
          <Router location={location} routes={routes}>
            <Boundary>
              <RouteGuard>
                <ModalProvider>
                  <DndProvider backend={HTML5Backend}>
                    <Stack direction="row" height="100%">
                      <AppSidebar />
                      <Boundary>
                        <CurationProvider>
                          <Outlet />
                          <ActionBar />
                        </CurationProvider>
                      </Boundary>
                    </Stack>
                  </DndProvider>
                </ModalProvider>
              </RouteGuard>
            </Boundary>
          </Router>

          {/* <ReactLocationDevtools initialIsOpen={true} /> */}
          {/* <ReactQueryDevtools initialIsOpen={false} /> */}
        </QueryClientProvider>
      </ApolloProvider>
    </div>
  );
};

export default App;
