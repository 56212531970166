import { ContentPasteGo } from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";
import React from "react";
import { useSelectedCurationQuestions } from "../pages/clobs/MapClob/selection-atom";
import { ROOT } from "../pages/curation/state/types";
import { useCurationActions } from "../pages/curation/state/useCurationActions";
import { useCurationState } from "../pages/curation/state/useCurationState";

interface Props {
  nodeId: string;
  parentId: string | undefined;
}

export const QuestionPaste: React.FC<Props> = ({ nodeId, parentId }) => {
  const { moveToNewNode, moveWithinNode } = useCurationActions();
  const { curationNodeMap, getQuestion, getSection } = useCurationState();
  const [selectedCurationQuestions] = useSelectedCurationQuestions();

  if (!nodeId || !parentId || selectedCurationQuestions.includes(nodeId) || selectedCurationQuestions.length === 0) {
    return null;
  }

  const handleMoveToSectionRoot = (nodeId: string, parentId: string, fromId: string) => {
    if (!nodeId || !parentId || !fromId) {
      return;
    }
    const beforeId = curationNodeMap.get(parentId)?.children.at(-1);
    moveToNewNode({ nodeId, toId: parentId, fromId: fromId, beforeId });
  };

  const handleMove = (nodeId: string, toId: string, fromId: string, beforeId?: string) => {
    if (!nodeId || !toId || !fromId) {
      return;
    }
    moveToNewNode({ nodeId, toId, fromId, beforeId });
  };
  const handleMoveWithinSection = (nodeId: string, toId: string, fromId: string, toIndex: number) => {
    if (!nodeId || !toId || !fromId) {
      return;
    }
    moveWithinNode({ childId: nodeId, parentId: fromId, toIndex });
  };

  return (
    <Tooltip title="Move copied questions">
      <IconButton
        size="small"
        color={selectedCurationQuestions.length > 0 ? "success" : undefined}
        onClick={() => {
          // TODO @mscolnik would like to look into adding parentId to CurationNodes
          const parentNodeMap = new Map();
          const parentValues = [...curationNodeMap.values()];

          selectedCurationQuestions.forEach((q) => {
            const parent = parentValues.find((node) => node.children.includes(q));
            if (parent) {
              parentNodeMap.set(q, parent);
            }
          });

          selectedCurationQuestions.forEach((q, i) => {
            const clickedNodeId = parentId === ROOT ? nodeId : parentId;
            const parentNode = parentNodeMap.get(q);

            if (!parentNode) {
              return;
            }

            const inSection = curationNodeMap.get(parentId)?.children.includes(q);
            const clickedQuestion = getQuestion(nodeId);

            // Handles move to a root section
            if (parentId === ROOT) {
              return handleMoveToSectionRoot(q, clickedNodeId, parentNode?.id);
            }
            // Handles move to nested section
            if (!clickedQuestion) {
              const beforeId = curationNodeMap.get(nodeId)?.children.at(-1);
              return handleMove(q, nodeId, parentNode?.id, beforeId);
            }
            // Handles move to a question in the same or different section
            if (clickedQuestion) {
              const indexOfClicked = curationNodeMap.get(parentId)?.children.indexOf(nodeId) || 0;
              const indexOfQuestion = curationNodeMap.get(parentId)?.children.indexOf(q) || 0;
              const indexOfClickedInGroup = selectedCurationQuestions.indexOf(q);
              const indexValue =
                indexOfClicked >= indexOfQuestion ? indexOfClicked - 1 : indexOfClicked + indexOfClickedInGroup;
              if (inSection) {
                return handleMoveWithinSection(q, parentId, parentNode?.id, indexValue || 0);
              }
              const beforeId = curationNodeMap.get(parentId)?.children[indexOfClicked];
              if (!beforeId) {
                return;
              }
              return handleMove(q, parentId, parentNode?.id, beforeId);
            }
          });
        }}
      >
        <ContentPasteGo />
      </IconButton>
    </Tooltip>
  );
};
