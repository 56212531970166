import { useEvent } from "@cp/react-hooks";
import { Stack, Typography } from "@mui/material";
import { SortableTree, TreeItemComponentProps } from "dnd-kit-sortable-tree";
import React from "react";
import { TreeItem } from "../../../components/sortables/components/TreeItem";
import { ClobFragment } from "../../../generated/graphql";
import { useClobContext } from "../../../model/clobs/context";
import { ClobTreeItemData } from "../../../model/clobs/types";
import { AddNewTreeItem } from "./AddNewTreeItem";
import { ConnectedClobForm } from "./ClobForm";
import { ClobSectionTitle } from "./ClobSectionTitle";
import { EditableClobQuestionTitle } from "./EditableClobQuestionTitle";

interface Props {
  clob: ClobFragment;
  draggable: boolean;
}

export const ClobFormEditor: React.FC<Props> = ({ draggable }) => {
  const {
    state,
    actions: { setTree },
  } = useClobContext();

  return (
    <>
      <SortableTree<ClobTreeItemData>
        items={state.tree}
        indicator={true}
        disableSorting={!draggable}
        onItemsChanged={setTree}
        TreeItemComponent={TreeItemComponent}
      />
      <AddNewTreeItem parentId="root" />
    </>
  );
};

const TreeItemComponent = React.memo(
  React.forwardRef<HTMLDivElement, TreeItemComponentProps<ClobTreeItemData>>((props, ref) => {
    const { depth, item, disableSorting, handleProps } = props;
    const id = item.id.toString();
    const isQuestion = item.type === "question";

    const onRemove = useEvent(() => {
      if (window.confirm("Are you sure you want to delete this item?")) {
        props.onRemove?.();
      }
    });

    if (!isQuestion) {
      return (
        <TreeItem {...props} ref={ref} handleProps={handleProps} onRemove={onRemove}>
          <ClobSectionTitle depth={depth} id={id} />
        </TreeItem>
      );
    }

    return (
      <TreeItem {...props} ref={ref} color={"#00bcd4"} handleProps={handleProps} onRemove={onRemove}>
        <Stack gap={1} direction="row" alignItems="center">
          <Typography fontWeight={700} color="text.disabled">
            Q
          </Typography>
          <EditableClobQuestionTitle id={id} />
        </Stack>
        {disableSorting && isQuestion && <ConnectedClobForm id={id} />}
      </TreeItem>
    );
  })
);

TreeItemComponent.displayName = "TreeItemComponent";
