import { Outlet } from "@tanstack/react-location";
import React from "react";
import { PageTemplate } from "../../components/layout/PageTemplate";

export const DataHealthPage: React.FC = () => {
  return (
    <PageTemplate title="Data Health" tabs={[{ label: "Question Graveyard", to: "graveyard" }]} noPadding={true}>
      <Outlet />
    </PageTemplate>
  );
};
