import { usePopover } from "@cp/react-hooks";
import { ArrowLeft, ArrowRight, Error, Info, Warning } from "@mui/icons-material";
import { ButtonGroup, IconButton, Popover, Stack, Typography } from "@mui/material";
import { sum, uniq } from "lodash";
import React, { useState } from "react";
import { useSetSelectedCurationNodeId } from "../panels/atoms";
import { LinterResult } from "./linters/types";
import { useLinters } from "./linters/useLinters";

const statusToIcon: Record<LinterResult["status"], React.ReactNode> = {
  error: <Error fontSize="small" color="error" />,
  warning: <Warning fontSize="small" color="warning" />,
  info: <Info fontSize="small" color="info" />,
};

export function CurationLint() {
  const results = useLinters();
  const { popoverProps, targetProps } = usePopover();
  // keep track of linter index
  const [activeLintingIndex, setActiveLintingIndex] = useState<number>(0);
  const setSelectedCurationNodeId = useSetSelectedCurationNodeId();

  const warningSum = sum(results.filter((result) => result.status === "warning").map((result) => result.count));
  const errorSum = sum(results.filter((result) => result.status === "error").map((result) => result.count));
  const infoSum = sum(results.filter((result) => result.status === "info").map((result) => result.count));

  const forQuestionIds = uniq(results.flatMap((result) => result.onQuestionIds));

  return (
    <>
      <Stack
        gap={3}
        direction="row"
        {...targetProps}
        sx={{ cursor: "pointer", opacity: 0.8, "&:hover": { opacity: 1 } }}
      >
        {errorSum > 0 && (
          <Typography display="flex" gap={1} fontSize={13}>
            <Error fontSize="small" color="error" /> {errorSum}
          </Typography>
        )}
        {warningSum > 0 && (
          <Typography display="flex" gap={1} fontSize={13}>
            <Warning fontSize="small" color="warning" /> {warningSum}
          </Typography>
        )}
        {infoSum > 0 && (
          <Typography display="flex" gap={1} fontSize={13}>
            <Info fontSize="small" color="info" /> {infoSum}
          </Typography>
        )}
      </Stack>
      {forQuestionIds.length > 0 && (
        <ButtonGroup variant="contained">
          <IconButton
            color="info"
            size="small"
            onClick={() => {
              // move forward
              const nextIdx = (activeLintingIndex + 1) % forQuestionIds.length;
              setActiveLintingIndex(nextIdx);
              setSelectedCurationNodeId(forQuestionIds[nextIdx]);
            }}
          >
            <ArrowLeft fontSize="small" />
          </IconButton>
          <IconButton
            size="small"
            color="info"
            onClick={() => {
              // move backward
              const nextIdx = (activeLintingIndex + forQuestionIds.length - 1) % forQuestionIds.length;
              setActiveLintingIndex(nextIdx);
              setSelectedCurationNodeId(forQuestionIds[nextIdx]);
            }}
          >
            <ArrowRight fontSize="small" />
          </IconButton>
        </ButtonGroup>
      )}
      <Popover {...popoverProps} anchorOrigin={{ vertical: "bottom", horizontal: "left" }}>
        <Stack p={2} gap={2} direction="column">
          {results.map((result) => {
            if (result.count === 0) {
              return null;
            }

            return (
              <Stack gap={1} key={result.message} direction="row">
                {statusToIcon[result.status]}
                {result.count}
                <Typography variant="caption">{result.message}</Typography>
              </Stack>
            );
          })}
        </Stack>
      </Popover>
    </>
  );
}
