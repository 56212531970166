import { FormBuilder, FormyForm } from "@cp/forms";
import { TimeAgo, useToaster } from "@cp/theme";
import { LoadingButton } from "@mui/lab";
import { Divider, Stack, Typography } from "@mui/material";
import React from "react";
import { formatConditions } from "../../../components/curation-questions/conditionalUtil";
import { CONDITIONS_FORM } from "../../../components/curation-questions/ConditionsEditor";
import { useCurationQuestionFormOptions } from "../../../components/curation-questions/useCurationQuestionFormOptions";
import { PropertyList } from "../../../components/properties/PropertyList";
import {
  CurationSectionFragment,
  EntityType,
  EntityTypeLayout,
  useUpsertCurationSectionMetadataMutation,
} from "../../../generated/graphql";
import {
  CurationEntitySectionFormState,
  CurationSectionFormState,
  curationSectionToInput,
  CurationTableSectionFormState,
} from "../../../model/curation-sections/deserialize";
import { CurationNode } from "../state/types";
import { useCurationState } from "../state/useCurationState";
import { Toolbar } from "../Toolbar/Toolbar";

const FORM_ID = "curation-question-form";

interface CurationSectionEditorProps {
  entity: CurationNode;
  section: Pick<CurationSectionFragment, "id" | "conditions" | "createdAt" | "updatedAt">;
}

const entitySectionForm = FormBuilder.objectOf<CurationEntitySectionFormState>({
  entityTypeId: FormBuilder.enum(Object.values(EntityType)).options({ label: "Entity type" }),
  entityTypeLayout: FormBuilder.enum(Object.values(EntityTypeLayout)).options({
    label: "Entity type layout",
    helperText: "The layout changes how the entity is displayed in the application form.",
    defaultValue: EntityTypeLayout.Modal,
  }),
}).options({
  label: "Entity Section",
  helperText: "Entity sections allow for duplicate entities to be created in the form. E.g. A list of locations",
});

const tableSectionForm = FormBuilder.objectOf<CurationTableSectionFormState>({
  canAddRemove: FormBuilder.boolean().options({ label: "Allow rows to be added/remove", defaultValue: true }),
  defaultValues: FormBuilder.string()
    .options({ label: "Default values", textArea: true, placeholder: "a,b\nc,d" })
    .optional(),
  fixedColumns: FormBuilder.number().options({ label: "Number of fixed columns", defaultValue: 0 }),
});

const FORM = FormBuilder.objectOf<CurationSectionFormState>({
  title: FormBuilder.string().options({ label: "Section Title", placeholder: "No section title" }).optional(),
  helperText: FormBuilder.string()
    .options({
      label: "Helper Text",
      helperText: "Helper text is displayed below the question to the user. Supports multi-line markdown.",
      textArea: true,
    })
    .optional(),
  config: FormBuilder.oneOf({
    none: FormBuilder.objectOf({}),
    entity: entitySectionForm,
    table: tableSectionForm,
    pivotSubsections: FormBuilder.objectOf({}),
  }).options({
    label: "Section Type",
    titles: {
      entity: "Entity Section",
      table: "Table Section",
      pivotSubsections: "Pivot Subsections Into a Table",
      none: "None",
    },
  }),
  conditions: CONDITIONS_FORM.options({ label: "Conditions", itemName: "condition" }).optional(),
});

export const CurationSectionEditor: React.VFC<CurationSectionEditorProps> = ({ entity, section }) => {
  const { toast } = useToaster();
  const [mutate, { loading }] = useUpsertCurationSectionMetadataMutation();
  const { getTitle, getQuestion } = useCurationState();
  const optionSets = useCurationQuestionFormOptions();

  const handleSubmit = (data: CurationSectionFormState) => {
    void mutate({
      variables: {
        input: {
          id: entity.id,
          title: data.title,
          conditions: data.conditions ?? [],
          helperText: data.helperText,
          // null means we are removing values
          entityTypeId: data.config.entity?.entityTypeId ?? null,
          entityTypeLayout: data.config.entity?.entityTypeLayout ?? null,
          tableConfig: data.config.table
            ? {
                canAddRemove: data.config.table.canAddRemove,
                defaultValues: data.config.table.defaultValues ?? "",
                fixedColumns: data.config.table.fixedColumns,
              }
            : null,
          pivotSubsectionsConfig: data.config.pivotSubsections
            ? {
                enabled: true,
              }
            : null,
        },
      },
      onCompleted: (data) => {
        toast("Curation section updated successfully");
      },
    });
  };

  return (
    <Stack py={3} gap={3}>
      <Typography variant="h2" m={0}>
        {getTitle(entity.id)}
      </Typography>
      <PropertyList
        pairs={[
          ["Conditions", formatConditions(section.conditions, getQuestion)],
          ["Last Edited", section.updatedAt ? <TimeAgo date={section.updatedAt} /> : undefined],
          ["ID", section.id],
        ]}
      />
      <Divider />
      <FormyForm
        optionSets={optionSets}
        id={FORM_ID}
        form={FORM}
        onSubmit={handleSubmit}
        defaultValues={curationSectionToInput({
          ...section,
          title: getTitle(entity.id),
        })}
      />
      <Toolbar
        right={
          <LoadingButton loading={loading} type="submit" form={FORM_ID}>
            Save
          </LoadingButton>
        }
      />
    </Stack>
  );
};
