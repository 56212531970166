import { LoadState } from "@cp/theme";
import { useMatch } from "@tanstack/react-location";
import React from "react";
import { LocationGenerics } from "../../../App";
import { CurationNodeSearch } from "../../../components/CurationNodeSearch";
import { Panels } from "../../../components/panels/Panels";
import { TopBar } from "../../../components/TopBar";
import { useCarrierProjectQuery } from "../../../generated/graphql";
import { CurationControls } from "../../clobs/MapClob/components/CurationControls";
import { useSetCurationApplicationToCarrierLineOfBusiness } from "../../hooks/useSetCurationApplicationToCarrierLineOfBusiness";
import { AgencyFormPreview } from "./AgencyFormPreview";

export const ProjectPreviewPage: React.FC = () => {
  const {
    params: { projectId },
  } = useMatch<LocationGenerics>();
  const { data, loading, error } = useCarrierProjectQuery({
    variables: { id: projectId },
    fetchPolicy: "cache-first",
    skip: !projectId,
  });
  useSetCurationApplicationToCarrierLineOfBusiness(data?.carrierProject ?? {});

  if (loading || error) {
    return <LoadState loading={loading} error={error} />;
  }

  if (!data?.carrierProject) {
    return null;
  }

  return (
    <Panels
      id="project-preview"
      config={[
        {
          title: "Application",
          header: <TopBar leftToolbar={<CurationNodeSearch />} />,
          noPadding: true,
          content: <CurationControls disableCurationQuestionList={true} />,
        },
        {
          defaultSize: 70,
          title: "Preview",
          noPadding: true,
          content: (
            <AgencyFormPreview
              carrierSlug={data.carrierProject.carrierSlug}
              lineOfBusiness={data.carrierProject.lineOfBusiness}
            />
          ),
        },
      ]}
    />
  );
};
