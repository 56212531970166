import { Button, Tooltip } from "@mui/material";
import React from "react";
import { v4 as uuidV4 } from "uuid";
import { useUpsertCurationSectionMetadataMutation } from "../../../generated/graphql";
import { useCurationActions } from "../state/useCurationActions";

interface Props {
  parentNodeId: string | undefined;
  addType: "category" | "section";
}

export const AddEntityButton: React.FC<Props> = ({ parentNodeId, addType }) => {
  const actions = useCurationActions();
  const [createSection] = useUpsertCurationSectionMetadataMutation();

  return (
    <Tooltip title={`Add ${addType}`}>
      <Button
        size="small"
        variant="text"
        onClick={async (evt) => {
          evt.stopPropagation();
          const title = window.prompt("Name of the section");
          if (title) {
            const newId = uuidV4();
            await createSection({
              variables: {
                input: { id: newId, title },
              },
            });
            actions.addNode({
              parentNodeId,
              title,
              newId,
            });
          }
        }}
      >
        + Add
      </Button>
    </Tooltip>
  );
};
