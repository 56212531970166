import { NavigateNext } from "@mui/icons-material";
import { Breadcrumbs, Link, Typography } from "@mui/material";
import React from "react";
import { useSetSelectedDrilldownId } from "../../../components/panels/atoms";
import { useCurationState } from "../state/useCurationState";

interface Props {
  /**
   * Section or question ID
   */
  nodeId: string;

  /**
   * Include the root section in the breadcrumbs
   */
  includeRoot?: boolean;
  /**
   * Include the current section in the breadcrumbs
   */
  includeSelf?: boolean;
}

const FOLLOW_UP_QUESTIONS = "Follow-up Questions";

export const SectionBreadcrumbs: React.FC<Props> = ({ nodeId, includeRoot, includeSelf }) => {
  const { curationNodeMap, getTitle } = useCurationState();
  const drillDownToSection = useSetSelectedDrilldownId();

  const ancestors = React.useMemo(() => {
    // Create a map of childId -> parentId
    const parentNodeMap = new Map<string, string>();
    for (const [id, entity] of curationNodeMap.entries()) {
      for (const childId of entity.children) {
        parentNodeMap.set(childId, id);
      }
    }

    const ancestors: string[] = [];
    // add parents
    let parentId: string | undefined = parentNodeMap.get(nodeId);
    while (parentId) {
      ancestors.unshift(parentId);
      // break at 6
      if (ancestors.length > 6) {
        break;
      }
      parentId = parentNodeMap.get(parentId);
    }
    return ancestors;
  }, [nodeId]); // only recompute when sectionId changes

  const handleNavigate = (id: string | undefined) => () => drillDownToSection(id);
  const selfTitle = getTitle(nodeId);

  return (
    <Breadcrumbs
      separator={<NavigateNext fontSize="small" />}
      sx={{ mb: 1 }}
      itemsAfterCollapse={2}
      itemsBeforeCollapse={2}
      maxItems={6}
    >
      {includeRoot && (
        <Link underline="hover" variant="caption" color="inherit" onClick={handleNavigate(undefined)}>
          Root
        </Link>
      )}
      {ancestors.map((id) => {
        const title = getTitle(id);
        return (
          <Link
            key={id}
            underline="hover"
            variant="caption"
            color={title === FOLLOW_UP_QUESTIONS ? "error" : "inherit"}
            onClick={handleNavigate(id)}
          >
            {title}
          </Link>
        );
      })}
      {includeSelf && (
        <Typography color={selfTitle === FOLLOW_UP_QUESTIONS ? "error" : "text.primary"} variant="caption">
          {selfTitle}
        </Typography>
      )}
    </Breadcrumbs>
  );
};
