import { Tooltip } from "@mui/material";
import { formatRelative } from "date-fns";

interface Props {
  date: string | Date | number | undefined;
}

export function TimeAgo({ date }: Props) {
  if (!date) {
    return null;
  }

  const readableDate = new Date(date);
  return (
    <Tooltip title={readableDate.toLocaleString()}>
      <span>{safeFormat(date)}</span>
    </Tooltip>
  );
}

function safeFormat(date: string | Date | number) {
  try {
    return formatRelative(new Date(date), new Date());
  } catch {
    return "";
  }
}
