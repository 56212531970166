import { useAtom } from "jotai";
import React from "react";
import { ModalAlertPayload, modalAtom, ModalConfirmPayload, ModalFormPayload, ModalPromptPayload } from "./atoms";
import { deferred } from "./deferred";

export interface UseModalResponse {
  openAlert: (payload: ModalAlertPayload) => Promise<void>;
  openConfirm: (payload: ModalConfirmPayload) => Promise<boolean>;
  openPrompt: (payload: ModalPromptPayload) => Promise<string | undefined>;
  openForm: (payload: ModalFormPayload) => Promise<"cancelled" | false | React.FormEvent>;
  /**
   * Opens a custom modal.
   * The payload is a function that returns a ReactNode.
   * The function is passed a callback that should be called when the modal is closed.
   * The value passed to the callback will be returned by the promise.
   */
  openCustomModal: <T>(payload: (onClose: (value: T) => void) => React.ReactNode) => Promise<T>;
}

export function useModal(): UseModalResponse {
  const [, setValue] = useAtom(modalAtom);

  return {
    openAlert: (payload) => {
      const promise = deferred<void>();
      setValue({
        activeModal: { type: "alert", payload },
        promise,
      });
      return promise.promise;
    },
    openConfirm: (payload) => {
      const promise = deferred<boolean>();
      setValue({
        activeModal: { type: "confirm", payload },
        promise,
      });
      return promise.promise;
    },
    openPrompt: (payload) => {
      const promise = deferred<string>();
      setValue({
        activeModal: { type: "prompt", payload },
        promise,
      });
      return promise.promise;
    },
    openForm: (payload) => {
      const promise = deferred<false | React.FormEvent>();
      setValue({
        activeModal: { type: "form", payload },
        promise,
      });
      return promise.promise;
    },
    openCustomModal: <T>(payload: (onClose: (value: T) => void) => React.ReactNode) => {
      const promise = deferred<T>();
      setValue({
        activeModal: { type: "custom", payload: payload as any },
        promise,
      });
      return promise.promise;
    },
  };
}
