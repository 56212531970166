import { Functions } from "@cp/toolkit";
import React, { createContext, ProviderProps } from "react";

export type SaveFormFunction = (key: string, value: unknown) => void;

const SaveFormContext = createContext<SaveFormFunction>(Functions.NOOP);

export const SaveFormProvider = ({ value, children }: ProviderProps<SaveFormFunction>) => {
  return <SaveFormContext.Provider value={value}>{children}</SaveFormContext.Provider>;
};

export const useSaveForm = () => React.useContext(SaveFormContext);
