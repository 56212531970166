import { Stack, Toolbar } from "@mui/material";
import * as React from "react";

interface HeaderProps {
  leftToolbar?: React.ReactNode;
  rightToolbar?: React.ReactNode;
}

export const TopBar = ({ leftToolbar, rightToolbar }: HeaderProps) => {
  return (
    <Stack
      direction="row"
      zIndex={1}
      justifyContent="space-between"
      sx={{ minHeight: "60px", backgroundColor: "white" }}
      boxShadow={1}
      flexShrink={0}
    >
      <Toolbar sx={{ gap: 3 }}>{leftToolbar}</Toolbar>
      <Toolbar sx={{ gap: 3 }}>{rightToolbar}</Toolbar>
    </Stack>
  );
};
