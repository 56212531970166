/**
 * Find the difference between two sets.
 * A - B
 */
export function difference<T>(a: Set<T>, b: Set<T>): Set<T> {
  const diff = new Set<T>();
  a.forEach((item) => {
    if (!b.has(item)) {
      diff.add(item);
    }
  });
  return diff;
}

export function collect<T>(iterable: Iterable<T | undefined | null>): Set<T> {
  const result = new Set<T>();
  for (const item of iterable) {
    if (item != null) {
      result.add(item);
    }
  }
  return result;
}

const EMPTY = new Set<any>();
export function empty<T>(): ReadonlySet<T> {
  return EMPTY;
}
