import { CellDataType } from "@qw/table";

export function formatCellValue(value: string | null, dataType: CellDataType): string {
  if (value == null || value === "") {
    return "";
  }

  switch (dataType) {
    case "percentage":
      return `${value}%`;
    default:
      return value;
  }
}
