import { useEffect, useRef, useState } from "react";

// Adapted from https://usehooks.com/useOnScreen/
export function useOnScreen<T extends Element>(rootMargin = "0px") {
  const ref = useRef<T | null>(null);
  // State and setter for storing whether element is visible
  const [isIntersecting, setIntersecting] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        // Update our state when observer callback fires
        setIntersecting(entry.isIntersecting);
      },
      {
        rootMargin,
      }
    );
    const current = ref.current;
    if (current) {
      observer.observe(current);
    }
    return () => {
      if (current) {
        observer.unobserve(current);
      }
    };
  }, []); // Empty array ensures that effect is only run on mount and unmount

  return [isIntersecting, ref] as const;
}
