import { useFormRenderers } from "../context/FormRendererProvider";
import { ContactFragment, Scalars, useApplicationContactsQuery } from "../generated/operations";
import { FormFieldProps } from "./FormFieldProps";
import { FormFieldSelect } from "./FormFieldSelect";

interface Props extends FormFieldProps {
  applicationId: string;
}

export const FormFieldContactSelect = (props: Props) => {
  const { data: contactsData } = useApplicationContactsQuery({
    variables: {
      id: props.applicationId as Scalars["ApplicationId"],
    },
  });

  const { onCreateContact } = useFormRenderers();

  const contacts = contactsData?.applicationContacts ?? [];

  const options = [
    { label: "", value: "" },
    ...contacts.map((contact) => ({
      label: getContactLabel(contact),
      value: stringifyContact(contact),
    })),
  ];

  const onCreate = async () => {
    if (!onCreateContact) {
      throw new Error("onCreateContact is not defined");
    }
    const contact = await onCreateContact();
    if (!contact) {
      return;
    }
    return {
      value: stringifyContact(contact),
      label: getContactLabel(contact),
    };
  };

  return <FormFieldSelect {...props} options={options} onCreate={onCreateContact ? onCreate : undefined} />;
};

const stringifyContact = (contact: ContactFragment) => {
  return [`${contact.firstName} ${contact.lastName}`.trim(), contact.title, contact.phone, contact.email]
    .filter(Boolean)
    .join(" - ");
};

const getContactLabel = (contact: ContactFragment) => {
  return [`${contact.firstName} ${contact.lastName}`.trim(), contact.title].filter(Boolean).join(" - ");
};
