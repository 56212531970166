import { useApolloClient } from "@apollo/client";
import { useContext } from "react";
import { useCurationQuestionsFilterResults } from "../../../components/panels/asyncAtoms";
import { ClobQuestionFragment, CurationQuestionFragment, CurationSectionFragment } from "../../../generated/graphql";
import { CurationStateContext } from "./CurationContext";
import { AllClobQuestionFragments, AllCurationQuestionFragments, AllCurationSectionFragments } from "./fragments";

export function useCurationState() {
  const state = useContext(CurationStateContext);
  const client = useApolloClient();
  const curationQuestionFilterRes = useCurationQuestionsFilterResults();

  const getQuestion = (id: string) => {
    if (curationQuestionFilterRes.size > 0 && !curationQuestionFilterRes.has(id)) {
      return null;
    }

    return client.readFragment<CurationQuestionFragment>({
      id: `CurationQuestion:${id}`,
      fragment: AllCurationQuestionFragments,
      fragmentName: "CurationQuestion",
    });
  };

  const getSection = (id: string) => {
    return client.readFragment<CurationSectionFragment>({
      id: `CurationSection:${id}`,
      fragment: AllCurationSectionFragments,
      fragmentName: "CurationSection",
    });
  };

  const getClobQuestion = (id: string) => {
    return client.readFragment<ClobQuestionFragment>({
      id: `ClobQuestion:${id}`,
      fragment: AllClobQuestionFragments,
      fragmentName: "ClobQuestion",
    });
  };

  return {
    ...state,
    /**
     * Get a question by ID. This loads the question from the Apollo cache.
     * It is expected that the question is already loaded in the cache.
     */
    getQuestion,
    /**
     * Get a section by ID. This loads the section from the Apollo cache.
     * It is expected that the section is already loaded in the cache.
     */
    getSection,
    /**
     * Get a clob question by ID. This loads the question from the Apollo cache.
     * It is expected that the question is already loaded in the cache.
     */
    getClobQuestion,
    /**
     * Get a title for any ID. Will search the state for a matching ID and existing title.
     */
    getTitle: (id: string) => {
      return getQuestion(id)?.text || getSection(id)?.title || "";
    },
  };
}
