import { Checkbox, styled } from "@mui/material";

export const EditCheckboxCell = styled(Checkbox)(({ theme }) => ({
  ...theme.typography.body2,
  padding: "1px 0",
  "& input": {
    padding: "0 16px",
    height: "100%",
  },
}));
