import { RefinementCtx, ZodType } from "zod";

export interface BaseOptions<T> {
  isRequired: boolean;
  defaultValue: T;
  validation: ZodType<T | undefined | null>;
  superRefine?: (obj: any, context: RefinementCtx) => void;
}

export class OptionHolder<T, O> {
  private constructor(private _options: BaseOptions<T> & O) {}

  static from<TT, OO>(options: OO & { defaultValue?: TT; validation: ZodType<TT> }): OptionHolder<TT, OO> {
    return new OptionHolder<TT, OO>({
      defaultValue: undefined as any as TT,
      isRequired: true,
      ...options,
    });
  }

  public get options(): O & BaseOptions<T> {
    return this._options;
  }

  public withOptions = <K extends keyof O>(newOptions: Pick<O, K> | Partial<BaseOptions<T>>) => {
    this._options = { ...this.options, ...newOptions };
    if ("superRefine" in newOptions && newOptions.superRefine && !newOptions.validation) {
      this._options.validation = this._options.validation.superRefine(newOptions.superRefine);
    }
  };

  public withOptional = () => {
    this._options.validation = this._options.validation.optional();
    this._options.isRequired = false;
  };

  public toJSON() {
    const { validation, ...rest } = this._options;
    return {
      validation: validation.description,
      ...rest,
    };
  }
}
