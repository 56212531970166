import React, { createContext, ProviderProps } from "react";

const FormIdContext = createContext("");

export const FormIdProvider = ({ value, children }: ProviderProps<string>) => {
  return <FormIdContext.Provider value={value}>{children}</FormIdContext.Provider>;
};

export const useFormId = () => {
  const result = React.useContext(FormIdContext);
  if (result === "") {
    throw new Error("Missing formId. Are you rendering within a ConnectedForm?");
  }
  return result;
};
