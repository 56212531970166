/**
 * These values need to match up with values that exist in ontolio and at present
 * are effectively database constants. Should these values ever actually change, then
 * hopefully this class can be easily retrofitted to provide dynamic values.
 *
 * These are curation question_ids
 */
export class QuestionIds {
  static readonly Street1 = "8587b258-67fa-4313-be50-9d3febeb5c5b";
  static readonly Street2 = "b58a1e8c-0456-4731-872b-aa95b606c645";
  static readonly City = "c2b0ca70-b2dd-4948-b305-e45765abf9b1";
  static readonly State = "000db033-3528-4c91-97ea-486676d613d7";
  static readonly County = "866c9041-2138-42b6-aac3-f003a6339cb8";
  static readonly Zip = "835ed9cf-2c87-427d-ab5a-1422470286ba";

  static readonly NAICS = "c74bd32a-37cf-47f2-a834-1ab5b5fe9585";
  static readonly SIC = "c0d4765c-7fc3-4c08-ae10-b08ae15b0012";
  static readonly CGLCode = "7e4d3cd8-e1ec-41d7-9700-29874254901d";

  static readonly EffectiveDate = "e9ad8edf-f24e-49df-bd7c-21bd793953c8";
  static readonly ExpirationDate = "f6ce06ec-3202-4bb2-b9e7-b74d09217911";
  static readonly PriorEffectiveDate = "53b2d8fa-8d23-458d-97b0-e58bb479fd5b";
  static readonly PriorExpirationDate = "0ee67e4c-9c7d-4836-9b5f-7d90872ef615";
  static readonly PolicyEffectiveDate = "e4f2acb3-9eb4-40a5-99c0-072f5edec433";
  static readonly PolicyExpirationDate = "65a71501-33cc-4084-ac75-610faa6bed60";

  static readonly BusinessDescription = "db90dddd-ebb7-447a-9777-6042ac9ad9ec";
  static readonly BusinessStartDate = "46445f52-a995-4a3f-b8f7-8260cdb2057e";
  static readonly DescriptionOfOperations = "6f2b536d-9dae-4cb5-abfa-566044ec6676";
  static readonly DBA = "e04b8f9a-8030-4fd0-b178-a4682ada0082";
  static readonly Website = "971b2c42-3cea-47e3-adeb-452d22f32e27";
  static readonly FEIN = "6a44e29f-64c1-4f8a-882e-dd686f49879f";

  static readonly Year = "cb287ea0-58e1-4a8b-985b-f4c0f2e42ab0";
  static readonly Make = "43b9dd54-0514-4f93-98bc-a7707bc0af7e";
  static readonly Model = "c046891d-4ceb-4df8-9520-6071338bf260";
  static readonly VehicleType = "2619a40e-5d0e-43cc-9f43-3ac9620d9c75";

  static readonly WcLocationQuestion = "16a70c9a-a3be-4fbd-b352-a4b95c6198d3";
  static readonly PredominantState = "5a3c19ad-ae68-4a92-b62e-efdf090629cf";
}
