import type { Components, Theme } from "@mui/material/styles";

export function CssBaseline(theme: Theme): Components<Theme> {
  return {
    MuiCssBaseline: {
      styleOverrides: {
        a: {
          color: theme.palette.primary.main,
        },
      },
    },
  };
}
