import { useEvent } from "@cp/react-hooks";
import { Box, Typography } from "@mui/material";
import React from "react";
import { DragableQuestion } from "../Dragables/DragableQuestion";
import { DragableSection } from "../Dragables/DragableSection";
import { MoveHandler } from "../Dragables/DragTypes";
import { useCurationActions } from "../state/useCurationActions";
import { useCurationState } from "../state/useCurationState";

interface SectionQuestionKeyListProps {
  sectionId: string;
  depth: number;
  disableSelection?: boolean;
}

export const SectionQuestionList: React.FC<SectionQuestionKeyListProps> = ({ sectionId, depth, disableSelection }) => {
  const { getQuestion, curationNodeMap, getSection } = useCurationState();
  const { moveWithinNode } = useCurationActions();
  const section = curationNodeMap.get(sectionId);
  const move: MoveHandler = useEvent(({ dragItemId, moveIndex }) => {
    if (section) {
      moveWithinNode({
        childId: dragItemId,
        parentId: section.id,
        toIndex: moveIndex,
      });
    }
  });

  const renderCurationQuestion = (curationQuestionId: string, index: number) => {
    const curationQuestion = getQuestion(curationQuestionId);

    if (!curationQuestion) {
      return null;
    }

    return (
      <DragableQuestion
        key={curationQuestion.id}
        canToggleConditionalQuestion={true}
        depth={depth}
        canRemove={true}
        parentId={sectionId}
        curationQuestion={curationQuestion}
        index={index}
        move={move}
        disableSelection={disableSelection}
      />
    );
  };

  const renderNestedSection = (nestedSectionId: string, index: number) => {
    return (
      <DragableSection
        key={nestedSectionId}
        id={nestedSectionId}
        depth={depth}
        parentId={sectionId}
        index={index}
        move={move}
      />
    );
  };

  if (!section) {
    return null;
  }

  return (
    <>
      {section.children.length === 0 && (
        <Box py={2}>
          <Typography component="i" color="text.secondary">
            Empty section
          </Typography>
        </Box>
      )}
      {section.children.map((id, i) =>
        getQuestion(id) ? renderCurationQuestion(id, i) : getSection(id) ? renderNestedSection(id, i) : null
      )}
    </>
  );
};

export default SectionQuestionList;
