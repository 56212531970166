import { Maps } from "@cp/toolkit";
import { createContext, FC, PropsWithChildren, useContext, useMemo } from "react";
import { FormContainerFragment, FormQuestionFragment, FormTreeItemFragment } from "../generated/operations";

const FormContext = createContext<FormContextValue>({
  applicationId: undefined,
  containerMap: Maps.EMPTY,
  questionMap: Maps.EMPTY,
  treeItemMap: Maps.EMPTY,
});

interface FormContextProps {
  applicationId: string | undefined;
  containers: FormContainerFragment[];
  questions: FormQuestionFragment[];
  treeItems: FormTreeItemFragment[];
}

export interface FormContextValue {
  applicationId: string | undefined;
  containerMap: ReadonlyMap<FormContainerFragment["id"], FormContainerFragment>;
  questionMap: ReadonlyMap<FormQuestionFragment["id"], FormQuestionFragment>;
  treeItemMap: ReadonlyMap<FormTreeItemFragment["id"], FormTreeItemFragment>;
}

/**
 * Provider for the Form context. This contains the form questions and layout - the view of the form, but no data.
 */
export const FormContextProvider: FC<PropsWithChildren<FormContextProps>> = ({
  children,
  containers,
  questions,
  treeItems,
  applicationId,
}) => {
  const value = useMemo(() => {
    return {
      applicationId,
      containerMap: Maps.keyBy(containers, (c) => c.id),
      questionMap: Maps.keyBy(questions, (q) => q.id),
      treeItemMap: Maps.keyBy(treeItems, (t) => t.id),
    };
  }, [applicationId, containers, questions, treeItems]);

  return <FormContext.Provider value={value}>{children}</FormContext.Provider>;
};

/**
 * React hook to get the FormContext
 */
export function useFormContext() {
  return useContext(FormContext);
}

/**
 * Override the FormContextProvider. This can be used to remove sections or change the layout.
 */
export const OverrideFormContextProvider: FC<PropsWithChildren<Partial<FormContextValue>>> = ({
  children,
  ...overrides
}) => {
  const context = useFormContext();

  return (
    <FormContext.Provider
      value={{
        ...context,
        ...overrides,
      }}
    >
      {children}
    </FormContext.Provider>
  );
};
