import {
  Assignment,
  Business,
  Category,
  Class,
  Construction,
  CoPresent,
  DirectionsBoat,
  DirectionsCar,
  Engineering,
  FactCheck,
  Flight,
  Group,
  Groups,
  HomeWork,
  Money,
  PendingActions,
  Person,
  PersonSearch,
  ReportProblem,
  RvHookup,
  Warehouse,
} from "@mui/icons-material";
import { EntityType } from "../../generated/graphql";

// Duplicated in applications/web
export const entityTypeIdToIcon: Record<EntityType, React.ReactElement> = {
  [EntityType.AdditionalInsured]: <Group />,
  [EntityType.AdditionalInterest]: <Groups />,
  [EntityType.Location]: <Business />,
  [EntityType.Loss]: <Money />,
  [EntityType.Driver]: <Person />,
  [EntityType.Vehicle]: <DirectionsCar />,
  [EntityType.Trailer]: <RvHookup />,
  [EntityType.Policy]: <Assignment />,
  [EntityType.PriorPolicy]: <PendingActions />,
  [EntityType.Hazard]: <ReportProblem />,
  [EntityType.Item]: <Category />,
  [EntityType.WorkersCompRateClass]: <Class />,
  [EntityType.Boat]: <DirectionsBoat />,
  [EntityType.Aircraft]: <Flight />,
  [EntityType.Terminal]: <Warehouse />,
  [EntityType.SpecificIndividual]: <PersonSearch />,
  [EntityType.Equipment]: <Construction />,
  [EntityType.Job]: <Engineering />,
  [EntityType.DirectorsAndOfficers]: <CoPresent />,
  [EntityType.ProductsAndOrCompletedOperations]: <FactCheck />,
  [EntityType.Building]: <HomeWork />,
};
