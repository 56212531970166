import { Box, Stack, Typography } from "@mui/material";
import { useMatch } from "@tanstack/react-location";
import React from "react";
import { LocationGenerics } from "../../App";
import { MatchingGoldenQuestionsCard } from "../../components/cards/clob-questions/MatchingGoldenQuestionsCard";
import { CurationNodeSearch } from "../../components/CurationNodeSearch";
import { Panels } from "../../components/panels/Panels";
import { GOLDEN_CURATION_APPLICATION_ID } from "../../constants";
import { useCarrierProjectQuery } from "../../generated/graphql";
import { CurationControls } from "../clobs/MapClob/components/CurationControls";
import { CurationProvider } from "../curation/state/CurationProvider";
import { CurationContextFeature } from "../curation/state/types";
import { useSetCurationApplicationToCarrierLineOfBusiness } from "../hooks/useSetCurationApplicationToCarrierLineOfBusiness";

const GOLDEN_QN_PANEL_SCOPE = Symbol("Golden Question Panel");

export const MappingPage: React.FC = () => {
  const {
    params: { projectId },
  } = useMatch<LocationGenerics>();

  const { data: projectData } = useCarrierProjectQuery({ variables: { id: projectId }, skip: !projectId });
  useSetCurationApplicationToCarrierLineOfBusiness(projectData?.carrierProject ?? {});

  return (
    <>
      <Box sx={{ padding: "15px", backgroundColor: "grey.200" }}>
        <Typography>
          Browse, search and review matches to this question in the Question Library panel at right. When you find a
          match, select the checkbox on the Golden Question at right, and click the 'swap' icon on the question on the
          left. When mapped, the Golden Question will replace the original question in the Carrier Application form
          panel at right.
        </Typography>
      </Box>
      <Panels
        id="projects"
        config={[
          {
            title: "SCA",
            noPadding: true,
            content: (
              <Stack gap={4}>
                <CurationNodeSearch />
                <CurationControls curationContext={CurationContextFeature.GOLDEN_QUESTION_SWAPPING_SCA_PANEL} />
              </Stack>
            ),
          },
          {
            title: "Golden Question Panel",
            noPadding: true,
            content: (
              <Stack gap={4}>
                <CurationProvider atomScope={GOLDEN_QN_PANEL_SCOPE}>
                  <MatchingGoldenQuestionsCard />
                  <CurationNodeSearch />
                  <CurationControls
                    lockedCurationApplicationId={GOLDEN_CURATION_APPLICATION_ID}
                    curationContext={CurationContextFeature.GOLDEN_QUESTION_LIBRARY}
                  />
                </CurationProvider>
              </Stack>
            ),
          },
        ]}
      />
    </>
  );
};
