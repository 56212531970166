import { Stack } from "@mui/material";
import React, { PropsWithChildren } from "react";

interface Props {
  header?: React.ReactNode;
  footer?: React.ReactNode;
  bodySx?: React.CSSProperties;
}

/**
 * Container with scrollable content and optional header and footer
 */
export const Container: React.FC<PropsWithChildren<Props>> = ({ header, footer, children, bodySx }) => {
  return (
    <Stack flex={1} height="100%" overflow="hidden">
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        px={3}
        height={50}
        zIndex={1}
        sx={{
          backgroundColor: "white",
          boxShadow: 1,
        }}
      >
        {header}
      </Stack>
      <Stack flex={1} overflow="auto" sx={bodySx}>
        {children}
      </Stack>
      {footer}
    </Stack>
  );
};
