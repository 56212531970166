import { Stack, Typography } from "@mui/material";
import * as React from "react";
import { CodeChip } from "../business-classification/CodeChip";
import { formatCode, WCCodeClassificationQueryResult } from "./WorkersCompUtils";

function getSystem(codeType: string | undefined | null): string {
  return !codeType || codeType === "NCCI" ? "NCCI" : codeType;
}

export const renderWorkersCompClassificationOption = (
  props: React.HTMLAttributes<HTMLElement>,
  { ncciDescription, generalDescription, ncciCode, matchingCode }: WCCodeClassificationQueryResult
) => (
  <li {...props}>
    <Stack
      flex={1}
      sx={{
        cursor: "pointer",
        backgroundColor: "grey.50",
      }}
    >
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography variant="h4">{ncciDescription || generalDescription}</Typography>
        <CodeChip
          code={formatCode(matchingCode?.code) || formatCode(ncciCode) || ""}
          system={getSystem(matchingCode?.codeType)}
        />
      </Stack>
    </Stack>
  </li>
);
