import { ScreenshotMonitor } from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";

interface Props {
  fileUrl: string | null | undefined;
}

export function ViewScreenshot({ fileUrl }: Props) {
  return (
    <Tooltip title="View screenshot">
      <IconButton component="a" disabled={!fileUrl} color="inherit" href={fileUrl || ""} target="_blank">
        <ScreenshotMonitor />
      </IconButton>
    </Tooltip>
  );
}
