import PhoneIcon from "@mui/icons-material/Phone";
import InputAdornment from "@mui/material/InputAdornment";
import { FormFieldNumberFormat, FormFieldNumberFormatProps } from "./FormFieldNumberFormat";

export const FormFieldPhone: React.VFC<FormFieldNumberFormatProps> = (props) => {
  return (
    <FormFieldNumberFormat
      format="+1 (###) ###-####"
      extractValueAs="formatted-string"
      mask="_"
      type="tel"
      {...props}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <PhoneIcon />
          </InputAdornment>
        ),
      }}
    />
  );
};
