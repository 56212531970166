import { Button, Stack, TableBody as MuiTableBody } from "@mui/material";
import { Column } from "../core/Column";
import { Row } from "../core/Row";
import { Cell } from "./cells/Cell";
import { EditableCell } from "./cells/EditableCell";
import { StyledTableRow } from "./components";

interface Props<T> {
  data: readonly T[];
  row: Row<T>;
  RowWrapper?: React.FC<
    React.PropsWithChildren<{
      rowId: T;
    }>
  >;

  columns: Array<Column<T>>;
  flex?: boolean;
  onAdd?: () => void;
}

export function TableBody<T>({ onAdd, data, row, columns, flex, RowWrapper }: Props<T>) {
  return (
    <MuiTableBody>
      {data.map((datum, index) => {
        const tableRow = (
          <StyledTableRow key={index} sx={{ ...row.getRowStyles(datum), display: flex ? "flex" : undefined }}>
            {columns.map((column) => {
              const isEditable = !!column.editable;
              if (isEditable) {
                return <EditableCell key={column.id} column={column} index={index} datum={datum} />;
              }

              return <Cell key={column.id} column={column} index={index} datum={datum} />;
            })}
          </StyledTableRow>
        );

        return (
          <WrapRow rowWrapper={RowWrapper} key={index} rowId={datum}>
            {tableRow}
          </WrapRow>
        );
      })}
      {onAdd && (
        <Stack key="_add" sx={{ backgroundColor: "grey.50" }} alignItems="flex-start" direction="row">
          <Button variant="text" size="small" sx={{ m: 1 }} onClick={onAdd}>
            Add Row
          </Button>
        </Stack>
      )}
    </MuiTableBody>
  );
}

interface WrapRowProps<T> {
  rowWrapper?: React.FC<
    React.PropsWithChildren<{
      rowId: T;
    }>
  >;
  children: React.ReactNode;
  rowId: T;
}

function WrapRow<T>({ rowWrapper: RowWrapper, children, rowId }: WrapRowProps<T>) {
  if (RowWrapper) {
    return <RowWrapper rowId={rowId}>{children}</RowWrapper>;
  }
  return <>{children}</>;
}
