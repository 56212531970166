import { Stack, Typography } from "@mui/material";

interface Props {
  title: string;
  description?: string;
  icon?: React.ReactNode;
  action?: React.ReactNode;
}

export function EmptyState({ title, description, icon, action }: Props) {
  return (
    <Stack height="100%" flex={1} alignContent="center" justifyContent="center">
      <Stack gap={2} alignItems="center">
        <Typography color="text.disabled" fontSize={4}>
          {icon}
        </Typography>
        <Typography fontSize={20} fontWeight={700}>
          {title}
        </Typography>
        {description && <Typography>{description}</Typography>}
        {action}
      </Stack>
    </Stack>
  );
}
