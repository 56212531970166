import { useEvent, useSearchQuery } from "@cp/react-hooks";
import { Stack } from "@mui/material";
import React, { useEffect } from "react";
import { SearchInput } from "../../../components/SearchInput";
import { useSearchCurationNodes } from "../../curation/atoms";
import { DragableSection } from "../../curation/Dragables/DragableSection";
import { MoveHandler } from "../../curation/Dragables/DragTypes";
import { ROOT } from "../../curation/state/types";
import { useAllCurationQuestions } from "../../curation/state/useAllCurationQuestions";
import { useCurationActions } from "../../curation/state/useCurationActions";
import { useCurationState } from "../../curation/state/useCurationState";
import { AddEntityButton } from "../../curation/Toolbar/AddEntityButton";
import { Toolbar } from "../../curation/Toolbar/Toolbar";

export const CurationEntityLayout: React.VFC = () => {
  const { rootIds, curationNodeMap } = useCurationState();
  const { moveWithinNode } = useCurationActions();

  const searchQuery = useSearchQuery();
  const questions = useAllCurationQuestions();
  const { onSearch } = useSearchCurationNodes(curationNodeMap, questions);

  useEffect(() => {
    onSearch(searchQuery.debouncedValue);
  }, [searchQuery.debouncedValue]);

  const moveRoot: MoveHandler = useEvent(({ dragItemId, moveIndex }) => {
    moveWithinNode({
      childId: dragItemId,
      toIndex: moveIndex,
      parentId: ROOT,
    });
  });

  const renderSection = (id: string, index: number) => {
    const section = curationNodeMap.get(id);
    if (!section) {
      return null;
    }

    return <DragableSection parentId={ROOT} depth={0} key={section.id} index={index} id={section.id} move={moveRoot} />;
  };

  return (
    <Stack gap={2}>
      <Toolbar
        left={<SearchInput {...searchQuery.inputProps} />}
        right={<AddEntityButton parentNodeId={undefined} addType="section" />}
      />
      {rootIds.map(renderSection)}
    </Stack>
  );
};
