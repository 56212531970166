import { exactMatch } from "@cp/toolkit";
import { atom, useAtomValue, useSetAtom } from "jotai";
import { atomFamily } from "jotai/utils";
import { useMemo, useState } from "react";
import { useAtomScope, useSelectedCurationApplicationId } from "../../components/panels/atoms";
import { CurationQuestionFragment, useCurationApplicationByIdQuery } from "../../generated/graphql";
import { CurationContextState } from "./state/types";

/**
 * This is the search query that is used to filter the curation tree.
 */
export const searchQueryAtom = atom<string>("");

interface ExpandedState {
  isAllExpanded: boolean;
  explicityCollapsed: Set<string>;
  expanded: Set<string>;
  expandedStateWhileSearching: Map<string, boolean>;
  searchHits: Set<string>;
  isSearching: boolean;
}

const expandedNodesAtom = atom<ExpandedState>({
  /**
   * Expand all, unless exists in explicityCollapsed
   */
  isAllExpanded: false,
  /**
   * Nodes that are explicitly collapsed, only used when isAllExpanded is true
   */
  explicityCollapsed: new Set<string>(),
  /**
   * Nodes that are expanded, only used when isAllExpanded is false
   */
  expanded: new Set<string>(),
  /**
   * Expanded state of nodes while searching
   */
  expandedStateWhileSearching: new Map<string, boolean>(),
  searchHits: new Set<string>(),
  isSearching: false,
});

export const searchResults = atom<string[] | "loading">([]);

export function useExpandedNodes() {
  const scope = useAtomScope();
  const setExpandedNodes = useSetAtom(expandedNodesAtom, scope);
  return {
    expandMany: (ids: string[]) => {
      setExpandedNodes((state) => {
        const expanded = new Set(state.expanded);
        ids.forEach((id) => expanded.add(id));
        return { ...state, expanded };
      });
    },
    expandAll: () => {
      setExpandedNodes((state) => ({
        ...state,
        isAllExpanded: true,
        explicityCollapsed: new Set(),
        expanded: new Set(),
        expandedNodesAtom: new Map(),
      }));
    },
    collapseAll: () => {
      setExpandedNodes((state) => ({
        ...state,
        isAllExpanded: false,
        explicityCollapsed: new Set(),
        expanded: new Set(),
        expandedNodesAtom: new Map(),
      }));
    },
  };
}

const expandedNodeFamily = atomFamily((id: string) =>
  atom((get) => {
    const { expanded, expandedStateWhileSearching, searchHits, isSearching, isAllExpanded, explicityCollapsed } =
      get(expandedNodesAtom);
    // If we are searching, we default to expanded, unless explicitly collapsed.
    if (isAllExpanded) {
      return !explicityCollapsed.has(id);
    }

    if (isSearching) {
      // explicitly collapsed/expanded
      if (expandedStateWhileSearching.has(id)) {
        return expandedStateWhileSearching.get(id)!;
      }
      // otherwise expand if its a search hit
      return searchHits.has(id);
    }
    return expanded.has(id);
  })
);

/**
 * This state is global and persisted across mount/unmounts.
 * This allows for searching, but then after searching, the user still has the same expanded entities.
 */
export function useExpandEntity(id: string): [isExpanded: boolean, toggle: () => void, searchQuery: string] {
  const scope = useAtomScope();
  const isExpanded = useAtomValue(expandedNodeFamily(id), scope);
  const setExpandedNodes = useSetAtom(expandedNodesAtom, scope);
  const searchQuery = useAtomValue(searchQueryAtom, scope);

  return [
    isExpanded,
    () => {
      setExpandedNodes((prev) => {
        if (isExpanded) {
          prev.explicityCollapsed.add(id);
        } else {
          prev.explicityCollapsed.delete(id);
        }

        // When searching
        if (prev.isSearching) {
          const next = new Map(prev.expandedStateWhileSearching);
          next.set(id, !isExpanded);
          return {
            ...prev,
            expandedStateWhileSearching: next,
          };
        }
        // When not searching
        const next = new Set(prev.expanded);
        next.has(id) ? next.delete(id) : next.add(id);
        return {
          ...prev,
          expanded: next,
        };
      });
    },
    searchQuery,
  ];
}

export function useSearchCurationNodes(
  curationNodes: CurationContextState["curationNodeMap"],
  questions: CurationQuestionFragment[]
) {
  const [curationAppId] = useSelectedCurationApplicationId();
  const application = useCurationApplicationByIdQuery({
    variables: { id: curationAppId },
    fetchPolicy: "cache-first",
  });
  const sections = application.data?.curationApplicationById.sections;

  // map to get parents of an entity
  const parentNodeMap = useMemo(() => {
    const map = new Map<string, string>();
    for (const [id, entity] of curationNodes) {
      for (const childId of entity.children) {
        map.set(childId, id);
      }
    }
    return map;
  }, [curationNodes]);

  const scope = useAtomScope();
  const setExpandedNodes = useSetAtom(expandedNodesAtom, scope);
  const setSearchResults = useSetAtom(searchResults, scope);
  const setSearchQuery = useSetAtom(searchQueryAtom, scope);

  const [searchIndex, setSearchIndex] = useState<number>(0);

  // the <Highlighter /> component in <QuestionItem /> will wrap highlighted text in a <mark></mark> tag, so leveraging that
  //  w/ browser API to accomplish this quickly
  //
  // relevant links:
  // https://developer.mozilla.org/en-US/docs/Web/API/HTMLCollection
  // https://developer.mozilla.org/en-US/docs/Web/API/Element/getElementsByTagName
  const markElements = document.getElementsByTagName("mark");

  const onSearch = (query: string) => {
    if (query.trim().length === 0) {
      // reset search and collapsed
      setSearchIndex(0);
      setSearchResults("loading");
      setSearchQuery("");
      setExpandedNodes((prev) => ({
        ...prev,
        searchHits: new Set(),
        questionHits: new Set(),
        expandedStateWhileSearching: new Map(),
        explicityCollapsed: new Set(),
        isAllExpanded: false,
        isSearching: false,
      }));
      return;
    }

    const questionMatches = query
      ? questions.filter((question) =>
          exactMatch(query, [
            question.id,
            question.key,
            question.notes,
            question.text,
            question.componentType,
            question.options.join(" , "),
          ])
        )
      : [];

    const sectionMatches = query ? sections?.filter((section) => exactMatch(query, [section.title])) || [] : [];

    const newSearchHits = new Set<string>();
    [...questionMatches, ...sectionMatches].forEach((item) => {
      // add self and parents
      let parentId: string | undefined = item.id;
      while (parentId) {
        // if we have already visited this node, we can skip
        // also prevent infinite while loop if there is a cycle
        if (newSearchHits.has(parentId)) {
          break;
        }
        newSearchHits.add(parentId);
        parentId = parentNodeMap.get(parentId);
      }
    });

    // add search hits, but don't mess with collapsed or expanded
    setSearchQuery(query);
    setExpandedNodes((prev) => ({
      ...prev,
      searchHits: newSearchHits,
      isSearching: true,
      isAllExpanded: false,
      explicityCollapsed: new Set(),
    }));
    setSearchIndex(0);
    setSearchResults([...questionMatches, ...sectionMatches].map((q) => q.id));
  };

  return {
    onSearch: onSearch,
    scrollToFirstSearchHit: () => {
      setSearchIndex(0);
      markElements[searchIndex]?.scrollIntoView({ behavior: "smooth", block: "center" });
    },
    scrollToNextSearchHit: () => {
      if (searchIndex < markElements.length - 1) {
        setSearchIndex(searchIndex + 1);
      } else {
        setSearchIndex(0);
      }
      markElements[searchIndex]?.scrollIntoView({ behavior: "smooth", block: "center" });
    },
    scrollToPrevSearchHit: () => {
      if (searchIndex > 0) {
        setSearchIndex(searchIndex - 1);
      } else {
        setSearchIndex(markElements.length - 1);
      }
      markElements[searchIndex]?.scrollIntoView({ behavior: "smooth", block: "center" });
    },
    index: searchIndex,
    total: markElements.length,
  };
}
