import { Search } from "@mui/icons-material";
import { CircularProgress, IconButton, InputAdornment, TextField } from "@mui/material";

interface SearchInputProps {
  value: string;
  loading?: boolean;
  placeholder?: string;
  onSubmit?: (value: string) => void;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export function SearchInput({ value, onSubmit, onChange, loading, placeholder = "Search" }: SearchInputProps) {
  return (
    <TextField
      value={value}
      onChange={onChange}
      size="small"
      variant="outlined"
      fullWidth={true}
      placeholder={placeholder}
      disabled={false}
      onKeyDown={(e) => {
        if (e.code === "Enter") {
          // Search on enter.
          // TODO: Could scroll to next result on enter for better user experience.
          e.preventDefault(); // Refreshes current page if not.
          onSubmit?.(value);
        }
      }}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <IconButton
              type="button"
              size="small"
              sx={{ p: "10px" }}
              onClick={() => {
                onSubmit?.(value);
              }}
            >
              <Search fontSize="small" />
            </IconButton>
          </InputAdornment>
        ),
        endAdornment: loading && (
          <InputAdornment position="end">
            <CircularProgress disableShrink={true} thickness={3} size="20px" color="primary" />
          </InputAdornment>
        ),
      }}
    />
  );
}
