import { useFormRenderers } from "../context/FormRendererProvider";
import { EntityType, useApplicationClientEntitiesQuery } from "../generated/operations";
import { FormFieldProps } from "./FormFieldProps";
import { FormFieldSelect } from "./FormFieldSelect";

interface Props extends FormFieldProps {
  applicationId: string;
}

export const FormFieldLocationSelect = (props: Props) => {
  const { data: entitiesData } = useApplicationClientEntitiesQuery({
    variables: {
      input: { entityType: EntityType.Location, applicationId: props.applicationId },
    },
  });

  const { onCreateLocation } = useFormRenderers();

  const emptyOption = {
    label: "",
    id: "",
    __typename: "ApplicationEntity",
  };

  const locations = entitiesData?.applicationClientEntities
    ? [emptyOption, ...entitiesData.applicationClientEntities]
    : [emptyOption];

  const options = locations.map((l) => ({
    label: l.label,
    value: JSON.stringify({
      id: l.id,
      label: l.label,
    }),
  }));

  const onCreate = async () => {
    if (!onCreateLocation) {
      throw new Error("onCreateLocation is not defined");
    }
    const location = await onCreateLocation();
    if (!location) {
      return;
    }
    return {
      value: JSON.stringify({
        id: location.id,
        label: location.label,
      }),
      label: location.label,
    };
  };

  return (
    <FormFieldSelect
      {...props}
      options={options}
      onCreate={onCreateLocation ? onCreate : undefined}
      createLabel="Create New Location"
    />
  );
};
