/**
 * A typed UUID.
 */
export type UUID = string & { __scalar: "UUID" };

export function isUUID(str: string | undefined | null): str is UUID {
  if (!str) {
    return false;
  }

  return /^[\da-f]{8}-[\da-f]{4}-[1-5][\da-f]{3}-[89ab][\da-f]{3}-[\da-f]{12}$/i.test(str);
}
