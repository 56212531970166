import { useBoolean } from "@cp/react-hooks";
import { Button } from "@mui/material";
import { EditableClobQuestion } from "../model/clobs/types";
import {
  useSelectedClobQuestions,
  useSelectedCurationQuestions,
  useUpdateQuestions,
} from "../pages/clobs/MapClob/selection-atom";
import { QuestionMappingDialog } from "./QuestionMappingDialog";

export const QuestionMappingButton = () => {
  const [mappingDialogOpen, mappingDialogOpenActions] = useBoolean(false);

  const [selectedClobQuestions, setSelectedClobQuestions] = useSelectedClobQuestions();
  const [selectedCurationQuestions, setSelectedCurationQuestions] = useSelectedCurationQuestions();
  const [, setUpdateQuestions] = useUpdateQuestions();

  const handleSuccess = (updates: Array<Partial<EditableClobQuestion> & { id: string }>) => {
    setSelectedClobQuestions([]);
    setSelectedCurationQuestions([]);
    setUpdateQuestions(updates);
  };

  return (
    <>
      <Button
        size="small"
        variant="contained"
        onClick={mappingDialogOpenActions.setTrue}
        disabled={!(selectedClobQuestions.length > 0 && selectedCurationQuestions.length === 1)}
      >
        Map Questions
      </Button>
      <QuestionMappingDialog
        open={mappingDialogOpen}
        clobQuestionIds={selectedClobQuestions}
        curationQuestionId={selectedCurationQuestions?.[0]}
        onClose={mappingDialogOpenActions.setFalse}
        onSuccess={handleSuccess}
      />
    </>
  );
};
