import { useBoolean, useDebounce, usePopover } from "@cp/react-hooks";
import { ArrowDropDown } from "@mui/icons-material";
import { Autocomplete, AutocompleteProps, Button, Menu, MenuItem, useTheme } from "@mui/material";
import { isNumberString } from "class-validator";
import * as React from "react";
import { Controller, FieldError, useFormContext } from "react-hook-form";
import { STRICT_STATE_OPTIONS, USState } from "../../../toolkit/src/states/options";
import { useWorkersCompClassSearchQuery } from "../generated/operations";
import { ClassificationCard } from "./common/classification-search/ClassificationCard";
import { renderAutocompleteInputFn, renderSearchField } from "./common/classification-search/ClassificationSearch";
import { useSaveForm } from "./common/useSaveForm";
import { FormFieldProps, processFormFieldProps } from "./FormFieldProps";
import { renderWorkersCompClassificationOption } from "./workers-comp-classification/WorkersCompClassificationResults";
import {
  addMatchingCode,
  DEFAULT_WORKERS_COMP_STATE_CODE,
  deSerializeWorkersCompCodeSafe,
  formatCode,
  getStateSpecificWorkersCompCode,
  serializeWorkersCompCodeSafe,
  StateCodeToWorkersCompCodeType,
  uniqueAndSorted,
  WCCodeClassificationQueryResult,
  WorkersCompCodeType,
} from "./workers-comp-classification/WorkersCompUtils";

export const FormFieldWorkersCompClassification: React.FC<FormFieldProps> = (props) => {
  const { label, sx, ControllerProps, helperText, disabled, required } = processFormFieldProps(props);
  // TODO: Handle the error case
  const { control } = useFormContext(); // retrieve all hook methods

  const saveForm = useSaveForm();

  return (
    <Controller
      control={control}
      defaultValue=""
      {...ControllerProps}
      render={({ field: { onChange, onBlur, value, name }, fieldState: { error } }) => (
        <WorkersCompClassificationInput
          label={label}
          sx={sx}
          required={required}
          value={value}
          onBlur={onBlur}
          error={value === "" ? { type: "required" } : error} // we always want the classification to be required
          helperText={helperText}
          disabled={disabled}
          onChange={(value) => {
            const saveableValue = value?.matchingCode ?? "";
            onChange(saveableValue);
            saveForm(name, serializeWorkersCompCodeSafe(saveableValue));
          }}
        />
      )}
    />
  );
};

interface WorkersCompClassificationInputProps
  extends Omit<AutocompleteProps<any, any, any, any>, "onChange" | "renderInput" | "options"> {
  label: React.ReactNode;
  helperText?: React.ReactNode;
  error?: FieldError;
  disabled?: boolean;
  required?: boolean;
  onChange: (value: WCCodeClassificationQueryResult | null) => void;
}

function WorkersCompClassificationInput({ onChange, value, error, disabled }: WorkersCompClassificationInputProps) {
  const theme = useTheme();
  const [isOpen, isOpenActions] = useBoolean(false);

  // Selected WC class code and state
  const deserializeValue = deSerializeWorkersCompCodeSafe(value);

  const { data, loading } = useWorkersCompClassSearchQuery({
    fetchPolicy: "cache-first",
    variables: {
      queryString: deserializeValue?.id || "",
      stateCode: deserializeValue?.stateCode || "",
    },
    skip: !deserializeValue,
  });

  const omnibar = (
    <WorkersCompClassificationOmnibar
      disabled={disabled}
      initialStateCode={deserializeValue?.stateCode}
      onChange={(value) => {
        onChange(value);
        isOpenActions.setFalse();
      }}
    />
  );

  // If we do not have a value render the search field
  if (!deserializeValue) {
    return renderSearchField(error, theme, isOpenActions, isOpen, omnibar);
  }

  // If we have a value but it is loading, render nothing while we wait for the data
  if (loading) {
    return null;
  }

  const onRemove = disabled
    ? undefined
    : () => {
        onChange(null);
      };

  // If we have a value and it is not loading, render the card
  const foundValue = data?.searchWorkersCompClassCodes.find((c) => c.id === deserializeValue?.id);
  if (foundValue && deserializeValue) {
    const stateCode: USState = deserializeValue.stateCode || DEFAULT_WORKERS_COMP_STATE_CODE;
    const matching = getStateSpecificWorkersCompCode(foundValue, stateCode);
    return (
      <ClassificationCard
        onRemove={onRemove}
        code={formatCode(matching.code) || ""}
        description={foundValue.ncciDescription || foundValue.generalDescription || ""}
        broadDescription=""
        definition=""
        system={StateCodeToWorkersCompCodeType[stateCode] || WorkersCompCodeType.NCCI}
        // Not necessary to show alternate codes
        alternateCodes={[]}
      />
    );
  } else if (deserializeValue) {
    // If no classification found for the saved value show the saved value on a card.
    // This is needed for backwards compatibility, wherein a user might have entered an invalid code in legacy text field component for workers comp.
    return (
      <ClassificationCard
        onRemove={onRemove}
        code={value}
        description={`Code not found: ${value}`}
        broadDescription=""
        definition=""
        system={
          StateCodeToWorkersCompCodeType[deserializeValue.stateCode || DEFAULT_WORKERS_COMP_STATE_CODE] ||
          WorkersCompCodeType.NCCI
        }
        alternateCodes={[]}
      />
    );
  }

  return renderSearchField(error, theme, isOpenActions, isOpen, omnibar);
}

interface WorkersCompClassificationOmnibarProps {
  disabled?: boolean;
  initialStateCode?: USState;
  onBlur?: () => void;
  onChange: (value: WCCodeClassificationQueryResult | null) => void;
}

export const WorkersCompClassificationOmnibar: React.FC<WorkersCompClassificationOmnibarProps> = ({
  onChange,
  disabled,
  onBlur,
  initialStateCode = DEFAULT_WORKERS_COMP_STATE_CODE,
}) => {
  const { targetProps, popoverProps } = usePopover();
  const [stateCode, setStateCode] = React.useState<USState>(initialStateCode);
  const [inputValue, setInputValue] = React.useState("");
  const debouncedInputValue = useDebounce(inputValue, 200);

  const { data: searchData, loading } = useWorkersCompClassSearchQuery({
    variables: { queryString: debouncedInputValue, stateCode: stateCode },
    skip: inputValue.length <= 1,
  });

  const searchResults = addMatchingCode(searchData?.searchWorkersCompClassCodes || [], debouncedInputValue, stateCode);

  const sortedResults = isNumberString(inputValue)
    ? uniqueAndSorted(addMatchingCode(searchResults, inputValue, stateCode))
    : searchResults;

  return (
    <Autocomplete
      className="business-classification"
      options={sortedResults || []}
      filterOptions={(x) => x}
      onInputChange={(_event, newInputValue) => {
        if (newInputValue) {
          setInputValue(newInputValue);
        }
      }}
      getOptionLabel={() => ""}
      renderOption={(props, record) => (loading ? <></> : renderWorkersCompClassificationOption(props, record))}
      onChange={(_e, data, reason) => {
        if (reason === "removeOption") {
          onChange(null);
        }

        if (reason === "selectOption" && data) {
          onChange(data);
        }
      }}
      onBlur={onBlur}
      clearOnBlur={false}
      loading={loading}
      loadingText={`Searching for "${inputValue}"`}
      fullWidth={true}
      selectOnFocus={true}
      open={debouncedInputValue.length > 1}
      noOptionsText={
        debouncedInputValue.length > 1
          ? "No Worker's Comp Classification Found"
          : "Enter a search term, e.g. 'computers'"
      }
      renderInput={renderAutocompleteInputFn({
        loading,
        startAdornment: (
          <>
            <Menu {...popoverProps} MenuListProps={{ disablePadding: true, dense: true }}>
              {STRICT_STATE_OPTIONS.map((option) => (
                <MenuItem key={option.value} onClick={() => setStateCode(option.value)} dense={true}>
                  {option.label}
                </MenuItem>
              ))}
            </Menu>
            <Button {...targetProps} variant="text" disabled={disabled} endIcon={<ArrowDropDown />}>
              {STRICT_STATE_OPTIONS.find((o) => o.value === stateCode)?.label || "Select State"}
            </Button>
          </>
        ),
        placeholderText: "Search Workers Comp",
      })}
      disabled={disabled}
    />
  );
};
