import { memo, PropsWithChildren, useMemo, useReducer } from "react";
import { CurationAtomScopeProvider } from "./CurationAtomScopeProvider";
import { CurationActionsContext, CurationStateContext } from "./CurationContext";
import { curationReducer } from "./reducer";
import { collectIds } from "./serialize";
import { CurationContextActions, INITIAL_STATE } from "./types";

export const CurationProvider = memo(({ atomScope, children }: PropsWithChildren<{ atomScope?: symbol }>) => {
  // Heavily memoized since this is the root of the tree and performance is critical.
  const [state, dispatch] = useReducer(curationReducer, INITIAL_STATE);

  const actions = useMemo((): CurationContextActions => {
    return {
      setState: (payload) => dispatch({ type: "SET_STATE", state: payload }),
      removeCurationSection: (payload) => dispatch({ type: "REMOVE_CURATION_SECTION", id: payload.sectionId }),
      moveToNewNode: (payload) => dispatch({ type: "MOVE_TO_NEW_NODE", ...payload }),
      moveWithinNode: (payload) => dispatch({ type: "MOVE_WITHIN_NODE", ...payload }),
      addNode: (payload) => dispatch({ type: "ADD_NODE", ...payload }),
      removeNode: (payload) => dispatch({ type: "REMOVE_NODE", ...payload }),
      swapNode: async (payload) => dispatch({ type: "SWAP_NODE", ...payload }),
      makeConditionalQuestion: (payload) => dispatch({ type: "MAKE_CONDITIONAL_QUESTION", ...payload }),
      unmakeConditionalQuestion: (payload) => dispatch({ type: "UNMAKE_CONDITIONAL_QUESTION", ...payload }),
    };
  }, [dispatch]);

  // We add additional properties that are useful for child components to have access to.
  // But we would rather do the computation here (once) than in the child components (many times).
  const value = useMemo(() => {
    return {
      ...state,
      ...collectIds(state.curationNodeMap),
    };
  }, [state]);

  return (
    <CurationAtomScopeProvider scope={atomScope}>
      <CurationStateContext.Provider value={value}>
        <CurationActionsContext.Provider value={actions}>{children}</CurationActionsContext.Provider>
      </CurationStateContext.Provider>
    </CurationAtomScopeProvider>
  );
});

CurationProvider.displayName = "CurationProvider";
