import { Clear } from "@mui/icons-material";
import { IconButton, ListItemIcon, ListItemText, MenuItem, Typography } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import InputLabel, { InputLabelProps } from "@mui/material/InputLabel";
import Select, { SelectProps } from "@mui/material/Select";
import * as React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useSaveForm } from "./common/useSaveForm";
import { FormFieldProps, processFormFieldProps } from "./FormFieldProps";

interface Props extends FormFieldProps {
  options?: Array<{ value: string; label: React.ReactNode; secondary?: string; icon?: React.ReactNode }>;
  placeholder?: string;
  SelectProps?: SelectProps<string>;
  InputLabelProps?: InputLabelProps;
  displayEmpty?: boolean;
  onCreate?: () => Promise<{ value: string; label: string } | undefined>;
  createLabel?: string;
}

export const FormFieldSelect: React.FC<Props> = (props) => {
  const { displayEmpty = true } = props;
  const { control } = useFormContext(); // retrieve all hook methods
  const {
    label,
    helperText,
    options = [],
    sx,
    placeholder,
    ControllerProps,
    disabled,
    required,
    createLabel,
    onCreate,
  } = processFormFieldProps(props);
  const saveForm = useSaveForm();

  return (
    <Controller
      control={control}
      defaultValue=""
      {...ControllerProps}
      render={({ field, fieldState: { error } }) => (
        <FormControl fullWidth={true} error={!!error} sx={sx} required={required}>
          {label !== "" && (
            <InputLabel required={required} {...props.InputLabelProps}>
              {label}
            </InputLabel>
          )}

          <Select
            {...field}
            {...props.SelectProps}
            required={required}
            placeholder={placeholder}
            multiple={false}
            data-test={`${props.name}-select`}
            displayEmpty={displayEmpty}
            renderValue={(value: string) => {
              const item = options.find(({ value: v }) => v === value);
              return (
                item?.label ||
                jsonStringWithLabel(value) ||
                value || <Typography color="text.secondary">{placeholder}</Typography>
              );
            }}
            onChange={(e) => {
              field.onChange(e);
              saveForm(field.name, e.target.value);
            }}
            disabled={disabled}
            endAdornment={
              !required && (
                <IconButton
                  size="small"
                  sx={{ visibility: field.value ? "visible" : "hidden", mr: 2 }}
                  onClick={() => {
                    field.onChange("");
                    saveForm(field.name, "");
                  }}
                >
                  <Clear fontSize="small" />
                </IconButton>
              )
            }
          >
            {options.map((option) => {
              // Hide empty options
              if (option.value === "" || option.label === "") {
                return null;
              }

              return (
                <MenuItem key={option.value} value={option.value}>
                  {option.icon && <ListItemIcon sx={{ minWidth: "40px" }}>{option.icon}</ListItemIcon>}
                  <ListItemText primary={option.label} secondary={option.secondary} />
                </MenuItem>
              );
            })}
            {onCreate && (
              <MenuItem
                onClick={async () => {
                  const created = await onCreate();
                  if (created) {
                    field.onChange(created.value);
                    saveForm(field.name, created.value);
                  }
                }}
              >
                <ListItemText sx={{ color: "primary.main" }} primary={createLabel || "Create New"} />
              </MenuItem>
            )}
          </Select>
          {helperText && <FormHelperText>{helperText}</FormHelperText>}
          {error?.message && <FormHelperText error={true}>{error?.message}</FormHelperText>}
        </FormControl>
      )}
    />
  );
};

function jsonStringWithLabel(str: unknown): string | undefined {
  if (typeof str !== "string") {
    return undefined;
  }
  if (!str) {
    return undefined;
  }
  try {
    const obj = JSON.parse(str) as Record<string, unknown> | undefined | null;
    if (typeof obj !== "object" || obj == null) {
      return undefined;
    }
    if ("label" in obj && typeof obj.label === "string") {
      return obj.label;
    }
  } catch {
    // ignore
  }
  return undefined;
}
