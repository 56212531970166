import { Stack, Typography } from "@mui/material";
import React from "react";
import { Visibility } from "../../../components/Visibility";
import { AddNewTreeItem } from "./AddNewTreeItem";
import { EditableClobSectionTitle } from "./EditableClobSectionTitle";

interface Props {
  id: string;
  depth: number;
}

export const ClobSectionTitle: React.FC<Props> = ({ id, depth }) => {
  return (
    <Stack className="hide-action-items" gap={1} direction="row" alignItems="center">
      <Typography fontWeight={700} color="text.disabled">
        H{depth + 1}
      </Typography>
      <EditableClobSectionTitle id={id} />
      <Visibility>
        <AddNewTreeItem parentId={id} />
      </Visibility>
    </Stack>
  );
};
