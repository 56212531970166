import { Boundary } from "@cp/async";
import { UnfoldMore } from "@mui/icons-material";
import { Box, IconButton, Stack, styled, Typography, useTheme } from "@mui/material";
import React from "react";
import { ImperativePanelHandle, Panel, PanelGroup } from "react-resizable-panels";
import { ResizeHandle } from "./ResizeHandle";

interface PanelConfig {
  title: string;
  /**
   * 0 to 100
   */
  defaultSize?: number;
  collapsible?: boolean;
  header?: React.ReactNode;
  noPadding?: boolean;
  content: React.ReactNode;
}

interface Props {
  id: string;
  config: PanelConfig[];
}

const COLLAPSE_SIZE = 5;

const Root = styled(Box)({
  height: "100%",
  width: "100%",
  flex: 1,
  background: "#EEEEEE",
  overflow: "hidden",

  [`& [data-panel-size='${COLLAPSE_SIZE}.0'] > .panel-container`]: {
    display: "none",
  },
  [`& [data-panel-size='${COLLAPSE_SIZE}.0'] > .panel-label`]: {
    display: "flex",
  },
  [`& .panel-label`]: {
    display: "none",
    background: "rgba(0, 0, 0, 0.5)",
  },
});

export const Panels: React.FC<Props> = (props) => {
  const elements: React.ReactNode[] = [];
  const theme = useTheme();

  props.config.forEach((config, index) => {
    elements.push(<CustomPanel key={index} config={config} />);
    if (index < props.config.length - 1) {
      elements.push(<ResizeHandle key={`handle-${index}`} />);
    }
  });

  return (
    <Root>
      <PanelGroup
        style={{ backgroundColor: theme.palette.grey[200] }}
        autoSaveId={props.id}
        direction="horizontal"
        id={props.id}
      >
        {elements}
      </PanelGroup>
    </Root>
  );
};

function CustomPanel({ config }: { config: PanelConfig }) {
  const ref = React.useRef<ImperativePanelHandle>(null);

  return (
    <Panel ref={ref} defaultSize={config.defaultSize} collapsible={config.collapsible} minSize={COLLAPSE_SIZE}>
      <Boundary>
        <Stack className="panel-container" overflow="auto" flex={1} height="100%">
          {config.header && (
            <Stack boxShadow={1} sx={{ backgroundColor: "grey.200" }} zIndex={2}>
              {config.header}
            </Stack>
          )}
          <Stack overflow="auto" p={config.noPadding ? 0 : 2} flex={1} height="100%">
            {config.content}
          </Stack>
        </Stack>
        <Stack className="panel-label" alignItems={"center"} justifyContent={"center"} flex={1} height="100%">
          <PanelLabel variant="caption">{config.title}</PanelLabel>
          <IconButton onClick={() => ref.current?.resize(15)}>
            <UnfoldMore sx={{ transform: "rotate(90deg)" }} htmlColor="white" />
          </IconButton>
        </Stack>
      </Boundary>
    </Panel>
  );
}

const PanelLabel = styled(Typography)({
  textAlign: "center",
  padding: "0.5rem",
  color: "white",
  fontSize: "0.75rem",
  fontWeight: "bold",
  textTransform: "uppercase",
});
