import { Stack, Typography } from "@mui/material";
import React from "react";
import { CopyClipboard } from "../CopyClipboard";
import { Highlighter } from "../Highlighter";
import { Visibility } from "../Visibility";

export type PropertyPairs = Array<[key: string, value: React.ReactNode, valueAsString?: string]>;

interface Props {
  pairs: PropertyPairs;
  searchQuery?: string;
}

export function PropertyList({ pairs, searchQuery }: Props) {
  const highlight = (text: React.ReactNode | undefined) => {
    if (!searchQuery) {
      return text;
    }
    if (typeof text === "string") {
      return <Highlighter searchWords={searchQuery.split(" ")} autoEscape={true} textToHighlight={text} />;
    }
    return text;
  };

  return (
    <Stack className="hide-action-items" gap={1}>
      {pairs.map(([key, value, valueAsString]) => {
        if (value == null || value === "") {
          return null;
        }
        return (
          <React.Fragment key={key}>
            <Stack direction="row" alignItems="center">
              <Typography fontSize={"10px"} fontWeight="bold" minWidth="100px" variant="caption" color="text.secondary">
                {key}
              </Typography>
              <Typography
                component="div"
                display="flex"
                gap={1}
                alignItems="center"
                fontSize={"12px"}
                variant="body2"
                overflow="hidden"
                sx={{ wordBreak: "break-word", whiteSpace: "normal" }}
              >
                {highlight(value)}
                <Visibility>
                  {typeof value === "string" ? (
                    <CopyClipboard value={value} kind={key} />
                  ) : valueAsString ? (
                    <CopyClipboard value={valueAsString} kind={key} />
                  ) : null}
                </Visibility>
              </Typography>
            </Stack>
          </React.Fragment>
        );
      })}
    </Stack>
  );
}
