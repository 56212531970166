import { Boundary } from "@cp/async";
import { useToaster } from "@cp/theme";
import { LoadingButton } from "@mui/lab";
import { Alert, AlertTitle, Divider, Stack, Typography } from "@mui/material";
import React from "react";
import { useQuestionLinter } from "../../../components/curation-lint/linters/useQuestionLinter";
import { formatConditions } from "../../../components/curation-questions/conditionalUtil";
import { MappedClobQuestionList } from "../../../components/curation-questions/MappedClobQuestionList";
import { PropertyList } from "../../../components/properties/PropertyList";
import { CurationQuestionFragment, useUpdateCurationQuestionMutation } from "../../../generated/graphql";
import {
  curationQuestionFragmentToInput,
  UpsertCurationQuestionInput,
} from "../../../model/curation-questions/deserialize";
import CurationQuestionForm from "../../clobs/MapClob/CurationQuestionForm";
import { SectionBreadcrumbs } from "../ApplicationLayout/SectionBreadcrumbs";
import { useCurationState } from "../state/useCurationState";
import { Toolbar } from "../Toolbar/Toolbar";

const FORM_ID = "curation-question-form";

interface Props {
  question: CurationQuestionFragment;
}

export const CurationQuestionEditor: React.VFC<Props> = ({ question }) => {
  const { toast } = useToaster();
  const [mutate, { loading }] = useUpdateCurationQuestionMutation();
  const { getQuestion } = useCurationState();
  const lintResults = useQuestionLinter(question.id);
  const lintErrors = lintResults.filter((r) => r.status === "error");
  const lintWarnings = lintResults.filter((r) => r.status === "warning");

  const handleSubmit = (data: UpsertCurationQuestionInput) => {
    void mutate({
      variables: {
        input: {
          ...data,
          id: question.id,
          componentType: data.componentType ?? undefined,
        },
      },
      onCompleted: (data) => {
        toast("Curation question updated successfully");
      },
    });
  };

  return (
    <Stack gap={3}>
      <SectionBreadcrumbs nodeId={question.id} />
      <Typography variant="h2" m={0} color={question.text ? undefined : "text.secondary"}>
        {question.text ?? <i>no name</i>}
      </Typography>
      {lintErrors.length > 0 && (
        <Alert severity="error">
          <AlertTitle>Error</AlertTitle>
          <ul>
            {lintErrors.map((r) => (
              <li key={r.message}>{r.message}</li>
            ))}
          </ul>
        </Alert>
      )}
      {lintWarnings.length > 0 && (
        <Alert severity="warning">
          <AlertTitle>Warning</AlertTitle>
          <ul>
            {lintWarnings.map((r) => (
              <li key={r.message}>{r.message}</li>
            ))}
          </ul>
        </Alert>
      )}
      <PropertyList
        pairs={[
          ["Question", question.text],
          ["Key", question.key],
          ["Helper Text", question.helperText],
          ["Default Value", question.defaultValue],
          ["Component Type", question.componentType],
          ["Options", question.options.join(", ")],
          ["Notes", question.notes],
          ["Conditions", formatConditions(question.conditions, getQuestion)],
        ]}
      />
      <Divider sx={{ py: 1 }} />
      <Boundary>
        <MappedClobQuestionList curationQuestionId={question.id} curationQuestionKey={question.key} />
      </Boundary>
      <Divider sx={{ py: 1 }} />
      <CurationQuestionForm
        formId={FORM_ID}
        curationQuestion={curationQuestionFragmentToInput(question)}
        onSubmit={handleSubmit}
      />
      <Toolbar
        right={
          <LoadingButton loading={loading} type="submit" form={FORM_ID}>
            Save
          </LoadingButton>
        }
      />
    </Stack>
  );
};
