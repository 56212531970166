import { useMatch } from "@tanstack/react-location";
import React, { useState } from "react";
import { LocationGenerics } from "../../../App";
import { Page } from "../../../components/column/Page";
import { PageColumn } from "../../../components/column/PageColumn";
import { useClobQuery } from "../../../generated/graphql";
import { ClobEditorProvider } from "../../../model/clobs/ClobEditorProvider";
import { ClobDetailsHeader } from "./ClobDetailsHeader";
import { ClobFormEditor } from "./ClobFormEditor";
import { ClobVersionChangeBanner } from "./ClobVersionChangeBanner";

export const ClobPage: React.VFC = () => {
  const {
    params: { clobId },
  } = useMatch<LocationGenerics>();

  const { data: clobData, refetch, loading } = useClobQuery({ variables: { id: clobId } });
  const clob = clobData?.clob;

  const [draggable, setDraggable] = useState(false);

  if (!clob || loading) {
    return null;
  }

  return (
    <Page>
      <ClobEditorProvider clob={clob}>
        <PageColumn
          flex={1}
          header={<ClobDetailsHeader clob={clob} draggable={draggable} onDraggableChange={setDraggable} />}
        >
          <ClobVersionChangeBanner onRefetch={refetch} clobId={clobId} />
          <ClobFormEditor clob={clob} draggable={draggable} />
        </PageColumn>
      </ClobEditorProvider>
    </Page>
  );
};
