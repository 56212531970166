import { Objects } from "@cp/toolkit";
import { ReactElement } from "react";
import { z, ZodType } from "zod";
import {
  AnyForm,
  ArrayForm,
  BooleanForm,
  CustomForm,
  EnumForm,
  Formy,
  MappedObjectOf,
  NumberForm,
  ObjectForm,
  OptionalOfForm,
  SelectForm,
  SelectFormOpts,
  StringForm,
  TypeUnionForm,
} from "../types";
import { getDefaultValue, getInternalOptions, hideInternalOptions } from "./getOptionsInternal";
import { OptionHolder } from "./OptionHolder";

export class FormyImpl implements Formy {
  custom<T>(render: (props: { name: string; value: T }) => ReactElement): CustomForm<T> {
    const options = OptionHolder.from({ render, validation: z.any(), defaultValue: undefined });

    const builder: CustomForm<T> = {
      type: "custom",
      __shape__: undefined!,
      optional() {
        options.withOptional();
        return this;
      },
    };
    return hideInternalOptions(builder, options);
  }

  string<T extends string>(): StringForm<T> {
    const options = OptionHolder.from({ validation: z.string().min(1), defaultValue: "" });

    const builder: StringForm<T> = {
      type: "string",
      __shape__: undefined!,
      options(opts) {
        options.withOptions(opts);
        return this;
      },
      optional() {
        options.withOptions({ validation: z.nullable(z.string()) });
        options.withOptional();
        return this;
      },
    };
    return hideInternalOptions(builder, options);
  }

  number<T extends number>(): NumberForm<T> {
    const options = OptionHolder.from({ validation: z.number(), defaultValue: 0 });

    const builder: NumberForm<T> = {
      type: "number",
      __shape__: undefined!,
      options(opts) {
        options.withOptions(opts);
        return this;
      },
      optional() {
        options.withOptional();
        return this;
      },
    };
    return hideInternalOptions(builder, options);
  }

  boolean(): BooleanForm<boolean> {
    const options = OptionHolder.from({ validation: z.boolean(), defaultValue: false });

    const builder: BooleanForm<boolean> = {
      type: "boolean",
      __shape__: undefined!,
      options(opts) {
        options.withOptions(opts);
        return this;
      },
      optional() {
        options.withOptional();
        return this;
      },
    };
    return hideInternalOptions(builder, options);
  }

  enum<T extends string>(enums: T[]): EnumForm<T> {
    const options = OptionHolder.from({ enums, validation: z.enum(enums as any) });

    const builder: EnumForm<T> = {
      type: "enum",
      __shape__: undefined!,
      options(opts) {
        options.withOptions({ enums, ...opts });
        return this;
      },
      optional() {
        options.withOptional();
        return this;
      },
    };
    return hideInternalOptions(builder, options);
  }

  select<T>(selectOpts: SelectFormOpts<T>): SelectForm<string> {
    const options = OptionHolder.from({ ...selectOpts, validation: z.string().min(1) });

    const builder: SelectForm<string> = {
      type: "select",
      __shape__: undefined!,
      options(opts) {
        options.withOptions(opts);
        return this;
      },
      optional() {
        options.withOptions({ validation: z.string() });
        options.withOptional();
        return this;
      },
    };
    return hideInternalOptions(builder, options);
  }

  optionalOf<T>(field: AnyForm<T>): OptionalOfForm<T> {
    const options = OptionHolder.from({
      field,
      validation: z.nullable(getInternalOptions(field).validation).optional(),
      defaultValue: undefined,
    });

    const builder: OptionalOfForm<T> = {
      type: "optionalOf",
      __shape__: undefined!,
      options(opts) {
        options.withOptions({ field, ...opts });
        return this;
      },
      optional() {
        // Do nothing
        return this;
      },
    };
    return hideInternalOptions(builder, options);
  }

  arrayOf<T>(field: AnyForm<T>): ArrayForm<T> {
    const options = OptionHolder.from({
      field,
      validation: z.array(getInternalOptions(field).validation),
      defaultValue: [] as T[],
    });

    const builder: ArrayForm<T> = {
      type: "array",
      __shape__: undefined!,
      options(opts) {
        let validation: z.ZodType<T[]> = options.options.validation;
        // set new validation
        if (opts.validation) {
          validation = opts.validation;
        } else if (opts.nonEmpty) {
          validation = z.array(getInternalOptions(field).validation).nonempty();
        } else if (opts.minItems || opts.maxItems) {
          let arrayValidation = z.array(getInternalOptions(field).validation);
          if (opts.minItems) {
            arrayValidation = arrayValidation.min(opts.minItems);
          }
          if (opts.maxItems) {
            arrayValidation = arrayValidation.max(opts.maxItems);
          }
          validation = arrayValidation;
        }

        options.withOptions({ field, ...opts, validation });
        return this;
      },
      optional() {
        options.withOptional();
        return this;
      },
    };
    return hideInternalOptions(builder, options);
  }

  objectOf<T extends object | undefined>(fields: MappedObjectOf<T>): ObjectForm<T> {
    const validation = Objects.mapValues(fields, (field) => getInternalOptions(field).validation);
    const defaultValue = Objects.mapValues(fields, (field) => getInternalOptions(field).defaultValue);

    const options = OptionHolder.from({
      fields,
      validation: z.object(validation) as any as z.ZodType<T>,
      defaultValue: defaultValue as T,
    });

    const builder: ObjectForm<T> = {
      type: "object",
      __shape__: undefined!,
      options(opts) {
        options.withOptions({ fields, ...opts });
        return this;
      },
      optional() {
        options.withOptional();
        return this;
      },
    };
    return hideInternalOptions(builder, options);
  }

  oneOf<T extends object>(fields: MappedObjectOf<T>): TypeUnionForm<T> {
    const validation = Objects.mapValues(fields, (field) => getInternalOptions(field).validation.optional());
    const [firstObjectKey, firstObjectForm] = Object.entries(fields)[0] as [string, AnyForm<T>];
    const options = OptionHolder.from({
      fields,
      validation: z.object(validation) as any as ZodType<T>,
      defaultValue: {
        [firstObjectKey]: getDefaultValue(firstObjectForm),
      } as T,
    });

    const builder: TypeUnionForm<T> = {
      type: "oneOf",
      __shape__: undefined!,
      options(opts) {
        options.withOptions({ fields, ...opts });
        return this;
      },
      optional() {
        options.withOptional();
        return this;
      },
    };
    return hideInternalOptions(builder, options);
  }
}
