import { TableChart } from "@mui/icons-material";
import { Chip, colors } from "@mui/material";
import React from "react";

export const PivotSectionChip: React.FC = () => {
  return (
    <Chip
      icon={<TableChart fontSize="small" />}
      sx={{
        borderRadius: 1,
        backgroundColor: colors.lightBlue[100],
      }}
      size="small"
      label="Pivot Section"
    />
  );
};
