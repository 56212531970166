import { styled } from "@mui/material";
import React, { forwardRef } from "react";
import { IId } from "../types";

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  id: string;
  style?: React.CSSProperties;
  renderItem: (item: IId, index?: number) => React.ReactNode;
  index?: number;
}

const Item = styled("div")(({ theme }) => ({
  "&.dragOverlay": {
    cursor: "inherit",
    animation: "pop 200ms cubic-bezier(0.18, 0.67, 0.6, 1.22)",
    transform: "scale(var(1.05))",
    boxShadow: theme.shadows[2],
    borderRadius: 4,
    opacity: 1,
  },
}));

// eslint-disable-next-line react/display-name
export const ListItem = forwardRef<HTMLDivElement, Props>(({ style, id, renderItem, index, ...rest }: Props, ref) => {
  return (
    <Item {...rest} style={style} ref={ref}>
      {renderItem({ id }, index)}
    </Item>
  );
});
