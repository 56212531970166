import { Arrays, Functions, Maps } from "@cp/toolkit";
import { CurationNodeType } from "../../../generated/graphql";

export type CurationSectionId = string;
export type CurationQuestionId = string;

export interface CurationNode {
  id: string;
  type: CurationNodeType;
  children: string[];
}

export interface CurationContextTreeState {
  id: string;
  version: number;
  lineOfBusiness: string;
  rootIds: CurationSectionId[];
  curationNodeMap: ReadonlyMap<string, CurationNode>;
}

export interface CurationContextState extends CurationContextTreeState {
  /**
   * Inferred from curationNodeMap, not mutable
   */
  questionIds: ReadonlySet<CurationQuestionId>;
  /**
   * Inferred from curationNodeMap, not mutable
   */
  sectionIds: ReadonlySet<CurationSectionId>;
}

export const INITIAL_STATE: CurationContextState = {
  id: "",
  version: 0,
  questionIds: new Set(),
  sectionIds: new Set(),
  lineOfBusiness: "",
  rootIds: Arrays.empty(),
  curationNodeMap: Maps.EMPTY,
};

export const ROOT = "__root__";

export interface CurationContextActions {
  setState: (state: CurationContextTreeState) => void;
  removeCurationSection: (opts: { sectionId: string; parentId: string }) => void;
  moveToNewNode: (opts: {
    nodeId: string;
    /**
     * Undefined means that the node is being moved to the root or outside of the tree
     */
    fromId: string | typeof ROOT | undefined;
    toId: string;
    /**
     * If provided, the node will be inserted before this node.
     */
    beforeId?: string;
  }) => void;
  moveWithinNode: (opts: { parentId: string | typeof ROOT; toIndex: number; childId: string }) => void;
  addNode: (opts: { parentNodeId: string | undefined; title: string; newId: string }) => void;
  removeNode: (opts: { nodeId: string; parentId: string }) => void;
  swapNode: (opts: { parentId: string; removeNodeId: string; insertNodeId: string }) => Promise<void>;
  makeConditionalQuestion: (opts: { questionId: string }) => void;
  unmakeConditionalQuestion: (opts: { questionId: string }) => void;
}

export const INITIAL_ACTIONS: CurationContextActions = {
  setState: Functions.NOOP,
  removeCurationSection: Functions.NOOP,
  removeNode: Functions.NOOP,
  moveToNewNode: Functions.NOOP,
  moveWithinNode: Functions.NOOP,
  addNode: Functions.NOOP,
  swapNode: Functions.ASYNC_NOOP,
  makeConditionalQuestion: Functions.NOOP,
  unmakeConditionalQuestion: Functions.NOOP,
};

/**
 * Allows us to customize curation application features based on the context (page)
 * on which the curation application is rendered (such as golden question
 * swapping buttons instead of regular 'select' boxes on the golden question
 * mapping page). 'DEFAULT' generally refers to the the original version at /clobs/map,
 * before we started adding bells/whistles for specialized pages.
 */
export enum CurationContextFeature {
  DEFAULT,
  ANNOTATION_MAPPING,
  GOLDEN_QUESTION_SWAPPING_SCA_PANEL,
  GOLDEN_QUESTION_LIBRARY,
}

export interface CurationFeatures {
  showCurationQuestionMappingIndicator: boolean;
  showGoldenQuestionSwappingIcon: boolean;
  showClobMappingDot: boolean;
  showAnnotationMappingDot: boolean;
}

export const featuresForContext: Record<CurationContextFeature, Partial<CurationFeatures>> = {
  [CurationContextFeature.DEFAULT]: {
    showCurationQuestionMappingIndicator: true,
  },
  [CurationContextFeature.ANNOTATION_MAPPING]: {
    showCurationQuestionMappingIndicator: true,
    showAnnotationMappingDot: true,
  },
  [CurationContextFeature.GOLDEN_QUESTION_SWAPPING_SCA_PANEL]: {
    showCurationQuestionMappingIndicator: true,
  },
  [CurationContextFeature.GOLDEN_QUESTION_LIBRARY]: {
    showGoldenQuestionSwappingIcon: true,
  },
};
