import { EmptyState } from "@cp/theme";
import { CircularProgress } from "@mui/material";
import React from "react";

interface Props extends React.HTMLAttributes<HTMLIFrameElement> {
  src: string;
}
/**
 * Wrapper around an iframe element with styling and loading state
 */
export const Iframe: React.FC<Props> = (props) => {
  const [loading, setLoading] = React.useState(true);

  return (
    <>
      <iframe
        title="Recording"
        {...props}
        onLoad={() => setLoading(false)}
        style={{
          flex: 1,
          width: "100%",
          border: "none",
          display: loading ? "none" : "block",
        }}
      />
      {loading && <EmptyState title="Loading" icon={<CircularProgress />} />}
    </>
  );
};
