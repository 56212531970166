import { useOnScreen } from "@cp/react-hooks";
import { Adjust } from "@mui/icons-material";
import { Tooltip } from "@mui/material";
import React from "react";
import LineTo from "react-lineto";

interface Props {
  id: string;
  lineToId?: string;
  hideLine?: boolean;
  onClick?: (questionId: string) => void;
}

export const ClobMappingDot: React.FC<Props> = ({ id, lineToId, hideLine, onClick }) => {
  const [isOnScreen, ref] = useOnScreen<SVGSVGElement>();
  const shouldShow = isOnScreen;

  const targetIcon = (
    <Adjust
      color="info"
      fontSize="small"
      id={id}
      className={shouldShow ? id : undefined}
      ref={ref}
      sx={{
        cursor: lineToId ? "pointer" : undefined,
      }}
      onClick={(evt) => {
        evt.stopPropagation();
        if (lineToId) {
          if (onClick) {
            onClick(lineToId);
          }
          document.getElementById(lineToId)?.scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" });
        }
      }}
    />
  );

  return (
    <>
      {lineToId ? <Tooltip title="Jump to mapping">{targetIcon}</Tooltip> : targetIcon}
      {lineToId && !hideLine && (
        <LineTo from={id} to={lineToId} borderColor="rgba(0, 188, 212, 0.7)" borderWidth={3} borderStyle="dashed" />
      )}
    </>
  );
};
