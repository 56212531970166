import { FormHelperText, FormLabel, useTheme } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import { Controller, useFormContext } from "react-hook-form";
import { YES_NO_OPTIONS } from "../readonly-fields/ReadonlyFieldBooleanRadio";
import { useSaveForm } from "./common/useSaveForm";
import { FormFieldProps, processFormFieldProps } from "./FormFieldProps";

export const FormFieldBooleanRadio: React.VFC<FormFieldProps> = (props) => {
  const { id, label, sx, ControllerProps, disabled, required } = processFormFieldProps(props);
  const { control } = useFormContext();
  const theme = useTheme();
  const saveForm = useSaveForm();

  return (
    <Controller
      control={control}
      {...ControllerProps}
      defaultValue=""
      rules={{
        ...ControllerProps.rules,
        // Historically, we've used 'null' to represent 'not answered' for boolean fields.
        // But this caused required-ness to pass. So we are not enforcing a Yes/No answer when required.
        validate: (value) => (required ? value !== "null" : true),
      }}
      render={({ field, fieldState: { error } }) => (
        <FormControl sx={sx} component="fieldset" error={!!error} disabled={disabled} required={required}>
          <FormLabel className="question">{label}</FormLabel>
          <RadioGroup
            id={id}
            row={true}
            {...field}
            sx={{ mb: "-4px", color: error ? theme.palette.error.main : undefined }}
            onChange={(e, val) => {
              field.onChange(e);
              saveForm(field.name, val);
            }}
          >
            {YES_NO_OPTIONS.map((option) => (
              <FormControlLabel
                key={option.label}
                value={option.value}
                control={<Radio sx={{ color: error ? theme.palette.error.main : undefined }} />}
                label={option.label}
              />
            ))}
          </RadioGroup>
          {error?.message && <FormHelperText error={true}>{error?.message}</FormHelperText>}
        </FormControl>
      )}
    />
  );
};
