import { AnyForm, BaseFormOpts } from "../types";
import { BaseOptions, OptionHolder } from "./OptionHolder";

const KEY = "__internal_options";

export function getInternalOptions<O extends BaseFormOpts<T>, T = any>(parent: AnyForm<T>): O & BaseOptions<T> {
  const options = (parent as any)[KEY] as OptionHolder<any, any>;
  if (!options) {
    throw new Error(`${parent.type} has no internal options`);
  }
  return options.options;
}

export function hideInternalOptions<T>(parent: T, options: OptionHolder<any, any>): T {
  (parent as any)[KEY] = options;
  return parent;
}

export function getDefaultValue<T>(parent: AnyForm<T>): T {
  const options = getInternalOptions(parent);
  if (Array.isArray(options.defaultValue)) {
    return [...options.defaultValue] as any;
  }
  if (typeof options.defaultValue === "object") {
    return { ...options.defaultValue };
  }
  return options.defaultValue;
}
