import { Chip, Stack, Typography } from "@mui/material";
import { createTableConfig, Table } from "@qw/table";
import { CarrierProjectsWithStatsQuery, useCarrierProjectsWithStatsQuery } from "../../generated/graphql";
import { TableLink } from "../tables/tables";

type CarrierProjectsWithStats = CarrierProjectsWithStatsQuery["carrierProjects"][0];

const config = createTableConfig<CarrierProjectsWithStats>()
  .addColumn({
    id: "name",
    header: "Application Projects",
    render: ({ data }) => <TableLink to={`/project/${data.id}`}>{data.name}</TableLink>,
  })
  .addColumn({
    id: "recordings",
    header: "Progress",
    align: "right",
    render: ({ data: { stats } }) => (
      <Stack>
        <strong>
          {stats.recordingsTranscribedCount} of {stats.recordingsCount} screens Transcribed
        </strong>
        <Typography sx={{ fontSize: "12px" }} color="text.secondary" variant="body1">
          {stats.recordingsMappedCount} of {stats.recordingsCount} screens Mapped
        </Typography>
      </Stack>
    ),
  })
  .addColumn({
    id: "lob",
    header: "Line of Business",
    align: "right",
    render: ({ data }) => data.lineOfBusiness,
  })
  .addColumn({
    id: "carrierSlug",
    header: "Carrier",
    align: "right",
    render: ({ data }) => <Chip label={data.carrierSlug} size="small" />,
  })
  .build();

export function CarrierProjectsTable() {
  const { data, loading, error } = useCarrierProjectsWithStatsQuery();

  return (
    <Table
      {...config}
      data={data?.carrierProjects}
      emptyText="You don't have any projects yet."
      error={error}
      loading={loading}
    />
  );
}
