import { useToaster } from "@cp/theme";
import { RouteMatch, useLocation, useMatches } from "@tanstack/react-location";
import { default as React, useEffect } from "react";
import { ConnectedToastActions } from "../../components/ConnectedActionsToast";
import { useShowGoldenQuestionDrawerButtonInActionBar } from "../../components/panels/atoms";
import { SNACK_BAR_KEY } from "../../constants";
import { useSelectedClobQuestions, useSelectedCurationQuestions } from "../../pages/clobs/MapClob/selection-atom";

/**
 * Handles logic for showing/hiding snackbar menu
 */
export const ActionBar = () => {
  const { toast, closeSnackbar } = useToaster();
  const [selectedClobQuestions] = useSelectedClobQuestions();
  const [selectedCurationQuestions] = useSelectedCurationQuestions();
  const [_, setShowGoldenButton] = useShowGoldenQuestionDrawerButtonInActionBar();

  const matches = useMatches();
  const location = useLocation();

  useEffect(() => {
    // This is a hack to get around the fact that the ConnectedToastActions component
    // doesn't have access to the router provider
    const allowGoldenDrawer = matches.some((match: RouteMatch) => {
      return location.current.pathname.includes(match?.route?.path || "") && match?.route?.meta?.allowGoldenDrawer;
    });
    setShowGoldenButton(allowGoldenDrawer);
  }, [location, matches]);

  React.useEffect(() => {
    if (selectedClobQuestions.length === 0 && selectedCurationQuestions.length === 0) {
      closeSnackbar(SNACK_BAR_KEY);
    }

    if (selectedClobQuestions.length > 0 || selectedCurationQuestions.length > 0) {
      toast(<ConnectedToastActions />, {
        key: SNACK_BAR_KEY,
        persist: true,
        preventDuplicate: true,
      });
    }
  }, [selectedClobQuestions.length, selectedCurationQuestions.length]);

  return null;
};
