import { useBoolean } from "@cp/react-hooks";
import { Stack, Typography } from "@mui/material";
import React from "react";
import { CurationQuestionFragment } from "../../../../generated/graphql";
import { useCurationSearchQuery } from "../atoms";
import { CurationActionItems } from "./CurationActionItems";
import { CurationQuestionBody } from "./CurationQuestionBody";
import { CurationQuestionMappingIndicator } from "./CurationQuestionMappingIndicator";

interface Props {
  id: string;
  title: string;
  question: CurationQuestionFragment | undefined;
  disableSelection?: boolean;
  initialMinimized?: boolean;
}

export const CurationQuestionTitle: React.FC<Props> = React.memo(
  ({ id, title, question, disableSelection, initialMinimized = true }) => {
    const [search] = useCurationSearchQuery();
    const [isMinimized, { toggle: toggleMinimized }] = useBoolean(initialMinimized);

    return (
      <Stack flex={1} overflow="hidden">
        <Stack gap={1} direction="row" alignItems="center" className="hide-action-items" minHeight="40px">
          <CurationQuestionMappingIndicator id={id} disableSelection={disableSelection} hideMappingIndicator={true} />
          <Typography title={title} flex={1} variant="body2">
            {title}
          </Typography>
          {question && (
            <CurationActionItems question={question} isMinimized={isMinimized} toggleMinimize={toggleMinimized} />
          )}
        </Stack>
        {!isMinimized && <CurationQuestionBody id={id} search={search} question={question} />}
      </Stack>
    );
  }
);

CurationQuestionTitle.displayName = "CurationQuestionTitle";
