import { CircularProgress, styled } from "@mui/material";
import { PropsWithChildren, Suspense } from "react";

const Centered = styled("div")({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  height: "100%",
  width: "100%",
  flex: 1,
});

export default function LoadingSuspense({ children }: PropsWithChildren<{}>) {
  return <Suspense fallback={<PageLoader />}>{children}</Suspense>;
}

export const PageLoader = () => (
  <Centered>
    <CircularProgress />
  </Centered>
);
