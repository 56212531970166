import {
  AttachMoney,
  BusinessCenter,
  CalendarMonth,
  CheckBox,
  CloudUpload,
  ContactPage,
  List,
  Mail,
  Numbers,
  Percent,
  Phone,
  Place,
  Public,
  RadioButtonChecked,
  TextFields,
  Work,
} from "@mui/icons-material";
import * as React from "react";
import { ApplicationComponentType } from "../../../generated/graphql";

export const COMPONENT_TYPE_ICONS: Record<ApplicationComponentType, React.ReactElement> = {
  [ApplicationComponentType.BusinessClassification]: <BusinessCenter />,
  [ApplicationComponentType.DynamicBusinessClassification]: <BusinessCenter />,
  [ApplicationComponentType.Checkbox]: <CheckBox />,
  [ApplicationComponentType.CheckboxGroup]: <CheckBox />,
  [ApplicationComponentType.ContactSelect]: <ContactPage />,
  [ApplicationComponentType.Currency]: <AttachMoney />,
  [ApplicationComponentType.Date]: <CalendarMonth />,
  [ApplicationComponentType.Email]: <Mail />,
  [ApplicationComponentType.Fein]: <Numbers />,
  [ApplicationComponentType.FileUpload]: <CloudUpload />,
  [ApplicationComponentType.FileUploadDrawerOpener]: <CloudUpload />,
  [ApplicationComponentType.Hidden]: <TextFields />,
  [ApplicationComponentType.Number]: <Numbers />,
  [ApplicationComponentType.LocationSelect]: <Place />,
  [ApplicationComponentType.Percentage]: <Percent />,
  [ApplicationComponentType.Phone]: <Phone />,
  [ApplicationComponentType.Radio]: <RadioButtonChecked />,
  [ApplicationComponentType.RadioBoolean]: <RadioButtonChecked />,
  [ApplicationComponentType.Select]: <List />,
  [ApplicationComponentType.StateSelect]: <Public />,
  [ApplicationComponentType.Text]: <TextFields />,
  [ApplicationComponentType.TextArea]: <TextFields />,
  [ApplicationComponentType.WorkersCompClassification]: <Work />,
  [ApplicationComponentType.Year]: <Numbers />,
  [ApplicationComponentType.Zip]: <Numbers />,
};
