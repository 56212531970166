import React from "react";
import { useSelectedCurationApplicationId } from "../../components/panels/atoms";
import { CurationApplicationBuilder } from "./CurationApplicationBuilder";
import { RealtimeCurationProvider } from "./state/RealtimeCurationProvider";

export const CurationPage: React.VFC = () => {
  const [curationId] = useSelectedCurationApplicationId();

  return (
    <RealtimeCurationProvider id={curationId}>
      <CurationApplicationBuilder />
    </RealtimeCurationProvider>
  );
};

export default CurationPage;
