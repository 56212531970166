import { atom, useAtom } from "jotai";
import { EditableClobQuestion } from "../../../model/clobs/types";
import { useSwapCurationQuestions } from "../../curation/state/useSwapCurationQuestions";

/**
 * Selects curation question id(s) in atom for certain actions to be performed on, including
 * archiving, mapping clob questions
 */
const selectedCurationQuestionsList = atom<string[]>([]);
export const useSelectedCurationQuestions = () => useAtom(selectedCurationQuestionsList);

/**
 * Save callbacks for selected questions that allow them to be swapped. The callbacks contain the necessary
 * curation context provider methods within their closure to perform the swap.
 *
 * NOTES:
 * 1) there's an implicit coupling between selectedCurationQuestionsList and selectedCurationQuestionsSwapCallbacks;
 *    they should always be updated together (on pages that contain swapping features), but it's possible to
 *    fall out of sync - so keep this in mind and perform necessary checks when using this atom.
 * 2) currently, we add to this map, but never clear it. This makes it simpler to use, but means that
 *    there's technically a memory leak. But it's low-volume, so shouldn't cause issues.
 */
const selectedCurationQuestionsSwapCallbacks = atom<Map<string, ReturnType<typeof useSwapCurationQuestions>>>(
  new Map()
);
export const useSelectedCurationQuestionsSwapCallbacks = () => useAtom(selectedCurationQuestionsSwapCallbacks);

const selectedClobQuestionsList = atom<string[]>([]);
export const useSelectedClobQuestions = () => useAtom(selectedClobQuestionsList);

const updateQuestions = atom<Array<Partial<EditableClobQuestion> & { id: string }>>([]);
export const useUpdateQuestions = () => useAtom(updateQuestions);
