import { DropTargetMonitor, useDrop } from "react-dnd";
import { Dragable, DragTypes, DropResult } from "../Dragables/DragTypes";
import { CurationNode } from "../state/types";

/**
 * React-dnd hook to handle dropping a node on a section.
 * This makes the node a child of the section.
 * You can drop a question or a section.
 */
export function useSectionDropzone(
  section: Pick<CurationNode, "id" | "children"> | undefined,
  moveToNewNode: (opts: { nodeId: string; fromId: string | undefined; toId: string }) => void,
  sectionId: string
) {
  return useDrop<Dragable, DropResult, { isOver: boolean; canDrop: boolean }>(
    () => ({
      accept: [DragTypes.QUESTION, DragTypes.SECTION, DragTypes.CLOB_QUESTION, DragTypes.PDF_ANNOTATION],
      canDrop: (item: Dragable) => {
        if (!section) {
          return false;
        }

        // we can always drop a clob-question or pdf-annotation
        if (item.type === DragTypes.CLOB_QUESTION || item.type === DragTypes.PDF_ANNOTATION) {
          return true;
        }

        // ignore self
        if (item.id === sectionId) {
          return false;
        }

        // we can only drop a question if its not already in its children
        const notInChildren = !section.children.includes(item.id);
        return notInChildren;
      },
      drop: (dragable: Dragable, monitor: DropTargetMonitor<Dragable, DropResult>) => {
        if (monitor.didDrop()) {
          return;
        }

        // If we are dragging a clob-question or pdf-annotation, we need to create a new curation question first and then move it.
        // So instead we are going to return a create-curation-question type and the caller will need to call onInsert
        if (dragable.type === DragTypes.CLOB_QUESTION || dragable.type === DragTypes.PDF_ANNOTATION) {
          return {
            type: "create-curation-question",
            onInsert: (curationQuestionId) => {
              moveToNewNode({ nodeId: curationQuestionId, fromId: undefined, toId: sectionId });
            },
          };
        }

        // Checks to make sure we have not already dropped within a nested section
        const dropResult = monitor.getDropResult();
        if (dropResult?.type === "inside-section-id") {
          return dropResult;
        }

        if (dragable.source.type === "uncurated") {
          moveToNewNode({
            nodeId: dragable.id,
            fromId: undefined,
            toId: sectionId,
          });
        }
        if (dragable.source.type === "section" && dragable.source.sectionId !== sectionId) {
          moveToNewNode({
            nodeId: dragable.id,
            fromId: dragable.source.sectionId,
            toId: sectionId,
          });
        }

        // The return type indicates a drop within a section
        return { type: "inside-section-id", sectionId };
      },
      collect: (monitor) => ({
        isOver: monitor.isOver({ shallow: true }) && monitor.canDrop(),
        canDrop: monitor.canDrop(),
      }),
    }),
    [sectionId]
  );
}
