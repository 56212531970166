import { useToaster } from "@cp/theme";
import { ContentCut } from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";
import React from "react";
import { useCopyPastSection } from "../state/useCopyPasteSection";

interface Props {
  id: string;
  parentId: string;
}

export const SectionCopy: React.FC<Props> = ({ id, parentId }) => {
  const { toast } = useToaster();
  const [copiedId, setCopied] = useCopyPastSection();

  const selected = copiedId?.sectionId === id;

  return (
    <Tooltip title="Copy section to move">
      <IconButton
        aria-selected={selected}
        aria-pressed={selected}
        size="small"
        color={selected ? "success" : undefined}
        onClick={() => {
          if (selected) {
            setCopied(undefined);
            toast("Section copy removed");
          } else {
            setCopied({
              sectionId: id,
              parentId,
            });
            toast("Section copied");
          }
        }}
      >
        <ContentCut fontSize="small" />
      </IconButton>
    </Tooltip>
  );
};
