import { useSetSelectedCurationApplicationId } from "../../components/panels/atoms";
import { MAIN_CURATION_APPLICATION_ID } from "../../constants";
import { useCurationApplicationsQuery } from "../../generated/graphql";

interface Props {
  carrierSlug?: string | null;
  lineOfBusiness?: string | null;
}

export function useSetCurationApplicationToCarrierLineOfBusiness({ carrierSlug, lineOfBusiness }: Props) {
  const setSelectedCurationApp = useSetSelectedCurationApplicationId();

  const skip = !carrierSlug || !lineOfBusiness;

  if (skip) {
    setSelectedCurationApp(MAIN_CURATION_APPLICATION_ID);
  }

  useCurationApplicationsQuery({
    variables: { carrierSlug, lineOfBusiness },
    skip,
    onCompleted: (data) => {
      if (data?.curationApplications[0]?.id) {
        setSelectedCurationApp(data?.curationApplications[0].id);
      } else {
        setSelectedCurationApp(MAIN_CURATION_APPLICATION_ID);
      }
    },
  });
}
