import { FormFieldAutocomplete } from "@cp/forms";
import { useQuery } from "react-query";

interface Option {
  label: string;
  value: string;
}

interface Props {
  name: string;
}

const getNiacsCodes = async (): Promise<Option[]> => {
  const response = await fetch("/naics_codes_2017.json");
  const niacsCodes = await response.json();
  return niacsCodes as Option[];
};

export const NaicsSelector = ({ name }: Props) => {
  const { data = [] } = useQuery({ queryKey: "niacs", queryFn: getNiacsCodes });

  return (
    <FormFieldAutocomplete
      name={name}
      options={data}
      getValue={(option) => option.value}
      getOptionLabel={(option) => (option.value === "*" ? option.label : `${option.value} - ${option.label}`)}
      multiple={true}
      table={{
        headers: ["NAICS Code", "Description"],
        getRow: (option) => [option.value, option.label],
      }}
      label="NAICS Codes"
    />
  );
};
