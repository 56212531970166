import { TreeItem } from "dnd-kit-sortable-tree";
import { Reducer } from "react";
import { v4 as uuidV4 } from "uuid";
import { ClobContextState, ClobTreeItem, EditableClobQuestion, EditableClobSection } from "./types";

export type Action =
  | { type: "UPDATE_TREE"; tree: ClobContextState["tree"] }
  | { type: "UPDATE_STATE"; state: Partial<ClobContextState> }
  | { type: "ADD_ITEM"; parentId: string | "root"; nodeType: "question" | "section" }
  | { type: "UPDATE_CLOB_QUESTION"; question: Partial<EditableClobQuestion> & { id: string } }
  | { type: "UPDATE_CLOB_QUESTIONS"; questions: Array<Partial<EditableClobQuestion> & { id: string }> }
  | { type: "UPDATE_CLOB_SECTION"; section: Partial<EditableClobSection> & { id: string } };

type ClobReducer = Reducer<ClobContextState, Action>;

export const clobReducer: ClobReducer = (previousState, action): ClobContextState => {
  switch (action.type) {
    case "UPDATE_TREE":
      return {
        ...previousState,
        tree: action.tree,
      };

    case "UPDATE_STATE":
      return {
        ...previousState,
        ...action.state,
      };

    case "ADD_ITEM": {
      const { parentId, nodeType } = action;
      const { tree } = previousState;
      const newId = uuidV4();

      return {
        ...previousState,
        tree: addItem(
          tree,
          {
            id: newId,
            title: "(untitled)",
            type: nodeType,
            canHaveChildren: nodeType === "section",
          },
          parentId
        ),
      };
    }

    case "UPDATE_CLOB_QUESTION": {
      const { question } = action;
      const questions = new Map(previousState.questions);
      questions.set(question.id, {
        ...questions.get(question.id)!, // previous
        ...question, // next
      });

      return {
        ...previousState,
        questions,
      };
    }

    case "UPDATE_CLOB_QUESTIONS": {
      const { questions: newQuestions } = action;
      const questions = new Map(previousState.questions);
      for (const question of newQuestions) {
        questions.set(question.id, {
          ...questions.get(question.id)!, // previous
          ...question, // next
        });
      }

      return {
        ...previousState,
        questions,
      };
    }

    case "UPDATE_CLOB_SECTION": {
      const { section } = action;
      const sections = new Map(previousState.sections);
      sections.set(section.id, {
        ...sections.get(section.id)!, // previous
        ...section, // next
      });

      return {
        ...previousState,
        sections,
      };
    }

    default:
      return previousState;
  }
};

/**
 * Recursively update the tree with the new item added to the parentId
 */
function addItem(items: ClobTreeItem[], newItem: ClobTreeItem, parentId: string | "root") {
  if (parentId === "root") {
    return [...items, newItem];
  }

  const newItems = [];

  for (const item of items) {
    newItems.push(item);

    if (item.id === parentId) {
      item.children = [...(item.children || []), newItem];
      item.collapsed = false;
      continue;
    }

    if (item.children?.length) {
      item.children = addItem(item.children, newItem, parentId);
    }
  }

  return newItems;
}

export function collapseAll<TreeItemType>(items: Array<TreeItem<TreeItemType>>): Array<TreeItem<TreeItemType>> {
  return items.map((item) => ({
    ...item,
    collapsed: true,
    children: item.children ? collapseAll(item.children) : item.children,
  }));
}

export function expandAll<TreeItemType>(items: Array<TreeItem<TreeItemType>>): Array<TreeItem<TreeItemType>> {
  return items.map((item) => ({
    ...item,
    collapsed: false,
    children: item.children ? expandAll(item.children) : item.children,
  }));
}
