import { Card, CardContent, Skeleton, Stack } from "@mui/material";
import React, { useRef } from "react";

export const LoadingStack: React.FC = () => {
  // random number between 14-20
  const num = useRef(Math.floor(Math.random() * 6) + 14);

  return (
    <Stack spacing="2px">
      {Array.from({ length: num.current }, (_, i) => (
        <Card key={i}>
          <CardContent>
            <Skeleton />
          </CardContent>
        </Card>
      ))}
    </Stack>
  );
};
