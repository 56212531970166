
      export type PossibleTypesResultData = {
  "possibleTypes": {
    "ApplicationCondition": [
      "ApplicationConditionBoolean",
      "ApplicationConditionMatchAll",
      "ApplicationConditionMatchAny",
      "ApplicationConditionNumber",
      "ApplicationConditionString",
      "ApplicationConditionStringSet"
    ]
  }
};
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "ApplicationCondition": [
      "ApplicationConditionBoolean",
      "ApplicationConditionMatchAll",
      "ApplicationConditionMatchAny",
      "ApplicationConditionNumber",
      "ApplicationConditionString",
      "ApplicationConditionStringSet"
    ]
  }
};
      export default result;
    