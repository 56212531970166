import { Dates, QuestionIds } from "@cp/toolkit";
import TextField, { TextFieldProps } from "@mui/material/TextField";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DesktopDatePicker, DesktopDatePickerProps } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { addYears, isValid } from "date-fns";
import * as React from "react";
import { Controller, FieldValues, useFormContext, UseFormSetValue } from "react-hook-form";
import { combinedText } from "./common/combineText";
import { SaveFormFunction, useSaveForm } from "./common/useSaveForm";
import { FormFieldDateProps, processFormFieldProps } from "./FormFieldProps";

interface Props
  extends Omit<DesktopDatePickerProps<Date, Date>, "renderInput" | "value" | "onChange">,
    FormFieldDateProps {
  label: React.ReactNode;
  TextFieldProps?: TextFieldProps;
  allowClear?: boolean;
  enableValidation?: boolean;
}

export const FormFieldDateSelector: React.VFC<Props> = ({
  clampMin,
  clampMax,
  minDate,
  maxDate,
  TextFieldProps,
  allowClear = false,
  enableValidation = false,
  ...props
}) => {
  const { control } = useFormContext(); // retrieve all hook methods
  const { id, sx, ControllerProps, helperText, fullWidth, required, ...rest } = processFormFieldProps(props);
  const saveForm = useSaveForm();
  const { setValue } = useFormContext();

  return (
    <Controller
      control={control}
      defaultValue={null}
      {...ControllerProps}
      render={({ field: { onChange, onBlur, value, name }, fieldState: { error, invalid } }) => {
        return (
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DesktopDatePicker
              value={Dates.reformatDate(value)}
              onChange={(date) => {
                // Check the date is valid, and that it's a 4 digit year
                if (date && isValid(date) && date.getFullYear() > 1000) {
                  if (clampMin && minDate && date < minDate) {
                    date = minDate;
                  }
                  if (clampMax && maxDate && date > maxDate) {
                    date = maxDate;
                  }
                  // NB: sanitize.ts converts this to an ISO date-only date string
                  // so that it is stored in the database without a time or timezone
                  // this ultimately leads to significantly better behavior when dealing
                  // with multiple timezones, but if we ever have a date picker which
                  // accepts time strings, this will be problematic and cause issues.
                  onChange(date);
                  saveForm(name, date);
                  maybeUpdateExpirationDate(name, setValue, date, saveForm);
                } else if (allowClear) {
                  onChange("");
                  saveForm(name, null);
                }
              }}
              InputAdornmentProps={{ position: "end" }}
              {...(clampMin && {
                minDate: minDate || new Date(Date.now()),
              })}
              {...(clampMax && {
                maxDate: maxDate || new Date(Date.now()),
              })}
              {...rest}
              renderInput={(params) => (
                <TextField
                  id={id}
                  name={name}
                  {...TextFieldProps}
                  {...params}
                  InputProps={{
                    ...params.InputProps,
                    ...TextFieldProps?.InputProps,
                  }}
                  sx={sx}
                  required={required}
                  fullWidth={fullWidth}
                  helperText={combinedText({
                    helperText: helperText,
                    error: error?.message,
                  })}
                  onBlur={onBlur}
                  error={!!error}
                />
              )}
            />
          </LocalizationProvider>
        );
      }}
      //rules={{ required: "This field is required" }}
    />
  );
};

const maybeUpdateExpirationDate = (
  name: string,
  setValue: UseFormSetValue<FieldValues>,
  date: Date,
  saveForm: SaveFormFunction
) => {
  const addOneYear = (questionId: string) => {
    setValue(questionId, addYears(date, 1));
    saveForm(questionId, addYears(date, 1));
  };
  switch (name) {
    case QuestionIds.EffectiveDate:
      addOneYear(QuestionIds.ExpirationDate);
      return;
    case QuestionIds.PriorEffectiveDate:
      addOneYear(QuestionIds.PriorExpirationDate);
      return;
    case QuestionIds.PolicyEffectiveDate:
      addOneYear(QuestionIds.PolicyExpirationDate);
      return;
  }
};
