import { colors, Divider, Stack } from "@mui/material";
import React, { useRef } from "react";
import { useDrag } from "react-dnd";
import { Handle } from "../../../components/sortables/components/Handle/Handle";
import { CurationQuestionTitle } from "../../clobs/MapClob/components/CurationQuestionTitle";
import { GoldenQuestionIcon } from "../icons/GoldenQuestionIcon";
import { QuestionItemProps } from "../Questions/QuestionItem";
import { Dragable, DragableItemProps, DragTypes } from "./DragTypes";

export const DraggableUncuratedQuestion: React.VFC<DragableItemProps<QuestionItemProps>> = React.memo(
  ({ index, curationQuestion }) => {
    const ref = useRef<HTMLButtonElement>(null);

    const [{ isDragging }, drag, preview] = useDrag<Dragable, unknown, { isDragging: boolean }>(
      {
        type: DragTypes.QUESTION,
        item: {
          id: curationQuestion.id,
          index,
          type: DragTypes.QUESTION,
          source: { type: "uncurated" },
        },
        collect: (monitor) => ({
          isDragging: monitor.isDragging(),
        }),
      },
      [curationQuestion.id, index]
    );

    drag(ref);

    return (
      <Stack
        direction="row"
        alignItems="center"
        width="100%"
        flex={1}
        p={1}
        divider={<Divider />}
        borderRadius={1}
        ref={preview}
        sx={{
          zIndex: isDragging ? 2 : 1,
          opacity: isDragging ? 0.2 : 1,
          backgroundColor: curationQuestion.golden ? colors.orange[50] : "white",
        }}
      >
        <Handle ref={ref} />
        <CurationQuestionTitle
          key={curationQuestion.id}
          question={curationQuestion}
          title={curationQuestion.text}
          id={curationQuestion.id}
        />
        {curationQuestion.golden && <GoldenQuestionIcon />}
      </Stack>
    );
  }
);

DraggableUncuratedQuestion.displayName = "DraggableUncuratedQuestion";
