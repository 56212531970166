import { Chip, MenuItem, Select, SelectChangeEvent, Stack } from "@mui/material";
import React from "react";
import { useClobQuestionMetadataSummaryQuery, useCurationApplicationsQuery } from "../generated/graphql";
import { TagSelect } from "./buttons/TagSelect";
import { useCurationQuestionsFilter, useSelectedCurationApplicationId } from "./panels/atoms";

export const CurationApplicationFilters: React.VFC<{}> = () => {
  const { data: metadataSummary } = useClobQuestionMetadataSummaryQuery();
  const { data: allCurationApps } = useCurationApplicationsQuery();

  const [selectedCurationApp, setSelectedCurationApp] = useSelectedCurationApplicationId();

  const [curationQuestionFilter, setCurationQuestionsFilter] = useCurationQuestionsFilter();

  const lineOfBusinessesOptions =
    metadataSummary?.clobQuestionMetadataSummary.linesOfBusiness.map((lob) => ({
      label: lob,
      value: lob,
    })) ?? [];

  const carrierSlugsOptions =
    metadataSummary?.clobQuestionMetadataSummary.carrierSlugs.map((slug) => ({
      label: slug,
      value: slug,
    })) ?? [];

  const handleCurationAppChange = (e: SelectChangeEvent<string | undefined>) => {
    if (e.target.value) {
      setSelectedCurationApp(e.target.value);
    }
  };

  return (
    <>
      <Stack
        flexWrap="wrap-reverse"
        flexShrink={1}
        direction="row"
        justifyContent="flex-start"
        gap={2}
        borderBottom="1px solid"
        borderColor="divider"
        p={2}
      >
        {curationQuestionFilter?.lineOfBusiness && (
          <Chip
            key={curationQuestionFilter?.lineOfBusiness}
            color="warning"
            variant="outlined"
            label={curationQuestionFilter?.lineOfBusiness}
            size="small"
            sx={{ borderRadius: 1 }}
            onDelete={() => setCurationQuestionsFilter({ ...curationQuestionFilter, lineOfBusiness: "" })}
          />
        )}
        {curationQuestionFilter?.carrierSlugs.map((slug) => (
          <Chip
            key={slug}
            color="info"
            variant="outlined"
            label={slug}
            size="small"
            sx={{ borderRadius: 1 }}
            onDelete={() =>
              setCurationQuestionsFilter({
                ...curationQuestionFilter,
                carrierSlugs: curationQuestionFilter.carrierSlugs.filter((s) => s !== slug),
              })
            }
          />
        ))}
      </Stack>
      {allCurationApps?.curationApplications && (
        <Select
          SelectDisplayProps={{ style: { paddingTop: "8px" } }}
          value={selectedCurationApp}
          onChange={handleCurationAppChange}
        >
          {[...allCurationApps.curationApplications]
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((curationApp) => (
              <MenuItem key={curationApp.id} value={curationApp.id}>
                {curationApp.name}
              </MenuItem>
            ))}
        </Select>
      )}
      <TagSelect
        onAdd={(carrier) =>
          setCurationQuestionsFilter({
            ...curationQuestionFilter,
            carrierSlugs: [...new Set([...curationQuestionFilter.carrierSlugs, carrier])],
          })
        }
        label="Carriers"
        options={carrierSlugsOptions}
      />
      <TagSelect
        onAdd={(lob) => setCurationQuestionsFilter({ ...curationQuestionFilter, lineOfBusiness: lob })}
        label="Line of Business"
        options={lineOfBusinessesOptions}
      />
    </>
  );
};
