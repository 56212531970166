import { Dates } from "@cp/toolkit";
import TextField, { TextFieldProps } from "@mui/material/TextField";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker, DatePickerProps } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { isValid } from "date-fns";
import * as React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { combinedText } from "./common/combineText";
import { useSaveForm } from "./common/useSaveForm";
import { FormFieldDateProps, processFormFieldProps } from "./FormFieldProps";

interface Props extends Omit<DatePickerProps<Date, Date>, "renderInput" | "value" | "onChange">, FormFieldDateProps {
  label: React.ReactNode;
  TextFieldProps?: TextFieldProps;
  allowClear?: boolean;
}

export const FormFieldYearSelector: React.FC<Props> = ({
  helperText,
  clampMin = false,
  clampMax = true,
  minDate,
  maxDate,
  TextFieldProps,
  allowClear = false,
  ...props
}) => {
  const { control } = useFormContext(); // retrieve all hook methods
  const { id, sx, ControllerProps, fullWidth, required, ...rest } = processFormFieldProps(props);

  const saveForm = useSaveForm();

  return (
    <Controller
      control={control}
      defaultValue={null}
      {...ControllerProps}
      render={({ field: { onChange, onBlur, value, name }, fieldState: { error } }) => {
        return (
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              {...rest}
              value={Dates.reformatDate(value)}
              views={["year"]}
              onChange={(date) => {
                if (date && isValid(date) && date.getFullYear() > 1000) {
                  if (clampMin && minDate && date < minDate) {
                    date = minDate;
                  }
                  if (clampMax && maxDate && date > maxDate) {
                    date = maxDate;
                  }
                  // NB: sanitize.ts converts this to an ISO date-only date string
                  // so that it is stored in the database without a time or timezone
                  // this ultimately leads to significantly better behavior when dealing
                  // with multiple timezones, but if we ever have a date picker which
                  // accepts time strings, this will be problematic and cause issues.
                  onChange(date);
                  saveForm(name, date);
                } else if (allowClear) {
                  onChange("");
                  saveForm(name, null);
                }
              }}
              InputAdornmentProps={{ position: "end" }}
              {...(clampMin && {
                minDate: minDate || new Date(Date.now()),
              })}
              {...(clampMax && {
                maxDate: maxDate || new Date(Date.now()),
              })}
              renderInput={(params) => (
                <TextField
                  id={id}
                  name={name}
                  {...TextFieldProps}
                  {...params}
                  sx={sx}
                  fullWidth={fullWidth}
                  required={required}
                  helperText={combinedText({
                    helperText: helperText,
                    error: error?.message,
                  })}
                  onBlur={onBlur}
                  error={!!error}
                />
              )}
            />
          </LocalizationProvider>
        );
      }}
    />
  );
};
