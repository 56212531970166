import { ErrorBoundary } from "@sentry/react";
import { PropsWithChildren } from "react";
import LoadingSuspense from "./LoadingSuspense";

/**
 * Both loading (Suspense) and error boundary
 */
export function Boundary(props: PropsWithChildren<{}>) {
  return (
    <ErrorBoundary>
      <LoadingSuspense>{props.children}</LoadingSuspense>
    </ErrorBoundary>
  );
}
