import { useToaster } from "@cp/theme";
import {
  CarrierProjectDocument,
  ClobQuestionFragment,
  PortalAnnotationsDocument,
  useBulkUpdateClobQuestionMappingMutation,
} from "../../generated/graphql";
import { useClobContext } from "../../model/clobs/context";
import { conditionToInput } from "../../model/conditions/conditionToInput";
interface Input {
  clobQuestionIds: string[];
  curationQuestionId: string;
  curationQuestionIdForUndo?: string;
}

export function useLinkCurationQuestion() {
  const { toast, toastUndo } = useToaster();
  const { actions } = useClobContext();
  const [updateClobQuestions, { loading }] = useBulkUpdateClobQuestionMappingMutation({
    // Refetch for updated counts
    refetchQueries: [PortalAnnotationsDocument, CarrierProjectDocument],
  });

  const handleSuccess = (newClobQuestions: ClobQuestionFragment[], curationQuestionIdForUndo?: string) => {
    // update internal state
    actions.updateQuestions(
      newClobQuestions.map((q) => {
        return {
          ...q,
          visibility: q.visibility.map(conditionToInput),
        };
      })
    );

    toastUndo("Curation mapping linked", {
      onUndo: async () => {
        await updateClobQuestions({
          variables: {
            mapping: {
              curationQuestionId: curationQuestionIdForUndo ?? null,
              ids: newClobQuestions.map((q) => q.id),
            },
          },
          onCompleted: ({ bulkUpdateClobQuestionMapping: previousClobQuestions }) => {
            toast("Mappings undone");

            // update internal state
            actions.updateQuestions(
              previousClobQuestions.map((q) => {
                return {
                  ...q,
                  visibility: q.visibility.map(conditionToInput),
                };
              })
            );
          },
        });
      },
    });
  };

  return [
    async ({ clobQuestionIds, curationQuestionId, curationQuestionIdForUndo }: Input) => {
      await updateClobQuestions({
        variables: {
          mapping: {
            curationQuestionId: curationQuestionId,
            ids: clobQuestionIds,
          },
        },
        onCompleted: (data) => handleSuccess(data.bulkUpdateClobQuestionMapping, curationQuestionIdForUndo),
      });
    },
    { loading },
  ] as const;
}
