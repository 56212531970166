import { LoadState } from "@cp/theme";
import { Link, MarkChatRead } from "@mui/icons-material";
import { Chip, colors, Divider, MenuItem, Select, Stack, Typography } from "@mui/material";
import { Outlet, useMatch, useNavigate } from "@tanstack/react-location";
import React from "react";
import { LocationGenerics } from "../../App";
import { PageTemplate } from "../../components/layout/PageTemplate";
import { useCarrierProjectQuery, useCarrierProjectsQuery } from "../../generated/graphql";

export const ProjectPage: React.FC = () => {
  const {
    params: { projectId },
  } = useMatch<LocationGenerics>();

  const navigate = useNavigate();

  const { data: allCarrierProjects } = useCarrierProjectsQuery();
  const { data, error, loading } = useCarrierProjectQuery({
    variables: { id: projectId },
  });

  if (!data) {
    return <LoadState error={error} loading={loading} />;
  }

  const { carrierSlug, lineOfBusiness, annotationCount, unmappedAnnotationCount, mappedToGoldenQuestionCount } =
    data.carrierProject;

  const mappedAnnotationCount = annotationCount - unmappedAnnotationCount;
  const goldenPercentage = 100 * (mappedToGoldenQuestionCount / mappedAnnotationCount);
  const goldenPercentageDisplay =
    mappedAnnotationCount > 0 ? `${Math.round((goldenPercentage + Number.EPSILON) * 100) / 100}%` : "N/A";

  return (
    <PageTemplate
      title={
        <Stack direction="row" spacing={2} alignItems="center">
          {allCarrierProjects?.carrierProjects && (
            <Select
              sx={{ borderRadius: 1 }}
              SelectDisplayProps={{ style: { paddingTop: "8px" } }}
              value={data.carrierProject.id}
              onChange={(e) => navigate({ to: `/project/${e.target.value}` })}
            >
              {allCarrierProjects?.carrierProjects
                ?.slice()
                ?.sort((a, b) => a.name.localeCompare(b.name))
                ?.map((project) => (
                  <MenuItem key={project.id} value={project.id}>
                    {project.name}
                  </MenuItem>
                ))}
            </Select>
          )}
          <Chip size="small" label={carrierSlug} sx={{ borderRadius: 1 }} />
          <Chip size="small" label={lineOfBusiness} sx={{ borderRadius: 1 }} />
        </Stack>
      }
      noPadding={true}
      tabs={[
        { label: "Edit Portal Screen Captures", to: `/project/${projectId}` },
        { label: "Transcribe Form", to: `/project/${projectId}/transcribe` },
        { label: "Map to Golden Questions", to: `/project/${projectId}/map` },
        { label: "Preview New Application", to: `/project/${projectId}/preview` },
      ]}
      actions={
        <Stack direction="row" spacing={1} alignItems="center" divider={<Divider orientation="vertical" />}>
          <MarkChatRead fontSize="small" />
          <Typography fontWeight={500} variant="body2">
            Application Questions
          </Typography>
          <Chip size="small" label={mappedAnnotationCount} color="info" sx={{ fontWeight: 700 }} />
          <Divider orientation="vertical" sx={{ height: 20 }} />
          <Link fontSize="small" />
          <Typography fontWeight={500} variant="body2">
            Golden Questions
          </Typography>
          <Chip
            size="small"
            label={goldenPercentageDisplay}
            sx={{ backgroundColor: colors.orange[200], color: "white", fontWeight: 700 }}
          />
        </Stack>
      }
    >
      <Outlet />
    </PageTemplate>
  );
};
