import { Box } from "@mui/material";
import React from "react";
import "./DisabledCell.css";

export const DisabledCell: React.VFC = () => {
  return (
    <Box
      data-testid="disabled-cell"
      className="disabled-cell"
      sx={{
        width: "100%",
        height: "100%",
        userSelect: "none",
      }}
    />
  );
};
