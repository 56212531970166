import { useEffect, useRef } from "react";

/**
 * Returns the previous value of a reference after a component update.
 *
 * @param value The value to track.
 * @param shouldUpdate A function that determines if the previous value should be updated.
 */
export function usePrevious<T>(
  value: T,
  shouldUpdate?: (opts: { prev: T | undefined; next: T }) => boolean
): T | undefined {
  const ref = useRef<T>();
  useEffect(() => {
    if (shouldUpdate && !shouldUpdate({ prev: ref.current, next: value })) {
      return;
    }
    ref.current = value;
  });
  return ref.current;
}
