import { useCallback, useState } from "react";

export function useSet<T>() {
  const [state, setState] = useState(new Set<T>());

  return [
    state as ReadonlySet<T>,
    {
      set: useCallback(
        (value: T) => {
          setState((prevState) => {
            return new Set(prevState).add(value);
          });
        },
        [setState]
      ),
      delete: useCallback(
        (value: T) => {
          setState((prevState) => {
            const newState = new Set(prevState);
            newState.delete(value);
            return newState;
          });
        },
        [setState]
      ),
      deleteMany: useCallback(
        (values: T[]) => {
          setState((prevState) => {
            const newState = new Set(prevState);
            values.forEach((value) => newState.delete(value));
            return newState;
          });
        },
        [setState]
      ),
      toggle: useCallback(
        (value: T) => {
          setState((prevState) => {
            const newState = new Set(prevState);
            newState.has(value) ? newState.delete(value) : newState.add(value);
            return newState;
          });
        },
        [setState]
      ),
      toggleMany: useCallback(
        (values: T[]) => {
          setState((prevState) => {
            const newState = new Set(prevState);
            values.forEach((value) => (newState.has(value) ? newState.delete(value) : newState.add(value)));
            return newState;
          });
        },
        [setState]
      ),
      setMany: useCallback(
        (values: T[]) => {
          setState((prevState) => {
            const newState = new Set(prevState);
            values.forEach((value) => newState.add(value));
            return newState;
          });
        },
        [setState]
      ),
      reset: useCallback(() => setState(new Set()), [setState]),
    },
  ] as const;
}
