import type { Components, Theme } from "@mui/material/styles";

export function Alert(theme: Theme): Components<Theme> {
  return {
    MuiAlert: {
      styleOverrides: {
        message: {
          flex: 1,
        },
      },
      variants: [
        {
          props: { severity: "error" },
          style: {
            backgroundColor: theme.palette.error.light,
            color: theme.palette.error.dark,
          },
        },
        {
          props: { severity: "info" },
          style: {
            backgroundColor: theme.palette.info.light,
            color: theme.palette.info.dark,
          },
        },
        {
          props: { severity: "success" },
          style: {
            backgroundColor: theme.palette.success.light,
            color: theme.palette.success.dark,
          },
        },
        {
          props: { severity: "warning" },
          style: {
            backgroundColor: theme.palette.warning.light,
            color: theme.palette.warning.dark,
          },
        },
      ],
    },
  };
}
