import { TextField } from "@mui/material";
import * as React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { default as NumberFormat, NumberFormatProps, NumberFormatValues } from "react-number-format";
import { combinedText } from "./common/combineText";
import { parseIntOrNull } from "./common/parseIntOrNull";
import { FormFieldProps, processFormFieldProps } from "./FormFieldProps";
import { FormFieldTextProps } from "./FormFieldText";

export interface FormFieldNumberFormatProps
  extends Omit<NumberFormatProps<FormFieldTextProps>, "defaultValue">,
    Omit<FormFieldProps, "label"> {
  /**
   * @default 'integer'
   */
  extractValueAs?: "formatted-string" | "integer";
}

export const FormFieldNumberFormat: React.VFC<FormFieldNumberFormatProps> = (props) => {
  const { control } = useFormContext(); // retrieve all hook methods
  const { ControllerProps, helperText, infoText, ...rest } = processFormFieldProps(props);

  return (
    <Controller
      control={control}
      defaultValue=""
      {...ControllerProps}
      render={({ field: { onChange, onBlur, value, name, ref }, fieldState: { error } }) => {
        const handleChange = (values: NumberFormatValues) => {
          if (props.extractValueAs === "formatted-string") {
            onChange(values.formattedValue);
          } else {
            const { value } = values;
            onChange(parseIntOrNull(value));
          }
        };

        return (
          <NumberFormat
            customInput={TextField}
            {...rest}
            name={name}
            inputRef={ref}
            error={!!error}
            helperText={combinedText({
              helperText: helperText,
              error: error?.message,
            })}
            value={value}
            onBlur={onBlur}
            onValueChange={handleChange}
          />
        );
      }}
    />
  );
};
