import type { Components, Theme } from "@mui/material/styles";

export function List(theme: Theme): Components<Theme> {
  return {
    MuiListItemButton: {
      styleOverrides: {
        root: {
          borderRadius: "5px",
          gap: "12px",
          "&.Mui-selected": {
            color: theme.palette.primary.main,
          },
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          color: "inherit",
          fontSize: "16px",
          minWidth: "0 !important",
          "& svg": {
            fontSize: "inherit",
          },
        },
      },
    },
    MuiListItemText: {
      defaultProps: {
        disableTypography: true,
      },
      styleOverrides: {
        root: {
          fontSize: "14px",
          fontWeight: 500,
          lineHeight: "16px",
          letterSpacing: "-0.04em",
        },
      },
    },
  };
}
