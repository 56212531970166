import { FormBuilder } from "@cp/forms";
import { useToaster } from "@cp/theme";
import { z } from "zod";
import { CarrierProjectsDocument, useCreateCarrierProjectMutation } from "../../../generated/graphql";
import { useModalForm } from "../../../hooks/useModalForm";

interface State {
  name: string;
  carrierSlug: string;
  lineOfBusiness: string;
}

const FORM = FormBuilder.objectOf<State>({
  name: FormBuilder.string().options({ label: "Name", helperText: "Human readable name. e.g. Markel - BOP" }),
  carrierSlug: FormBuilder.string().options({
    label: "Carrier Slug",
    helperText: "Lowercase and no spaces",
    validation: z.string().regex(/^[a-z-]+$/, { message: "Carrier slug must be lowercase and no special characters" }),
  }),
  lineOfBusiness: FormBuilder.string().options({ label: "Line Of Business", helperText: "e.g. BOP, AUTOB, WC" }),
});

export function useCreateCarrierProject() {
  const { toast, toastError } = useToaster();
  const [create, response] = useCreateCarrierProjectMutation({
    refetchQueries: [CarrierProjectsDocument],
  });
  const { openFormModal } = useModalForm({
    title: "Create a new project",
    message: "Create a new carrier project to group recordings.",
    submitText: "Create",
    onSubmit: async (data) => {
      await create({
        variables: {
          input: data,
        },
        onCompleted: () => {
          toast("Project created");
        },
        onError: toastError,
      });
    },
    form: FORM,
  });

  return [openFormModal, response] as const;
}
