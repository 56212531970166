import { useMemo } from "react";
import { useCurationState } from "../../../pages/curation/state/useCurationState";
import { QUESTION_LINTERS } from "./linters";
import { LinterResult } from "./types";

export function useQuestionLinter(questionId: string): LinterResult[] {
  const { getQuestion } = useCurationState();
  return useMemo(() => {
    const question = getQuestion(questionId);
    if (!question) {
      return [];
    }

    return QUESTION_LINTERS.flatMap((linter) => linter(question));
  }, [questionId]);
}
