import { useInterval } from "@cp/react-hooks";
import { Arrays } from "@cp/toolkit";
import { useAtom } from "jotai";
import { atomWithStorage } from "jotai/utils";
import { useEffect } from "react";
import { v4 as uuid } from "uuid";
import { MultiplayerQuery, useMultiplayerQuery } from "../../generated/graphql";

/**
 * This state is stored in localStorage and should not include any breaking changes without
 * changing the storage key.
 */
interface PlayerState {
  userId: string;
  name: string;
}

const playerAtom = atomWithStorage<PlayerState | null>("ontolio:player", null);

export function useMultiplayer(): MultiplayerQuery["multiplayer"]["players"] {
  // Handle name
  const [user, setUser] = useAtom(playerAtom);
  useEffect(() => {
    if (!user?.name) {
      const name = window.prompt("What is your name?");
      if (name) {
        setUser({ userId: uuid(), name });
      }
    }
  }, [user, setUser]);

  const { data, refetch, previousData } = useMultiplayerQuery({
    variables: { input: user! },
    skip: !user?.name,
  });

  // ping every 5 seconds
  useInterval(() => {
    if (document.visibilityState === "visible" && user) {
      refetch();
    }
  }, 5000);

  return (data || previousData)?.multiplayer.players ?? Arrays.empty();
}
