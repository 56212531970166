import { Chip } from "@mui/material";
import { useMatch } from "@tanstack/react-location";
import { TagSelect } from "../../../../components/buttons/TagSelect";
import { useClobQuestionMetadataSummaryQuery } from "../../../../generated/graphql";
import { useClobQuestionLobCarrierFilter } from "../atoms";

const MISSING_QUESTIONS_CLOB_ID = "070ea272-35b1-40cb-89ee-e09e55090f21";

export const ClobLobAndCarrierFilter = () => {
  const [clobQuestionLobCarrierFilter, setClobQuestionLobCarrierFilter] = useClobQuestionLobCarrierFilter();
  const {
    params: { clobId },
  } = useMatch();

  const { data: metadataSummary } = useClobQuestionMetadataSummaryQuery();

  const lineOfBusinessesOptions =
    metadataSummary?.clobQuestionMetadataSummary.linesOfBusiness.map((lob) => ({
      label: lob,
      value: lob,
    })) ?? [];

  const carrierSlugsOptions =
    metadataSummary?.clobQuestionMetadataSummary.carrierSlugs.map((slug) => ({
      label: slug,
      value: slug,
    })) ?? [];

  // _missing_questions ClOB ID
  if (clobId !== MISSING_QUESTIONS_CLOB_ID) {
    return null;
  }

  return (
    <>
      {clobQuestionLobCarrierFilter?.lineOfBusiness && (
        <Chip
          key={clobQuestionLobCarrierFilter?.lineOfBusiness}
          color="warning"
          variant="outlined"
          label={clobQuestionLobCarrierFilter?.lineOfBusiness}
          size="small"
          sx={{ borderRadius: 1 }}
          onDelete={() => setClobQuestionLobCarrierFilter({ ...clobQuestionLobCarrierFilter, lineOfBusiness: "" })}
        />
      )}
      {clobQuestionLobCarrierFilter?.carrierSlugs.map((slug) => (
        <Chip
          key={slug}
          color="info"
          variant="outlined"
          label={slug}
          size="small"
          sx={{ borderRadius: 1 }}
          onDelete={() =>
            setClobQuestionLobCarrierFilter({
              ...clobQuestionLobCarrierFilter,
              carrierSlugs: clobQuestionLobCarrierFilter.carrierSlugs.filter((s) => s !== slug),
            })
          }
        />
      ))}
      <TagSelect
        onAdd={(carrier) =>
          setClobQuestionLobCarrierFilter({
            ...clobQuestionLobCarrierFilter,
            carrierSlugs: [...new Set([...clobQuestionLobCarrierFilter.carrierSlugs, carrier])],
          })
        }
        label="Carriers"
        options={carrierSlugsOptions}
      />
      <TagSelect
        onAdd={(lob) => setClobQuestionLobCarrierFilter({ ...clobQuestionLobCarrierFilter, lineOfBusiness: lob })}
        label="Line of Business"
        options={lineOfBusinessesOptions}
      />
    </>
  );
};
