import { smartMatch } from "@cp/toolkit";
import { Stack, Typography } from "@mui/material";
import { useSearch } from "@tanstack/react-location";
import { SortableTree, TreeItemComponentProps } from "dnd-kit-sortable-tree";
import React, { useEffect } from "react";
import { LocationGenerics } from "../../../App";
import { TreeItem } from "../../../components/sortables/components/TreeItem";
import { useClobContext } from "../../../model/clobs/context";
import { ClobTreeItemData, EditableClobQuestion } from "../../../model/clobs/types";
import { useClobQuestionFilter, useClobQuestionLobCarrierFilter, useMinimizedMode } from "./atoms";
import { ClobQuestionBody } from "./components/ClobQuestionBody";
import { ClobQuestionTitle } from "./components/ClobQuestionTitle";

export const MappableClobTree: React.VFC = React.memo(() => {
  const {
    state,
    actions: { setTree },
  } = useClobContext();

  return (
    <SortableTree<ClobTreeItemData>
      items={state.tree}
      disableSorting={true}
      onItemsChanged={setTree}
      TreeItemComponent={TreeItemComponent}
    />
  );
});

MappableClobTree.displayName = "MappableClobTree";

const TreeItemComponent = React.memo(
  React.forwardRef<HTMLDivElement, TreeItemComponentProps<ClobTreeItemData>>((props, ref) => {
    const { depth, item, handleProps } = props;
    const id = item.id.toString();
    const isQuestion = item.type === "question";
    const question = useClobContext().state.questions.get(id);
    const [clobQuestionFilter] = useClobQuestionFilter();
    const [clobQuestionLobCarrierFilter] = useClobQuestionLobCarrierFilter();

    const [isMinimized] = useMinimizedMode();

    const { navigateToClobQuestion, clobSearchQuery } = useSearch<LocationGenerics>();
    useEffect(() => {
      if (navigateToClobQuestion) {
        handleScrollToQuestion(navigateToClobQuestion);
      }
    }, [navigateToClobQuestion]);

    const handleScrollToQuestion = (id: string) => {
      const question = document.getElementById(id);
      if (question) {
        question.scrollIntoView({ behavior: "smooth" });
      }
    };

    if (!isQuestion) {
      return (
        <TreeItem {...props} ref={ref} handleProps={handleProps} onRemove={undefined}>
          <Stack gap={1} direction="row" alignItems="center">
            <Typography fontWeight={700} color="text.disabled">
              H{depth + 1}
            </Typography>
            <Typography flex={1} sx={{ whiteSpace: "normal" }}>
              {item.title}
            </Typography>
          </Stack>
        </TreeItem>
      );
    }

    const shouldHide =
      (clobQuestionFilter === "mapped" && !question?.curationQuestion) ||
      (clobQuestionFilter === "unmapped" && !!question?.curationQuestion) ||
      (question?.missingQuestion &&
        clobQuestionLobCarrierFilter.lineOfBusiness &&
        clobQuestionLobCarrierFilter.lineOfBusiness !== question?.missingQuestion?.lineOfBusiness) ||
      (question?.missingQuestion &&
        clobQuestionLobCarrierFilter.carrierSlugs.length > 0 &&
        !clobQuestionLobCarrierFilter.carrierSlugs.includes(question?.missingQuestion?.carrierSlug ?? ""));

    if (shouldHide) {
      return null;
    }

    const expand = expandQuestion(question, clobSearchQuery, isMinimized);

    return (
      <TreeItem {...props} ref={ref} color={"#00bcd4"} handleProps={handleProps} onRemove={undefined}>
        <ClobQuestionTitle
          id={id}
          showScreenshotIcon={!!question?.missingQuestion?.fileUrl}
          title={item.title}
          expanded={expand}
          body={<ClobQuestionBody id={id} search={clobSearchQuery ?? ""} />}
        />
      </TreeItem>
    );
  })
);

function expandQuestion(question: EditableClobQuestion | undefined, query: string | undefined, isMinimized: boolean) {
  if (!query || !question) {
    return !isMinimized;
  }

  return smartMatch(query, [
    question.componentType,
    question.curationQuestion?.text,
    question.curationQuestion?.key,
    question.helperText,
    `${question.acordELabelId}_${question.acordELabelCounter}`,
    question.infoText,
    question.text,
  ]);
}

TreeItemComponent.displayName = "TreeItemComponent";
