import { createContext, useContext } from "react";
import { DefaultRenderers, Renderers } from "../form/Renderers";

/**
 * React-context provider for the form renderers.
 */
export const FormRendererContext = createContext<Renderers>(DefaultRenderers);

/**
 * React hook to get the FormContext
 */
export function useFormRenderers() {
  return useContext(FormRendererContext);
}
