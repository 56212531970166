import { Switch, Tooltip } from "@mui/material";
import { sum } from "lodash";
import React from "react";
import { CurationNodeType } from "../../../generated/graphql";
import { useCurationActions } from "../state/useCurationActions";
import { useCurationState } from "../state/useCurationState";

interface Props {
  questionId: string;
}

export const ConditionalQuestionToggle: React.FC<Props> = ({ questionId }) => {
  const { curationNodeMap } = useCurationState();
  const actions = useCurationActions();
  const maybeConditionalQuestion = curationNodeMap.get(questionId);
  const isConditionalQuestion = maybeConditionalQuestion?.type === CurationNodeType.CoverageQuestion;
  const subsectionLength =
    maybeConditionalQuestion?.type === CurationNodeType.CoverageQuestion
      ? sum(maybeConditionalQuestion.children.map((childId) => curationNodeMap.get(childId)?.children.length ?? 0))
      : 0;
  const isDisabled = isConditionalQuestion && subsectionLength > 0;

  const control = (
    <Tooltip title="Conditional question">
      <Switch
        size="small"
        checked={isConditionalQuestion}
        disabled={isDisabled}
        onChange={(evt, checked) => {
          evt.stopPropagation();
          if (checked) {
            actions.makeConditionalQuestion({ questionId });
          } else {
            actions.unmakeConditionalQuestion({ questionId });
          }
        }}
      />
    </Tooltip>
  );

  if (isDisabled) {
    return (
      <Tooltip title="Cannot remove coverage section when the child section has children.">
        <span>{control}</span>
      </Tooltip>
    );
  }
  return control;
};
