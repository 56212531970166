export type SparseRow = number & { __sparseRow: true };

export type Primitive = string | number | boolean | null | undefined;

/**
 * Util to track which rows exist, translating from sparse to not sparse and adding new rows
 */
export class RowTracker {
  private constructor(private existingRows: SparseRow[]) {}

  static create(fieldsIds: string[], getValues: (id: string) => Primitive | Primitive[]): RowTracker {
    const rowSet = new Set<SparseRow>();
    for (const fieldId of fieldsIds) {
      const value = getValues(fieldId);

      const valueAsArray = Array.isArray(value) ? value : [value];
      valueAsArray.forEach((v, i) => {
        if (v != null && v !== "") {
          rowSet.add(i as SparseRow);
        }
      });
    }
    // they may be sparse, but they are sorted
    const existingRows = [...rowSet].sort();
    return new RowTracker(existingRows);
  }

  atLeast(numRows: number): RowTracker {
    // eslint-disable-next-line @typescript-eslint/no-this-alias, unicorn/no-this-assignment
    let current: RowTracker = this;
    while (current.existingRows.length < numRows) {
      current = current.addRow();
    }
    return current;
  }

  /**
   * Returns the next row index.
   * Gets the last row index and increments it.
   */
  addRow(): RowTracker {
    if (this.existingRows.length === 0) {
      return new RowTracker([0 as SparseRow]);
    }

    const nextRow = (this.existingRows[this.existingRows.length - 1] + 1) as SparseRow;
    return new RowTracker([...this.existingRows, nextRow]);
  }

  getRows() {
    return this.existingRows;
  }
}
