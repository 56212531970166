import { EmptyState } from "@cp/theme";
import { Edit } from "@mui/icons-material";
import React from "react";
import { useSelectedCurationNodeId } from "../../../components/panels/atoms";
import { useCurationState } from "../state/useCurationState";
import { CurationQuestionEditor } from "./CurationQuestionEditor";
import { CurationSectionEditor } from "./CurationSectionEditor";

export const DetailsEditor: React.FC = () => {
  const [selectedId] = useSelectedCurationNodeId();
  const { getQuestion, getSection, curationNodeMap } = useCurationState();

  if (!selectedId) {
    return (
      <EmptyState
        title="Details"
        description="Select a question or section to view it here"
        icon={<Edit fontSize="large" />}
      />
    );
  }

  const maybeQuestion = getQuestion(selectedId);
  const maybeSection = getSection(selectedId);
  const maybeEntity = curationNodeMap.get(selectedId);

  if (maybeQuestion) {
    // key for force re-render when question changes
    return <CurationQuestionEditor key={maybeQuestion.id} question={maybeQuestion} />;
  }

  if (maybeEntity) {
    return (
      <CurationSectionEditor
        key={selectedId}
        section={
          maybeSection ?? {
            id: selectedId,
            conditions: [],
            createdAt: null,
            updatedAt: null,
          }
        }
        entity={maybeEntity}
      />
    );
  }

  return null;
};
