import {
  CurationSectionFragment,
  EntityType,
  EntityTypeLayout,
  UpsertCurationSectionInput,
} from "../../generated/graphql";
import { conditionToInput } from "../conditions/conditionToInput";

export interface CurationTableSectionFormState {
  canAddRemove: boolean;
  defaultValues: string | undefined;
  fixedColumns: number;
}

export interface CurationEntitySectionFormState {
  entityTypeId: EntityType | undefined;
  entityTypeLayout: EntityTypeLayout;
}

export interface CurationSectionFormState {
  title: string | undefined;
  config: {
    table?: CurationTableSectionFormState;
    entity?: CurationEntitySectionFormState;
    none?: {};
    pivotSubsections?: {};
  };
  conditions: UpsertCurationSectionInput["conditions"];
  helperText: string | undefined;
}

export function curationSectionToInput(section: Partial<CurationSectionFragment> = {}): CurationSectionFormState {
  // We don't spread here since we will otherwise get unwanted fields
  const formState: CurationSectionFormState = {
    title: section.title || "",
    conditions: section.conditions?.map(conditionToInput) ?? [],
    helperText: section.helperText ?? "",
    config: {},
  };

  if (section.entityTypeId) {
    formState.config.entity = {
      entityTypeId: section.entityTypeId,
      entityTypeLayout: section.entityTypeLayout ?? EntityTypeLayout.Paged,
    };
  } else if (section.tableConfig) {
    formState.config.table = {
      canAddRemove: section.tableConfig.canAddRemove,
      defaultValues: section.tableConfig.defaultValues,
      fixedColumns: section.tableConfig.fixedColumns,
    };
  } else if (section.pivotSubsectionsConfig?.enabled) {
    formState.config.pivotSubsections = {};
  } else {
    formState.config.none = {};
  }

  return formState;
}
