export enum SortOrder {
  Asc = "asc",
  Desc = "desc",
}

export interface SortingProps {
  orderBy?: string;
  order?: SortOrder;
  onSortChange?: (orderBy: string, order: SortOrder) => void;
}
