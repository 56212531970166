import { useResizer } from "@cp/react-hooks";
import { Box } from "@mui/material";
import React, { PropsWithChildren } from "react";

interface Props {
  side: "left" | "right";
  initialWidth: number;
}

export const ResizingSidebar: React.FC<PropsWithChildren<Props>> = ({ children, side, initialWidth }) => {
  const { sidebarProps, sidebarResizer } = useResizer<HTMLDivElement>({
    side,
    initialWidth: initialWidth,
    minWidth: 300,
    maxWidth: 1200,
  });

  return (
    <div {...sidebarProps}>
      {side === "right" && <Box sx={{ "&:hover": { background: "#c1c3c5b4" } }} {...sidebarResizer} />}
      {children}
      {side === "left" && <Box sx={{ "&:hover": { background: "#c1c3c5b4" } }} {...sidebarResizer} />}
    </div>
  );
};
