import { useBoolean } from "@cp/react-hooks";
import { Alert, Button } from "@mui/material";
import React from "react";
import { useClobApplicationVersionQuery } from "../../../generated/graphql";
import { useClobContext } from "../../../model/clobs/context";

interface Props {
  onRefetch: () => void;
  clobId: string;
}

export const ClobVersionChangeBanner: React.VFC<Props> = ({ clobId, onRefetch }) => {
  const { version: loadedVersion } = useClobContext().state;
  const [isVisible, isVisibleActions] = useBoolean(true);
  const { data } = useClobApplicationVersionQuery({
    variables: { id: clobId },
    // 30 seconds
    pollInterval: 30_000,
  });

  if (!data) {
    return null;
  }

  const latestVersion = data.clob.version;
  if (loadedVersion === latestVersion || latestVersion === 0 || loadedVersion === 0) {
    return null;
  }

  if (!isVisible) {
    return null;
  }

  return (
    <Alert
      color="warning"
      sx={{ fontSize: "medium", position: "fixed", top: 0, left: 0, right: 0, zIndex: 10 }}
      onClose={isVisibleActions.toggle}
    >
      <span>
        You are viewing an outdated version {loadedVersion} of this application. The latest version is{" "}
        <b>{latestVersion}</b>.
      </span>
      <Button onClick={onRefetch}>Refresh</Button>
    </Alert>
  );
};
