import { Backdrop, Stack } from "@mui/material";
import React from "react";

interface Props {
  open: boolean;
  onClose: () => void;
  children: React.ReactNode;
}

export const Omnibar: React.FC<Props> = ({ open, onClose, children }) => {
  if (!open) {
    return null;
  }

  return (
    <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={open} onClick={onClose}>
      <Stack
        alignItems="center"
        style={{ width: "100%", maxWidth: 600 }}
        // Prevent clicks from closing the omnibar or losing focus
        onClick={(e) => e.stopPropagation()}
        onPointerDown={(e) => e.stopPropagation()}
        onKeyPress={(e) => e.stopPropagation()}
        onKeyDown={(e) => e.stopPropagation()}
      >
        {children}
      </Stack>
    </Backdrop>
  );
};
