import { ApolloClient, ApolloLink, createHttpLink, InMemoryCache, split } from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { getMainDefinition } from "@apollo/client/utilities";
import fetch from "cross-fetch";
import { Kind, OperationDefinitionNode, OperationTypeNode } from "graphql";
import { createClient } from "graphql-ws";
import cacheConfig from "../generated/cacheConfig";
import {
  CurationQuestionsFilteredDocument,
  PortalAnnotationDocument,
  RecordingSessionsDocument,
} from "../generated/graphql";
import { AuthToken } from "../util/auth";
import { BACKEND_API_PATH } from "../util/constants";

const httpLink = createHttpLink({
  uri: BACKEND_API_PATH,
  fetch,
  credentials: "include",
});

const unauthorizedError = onError(({ graphQLErrors }) => {
  if (graphQLErrors) {
    for (const err of graphQLErrors) {
      if (err.extensions?.code === "UNAUTHENTICATED") {
        AuthToken.removeToken();
      }
    }
  }
});

const wsScheme = window.location.protocol === "https:" ? "wss" : "ws";

const wsLink = new GraphQLWsLink(
  createClient({
    // e.g. "ws://localhost:3001/graphql"
    url: process.env.REACT_APP_WS_GRAPHQL
      ? process.env.REACT_APP_WS_GRAPHQL
      : process.env.NODE_ENV === "development"
      ? `ws://localhost:9000/graphql`
      : `${wsScheme}://${window.location.host}/graphql`,
  })
);

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    const isSubscription =
      definition.kind === Kind.OPERATION_DEFINITION && definition.operation === OperationTypeNode.SUBSCRIPTION;
    return isSubscription;
  },
  wsLink,
  httpLink
);

const cleanTypeName = new ApolloLink((operation, forward) => {
  if (operation.variables) {
    const omitTypename = (key: string, value: unknown) => (key === "__typename" ? undefined : value);
    operation.variables = JSON.parse(JSON.stringify(operation.variables), omitTypename) as Record<string, unknown>;
  }
  return forward(operation);
});

// track mutation operations and invalidate cache based on the operation name
const invalidationLink = new ApolloLink((operation, forward) => {
  // get all mutation operations
  const mutations = operation.query.definitions.filter<OperationDefinitionNode>(
    (d): d is OperationDefinitionNode =>
      d.kind === Kind.OPERATION_DEFINITION && d.operation === OperationTypeNode.MUTATION
  );
  // get all the names of the mutation operations
  const mutationNames = mutations.flatMap((m) =>
    m.selectionSet.selections.flatMap((s) => (s.kind === Kind.FIELD ? [s.name.value] : []))
  );

  if (mutationNames.length === 0) {
    return forward(operation);
  }

  return forward(operation).map((data) => {
    if (mutationNames.includes("updateClobQuestion")) {
      void client.refetchQueries({
        include: [CurationQuestionsFilteredDocument],
      });
    }
    if (mutationNames.includes("moveCarrierRecordingsToProject")) {
      void client.refetchQueries({
        include: [RecordingSessionsDocument],
      });
    }
    if (mutationNames.includes("submitCurationApplicationEvents")) {
      void client.refetchQueries({
        include: [PortalAnnotationDocument],
      });
    }
    return data;
  });
});

export const createErrorLink = (handleError: (error: any) => void) =>
  onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      graphQLErrors.forEach(({ message, locations, path }) => {
        console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`);
      });
      handleError({ graphQLErrors, message: "An error occurred", name: "GraphQL Error" });
    }
    if (networkError) {
      console.log(`[Network error]: ${networkError}`);
      handleError(networkError);
    }
  });

export const client = new ApolloClient({
  link: ApolloLink.from([invalidationLink, cleanTypeName, splitLink, unauthorizedError]),
  cache: new InMemoryCache({
    ...cacheConfig,
    typePolicies: {
      ApplicationField: {
        keyFields: false,
      },
      ApplicationFieldSection: {
        keyFields: false,
      },
      CurationNode: {
        keyFields: false,
      },
      Query: {
        fields: {
          // This is meant to query agency-api, but since this is Ontolio, we will mock it instead.
          businessClassificationSearch: {
            read() {
              return [];
            },
          },
          // This is meant to query agency-api, but since this is Ontolio, we will mock it instead.
          guessBusinessClassification: {
            read() {
              return [];
            },
          },
        },
      },
    },
  }),

  queryDeduplication: true,
  defaultOptions: {
    query: {
      // Default to not using the cache.
      // We can slowly enable this once we have a good setup for invalidating the cache.
      fetchPolicy: "network-only",
    },
  },
});
