import { Box, Stack } from "@mui/material";
import { createTableConfig, Table } from "@qw/table";
import { RecordingSessionsQuery } from "../../generated/graphql";
import { MoveRecordings } from "../../pages/recordings/projects/MoveRecordings";

type Session = RecordingSessionsQuery["recordingSessions"][0];

const config = createTableConfig<Session>()
  .addColumn({
    id: "thumbnail",
    header: "",
    render: ({ data }) => (
      <Box
        component="img"
        loading="lazy"
        alt="screenshot"
        src={`/api/recording/${data.recordings[0].id}/screenshot.png`}
        sx={{
          minWidth: "80px",
          minHeight: "80px",
          maxWidth: "80px",
          maxHeight: "80px",
          flexShrink: 0,
          objectFit: "fill",
          boxShadow: 1,
          borderRadius: 1,
          mr: 3,
        }}
      />
    ),
  })
  .addColumn({
    id: "recording",
    header: "Total Captures",
    align: "right",
    render: ({ data }) => data.recordings.length,
  })
  .addColumn({
    id: "recording",
    header: "Screen Capture",
    render: ({ data }) => (
      <Stack>
        {data.recordings.map((recording) => (
          <span key={recording.id}>{recording.name}</span>
        ))}
      </Stack>
    ),
  })
  .addColumn({
    id: "url",
    header: "URL",
    render: ({ data }) => (data.recordings[0].href ? new URL(data.recordings[0].href).hostname : ""),
  })
  .addColumn({
    id: "recorder",
    header: "Recorder",
    render: ({ data }) => data.recorderEmail,
  })
  .addColumn({
    id: "createdAt",
    header: "Created At",
    render: ({ data }) => new Date(data.date).toDateString(),
  })
  .addColumn({
    id: "actions",
    header: "",
    render: ({ data }) => (
      <Stack direction="row" spacing={1}>
        <MoveRecordings recordingIds={data.recordings.map((recording) => recording.id)} />
      </Stack>
    ),
  })
  .build();

interface Props {
  loading: boolean;
  error?: Error | undefined;
  sessions: Session[];
}

export function NewPortalRecordingsTable({ sessions, error, loading }: Props) {
  return (
    <Table
      {...config}
      data={sessions}
      emptyText="You don't have any screen captures yet."
      error={error}
      loading={loading}
    />
  );
}
