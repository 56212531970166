import { PaletteMode, Theme } from "@mui/material";
import { Components, createTheme as createMaterialTheme } from "@mui/material/styles";
import { createMixins } from "./mixins";
import { componentsOverrides } from "./overrides/overrides";
import { createPalette } from "./palette";
import { createShadows } from "./shadows";

export function createTheme(mode: PaletteMode): Theme {
  const palette = createPalette(mode);
  const shadows = createShadows();

  let theme = createMaterialTheme({
    palette,
    shadows,
    spacing: 6,
    typography: {
      fontFamily: [
        "Inter",
        "-apple-system",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(","),
      h1: {
        fontSize: "32px",
        fontWeight: 600,
        letterSpacing: "-0.04em",
        lineHeight: "40px",
        margin: "32px 0",
      },
      h2: {
        fontSize: "24px",
        fontWeight: 600,
        letterSpacing: "-0.04em",
        lineHeight: "32px",
        margin: "32px 0",
      },
      h3: {
        fontSize: "20px",
        fontWeight: 600,
        letterSpacing: "-0.04em",
        lineHeight: "28px",
        margin: "24px 0",
      },
      h4: {
        fontSize: "16px",
        fontWeight: 600,
        letterSpacing: "-0.04em",
        lineHeight: "24px",
        margin: "16px 0",
      },
      h5: {
        fontSize: "12px",
        fontWeight: 600,
        letterSpacing: "-0.02em",
        lineHeight: "16px",
        margin: "12px 0",
      },
      h6: {
        fontSize: "10px",
        fontWeight: 600,
        letterSpacing: "-0.02em",
        lineHeight: "16px",
        margin: "10px 0",
      },
      body2: {
        fontSize: "14px",
        letterSpacing: "-0.02em",
        lineHeight: "24px",
      },
      subtitle1: {
        color: palette.text.primary,
        fontSize: "14px",
        fontWeight: 600,
        letterSpacing: "-0.02em",
        lineHeight: "16px",
        margin: 0,
      },
      subtitle2: {
        color: palette.text.secondary,
        fontSize: "12px",
        fontWeight: 600,
        letterSpacing: "-0.02em",
        lineHeight: "16px",
        margin: 0,
      },
    },
  });

  const mixins = createMixins(theme);

  theme = createMaterialTheme(theme, mixins);

  // Add overrides for components
  theme.components = componentsOverrides(theme) as Components;
  return theme;
}

declare module "@mui/material/styles/createMixins" {
  interface Mixins {
    contentWidth?: CSSProperties;
    contentNavWidth?: CSSProperties;
    contentContainerWidth?: CSSProperties;
    contentContainerShim?: CSSProperties;
    responsiveGap?: CSSProperties;
    responsivePx?: CSSProperties;
  }
}
