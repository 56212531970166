import {
  Book,
  Category,
  CollectionsBookmark,
  Domain,
  Explore,
  HealthAndSafety,
  Logout,
  PictureAsPdf,
} from "@mui/icons-material";
import { Box, colors, IconButton, Stack, Tooltip } from "@mui/material";
import { Link } from "@tanstack/react-location";
import React from "react";
import { AuthToken } from "../../util/auth";

const ITEMS = [
  {
    name: "Carrier Forms",
    Icon: <Domain />,
    path: "/",
  },
  {
    name: "Curation Library",
    Icon: <Book />,
    path: `/curations`,
  },
  {
    name: "Entities",
    Icon: <Category />,
    path: "/entities",
  },
  {
    name: "Preview",
    Icon: <Explore />,
    path: "/preview",
  },
  {
    name: "PDF Curation",
    Icon: <PictureAsPdf />,
    path: "/pdfs",
  },
  {
    name: "Application Projects",
    Icon: <CollectionsBookmark />,
    path: "/projects",
  },
  {
    name: "Data Health",
    Icon: <HealthAndSafety />,
    path: "/data-health",
  },
];

export const AppSidebar: React.VFC = () => {
  return (
    <Stack
      alignContent={"center"}
      gap={2}
      px={2}
      py={3}
      width={64}
      border="1px solid"
      borderColor="divider"
      sx={{ backgroundColor: colors.blueGrey[100] }}
    >
      <img src="/favicon.ico" alt="Ontolio" />
      {ITEMS.map((item) => (
        <Tooltip key={item.name} title={item.name} placement="right">
          <div>
            <Link to={item.path}>
              <IconButton key={item.name}>{item.Icon}</IconButton>
            </Link>
          </div>
        </Tooltip>
      ))}
      <Box my="auto" />
      <Tooltip title="Logout" placement="right">
        <IconButton
          onClick={() => {
            AuthToken.removeToken();
            window.location.reload();
          }}
        >
          <Logout />
        </IconButton>
      </Tooltip>
    </Stack>
  );
};
