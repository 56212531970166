import { DragEndEvent } from "@dnd-kit/core";
import { LexoRank } from "lexorank";
import { IHasRank, IListItemData, ISortablePayload } from "./types";

export const defaultItems = (): IListItemData[] => {
  let currentRank = LexoRank.middle();
  const data: IListItemData[] = [];
  for (let i = 0; i < 10; i++) {
    data.push({
      id: `${i}-id`,
      lexoRank: currentRank.toString(),
    });
    currentRank = currentRank.genNext();
  }

  return data.sort(sortByLexoRankAsc);
};

export function sortByLexoRankAsc(a: IHasRank, b: IHasRank): number {
  if (!a.lexoRank && b.lexoRank) {
    return -1;
  }
  if (a.lexoRank && !b.lexoRank) {
    return 1;
  }

  if (!a.lexoRank || !b.lexoRank) {
    return 0;
  }

  return a.lexoRank.localeCompare(b.lexoRank);
}

export function createSortablePayloadByIndex(items: IListItemData[], event: DragEndEvent): ISortablePayload {
  const { active, over } = event;
  const oldIndex = items.findIndex((x) => x.id === active.id);
  const newIndex = items.findIndex((x) => x.id === over?.id);
  let input: ISortablePayload;
  const entity = items[oldIndex];
  if (newIndex === 0) {
    const nextEntity = items[newIndex];
    input = { prevEntity: undefined, entity: entity, nextEntity: nextEntity } as ISortablePayload;
  } else if (newIndex === items.length - 1) {
    const prevEntity = items[newIndex];
    input = { prevEntity: prevEntity, entity: entity, nextEntity: undefined } as ISortablePayload;
  } else {
    const prevEntity = items[newIndex];
    const offset = oldIndex > newIndex ? -1 : 1;
    const nextEntity = items[newIndex + offset];
    input = { prevEntity: prevEntity, entity: entity, nextEntity: nextEntity } as ISortablePayload;
  }

  return input;
}

export function getBetweenRankAsc(payload: ISortablePayload): LexoRank {
  const { prevEntity, entity, nextEntity } = payload;
  let newLexoRank: LexoRank;
  if (!prevEntity && !!nextEntity) {
    newLexoRank = LexoRank.parse(nextEntity.lexoRank).genPrev();
  } else if (!nextEntity && !!prevEntity) {
    newLexoRank = LexoRank.parse(prevEntity.lexoRank).genNext();
  } else if (!!prevEntity && !!nextEntity) {
    newLexoRank = LexoRank.parse(nextEntity.lexoRank).between(LexoRank.parse(prevEntity.lexoRank));
  } else {
    newLexoRank = LexoRank.parse(entity.lexoRank).genNext();
  }

  return newLexoRank;
}
