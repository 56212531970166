import { useEffect } from "react";
import { useEvent } from "./useEvent";

export function useInterval<T extends (...args: any[]) => unknown>(callback: T, delay: number | null) {
  // Remember the latest callback.
  const savedCallback = useEvent(callback);

  // Set up the interval.
  useEffect(() => {
    if (delay !== null) {
      const id = setInterval(savedCallback, delay);
      return () => clearInterval(id);
    }
    return () => {
      // do nothing
    };
  }, [delay]);
}
