import { Box, colors, Typography } from "@mui/material";
import React from "react";

interface Props {
  drop: React.Ref<HTMLElement>;
  dropText: string;
  children: React.ReactNode;
  isOver: boolean;
  canDrop: boolean;
  color?: "cyan" | "green";
}

export const DropArea: React.FC<Props> = ({ drop, dropText, canDrop, children, isOver, color = "cyan" }) => {
  return (
    <Box style={{ minHeight: "44px", position: "relative" }}>
      {children}
      {canDrop && (
        <Box
          ref={drop}
          sx={{
            minWidth: "250px",
            position: "absolute",
            borderRadius: "4px",
            zIndex: 5,
            border: "2px dashed",
            borderColor: colors[color][200],
            top: "10px",
            px: "10px",
            bottom: "10px",
            right: "10px",
            backgroundColor: isOver ? colors[color][200] : colors[color][100],
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography variant="body2" fontWeight={600} color="white">
            {dropText}
          </Typography>
        </Box>
      )}
    </Box>
  );
};
