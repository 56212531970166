import { NativeSelect, Stack } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import InputLabel from "@mui/material/InputLabel";
import { Controller, useFormContext } from "react-hook-form";
import { processFormFieldProps } from "../../form-fields/FormFieldProps";
import { getDefaultValue } from "../builders/getOptionsInternal";
import { MappedObjectOf } from "../types";
import { join } from "./join";
import { renderForm } from "./renderForm";

interface Props {
  name: string;
  fields: MappedObjectOf<any>;
  titles: Record<string, string>;
  label?: string;
}

export function OneOfForm({ name, fields, titles, label = "" }: Props) {
  const { control } = useFormContext();
  const { label: formLabel, ControllerProps } = processFormFieldProps({
    pseudoRequire: false,
    label,
    name,
  });

  return (
    <Controller
      control={control}
      defaultValue=""
      {...ControllerProps}
      render={({ field, fieldState: { error } }) => {
        const nestedKey = field.value ? Object.keys(field.value)[0] : "";
        const nestedForm = fields[nestedKey];
        const fieldForm = nestedForm ? renderForm(nestedForm, join(name, nestedKey)) : null;

        return (
          <Stack gap={2}>
            <InputLabel>{formLabel}</InputLabel>
            <FormControl variant="standard" fullWidth={true} error={!!error}>
              <FormHelperText error={true}>{error?.message}</FormHelperText>

              <NativeSelect
                value={nestedKey}
                onChange={(event) => {
                  const nextDefaultValue = getDefaultValue(fields[event.target.value]);
                  field.onChange({
                    [event.target.value]: nextDefaultValue,
                  });
                }}
              >
                {Object.entries(titles).map(([value, label]) => (
                  <option key={value} value={value}>
                    {label}
                  </option>
                ))}
              </NativeSelect>
            </FormControl>
            {fieldForm}
          </Stack>
        );
      }}
    />
  );
}
