import React from "react";
import { NewPortalRecordingsTable } from "../../../components/recordings/NewPortalRecordingsTable";
import { useRecordingSessionsQuery } from "../../../generated/graphql";

export const NewRecordingsPage: React.FC = () => {
  const { data, loading, error } = useRecordingSessionsQuery();
  const sessions = data?.recordingSessions ?? [];

  return <NewPortalRecordingsTable sessions={sessions} loading={loading} error={error} />;
};
