import { useEffect, useRef } from "react";
import { PortalAnnotationQuery } from "../../../generated/graphql";

export function useWatchWhenAnnotationMappingChanges(
  annotation: PortalAnnotationQuery["portalAnnotation"] | undefined,
  onChange: () => void
) {
  const annotationId = annotation?.id;
  const curationQuestionId = annotation?.clobQuestion.curationQuestionId;

  // keep track of the current annotation id
  const watchingAnnotationId = useRef(annotation?.id);

  // watch for changes to the annotation id and the curation question id
  useEffect(() => {
    // if the annotation id changes, update the current annotation id, but do not emit update
    if (annotationId !== watchingAnnotationId.current) {
      watchingAnnotationId.current = annotationId;
      return;
    }

    // if there is no annotation id, do not emit update
    if (!annotationId) {
      return;
    }

    // if the annotation id does not change, but the mapping id does, emit update
    if (curationQuestionId && watchingAnnotationId.current === annotationId) {
      onChange();
    }

    watchingAnnotationId.current = annotationId;
  }, [annotationId, curationQuestionId]);
}
