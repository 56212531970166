import { SwapHoriz } from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";
import React from "react";
import { useSelectedCurationQuestions, useSelectedCurationQuestionsSwapCallbacks } from "../selection-atom";

interface Props {
  id: string;
}

export const GoldenQuestionSwappingIcon: React.FC<Props> = ({ id }) => {
  const [selectedCurationQuestionIds] = useSelectedCurationQuestions();
  const [selectedCurationQuestionsSwapCallbacks] = useSelectedCurationQuestionsSwapCallbacks();

  if (selectedCurationQuestionIds.length === 0) {
    return <DisabledSwapIcon tooltipText="Select a question in the SCA to swap with" />;
  }
  /**
   * Limit users to swapping only one question at a time for now.
   * Our 'undo' functionality doesn't support undoing multiple swaps.
   */
  if (selectedCurationQuestionIds.length > 1) {
    return <DisabledSwapIcon tooltipText="Multple SCA questions selected - please select only one" />;
  }

  /**
   * There's an implicit coupling between useSelectedCurationQuestions and useSelectedCurationQuestionsSwapCallbacks,
   * such that it's possible for the two to get out of sync (e.g. if we set the 'useSelectedCurationQuestions' atom,
   * but not the 'useSelectedCurationQuestionsSwapCallbacks atom). This is a hacky way to detect that.
   */
  if (!selectedCurationQuestionsSwapCallbacks.get(selectedCurationQuestionIds[0])) {
    return (
      <DisabledSwapIcon tooltipText="Something went wrong with the swapping feature - please notify an engineer, and try refreshing for now" />
    );
  }

  return (
    <Tooltip title="Insert this golden question">
      <IconButton
        onClick={async (evt) => {
          evt.stopPropagation();
          void selectedCurationQuestionsSwapCallbacks.get(selectedCurationQuestionIds[0])?.(id);
        }}
      >
        <SwapHoriz />
      </IconButton>
    </Tooltip>
  );
};

const DisabledSwapIcon = (props: { tooltipText: string }) => {
  return (
    <Tooltip title={props.tooltipText}>
      <span>
        {/* span is required for tooltip to display on a disabled button */}
        <IconButton disabled={true}>
          <SwapHoriz />
        </IconButton>
      </span>
    </Tooltip>
  );
};
