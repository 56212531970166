import { Archive, Unarchive } from "@mui/icons-material";
import { ToggleButton, ToggleButtonGroup, Tooltip } from "@mui/material";
import React from "react";

interface Props {
  value: undefined | "archived" | "unarchived";
  handleFilter: (filter: undefined | "archived" | "unarchived") => void;
}

export const CurationQuestionFilter: React.VFC<Props> = ({ handleFilter, value }) => {
  const handleChange = (_: React.MouseEvent<HTMLElement>, newFilter: "archived" | "unarchived" | null) => {
    handleFilter(newFilter ?? undefined);
  };

  return (
    <ToggleButtonGroup value={value} exclusive={true} onChange={handleChange}>
      <ToggleButton size="small" value="unarchived">
        <Tooltip title="Filter by unarchived">
          <Unarchive fontSize="small" sx={{ mr: 1, color: "#7BC67E" }} />
        </Tooltip>
      </ToggleButton>
      <ToggleButton size="small" value="archived">
        <Tooltip title="Filter by archived">
          <Archive fontSize="small" sx={{ mr: 1, color: "#F88078" }} />
        </Tooltip>
      </ToggleButton>
    </ToggleButtonGroup>
  );
};
