import { Delete, Edit, KeyboardDoubleArrowDown } from "@mui/icons-material";
import { Box, BoxProps, colors, IconButton, Stack, Tooltip, Typography } from "@mui/material";
import { omit } from "lodash";
import React, { useMemo } from "react";
import { useSetSelectedCurationNodeId, useSetSelectedDrilldownId } from "../../../components/panels/atoms";
import { QuestionPaste } from "../../../components/QuestionPaste";
import { Visibility } from "../../../components/Visibility";
import { highlightText } from "../../../util/highlighter";
import { getAllNestedChildIds } from "../../../util/nestedChildren";
import { useExpandEntity } from "../atoms";
import { DragableHeader } from "../Dragables/DragableHeader";
import { DropArea } from "../Dragables/DropArea";
import { useCurationActions } from "../state/useCurationActions";
import { useCurationState } from "../state/useCurationState";
import { AddCurationQuestionField } from "../Toolbar/AddCurationQuestionField";
import { AddEntityButton } from "../Toolbar/AddEntityButton";
import { EntityChip } from "../Toolbar/EntityChip";
import { PivotSectionChip } from "../Toolbar/PivotSectionChip";
import { TableSectionChip } from "../Toolbar/TableSectionChip";
import { ConditionsWidget } from "../widgets/ConditionsWidget";
import { InheritedEntityQuestions } from "./InheritedEntityQuestions";
import { SectionCopy } from "./SectionCopy";
import { SectionPaste } from "./SectionPaste";
import SectionQuestionList from "./SectionQuestionList";
import { useSectionDropzone } from "./useSectionDropzone";

export interface ApplicationSectionProps {
  id: string;
  parentId: string;
  autoExpand?: boolean;
  depth: number;

  hideDelete?: boolean;
  dragRef?: React.Ref<HTMLButtonElement>;
  disableSelection?: boolean;
}

export const ApplicationSection = React.memo(
  React.forwardRef<HTMLDivElement, ApplicationSectionProps & BoxProps>(function ApplicationSectionComponent(
    props,
    ref
  ) {
    const isCurationPage = window.location.pathname.startsWith("/curations");

    const { id: sectionId, depth, parentId, autoExpand, hideDelete, dragRef, disableSelection, ...boxProps } = props;
    const { curationNodeMap, getSection, getTitle, getQuestion } = useCurationState();
    const { removeCurationSection, moveToNewNode } = useCurationActions();
    const drillDownToSection = useSetSelectedDrilldownId();
    const section = curationNodeMap.get(sectionId);
    const sectionMetadata = getSection(sectionId);
    const setSelectedCurationNodeId = useSetSelectedCurationNodeId();
    const [isExpanded, toggleExpanded, searchQuery] = useExpandEntity(sectionId);

    const [{ isOver, canDrop }, drop] = useSectionDropzone(section, moveToNewNode, sectionId);

    const highlight = (text: string) => {
      if (!searchQuery) {
        return text;
      }
      return highlightText({ searchWords: [searchQuery], autoEscape: true, textToHighlight: text });
    };

    const isEmptySection = useMemo(() => {
      if (sectionMetadata?.entityTypeId) {
        return false;
      }

      return ![...getAllNestedChildIds(sectionId, curationNodeMap)].some(getQuestion);
    }, [sectionId, curationNodeMap, sectionMetadata?.entityTypeId]);

    if (!section) {
      return null;
    }

    return (
      <Box ref={ref} {...omit(boxProps, ["isDragging"])}>
        <DropArea drop={drop} canDrop={canDrop} isOver={isOver} dropText="Drop to add to section">
          <DragableHeader
            dragRef={dragRef}
            backgroundColor={isEmptySection ? "grey.200" : undefined}
            collapsed={!isExpanded}
            borderColor={sectionMetadata?.entityTypeId ? colors.purple[500] : undefined}
            onCollapse={autoExpand ? undefined : toggleExpanded}
            actions={
              <>
                <SectionPaste id={sectionId} />
                <Visibility>
                  <QuestionPaste nodeId={sectionId} parentId={parentId} />

                  <Tooltip title="Drill down to section">
                    <IconButton size="small">
                      <KeyboardDoubleArrowDown fontSize="small" onClick={() => drillDownToSection(sectionId)} />
                    </IconButton>
                  </Tooltip>
                  {parentId && <SectionCopy id={sectionId} parentId={parentId} />}
                  <Tooltip title="Edit section">
                    <IconButton
                      size="small"
                      onClick={(evt) => {
                        evt.stopPropagation();
                        setSelectedCurationNodeId(sectionId);
                      }}
                    >
                      <Edit fontSize="small" />
                    </IconButton>
                  </Tooltip>
                  {!hideDelete && (
                    <Tooltip title="Delete section">
                      <IconButton
                        size="small"
                        onClick={(evt) => {
                          evt.stopPropagation();
                          if (window.confirm("Are you sure you want to delete this section?")) {
                            removeCurationSection({ sectionId, parentId });
                          }
                        }}
                      >
                        <Delete fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  )}
                </Visibility>
                {sectionMetadata?.tableConfig && <TableSectionChip />}
                {sectionMetadata?.pivotSubsectionsConfig && <PivotSectionChip />}
                <EntityChip entityType={sectionMetadata?.entityTypeId} />
                <ConditionsWidget conditions={sectionMetadata?.conditions} />
                <AddEntityButton parentNodeId={sectionId} addType="section" />
              </>
            }
          >
            <Typography color="text.primary" fontWeight={500} display="flex" alignItems="center" fontSize={"14px"}>
              <>
                <Typography component="span" color="text.secondary" fontWeight={600} pr={1} fontSize={"13px"}>
                  H{depth + 1}
                </Typography>
                {highlight(getTitle(sectionId))}
              </>
            </Typography>
          </DragableHeader>
        </DropArea>
        {(isExpanded || autoExpand) && (
          <Stack ml={2}>
            {sectionMetadata?.entityTypeId && (
              <InheritedEntityQuestions entityType={sectionMetadata.entityTypeId} disableSelection={disableSelection} />
            )}
            {!isCurationPage && <AddCurationQuestionField parentNodeId={sectionId} />}

            <SectionQuestionList depth={depth + 1} sectionId={sectionId} disableSelection={disableSelection} />
          </Stack>
        )}
      </Box>
    );
  })
);

ApplicationSection.displayName = "ApplicationSection";
