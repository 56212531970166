import { LinearProgress } from "@mui/material";
import React, { useEffect, useState } from "react";

interface Props {
  /**
   * The number of seconds it will take to complete the task.
   */
  secondsToComplete: number;
}

export const EstimateLinearProgress: React.FC<Props> = (props) => {
  const [value, setValue] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setValue((prevValue) => {
        const newValue = prevValue + 1; // increase the value by 1
        // stop the timer when the value reaches 95
        if (newValue === 95) {
          clearInterval(timer);
        }
        return newValue;
      });
    }, (props.secondsToComplete * 1000) / 100);

    return () => clearInterval(timer);
  }, []);

  return <LinearProgress sx={{ width: "100%" }} variant="buffer" value={value} valueBuffer={100} />;
};
