import { Controller, useFormContext } from "react-hook-form";

interface Props<T> {
  name: string;
  render: (props: { name: string; value: T }) => React.ReactElement;
}

export function CustomForm<T>({ render, name }: Props<T>) {
  const { control } = useFormContext();

  return (
    <Controller
      control={control}
      defaultValue=""
      name={name}
      render={({ field }) => {
        return render(field);
      }}
    />
  );
}
