import { usePrevious } from "@cp/react-hooks";
import { Box, Pagination, styled, Typography } from "@mui/material";
import { PaginationProps } from "./props";

const TableFooter = styled(Box)(({ theme }) => ({
  alignItems: "center",
  backgroundColor: theme.palette.background.default,
  borderTop: "1px solid",
  borderColor: theme.palette.divider,
  bottom: 0,
  display: "flex",
  flex: "0 0 65px",
  justifyContent: "center",
  marginTop: "-1px",
  position: "sticky",
  "& .MuiBox-root": {
    display: "flex",
    justifyContent: "space-between",
    left: 0,
    position: "absolute",
    right: 0,
  },
}));

export const PaginationFooter: React.FC<PaginationProps> = ({ page, totalPages, onPageChange }) => {
  const handleChange = (_event: React.ChangeEvent<unknown>, value: number) => {
    onPageChange(value);
  };
  // track previous total pages to prevent pagination from flashing.
  // don't update if the next value is undefined
  const prevTotalPages = usePrevious(totalPages, ({ next }) => next !== undefined);

  return (
    <TableFooter>
      <Box className="paginationInformation">
        <Typography variant="h5">
          Page {page} of {totalPages}
        </Typography>
      </Box>
      <Pagination count={totalPages ?? prevTotalPages} page={page} onChange={handleChange} />
    </TableFooter>
  );
};
