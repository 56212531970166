import { logNever } from "@cp/toolkit";
import { groupBy, isEmpty, isEqual } from "lodash";
import { ApplicationComponentType, ApplicationConditionFragment } from "../../../generated/graphql";
import { Linter, LinterResult, LinterState, QuestionLinter } from "./types";

const COMPONENTS_WITH_OPTIONS = new Set([
  ApplicationComponentType.Select,
  ApplicationComponentType.Radio,
  ApplicationComponentType.CheckboxGroup,
]);

const COMPONENTS_WITH_TEXT_LIMIT = new Set([
  ApplicationComponentType.Text,
  ApplicationComponentType.TextArea,
  ApplicationComponentType.Select,
]);

export const QUESTION_LINTER: QuestionLinter = (question) => {
  const results: LinterResult[] = [];
  const { componentType, options, text, id, isPdfOrAcordMapped, questionsConditionalOn } = question;

  const hasOptions = options && options.some((option) => !!option.trim());

  if (componentType && COMPONENTS_WITH_OPTIONS.has(componentType) && !hasOptions) {
    results.push({
      status: "error",
      message: `Component type '${componentType.toString()}' selected, but no options defined.`,
      onQuestionIds: [id ?? ""],
      path: ["options"],
    });
  }

  if (componentType && !COMPONENTS_WITH_OPTIONS.has(componentType) && hasOptions) {
    results.push({
      status: "error",
      message: "Options are defined but the component type does not take options.",
      onQuestionIds: [id ?? ""],
      path: ["componentType"],
    });
  }

  if (componentType && COMPONENTS_WITH_OPTIONS.has(componentType) && hasOptions && options?.length === 1) {
    results.push({
      status: "warning",
      message: `Only one option defined for this '${componentType.toString()}' question. Are you sure you are not missing any other options?`,
      onQuestionIds: [id ?? ""],
    });
  }

  if (componentType === ApplicationComponentType.RadioBoolean && text && text?.length < 20) {
    results.push({
      status: "warning",
      message: "This question may be a good candidate for a `checkbox` component type.",
      onQuestionIds: [id ?? ""],
    });
  }

  if (componentType && COMPONENTS_WITH_TEXT_LIMIT.has(componentType) && text && text?.length > 50) {
    results.push({
      status: "warning",
      message:
        "There’s a good chance that the user will not be able to read the full question text. " +
        "Please consider shortening the question text or at least put the full question text into " +
        "the `Helper Text` field.",
      onQuestionIds: [id ?? ""],
    });
  }

  if (isPdfOrAcordMapped) {
    results.push({
      status: "warning",
      message:
        "This question is mapped to a an Acord/PDF application. Usually pdf transforms are based on " +
        "options/componentType fields. Hence changing theses fields may impact how we print to PDF.",
      onQuestionIds: [id ?? ""],
    });
  }

  if (options && !validateOptions(options).success) {
    results.push({
      status: "warning",
      message: validateOptions(options).message,
      onQuestionIds: [id ?? ""],
    });
  }

  if (text && !validateText(text).success) {
    results.push({
      status: "warning",
      message: validateText(text).message,
      onQuestionIds: [id ?? ""],
    });
  }

  if (
    questionsConditionalOn?.find(
      (q) =>
        q.componentType === ApplicationComponentType.Checkbox ||
        q.componentType === ApplicationComponentType.RadioBoolean
    )
  ) {
    results.push({
      status: "warning",
      onQuestionIds: [id ?? ""],
      message:
        "In most cases a question conditional on a radio boolean or checkbox question should be be turned into a nested question under that radio boolean or checkbox question",
    });
  }

  if (!componentType) {
    results.push({
      status: "warning",
      message: "Missing question component type",
      onQuestionIds: [id ?? ""],
    });
  }

  if (!text) {
    results.push({
      status: "warning",
      message: "Empty question text",
      onQuestionIds: [id ?? ""],
    });
  }

  return results;
};

const QUESTIONS_LINTER: Linter = ({ questions }: LinterState) => {
  const questionResults = [...questions.values()].flatMap((q) => QUESTION_LINTER(q));
  // Group by and count the results
  const groupedResults = groupBy(questionResults, (result) => result.message);
  return Object.entries(groupedResults).map(([message, results]) => {
    return {
      status: results[0].status,
      message: results[0].message,
      onQuestionIds: results.flatMap((result) => result.onQuestionIds),
      count: results.length,
    };
  });
};

const MISSING_CONDITION_FIELDS_LINTER: Linter = ({ questions, sections }: LinterState) => {
  let missingSubjectCount = 0;
  let missingValueCount = 0;
  const onQuestionIds: string[] = [];

  const lintConditions = (conditions: ApplicationConditionFragment[] | undefined, id: string) => {
    if (!conditions) {
      return;
    }
    const handleCondition = (condition: ApplicationConditionFragment): void => {
      if ("subject" in condition && questions.get(condition.subject) == null) {
        missingSubjectCount++;
      }
      switch (condition.__typename) {
        case "ApplicationConditionBoolean":
          return;
        case "ApplicationConditionString":
          if (isEmpty(condition.stringValue)) {
            missingValueCount++;
            onQuestionIds.push(id);
          }
          return;
        case "ApplicationConditionStringSet":
          if (isEmpty(condition.stringSetValue)) {
            missingValueCount++;
            onQuestionIds.push(id);
          }
          return;
        case "ApplicationConditionNumber":
          if (isEmpty(condition.numberValue)) {
            missingValueCount++;
            onQuestionIds.push(id);
          }
          return;
        case "ApplicationConditionMatchAll":
          condition.matchAllConditions.forEach((subcondition) =>
            handleCondition(subcondition as ApplicationConditionFragment)
          );
          return;
        case "ApplicationConditionMatchAny":
          condition.matchAnyConditions.forEach((subcondition) =>
            handleCondition(subcondition as ApplicationConditionFragment)
          );
          return;
        default:
          logNever(condition);
          return;
      }
    };

    conditions.forEach(handleCondition);
  };

  questions.forEach(({ id, conditions }) => lintConditions(conditions, id));
  sections.forEach(({ id, conditions }) => lintConditions(conditions, id));

  return [
    {
      status: "error",
      count: missingSubjectCount,
      onQuestionIds,
      message: "Missing condition subject",
    },
    {
      status: "warning",
      count: missingValueCount,
      onQuestionIds,
      message: "Empty condition value",
    },
  ];
};

export const validateOptions = (options: string[]) => {
  const allCapsOptions = options.map((option) => option.toUpperCase());

  if (!isOtherOptionLastIfExists(options)) {
    return {
      success: false,
      message: "Consider moving the `Other` option to the end of the options list.",
    };
  }

  const sorted =
    isEqual(allCapsOptions, sortOptions(allCapsOptions, "ascending")) ||
    isEqual(allCapsOptions, sortOptions(allCapsOptions, "descending"));

  return {
    success: sorted,
    message: "Please consider alphabetizing and/or sorting the options from least to greatest or greatest to least.",
  };
};

export const validateText = (text: string) => {
  const regex = /^if\s+(so|yes|no)\b/i;
  const match = regex.exec(text);

  return {
    success: !match,
    message: match
      ? `Please consider removing '${match[0]}' from the question text if the question is conditional.`
      : "",
  };
};

export const isNumberOrCurrencyString = (text: string) => {
  const currencyRegex = /^\$?\d{1,3}(,?\d{3})*(\.\d{1,2})?$/;
  return currencyRegex.test(text);
};

export const extractNumberFromCurrencyString = (currencyString: string) => {
  const numericalPart = currencyString.replaceAll(/[^\d.-]+/g, "");
  return Number.parseFloat(numericalPart);
};

const DUPLICATE_QUESTIONS_LINTER: Linter = ({ curationNodeMap }: LinterState) => {
  const seen = new Set<string>();
  const duplicates = new Set<string>();
  curationNodeMap.forEach((node) => {
    node.children.forEach((child) => {
      if (seen.has(child)) {
        duplicates.add(child);
      }
      seen.add(child);
    });
  });

  return [
    {
      status: "error",
      count: duplicates.size,
      onQuestionIds: [...duplicates.values()],
      message: "Duplicate questions",
    },
  ];
};

const SPECIAL_HANDLING_OPTIONS = [
  ["YES", "NO", "MAYBE"],
  ["YES", "NO"],
];

const sortOptions = (options: string[], order: "ascending" | "descending") => {
  const optionsCopy: string[] = [...options];
  const otherOptionIndex = options.findIndex((o) => o.toUpperCase() === "OTHER");

  const match = SPECIAL_HANDLING_OPTIONS.find((specialOptions) => containSameElements(optionsCopy, specialOptions));
  if (match) {
    return match;
  }

  if (otherOptionIndex !== -1) {
    optionsCopy.splice(otherOptionIndex, 1);
  }

  const allNumbersOrCurrencies = optionsCopy.every((o) => isNumberOrCurrencyString(o));
  if (allNumbersOrCurrencies) {
    const currencySorted = sortCurrencyString(optionsCopy, order);

    if (otherOptionIndex !== -1) {
      currencySorted.push("OTHER");
    }
    return currencySorted;
  }

  if (order === "ascending") {
    optionsCopy.sort((a, b) => a.localeCompare(b));
  } else {
    optionsCopy.sort((a, b) => b.localeCompare(a));
  }

  if (otherOptionIndex !== -1) {
    // push other option at the end of the array
    optionsCopy.push("OTHER");
  }

  return optionsCopy;
};

const isOtherOptionLastIfExists = (options: string[]) => {
  const otherOptionIndex = options.findIndex((o) => o.toUpperCase() === "OTHER");
  return otherOptionIndex === -1 || otherOptionIndex === options.length - 1;
};

const sortCurrencyString = (options: string[], order: "ascending" | "descending") => {
  const parsedArr = options.map((currency) => {
    const num = extractNumberFromCurrencyString(currency);
    return { currency, num };
  });

  if (order === "ascending") {
    parsedArr.sort((a, b) => a.num - b.num);
  } else {
    parsedArr.sort((a, b) => b.num - a.num);
  }

  return parsedArr.map(({ currency }) => currency);
};

const containSameElements = (array1: string[], array2: string[]) => {
  return array1.length === array2.length && isEqual([...array1].sort(), [...array2].sort());
};

export const QUESTION_LINTERS = [QUESTION_LINTER];
export const LINTERS = [QUESTIONS_LINTER, MISSING_CONDITION_FIELDS_LINTER, DUPLICATE_QUESTIONS_LINTER];
