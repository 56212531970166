import { EmptyState } from "@cp/theme";
import { FormatShapes } from "@mui/icons-material";
import { Card, ListItemButton, ListItemIcon, ListItemText, MenuList, Typography } from "@mui/material";
import { startCase } from "lodash";
import React from "react";
import { PageColumn } from "../../../components/column/PageColumn";
import { CurationLint } from "../../../components/curation-lint/CurationLint";
import { useSelectedEntityId } from "../../../components/panels/atoms";
import { Panels } from "../../../components/panels/Panels";
import { TopBar } from "../../../components/TopBar";
import { useCurationEntitiesQuery } from "../../../generated/graphql";
import { DetailsEditor } from "../../curation/DetailsEditor/DetailsEditor";
import { EditEntityPanel } from "../EditEntity/EditEntityPanel";
import { entityTypeIdToIcon } from "../icons";

export const EntityPage: React.VFC = () => {
  const [entityId, setSelectedEntityId] = useSelectedEntityId();
  const { data, loading } = useCurationEntitiesQuery();

  return (
    <Panels
      id="entity-page"
      config={[
        {
          title: "Entities",
          defaultSize: 30,
          header: <TopBar leftToolbar={<Typography variant="h4">Entities</Typography>} />,
          content: (
            <Card sx={{ overflow: "auto" }}>
              <MenuList>
                {data?.curationEntities.map((entity) => (
                  <ListItemButton key={entity.id} onClick={() => setSelectedEntityId(entity.id)}>
                    <ListItemIcon>{entityTypeIdToIcon[entity.type]}</ListItemIcon>
                    <ListItemText primary={startCase(entity.type)} />
                  </ListItemButton>
                ))}
              </MenuList>
            </Card>
          ),
        },
        {
          title: "Entity Application",
          noPadding: true,
          content: (
            <>
              {!entityId && (
                <PageColumn flex={6}>
                  <EmptyState
                    title="No entity selected"
                    description="Select an entity from the left panel to view it here"
                    icon={<FormatShapes fontSize="large" />}
                  />
                </PageColumn>
              )}
              {entityId && <EditEntityPanel />}
            </>
          ),
        },
        {
          title: "Details",
          defaultSize: 20,
          header: <TopBar rightToolbar={<CurationLint />} />,
          content: <DetailsEditor />,
        },
      ]}
    />
  );
};
