import { detectCycles, Maps, printCycle, Tree } from "@cp/toolkit";
import { Alert, AlertTitle } from "@mui/material";
import React, { useMemo } from "react";
import { CurationQuestionFragment } from "../../../generated/graphql";
import { CurationNode } from "../state/types";
import { useCurationState } from "../state/useCurationState";

export const ApplicationValidationBanner: React.FC = () => {
  const { rootIds, curationNodeMap, getTitle, getQuestion } = useCurationState();

  const cycle = useMemo(() => {
    return detectCurationCycle(curationNodeMap, getTitle);
    // only re-run if the curationNodeMap changes
  }, [curationNodeMap]);

  const duplicateIds = useMemo(() => {
    return detectDuplicateIds(curationNodeMap);
    // only re-run if the curationNodeMap changes
  }, [curationNodeMap]);

  const questionsInH1 = useMemo(() => {
    return detectQuestionsInH1(rootIds, curationNodeMap, getQuestion);
    // only re-run if the curationNodeMap changes
  }, [curationNodeMap]);

  if (cycle) {
    return (
      <Alert severity="error">
        <AlertTitle>Cycle detected in curation application</AlertTitle>
        {cycle}
      </Alert>
    );
  }

  if (questionsInH1.length > 0) {
    return (
      <Alert severity="warning">
        <AlertTitle>Questions mixed with Sections in H1</AlertTitle>
        <p>Questions and sections should not be mixed in an H1. This will create a bad looking page.</p>
        <ul>
          {questionsInH1.map((id) => (
            <li key={id}>
              {getTitle(id)} ({id})
            </li>
          ))}
        </ul>
      </Alert>
    );
  }

  if (duplicateIds.length > 0) {
    return (
      <Alert severity="warning">
        <AlertTitle>Duplicate IDs detected in curation application</AlertTitle>
        {duplicateIds.map((id) => (
          <div key={id}>
            {getTitle(id)} ({id})
          </div>
        ))}
      </Alert>
    );
  }

  return null;
};

function detectCurationCycle(
  curationNodeMap: ReadonlyMap<string, CurationNode>,
  getTitle: (id: string) => string
): string | null {
  const nodeMap = Maps.mapValues(curationNodeMap, (node) => node.children);
  const tree = new Tree(nodeMap);
  const cycle = detectCycles(tree);
  if (cycle) {
    return printCycle(cycle, getTitle);
  }
  return null;
}

function detectDuplicateIds(curationNodeMap: ReadonlyMap<string, CurationNode>): string[] {
  const idSet = new Set<string>();
  const duplicateIds: string[] = [];
  for (const node of curationNodeMap.values()) {
    for (const childId of node.children) {
      if (idSet.has(childId)) {
        duplicateIds.push(childId);
      } else {
        idSet.add(childId);
      }
    }
  }
  return duplicateIds;
}

/**
 * Return all questions that are in an H1, unless all children are questions.
 */
function detectQuestionsInH1(
  rootIds: string[],
  curationNodeMap: ReadonlyMap<string, CurationNode>,
  getQuestion: (id: string) => CurationQuestionFragment | null
): string[] {
  const questionIds: string[] = [];
  for (const id of rootIds) {
    const children = curationNodeMap.get(id)?.children || [];
    const questionsInH1 = children.filter(getQuestion);
    if (questionsInH1.length === 0) {
      continue;
    }

    // If its all questions, then we don't need to warn
    if (children.length !== questionsInH1.length) {
      questionIds.push(...questionsInH1);
    }
  }

  return questionIds;
}
