import { atom, useAtom } from "jotai";
import { ROOT } from "./types";

interface CopiedSection {
  sectionId: string;
  parentId: string | typeof ROOT;
}

const copyPasteSectionAtom = atom<CopiedSection | undefined>(undefined);

export function useCopyPastSection() {
  return useAtom(copyPasteSectionAtom);
}
