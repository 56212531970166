/* eslint-disable react-hooks/rules-of-hooks */
import { Arrays } from "@cp/toolkit";
import { useContext, useRef } from "react";
import { useFormContext } from "react-hook-form";
import { FormDataContext } from "../../context/FormDataProvider";
import { useSaveForm } from "../../form-fields/common/useSaveForm";

interface Response {
  getValues: (fieldId: string) => string | string[];
  onSaveValue?: (fieldId: string, value: unknown) => void;
}

/**
 * Gets the read/write props for a table based on if its readonly or not.
 *
 * While technically you should not use conditional hooks, this is a special case where we know readonly will never change between renders.
 */
export function useFormTableProps(opts: { readonly: boolean }): Response {
  const readonly = useRef(opts.readonly);

  if (readonly.current) {
    const { dataMap } = useContext(FormDataContext);

    return {
      getValues: (id: string) => dataMap.get(id) || Arrays.empty(),
    };
  }

  const methods = useFormContext();
  const saveValue = useSaveForm();

  return {
    getValues: methods.getValues,
    onSaveValue: (key, value) => {
      methods.setValue(key, value);
      saveValue(key, value);
    },
  };
}
