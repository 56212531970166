import { Maps } from "@cp/toolkit";
import { useMemo } from "react";
import { useAllCurationQuestions } from "../../../pages/curation/state/useAllCurationQuestions";
import { useAllCurationSections } from "../../../pages/curation/state/useAllCurationSections";
import { useCurationState } from "../../../pages/curation/state/useCurationState";
import { LINTERS } from "./linters";
import { LinterResult } from "./types";

export function useLinters(): LinterResult[] {
  const { curationNodeMap } = useCurationState();
  const questions = useAllCurationQuestions();
  const sections = useAllCurationSections();
  const results = useMemo(() => {
    return LINTERS.flatMap((linter) => {
      return linter({
        questions: Maps.keyBy(questions, (q) => q.id),
        sections: Maps.keyBy(sections, (s) => s.id),
        curationNodeMap,
      });
    }).filter((result) => result.count && result.count > 0);
  }, [curationNodeMap, questions, sections]);

  return results;
}
