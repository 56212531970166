import { Button, FormLabel } from "@mui/material";
import { useFormRenderers } from "../context/FormRendererProvider";
import { FormFieldTextProps } from "./FormFieldAddressSearch";
import { processFormFieldProps } from "./FormFieldProps";

export const FileUploadDrawerOpener: React.FC<FormFieldTextProps> = (props) => {
  const { label } = processFormFieldProps(props);
  const { openApplicationDocumentsDrawer } = useFormRenderers();

  if (!openApplicationDocumentsDrawer) {
    return null;
  }

  return (
    <>
      <FormLabel className="question">{label}</FormLabel>
      <br />
      <Button sx={{ marginTop: "10px" }} onClick={() => openApplicationDocumentsDrawer()}>
        Upload File(s)
      </Button>
    </>
  );
};
