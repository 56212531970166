import { usePopover } from "@cp/react-hooks";
import { OpenInNew } from "@mui/icons-material";
import { ButtonProps, IconButton, Popover } from "@mui/material";
import React from "react";
import { CurationQuestionPropertiesCard } from "../../cards/curation-questions/CurationQuestionPropertiesCard";
import { useSetSelectedCurationNodeId } from "../../panels/atoms";

interface Props extends ButtonProps {
  curationQuestionId: string;
}

/**
 * Re-usable button component that sets the selected curation node id to the given id.
 */
export const ViewCurationQuestionButton: React.FC<Props> = ({ curationQuestionId, ...buttonProps }) => {
  const setId = useSetSelectedCurationNodeId();
  const { targetProps, popoverProps } = usePopover({ trigger: "hover" });

  return (
    <>
      <Popover {...popoverProps}>
        <CurationQuestionPropertiesCard curationQuestionId={curationQuestionId} />
      </Popover>
      <IconButton size="small" onClick={() => setId(curationQuestionId)} {...buttonProps} {...targetProps}>
        <OpenInNew fontSize="small" />
      </IconButton>
    </>
  );
};
