import { gql } from "@apollo/client";
import {
  ApplicationConditionBooleanFragmentDoc,
  ApplicationConditionFragmentDoc,
  ApplicationConditionMatchAllFragmentDoc,
  ApplicationConditionMatchAnyFragmentDoc,
  ApplicationConditionNumberFragmentDoc,
  ApplicationConditionStringFragmentDoc,
  ApplicationConditionStringSetFragmentDoc,
  ClobQuestionFragmentDoc,
  CurationQuestionFragmentDoc,
  CurationSectionFragmentDoc,
  LeafApplicationConditionFragmentDoc,
} from "../../../generated/graphql";

const ConditionsFragment = gql`
  ${ApplicationConditionFragmentDoc}
  ${ApplicationConditionMatchAllFragmentDoc}
  ${ApplicationConditionMatchAnyFragmentDoc}
  ${LeafApplicationConditionFragmentDoc}
  ${ApplicationConditionStringFragmentDoc}
  ${ApplicationConditionStringSetFragmentDoc}
  ${ApplicationConditionBooleanFragmentDoc}
  ${ApplicationConditionNumberFragmentDoc}
`;

/**
 * All fragments used in the CurationQuestion fragment.
 */
export const AllCurationQuestionFragments = gql`
  ${CurationQuestionFragmentDoc}
  ${ConditionsFragment}
`;

/**
 * All fragments used in the CurationSection fragment.
 */
export const AllCurationSectionFragments = gql`
  ${CurationSectionFragmentDoc}
  ${ConditionsFragment}
`;

/**
 * All fragments used in the ClobQuestion fragment.
 */
export const AllClobQuestionFragments = gql`
  ${CurationQuestionFragmentDoc}
  ${ClobQuestionFragmentDoc}
  ${ConditionsFragment}
`;
