import type { Components, Theme } from "@mui/material/styles";

export function Menu(theme: Theme): Components<Theme> {
  return {
    MuiMenu: {
      styleOverrides: {
        list: {
          alignItems: "stretch",
          display: "flex",
          flexDirection: "column",
          padding: "4px !important",
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          gap: "8px",
          padding: "8px",
          "&.Mui-selected": {
            color: theme.palette.primary.main,
          },
          ".MuiMenu-list &": {
            borderRadius: "5px",
            gap: "12px",
            padding: "12px",
          },
        },
      },
    },
  };
}
