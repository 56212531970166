import { STATE_OPTIONS } from "@cp/toolkit";
import { CellDataType } from "@qw/table";
import { ApplicationComponentType } from "../../generated/operations";
import { YES_NO_OPTIONS } from "../../readonly-fields/ReadonlyFieldBooleanRadio";

export function getDataTypeForComponentType(componentType: ApplicationComponentType): CellDataType {
  switch (componentType) {
    case ApplicationComponentType.Percentage:
      return "percentage";
    case ApplicationComponentType.Number:
      return "number";
    case ApplicationComponentType.Checkbox:
      return "boolean";
    case ApplicationComponentType.Currency:
      return "currency";
    case ApplicationComponentType.Date:
      return "date";
    case ApplicationComponentType.RadioBoolean:
    case ApplicationComponentType.CheckboxGroup:
    case ApplicationComponentType.Radio:
    case ApplicationComponentType.Select:
    case ApplicationComponentType.LocationSelect:
    case ApplicationComponentType.StateSelect:
      return "select";
    default:
      return "text";
  }
}
export function getOptions(componentType: ApplicationComponentType, options?: Array<{ label: string; value: string }>) {
  if (componentType === ApplicationComponentType.StateSelect) {
    return STATE_OPTIONS;
  }
  if (componentType === ApplicationComponentType.RadioBoolean) {
    return YES_NO_OPTIONS;
  }
  return options;
}
