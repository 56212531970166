import { Stack, Typography } from "@mui/material";
import React from "react";
import { Panels } from "../../../../components/panels/Panels";
import { ArchivedCurationQuestions } from "./ArchivedQuestions";
import { UnmappedCurationQuestions } from "./UnmappedCurationQuestions";

export const QuestionGraveyardPage: React.FC = () => {
  return (
    <Stack sx={{ backgroundColor: "grey.200" }} flex={1}>
      <Typography p={4} borderBottom={1} borderColor="grey.300">
        Orphaned curation questions are questions that are not mapped to any clob or clob question, they are great
        questions to be archived.
      </Typography>
      <Panels
        id="graveyard-questions"
        config={[
          {
            title: "Orphaned Questions",
            defaultSize: 30,
            content: <UnmappedCurationQuestions />,
          },
          {
            title: "Archived Questions",
            content: <ArchivedCurationQuestions />,
          },
        ]}
      />
    </Stack>
  );
};
