import { Check } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Tooltip } from "@mui/material";
import { useLinkCurationQuestion } from "./useLinkCurationQuestion";

interface Props {
  clobQuestionIds: string[];
  curationQuestionId: string;
  curationQuestionIdForUndo?: string;
}

export function LinkCurationQuestion({ clobQuestionIds, curationQuestionId, curationQuestionIdForUndo }: Props) {
  const [linkCurationQuestion, { loading }] = useLinkCurationQuestion();

  return (
    <Tooltip title="Link CLOB question to Curation question">
      <LoadingButton
        loading={loading}
        variant="text"
        color="success"
        size="small"
        sx={{
          opacity: 0.5,
          "&:hover": { opacity: 1 },
        }}
        onClick={async (evt) => {
          evt.stopPropagation();
          await linkCurationQuestion({
            clobQuestionIds: clobQuestionIds,
            curationQuestionId: curationQuestionId,
            curationQuestionIdForUndo: curationQuestionIdForUndo,
          });
        }}
      >
        <Check fontSize="small" />
      </LoadingButton>
    </Tooltip>
  );
}
