import { useToaster } from "@cp/theme";
import FormDialog from "../../../components/FormDialog";
import { CurationQuestionFragment, useUpdateCurationQuestionMutation } from "../../../generated/graphql";
import {
  curationQuestionFragmentToInput,
  UpsertCurationQuestionInput,
} from "../../../model/curation-questions/deserialize";
import CurationQuestionForm from "./CurationQuestionForm";

const FORM_ID = "curation-question-form";

export interface EditCurationQuestionDialogProps {
  curationQuestion: CurationQuestionFragment;
  open: boolean;
  handleClose: () => void;
}

export default function EditCurationQuestionDialog({
  curationQuestion,
  open,
  handleClose,
}: EditCurationQuestionDialogProps) {
  const { toast } = useToaster();
  const [mutate, { loading }] = useUpdateCurationQuestionMutation();

  const handleSubmit = (data: UpsertCurationQuestionInput) => {
    mutate({
      variables: {
        input: {
          id: curationQuestion.id,
          ...data,
        },
      },
      onCompleted: () => {
        toast("Curation question updated successfully");
        handleClose();
      },
    });
  };

  if (!open) {
    return null;
  }

  return (
    <FormDialog formId={FORM_ID} title="Edit Curation Question" loading={loading} open={open} onClose={handleClose}>
      <CurationQuestionForm
        formId={FORM_ID}
        curationQuestion={curationQuestionFragmentToInput(curationQuestion)}
        onSubmit={handleSubmit}
      />
    </FormDialog>
  );
}
