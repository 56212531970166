import { Stack } from "@mui/material";
import { useState } from "react";
import { useCurationQuestionQuery } from "../../generated/graphql";
import { CurationQuestionBody } from "../../pages/clobs/MapClob/components/CurationQuestionBody";
import FormDialog from "../FormDialog";
import { CurationQuestionAutocomplete } from "./CurationQuestionAutocomplete";
import { useLinkCurationQuestion } from "./useLinkCurationQuestion";

const FORM_ID = "bulk-remap-clob-questions";

interface Props {
  clobQuestionIds: string[];
  curationQuestionIdForUndo: string;
  open: boolean;
  setOpen: (open: boolean) => void;
}

export const BulkRemapClobQuestionsModal: React.FC<Props> = ({
  clobQuestionIds,
  curationQuestionIdForUndo,
  open,
  setOpen,
}) => {
  const [linkCurationQuestion] = useLinkCurationQuestion();
  const [curationQuestionId, setCurationQuestionId] = useState<string>("");
  const curationQuestion = useCurationQuestionQuery({
    variables: { id: curationQuestionId },
    skip: curationQuestionId === "",
  });
  const questionData = curationQuestion.data?.curationQuestion;

  const handleSubmit = async () => {
    await linkCurationQuestion({
      clobQuestionIds: clobQuestionIds,
      curationQuestionId: curationQuestionId,
      curationQuestionIdForUndo: curationQuestionIdForUndo,
    });
    setOpen(false);
  };

  return (
    <FormDialog
      open={open}
      onClose={() => {
        setOpen(false);
      }}
      title={"Select a curation question to re-map to"}
      confirmText={"Re-map"}
      formId={FORM_ID}
    >
      <Stack sx={{ paddingTop: 2, paddingBottom: 2 }}>
        <form id={FORM_ID} onSubmit={handleSubmit}>
          <CurationQuestionAutocomplete
            placeholderText="Search curation questions"
            onChange={(id) => setCurationQuestionId(id)}
          />
        </form>
      </Stack>
      {questionData !== undefined && <CurationQuestionBody id={questionData.id} question={questionData} search="" />}
    </FormDialog>
  );
};
