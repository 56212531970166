import { Maps } from "@cp/toolkit";
import { uniq } from "lodash";
import { createContext } from "react";
import { ApplicationCondition } from "../../form-fields/conditions/ApplicationConditions";
import {
  ApplicationComponentType,
  FormContainerFragment,
  FormQuestionFragment,
  FormTreeItemFragment,
} from "../../generated/operations";
import { getOptions } from "../common/utils";

export type FormQuestionId = string;
export type FormContainerId = string;
export type FormTreeItemId = string;

export const PivotSectionTableManagerContext = createContext<PivotSectionTableManager>(null!);

/**
 * Wrapper around the form data to provide a pivot table like interface for nested sections with repeating questions.
 */
export class PivotSectionTableManager {
  private readonly questions: Map<FormQuestionId, FormQuestionFragment>;
  private readonly containers: Map<FormContainerId, FormContainerFragment>;
  private readonly treeItems: Map<FormTreeItemId, FormTreeItemFragment>;
  private readonly rows: FormTreeItemId[];
  private readonly columnNames: FormTreeItemId[];

  constructor(opts: {
    formQuestions: FormQuestionFragment[];
    formContainers: FormContainerFragment[];
    formTreeItems: FormTreeItemFragment[];
  }) {
    this.questions = Maps.keyBy(opts.formQuestions, (k) => k.id);
    this.containers = Maps.keyBy(opts.formContainers, (k) => k.id);
    this.treeItems = Maps.keyBy(opts.formTreeItems, (k) => k.id);

    this.columnNames = uniq(opts.formQuestions.map((q) => q.label));
    this.rows = opts.formTreeItems.map((ti) => ti.id);
  }

  public getColumnNames(): string[] {
    return this.columnNames;
  }

  public getRowIds(): FormTreeItemId[] {
    return this.rows;
  }

  public getRowLabel(rowId: FormTreeItemId): string {
    return this.containers.get(rowId)?.label || "";
  }

  public getRowVisibility(rowId: FormTreeItemId): ApplicationCondition[] | undefined {
    return this.containers.get(rowId)?.visibility;
  }

  /**
   * Get the FormQuestionFragment for a given rowId and columnName
   * @param rowId - the id of the FormTreeItem, i.e. the section
   * @param columnName - the label of the FormQuestion
   * @returns the FormQuestionFragment or undefined if not found
   */
  public getCell(rowId: FormTreeItemId, columnName: string): FormQuestionFragment | undefined {
    const treeItem = this.treeItems.get(rowId);
    if (!treeItem) {
      return undefined;
    }
    const matchingId = treeItem.children.find((childId) => {
      const question = this.questions.get(childId);
      return question && question.label === columnName;
    });

    return matchingId ? this.questions.get(matchingId) : undefined;
  }

  public findFirstQuestion(columnName: string): FormQuestionFragment {
    return [...this.questions.values()].find((q) => q.label === columnName) as FormQuestionFragment;
  }

  /**
   * Get the Options for all cells in the column.
   * @param columnName - the label of the FormQuestion
   * @returns the array of options for each cell in the column.
   */
  public getColumnOptions(columnName: string): Array<Array<{ label: string; value: string }> | undefined> {
    return this.getRowIds().map((r) => {
      const question = this.getCell(r, columnName);
      return getOptions(question?.componentType ?? ApplicationComponentType.Text, question?.options);
    });
  }
}
