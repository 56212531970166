import { Chip, Stack, Typography } from "@mui/material";
import React from "react";
import { EntityApplicationTag, EntityTag } from "../../../generated/graphql";

interface Props {
  supportedTags: string[];
  supportedApplicationTags: string[];
  setSupportedTags: (tags: string[]) => void;
  setSupportedApplicationTags: (tags: string[]) => void;
}

export const EntityTagSelector: React.VFC<Props> = ({
  supportedTags,
  supportedApplicationTags,
  setSupportedTags,
  setSupportedApplicationTags,
}) => {
  const availableTags = Object.keys(EntityTag);
  const availableApplicationTags = Object.keys(EntityApplicationTag);

  const handleBaseClick = (tag: string) => {
    if (supportedTags.includes(tag)) {
      setSupportedTags(supportedTags.filter((t) => t !== tag));
    } else {
      setSupportedTags([...supportedTags, tag]);
    }
  };

  const handleApplicationClick = (tag: string) => {
    if (supportedApplicationTags.includes(tag)) {
      setSupportedApplicationTags(supportedApplicationTags.filter((t) => t !== tag));
    } else {
      setSupportedApplicationTags([...supportedApplicationTags, tag]);
    }
  };

  return (
    <Stack>
      <Typography variant="h4">Base entity supported tags</Typography>
      <Stack direction="row">
        {availableTags.map((tag) => (
          <Chip
            key={tag}
            label={tag}
            clickable={true}
            color="primary"
            onClick={() => handleBaseClick(tag)}
            component="div"
            variant={supportedTags.includes(tag) ? "filled" : "outlined"}
          />
        ))}
      </Stack>
      <Typography variant="h4">Application entity supported tags</Typography>
      <Stack direction="row">
        {availableApplicationTags.map((tag) => (
          <Chip
            key={tag}
            label={tag}
            clickable={true}
            onClick={() => handleApplicationClick(tag)}
            component="div"
            variant={supportedApplicationTags.includes(tag) ? "filled" : "outlined"}
          />
        ))}
      </Stack>
    </Stack>
  );
};
