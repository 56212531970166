import { Maps, Strings } from "@cp/toolkit";
import { Key, Lock, OpenInNew } from "@mui/icons-material";
import { Alert, AlertTitle, Button, Stack, Tooltip, Typography } from "@mui/material";
import React, { useMemo } from "react";
import { CopyClipboard } from "../../../components/CopyClipboard";
import { EntityType, useCurationEntityByTypeQuery } from "../../../generated/graphql";
import { CurationQuestionMappingIndicator } from "../../clobs/MapClob/components/CurationQuestionMappingIndicator";
import { CurationQuestionDropArea } from "./CurationQuestionDropArea";

interface Props {
  entityType: EntityType;
  disableSelection?: boolean;
}

export const InheritedEntityQuestions: React.FC<Props> = ({ entityType, disableSelection }) => {
  const { data } = useCurationEntityByTypeQuery({
    variables: { type: entityType },
  });

  // HACK: This is a hack show the mapping of clob question to curation question
  const isClobPage = window.location.pathname.includes("/clobs");

  const { questionsById, nodeMap, sectionsById } = useMemo(() => {
    const { questions = [], nodes = [], sections = [] } = data?.curationEntityByType ?? {};

    return {
      questionsById: Maps.keyBy(questions, (s) => s.id),
      nodeMap: Maps.keyBy(nodes, (s) => s.id),
      sectionsById: Maps.keyBy(sections, (s) => s.id),
    };
  }, [data]);

  if (!data) {
    return null;
  }

  const { rootIds, id } = data.curationEntityByType;

  const renderQuestion = (id: string) => {
    const question = questionsById.get(id);
    if (!question) {
      return;
    }

    return (
      <Stack direction="row" alignItems="center" spacing={1} pt={1} key={id} flex={1}>
        {isClobPage && <CurationQuestionMappingIndicator id={id} disableSelection={disableSelection} />}
        <Tooltip title="Copy ID">
          <CopyClipboard value={id} kind="ID" icon={Key} />
        </Tooltip>
        <Typography variant="body2" flex={1}>
          {question.text}
        </Typography>
        <CurationQuestionDropArea curationQuestionId={id} />
      </Stack>
    );
  };

  const renderSection = (id: string, depth: number) => {
    const node = nodeMap.get(id);
    if (!node) {
      return;
    }

    const { children } = node;

    return (
      <Stack pt={1} key={id}>
        <Typography color="text.secondary" fontWeight={700} variant="subtitle2">
          {sectionsById.get(id)?.title}
        </Typography>
        <Stack pl={depth}>
          {children?.map(renderQuestion)}
          {children?.map((id) => renderSection(id, depth + 1))}
        </Stack>
      </Stack>
    );
  };

  return (
    <Alert
      color="info"
      icon={<Lock />}
      action={
        <Button size="small" endIcon={<OpenInNew />} href={`/entities/${id}`} target="_blank">
          Edit
        </Button>
      }
    >
      <AlertTitle>Inherited {Strings.titleCase(entityType)} properties</AlertTitle>
      <Stack gap={1}>{rootIds.map((id) => renderSection(id, 1))}</Stack>
    </Alert>
  );
};
