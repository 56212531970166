import { useModal } from "@cp/modals";
import { Button, Dialog, Stack } from "@mui/material";
import { Outlet } from "@tanstack/react-location";
import React from "react";
import { PageTemplate } from "../../components/layout/PageTemplate";
import { useCreateCarrierProject } from "./projects/useCreateCarrierProject";
import { RecordingDropzone } from "./recordings/RecordingDropzone";

export const ProjectsPage: React.VFC = () => {
  const [handleCreate] = useCreateCarrierProject();
  const { openCustomModal } = useModal();

  return (
    <PageTemplate
      title="Application Development"
      tabs={[
        { label: "Application Projects", to: "/projects" },
        { label: "New Screen Captures", to: "/projects/new-captures" },
        { label: "SA Captures", to: "/projects/sa-captures" },
      ]}
      actions={
        <Stack direction="row" spacing={1}>
          <Button variant="contained" color="primary" onClick={handleCreate} size="small">
            Create Project
          </Button>
          <Button
            onClick={() =>
              openCustomModal((onClose) => (
                <Dialog open={true} onClose={onClose}>
                  <RecordingDropzone onSuccess={() => onClose(undefined)} />
                </Dialog>
              ))
            }
            size="small"
            variant="text"
          >
            Upload screen captures
          </Button>
        </Stack>
      }
    >
      <Outlet />
    </PageTemplate>
  );
};
