import { Maps } from "@cp/toolkit";
import React, { useMemo } from "react";
import {
  CurationApplicationFragment,
  CurationQuestionFragment,
  CurationSectionFragment,
} from "../../generated/graphql";
import { CurationNode } from "../curation/state/types";

interface CurationApplicationRendererProps extends CurationApplicationFragment {
  render(opts: {
    nodeId: string;
    depth: number;
    question: CurationQuestionFragment | undefined;
    section: CurationSectionFragment | undefined;
    curationNode: CurationNode | undefined;
    renderChild: (id: string) => React.ReactNode | undefined;
  }): React.ReactNode | undefined;
}

export const CurationApplicationRenderer: React.VFC<CurationApplicationRendererProps> = ({
  questions,
  sections,
  nodes,
  render,
  rootIds,
}) => {
  const { questionsByIds, curationNodesByIds, sectionsByIds } = useMemo(() => {
    return {
      questionsByIds: Maps.keyBy(questions, (q) => q.id),
      curationNodesByIds: Maps.keyBy(nodes, (q) => q.id),
      sectionsByIds: Maps.keyBy(sections, (q) => q.id),
    };
  }, [questions, nodes, sections]);

  const renderElement = (nodeId: string, depth: number): React.ReactNode => {
    const question = questionsByIds.get(nodeId);
    const section = sectionsByIds.get(nodeId);
    const curationNode = curationNodesByIds.get(nodeId);

    return render({
      nodeId,
      depth,
      question,
      section,
      curationNode: curationNode as CurationNode,
      renderChild: (id) => renderElement(id, depth + 1),
    });
  };

  return <>{rootIds.map((nodeId) => renderElement(nodeId, 0))}</>;
};
