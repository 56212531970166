import { Provider } from "jotai";
import { createContext, PropsWithChildren } from "react";

const DEFAULT_SCOPE: unique symbol = Symbol("Default");
export const CurationAtomScopeContext = createContext<symbol>(DEFAULT_SCOPE);

export const CurationAtomScopeProvider = ({
  scope = DEFAULT_SCOPE,
  children,
}: PropsWithChildren<{ scope?: symbol }>) => {
  return (
    <CurationAtomScopeContext.Provider value={scope}>
      <Provider scope={scope}>{children}</Provider>
    </CurationAtomScopeContext.Provider>
  );
};
