import type { Components, Theme } from "@mui/material/styles";

export function Drawer(theme: Theme): Components<Theme> {
  return {
    MuiDrawer: {
      styleOverrides: {
        docked: {
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            backgroundColor: theme.palette.background.alternate,
            boxSizing: "border-box",
            width: "inherit",
          },
        },
      },
    },
  };
}
