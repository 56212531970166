import { Skeleton, TableBody as MuiTableBody, TableCell } from "@mui/material";

import { Column } from "../core/Column";
import { StyledTableRow } from "./components";

interface Props<T> {
  columns: Array<Column<T>>;
  pageSize: number;
  flex?: boolean;
}

export function LoadingTableBody<T>({ columns, pageSize, flex }: Props<T>) {
  return (
    <MuiTableBody>
      {Array.from({ length: pageSize }, (_, index) => (
        <StyledTableRow key={index} sx={{ display: flex ? "flex" : undefined }}>
          {columns.map((column) => (
            <TableCell
              key={column.id}
              align={column.align}
              sx={{
                maxWidth: column.maxWidth,
                minWidth: column.minWidth,
                flex: column.flex ?? 1,
              }}
            >
              <Skeleton variant="text" />
            </TableCell>
          ))}
        </StyledTableRow>
      ))}
    </MuiTableBody>
  );
}
