import { useToaster } from "@cp/theme";
import {
  CarrierProjectDocument,
  ClobQuestionFragment,
  PortalAnnotationsDocument,
  useUpdateClobQuestionMutation,
} from "../../generated/graphql";
import { useClobContext } from "../../model/clobs/context";
import { conditionToInput } from "../../model/conditions/conditionToInput";

interface Props {
  curationQuestionId: string;
  clobQuestionId: string;
}

/**
 * Hook to unlink a curation question from a clob question.
 * Includes undo functionality and toast notifications.
 */
export function useUnlinkCurationQuestion({ curationQuestionId, clobQuestionId }: Props) {
  const { toastUndo } = useToaster();
  const { actions } = useClobContext();
  const [updateClobQuestion, { loading }] = useUpdateClobQuestionMutation({
    // Refetch for updated counts
    refetchQueries: [PortalAnnotationsDocument, CarrierProjectDocument],
  });

  const handleSuccess = (newClobQuestion: ClobQuestionFragment) => {
    // update internal state
    actions.updateQuestion({
      ...newClobQuestion,
      visibility: newClobQuestion.visibility.map(conditionToInput),
    });

    toastUndo("Curation mapping unlinked", {
      onUndo: () => {
        void updateClobQuestion({
          variables: {
            clobQuestion: {
              curationQuestionId: curationQuestionId,
              id: clobQuestionId,
            },
          },
          onCompleted: (prevClobQuestion) => {
            // update internal state
            actions.updateQuestion({
              ...prevClobQuestion.updateClobQuestion,
              visibility: prevClobQuestion.updateClobQuestion.visibility.map(conditionToInput),
            });
          },
        });
      },
    });
  };

  return {
    loading,
    unlink: async () => {
      await updateClobQuestion({
        variables: {
          clobQuestion: {
            curationQuestionId: null,
            id: clobQuestionId,
          },
        },
        onCompleted: (data) => handleSuccess(data.updateClobQuestion),
      });
    },
  };
}
