import type { Components, Theme } from "@mui/material/styles";

export function FormLabel(theme: Theme): Components<Theme> {
  return {
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: theme.palette.text.secondary,
          fontSize: "16px",
          fontWeight: 600,
          lineHeight: "26px",
          "&.Mui-focused": {
            color: theme.palette.text.secondary,
          },
          "&.Mui-error": {
            color: theme.palette.text.secondary,
          },
          "&.MuiInputLabel-shrink": {
            transform: "translate(13px, 8px) scale(0.625)",
          },
          "&.question": {
            color: theme.palette.text.primary,
            fontSize: "14px",
            fontWeight: 500,
            letterSpacing: "-0.02em",
            lineHeight: "24px",
            marginBottom: "4px",
          },
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          color: theme.palette.text.secondary,
          fontSize: "12px",
          lineHeight: "18px",
          marginTop: "6px",
        },
      },
    },
  };
}
