import { UndoRedoActions } from "@cp/react-hooks";
import { Functions } from "@cp/toolkit";
import { createContext, useContext } from "react";
import { ClobContextState, EditableClobQuestion, EditableClobSection } from "./types";

export interface ClobContextValue {
  state: ClobContextState;
  actions: {
    /**
     * Set the full state, resetting undo/redo
     */
    setState: (state: ClobContextState) => void;
    setTree: (tree: ClobContextState["tree"]) => void;
    /**
     * Update the partial state
     */
    updateState: <K extends keyof ClobContextState>(partial: Pick<ClobContextState, K>) => void;
    collapseAll: () => void;
    expandAll: () => void;
    addItem: (payload: { parentId: string | "root"; nodeType: "question" | "section" }) => void;
    updateQuestion: (opts: Partial<EditableClobQuestion> & { id: string }) => void;
    updateQuestions: (opts: Array<Partial<EditableClobQuestion> & { id: string }>) => void;
    updateSection: (opts: Partial<EditableClobSection> & { id: string }) => void;
  } & UndoRedoActions<ClobContextState>;
}

export const INITIAL_STATE: ClobContextState = {
  id: "",
  version: 0,
  name: "",
  lineOfBusiness: "",
  tree: [],
  sections: new Map(),
  questions: new Map(),
};

export const ClobContext = createContext<ClobContextValue>({
  state: INITIAL_STATE,
  actions: {
    setState: Functions.NOOP,
    setTree: Functions.NOOP,
    updateState: Functions.NOOP,
    collapseAll: Functions.NOOP,
    expandAll: Functions.NOOP,
    addItem: Functions.NOOP,
    updateQuestion: Functions.NOOP,
    updateQuestions: Functions.NOOP,
    updateSection: Functions.NOOP,
    undo: Functions.NOOP,
    redo: Functions.NOOP,
    clear: Functions.NOOP,
    canRedo: false,
    canUndo: false,
    nonce: 0,
  },
});

export function useClobContext() {
  return useContext(ClobContext);
}
