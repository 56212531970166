import { EmptyState } from "@cp/theme";
import { BorderColor, Check, DragIndicator, LinkOff } from "@mui/icons-material";
import {
  Alert,
  Chip,
  Divider,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  MenuItem,
  MenuList,
  Stack,
} from "@mui/material";
import { keyBy } from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { useHandshake } from "../../../annotator/handshake";
import { Card } from "../../../components/Card";
import { ClobQuestionDetailsCard } from "../../../components/cards/clob-questions/ClobQuestionDetailsCard";
import { ClobQuestionEditableFieldsCard } from "../../../components/cards/clob-questions/ClobQuestionEditableFieldsCard";
import { DirectCurationQuestionDragAndDrop } from "../../../components/curation-questions/DirectCurationQuestionDragAndDrop";
import { UnlinkCurationQuestionChip } from "../../../components/curation-questions/UnlinkCurationQuestion";
import { useSelectedPortalAnnotationId, useSelectedRecordingId } from "../../../components/panels/atoms";
import {
  Scalars,
  useConnectPortalAnnotationMutation,
  useCurationEntitiesQuery,
  usePortalAnnotationQuery,
  usePortalAnnotationsQuery,
} from "../../../generated/graphql";
import { useClobDetailsSearchCurationNodes } from "../../clobs/MapClob/components/ClobDetailsCurationNodeSearch";
import { useCurationState } from "../../curation/state/useCurationState";
import { useWatchWhenAnnotationMappingChanges } from "../hooks/useWatchWhenAnnotationMappingChanges";
import { RemoveAnnotation } from "./RemoveAnnotation";

export const AnnotationsPanel: React.FC = () => {
  // Create a set of all curation question IDs in the SCA
  // CurationState doesn't include questions from entities, so we need to pull them back separately
  const { questionIds, sectionIds, getSection } = useCurationState();
  const { data: entitiesData } = useCurationEntitiesQuery();
  const questionIdsIncludingEntityQuestions = useMemo(() => {
    const entitiesByType = keyBy(entitiesData?.curationEntities ?? [], (s) => s.type);
    const entityQuestionIds = new Set();
    for (const sectionId of sectionIds) {
      const section = getSection(sectionId);
      if (section?.entityTypeId) {
        const entity = entitiesByType[section.entityTypeId];
        const questionIdsFromEntity = entity?.questions?.map((q) => q.id) ?? [];
        questionIdsFromEntity.forEach((id) => entityQuestionIds.add(id));
      }
    }
    return new Set([...questionIds, ...entityQuestionIds]);
  }, [sectionIds, entitiesData]);

  const [recordingId] = useSelectedRecordingId();
  const [selectedAnnotationId, setSelectedAnnotationId] = useSelectedPortalAnnotationId();
  // The iframe/annotator will emit a message for which suggestions if found.
  const [foundSuggestedAnnotations, setFoundSuggestedAnnotations] = useState<Set<Scalars["PortalAnnotationId"]>>(
    new Set()
  );

  const { data, refetch } = usePortalAnnotationsQuery({
    variables: { recordingId: recordingId! },
    skip: !recordingId,
  });
  const annotations = data?.recording.annotations ?? [];
  const suggestedAnnotations = (data?.recording.suggestedAnnotations.notInProject ?? []).filter(({ id }) =>
    foundSuggestedAnnotations.has(id)
  );
  const [connectAnnotation] = useConnectPortalAnnotationMutation({
    onCompleted: () => {
      refetch();
    },
  });

  const { data: selectedAnnotationData, loading: selectedAnnotationLoading } = usePortalAnnotationQuery({
    variables: { id: selectedAnnotationId! },
    skip: !selectedAnnotationId,
  });
  const selectedAnnotation = selectedAnnotationData?.portalAnnotation;

  const { onSearch } = useClobDetailsSearchCurationNodes();
  const curationQuestionId = selectedAnnotation?.clobQuestion?.curationQuestion?.id;
  useEffect(() => {
    curationQuestionId && onSearch(curationQuestionId);
  }, [curationQuestionId]);

  const childFrame = (document.getElementById("recording-iframe") as HTMLIFrameElement | null)?.contentWindow;

  const { postMessage } = useHandshake({
    onMessage: (message, response) => {
      console.debug("[ontolio] Received message", message);
      if (message.type === "create-annotation" || message.type === "refetch-annotations") {
        void refetch();
      }
      if (message.type === "set-found-suggested-annotations") {
        setFoundSuggestedAnnotations(new Set(message.annotationIds));
      }
      if (message.type === "select-annotation") {
        setSelectedAnnotationId(message.annotationId);
      }
      if (message.type === "ready" && recordingId) {
        response({ type: "set-recording", recordingId });
      }
    },
    frame: childFrame,
  });

  useEffect(() => {
    if (recordingId && childFrame) {
      postMessage({ type: "set-recording", recordingId });
    }
  }, [recordingId, childFrame]);

  useWatchWhenAnnotationMappingChanges(selectedAnnotation, () => {
    postMessage({ type: "refetch-annotations" });
  });

  if (!recordingId) {
    return (
      <EmptyState
        icon={<BorderColor fontSize="large" />}
        title="No screen capture selected"
        description="Select a recording to view its annotations."
      />
    );
  }

  return (
    <Stack gap={4}>
      {selectedAnnotationId && (
        <Card loading={selectedAnnotationLoading} title="Clob Question">
          {selectedAnnotation && (
            <ClobQuestionDetailsCard
              clobQuestion={selectedAnnotation.clobQuestion}
              search=""
              matchingCurationQuestions={false}
            />
          )}
          {selectedAnnotation?.clobQuestion.curationQuestion && (
            <UnlinkCurationQuestionChip
              curationQuestionId={selectedAnnotation.clobQuestion.curationQuestion.id}
              clobQuestionId={selectedAnnotation.clobQuestion.id}
            />
          )}
          {selectedAnnotation && !curationQuestionId && (
            <Alert severity="info" icon={false}>
              You can drag this{" "}
              <DirectCurationQuestionDragAndDrop
                clobQuestion={selectedAnnotation.clobQuestion}
                renderDragHandle={renderDragHandle}
              />
              to a curation question to link it or to a section to create a new curation question and associate it with
              this form field.
            </Alert>
          )}
          {selectedAnnotation?.clobQuestion && (
            <>
              <Divider />
              <ClobQuestionEditableFieldsCard clobQuestion={selectedAnnotation.clobQuestion} />
            </>
          )}
        </Card>
      )}
      <Card title={`Annotations (${annotations.length})`}>
        <MenuList dense={true}>
          {[...annotations]
            .sort((a, b) => (a.primaryRecordingId === recordingId && b.primaryRecordingId !== recordingId ? -1 : 0))
            .map((annotation) => (
              <MenuItem
                key={annotation.id}
                selected={annotation.id === selectedAnnotationId}
                sx={annotation.primaryRecordingId === recordingId ? {} : { color: "text.secondary" }}
                onClick={() => {
                  setSelectedAnnotationId(annotation.id);
                  postMessage({ type: "select-annotation", annotationId: annotation.id });
                }}
                onMouseEnter={() => postMessage({ type: "hover-annotation", annotationId: annotation.id })}
                onMouseLeave={() => postMessage({ type: "hover-annotation", annotationId: undefined })}
              >
                <ListItem
                  disablePadding={true}
                  dense={true}
                  secondaryAction={
                    <RemoveAnnotation
                      annotationId={annotation.id}
                      recordingId={recordingId}
                      onRemove={() => {
                        setSelectedAnnotationId(undefined);
                        refetch();
                        postMessage({ type: "refetch-annotations" });
                      }}
                    />
                  }
                >
                  <ListItemIcon>
                    {annotation.clobQuestion.curationQuestionId ? (
                      <Check
                        fontSize="small"
                        color={
                          questionIdsIncludingEntityQuestions?.has(annotation.clobQuestion.curationQuestionId ?? "")
                            ? "success"
                            : "error"
                        }
                      />
                    ) : (
                      <LinkOff fontSize="small" color="info" />
                    )}
                  </ListItemIcon>
                  <ListItemText primary={annotation.clobQuestion.text} />
                </ListItem>
              </MenuItem>
            ))}
        </MenuList>
      </Card>
      {suggestedAnnotations.length > 0 && (
        <Card title="Suggested annotations">
          <MenuList dense={true}>
            {suggestedAnnotations.map((annotation) => (
              <MenuItem
                key={annotation.id}
                selected={annotation.id === selectedAnnotationId}
                onClick={() => {
                  setSelectedAnnotationId(annotation.id);
                  postMessage({ type: "select-annotation", annotationId: annotation.id });
                }}
                onMouseEnter={() => postMessage({ type: "hover-annotation", annotationId: annotation.id })}
                onMouseLeave={() => postMessage({ type: "hover-annotation", annotationId: undefined })}
              >
                <ListItem>
                  <ListItemText primary={annotation.selectionText} />
                </ListItem>
                <ListItemSecondaryAction
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  <Chip
                    icon={<Check />}
                    label="Confirm"
                    size="small"
                    color="info"
                    variant="outlined"
                    sx={{ borderRadius: 1 }}
                    onClick={(e) => {
                      e.stopPropagation();
                      void connectAnnotation({
                        variables: {
                          input: {
                            annotationId: annotation.id,
                            recordingId,
                          },
                        },
                      });
                    }}
                  />
                </ListItemSecondaryAction>
              </MenuItem>
            ))}
          </MenuList>
        </Card>
      )}
    </Stack>
  );
};

function renderDragHandle(ref: React.RefObject<HTMLButtonElement>): React.ReactNode {
  return (
    <Chip
      size="small"
      clickable={true}
      component="button"
      icon={<DragIndicator />}
      sx={{ mx: 1, borderRadius: 1, fontSize: "12px", cursor: "grab", "&:hover": { backgroundColor: "info.light" } }}
      color="info"
      ref={ref}
      label="Drag me!"
    />
  );
}
