export function useNavigateItems<T>(items: T[], onChange: (item: T) => void) {
  const current = (item: T) => {
    return items.indexOf(item);
  };

  const canPrev = (item: T) => {
    return current(item) > 0;
  };

  const canNext = (item: T) => {
    return current(item) < items.length - 1;
  };

  const prev = (item: T) => {
    if (canPrev(item)) {
      onChange(items[current(item) - 1]);
    }
  };
  const next = (item: T) => {
    if (canNext(item)) {
      onChange(items[current(item) + 1]);
    }
  };

  return { prev, current, next, canPrev, canNext };
}
