import { STATE_OPTIONS } from "@cp/toolkit";
import FilledInput from "@mui/material/FilledInput";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import InputLabel from "@mui/material/InputLabel";
import NativeSelect, { NativeSelectProps } from "@mui/material/NativeSelect";
import { Controller, useFormContext } from "react-hook-form";
import { useSaveForm } from "./common/useSaveForm";
import { FormFieldProps, processFormFieldProps } from "./FormFieldProps";

export const FormFieldStateSelect: React.VFC<FormFieldProps> = (props) => {
  const { control } = useFormContext(); // retrieve all hook methods
  const { label, options = STATE_OPTIONS, sx, ControllerProps, disabled, required } = processFormFieldProps(props);
  const saveForm = useSaveForm();

  const finalOptions = options.length === 0 || !options.some((o) => o.value) ? STATE_OPTIONS : options;

  return (
    <Controller
      control={control}
      defaultValue=""
      {...ControllerProps}
      render={({ field: { value, onBlur, onChange, name, ref }, fieldState: { error } }) => (
        <FormControl fullWidth={true} error={!!error} sx={sx} disabled={disabled} required={required}>
          <InputLabel>{label}</InputLabel>
          <StateSelect
            value={value}
            name={name}
            input={<FilledInput />}
            onBlur={onBlur}
            onChange={(e) => {
              onChange(e);
              saveForm(name, e.target.value);
            }}
            inputRef={ref}
          >
            {finalOptions.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </StateSelect>
          <FormHelperText error={true}>{error?.message}</FormHelperText>
        </FormControl>
      )}
    />
  );
};

export const StateSelect = ({
  options = STATE_OPTIONS,
  ...props
}: NativeSelectProps & { options?: Array<{ label: string; value: string }> }) => {
  return (
    <NativeSelect {...props} input={<FilledInput />}>
      {options.map((option) => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </NativeSelect>
  );
};
