import React from "react";
import { EditableText } from "../../../components/properties/EditableText";
import { useClobContext } from "../../../model/clobs/context";

interface Props {
  id: string;
}

export const EditableClobQuestionTitle: React.FC<Props> = ({ id }) => {
  const {
    state,
    actions: { updateQuestion },
  } = useClobContext();
  return <EditableText value={state.questions.get(id)?.text ?? ""} onChange={(text) => updateQuestion({ id, text })} />;
};
