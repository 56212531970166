import { PaletteMode } from "@mui/material";
import { TypeDivider } from "@mui/material/styles/createPalette";

const rgb = {
  blue: "78, 110, 237",
  grey: "157, 161, 178",
  green: "59, 178, 95",
  yellow: "237, 140, 55",
  red: "237, 78, 84",
};

const alphaScale = (rgb: string) => ({
  alpha: {
    10: `rgba(${rgb}, 0.03)`,
    50: `rgba(${rgb}, 0.06)`,
    100: `rgba(${rgb}, 0.12)`,
    200: `rgba(${rgb}, 0.24)`,
    300: `rgba(${rgb}, 0.48)`,
  },
});

const blue = {
  light: {
    400: "#4E6EED",
    500: "#425BBC",
    600: "#37488B",
    700: "#2B355A",
  },
  dark: {
    400: "#4E6EED",
    500: "#7891F1",
    600: "#A3B4F6",
    700: "#CDD6FA",
  },
  ...alphaScale(rgb.blue),
  contrastText: "#FFF",
};

const grey = {
  light: {
    0: "#FFF",
    10: "#FCFCFD",
    50: "#F9F9FA",
    100: "#F3F4F6",
    200: "#E7E9ED",
    300: "#D0D2DA",
    400: "#9DA1B2",
    500: "#7E828F",
    600: "#60626C",
    700: "#41434A",
    800: "#232427",
  },
  dark: {
    0: "#1D1E21",
    10: "#212225",
    50: "#25262A",
    100: "#2C2E32",
    200: "#3C3D44",
    300: "#5A5D67",
    400: "#9DA1B2",
    500: "#B5B8C4",
    600: "#CCCED7",
    700: "#E4E5E9",
    800: "#FBFBFC",
  },
  ...alphaScale(rgb.grey),
  contrastText: "#FFF",
};

const green = {
  light: {
    400: "#3BB25F",
    500: "#348F50",
    600: "#2D6B41",
    700: "#264832",
  },
  dark: {
    400: "#3BB25F",
    500: "#6AC485",
    600: "#99D7AC",
    700: "#C8E9D2",
  },
  ...alphaScale(rgb.green),
  contrastText: "#FFF",
};

const yellow = {
  light: {
    400: "#ED8C37",
    500: "#BB7232",
    600: "#89572D",
    700: "#583D27",
  },
  dark: {
    400: "#ED8C37",
    500: "#F1A867",
    600: "#F6C397",
    700: "#FADFC7",
  },
  ...alphaScale(rgb.yellow),
  contrastText: "#FFF",
};

const red = {
  light: {
    400: "#ED4E54",
    500: "#BB4348",
    600: "#89373C",
    700: "#582C2F",
  },
  dark: {
    400: "#ED4E54",
    500: "#F1787D",
    600: "#F6A3A6",
    700: "#FACDCF",
  },
  ...alphaScale(rgb.red),
  contrastText: "#FFF",
};

export const createPalette = (mode: PaletteMode) => {
  return {
    mode,
    grey: grey[mode],
    background: {
      paper: grey[mode][0],
      default: grey[mode][0],
      alternate: grey[mode][10],
    },
    text: {
      primary: grey[mode][700],
      secondary: grey[mode][500],
      disabled: grey[mode][400],
    },
    primary: {
      main: blue[mode][400],
      dark: blue[mode][500],
      light: blue.alpha[50],
      shadow: blue.alpha[100],
      contrastText: blue.contrastText,
    },
    secondary: {
      main: red[mode][400],
      dark: red[mode][500],
      light: red.alpha[50],
      shadow: red.alpha[100],
      contrastText: red.contrastText,
    },
    highlight: {
      main: yellow.alpha[100],
      dark: yellow[mode][400],
      light: yellow.alpha[50],
      shadow: yellow.alpha[100],
      contrastText: yellow[mode][400],
    },
    info: {
      main: blue[mode][400],
      dark: blue[mode][500],
      light: blue.alpha[50],
      shadow: blue.alpha[100],
      contrastText: blue.contrastText,
    },
    success: {
      main: green[mode][400],
      dark: green[mode][500],
      light: green.alpha[50],
      shadow: green.alpha[100],
      contrastText: green.contrastText,
    },
    warning: {
      main: yellow[mode][400],
      dark: yellow[mode][500],
      light: yellow.alpha[50],
      shadow: yellow.alpha[100],
      contrastText: yellow.contrastText,
    },
    error: {
      main: red[mode][400],
      dark: red[mode][500],
      light: red.alpha[50],
      shadow: red.alpha[100],
      contrastText: red.contrastText,
    },
    divider: grey.alpha[100],
    outline: grey.alpha[200],
  };
};

declare module "@mui/material/styles" {
  interface TypeBackground {
    alternate: string;
  }

  interface Palette {
    outline: TypeDivider;
  }
  interface PaletteColor {
    shadow: string;
  }
}
