// import { DevTool } from "@hookform/devtools";
import { ForwardedRef, useImperativeHandle } from "react";
import { DeepMap, DeepPartial, DefaultValues, FieldErrors, FieldValues, FormProvider, useForm } from "react-hook-form";
import { getDefaultValue } from "../builders/getOptionsInternal";
import { AnyForm } from "../types";
import { getResolver } from "./getResolver";
import { OptionsContext, OptionSets } from "./OptionsContext";
import { renderForm } from "./renderForm";

interface Props<T extends FieldValues> {
  id: string;
  form: AnyForm<T>;
  defaultValues?: DefaultValues<T>;
  optionSets?: OptionSets;
  onSubmit: (
    data: T,
    opts: { isDirty: boolean; dirtyFields?: Partial<Readonly<DeepMap<DeepPartial<T>, boolean>>> },
    event?: React.BaseSyntheticEvent
  ) => void;
  onChange?: (data: T) => void;
  onSubmitInvalid?: (errors: FieldErrors<T>, event?: React.BaseSyntheticEvent) => void;
}

export interface FormyFormHandle<T extends FieldValues> {
  reset: (values?: T) => void;
}

export function FormyForm<T extends FieldValues>(
  { id, form, onChange, onSubmit, onSubmitInvalid, defaultValues, optionSets }: Props<T>,
  ref: ForwardedRef<FormyFormHandle<T>>
) {
  const methods = useForm<T>({
    mode: "onBlur",
    resolver: getResolver(form),
    defaultValues: defaultValues ? { ...defaultValues } : (getDefaultValue(form) as DefaultValues<T>),
  });

  const { formState } = methods;
  const { isDirty, dirtyFields } = formState;

  const handleSubmit = (formEvent: React.FormEvent<HTMLFormElement>) => {
    void methods.handleSubmit(
      (v, e) => onSubmit(v, { isDirty, dirtyFields }, e),
      (v, e) => onSubmitInvalid && onSubmitInvalid(v, e)
    )(formEvent);
  };

  useImperativeHandle(
    ref,
    () => ({
      reset: (values?: T) => methods.reset(values),
    }),
    []
  );

  return (
    <>
      <OptionsContext.Provider value={optionSets}>
        <FormProvider {...methods}>
          <form
            id={id}
            onSubmit={handleSubmit}
            autoComplete="false"
            onBlur={() => {
              onChange?.(methods.getValues());
            }}
          >
            {renderForm(form, "")}
          </form>
        </FormProvider>
      </OptionsContext.Provider>
      {/* <DevTool control={methods.control} /> */}
    </>
  );
}
