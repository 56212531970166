import { coerceBoolean, Dates } from "@cp/toolkit";
import { Warning } from "@mui/icons-material";
import { colors, MenuItem, Stack, TableCell, TextField, Typography, useTheme } from "@mui/material";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { isValid } from "date-fns";
import React, { useEffect, useState } from "react";
import NumberFormat from "react-number-format";
import { Column, ColumnOptions } from "../../core/Column";
import { SafeRender } from "../SafeRender";
import { Cell, tableCellSx } from "./Cell";
import { EditCheckboxCell } from "./EditCheckboxCell";
import { EditInputCell } from "./EditInputCell";
import { EditSelectCell } from "./EditSelectCell";
interface Props<T = any> {
  index: number;
  datum: T;
  column: Column<T>;
}

export const EditableCell: React.FC<Props> = ({ column, index, datum }) => {
  const theme = useTheme();
  const [focused, setFocused] = useState(false);
  const [options, setOptions] = useState<Array<{ label: string; value: string }>>([]);
  useEffect(() => {
    void (async () => {
      const optionsRes = column?.editable?.getInitialOptions ? await column?.editable.getInitialOptions() : [];
      setOptions(optionsRes);
    })();
  }, [column]);

  if (!column.editable) {
    return null;
  }
  if (column.editable.cellNotEditable?.(datum)) {
    return <Cell column={column} index={index} datum={datum} />;
  }
  const editable = column.editable;
  const value = editable.getInitialValue(datum, index);
  const displayOptions =
    options.length > 0 ? options : editable.options ? getCellOptions(editable.options, index) : undefined;

  const isEmpty = value == null || value === "";
  const variant = editable.required && isEmpty ? "warning" : "default";

  const renderInput = () => {
    const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === "Enter") {
        if (e.target instanceof HTMLInputElement) {
          editable.onEdit({
            index,
            value: e.target.value,
            data: datum,
          });
        }
        setFocused(false);
      }
    };
    const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
      editable.onEdit({
        index,
        value: e.target.value,
        data: datum,
      });
    };

    switch (editable.dataType) {
      case "number":
        return (
          <EditInputCell
            autoFocus={true}
            fullWidth={true}
            type="number"
            defaultValue={value}
            onBlur={handleBlur}
            onKeyPress={handleKeyPress}
          />
        );
      case "percentage":
        return (
          <NumberFormat
            suffix="%"
            decimalScale={0}
            allowNegative={false}
            isAllowed={({ floatValue }) => floatValue == null || floatValue <= 100}
            onValueChange={({ floatValue }) => {
              editable.onEdit({
                index,
                value: floatValue,
                data: datum,
              });
            }}
            customInput={EditInputCell}
            value={value}
            onKeyPress={handleKeyPress}
          />
        );
      case "currency":
        return (
          <NumberFormat
            prefix="$"
            thousandsGroupStyle="thousand"
            thousandSeparator={true}
            decimalScale={2}
            decimalSeparator="."
            fixedDecimalScale={true}
            onValueChange={({ floatValue }) => {
              editable.onEdit({
                index,
                value: floatValue,
                data: datum,
              });
            }}
            customInput={EditInputCell}
            value={value}
            onKeyPress={handleKeyPress}
          />
        );
      case "text":
        return (
          <EditInputCell
            autoFocus={true}
            fullWidth={true}
            defaultValue={value}
            onBlur={handleBlur}
            onKeyPress={handleKeyPress}
          />
        );
      case "boolean":
        return (
          <EditCheckboxCell
            defaultChecked={coerceBoolean(value)}
            onChange={(e) => {
              editable.onEdit({
                index,
                value: e.target.checked,
                data: datum,
              });
            }}
          />
        );
      case "select": {
        // can only clear if not required and has a value
        const isClearable = !editable.required && !!value;
        return (
          <EditSelectCell
            value={value ?? ""}
            displayEmpty={true}
            size="small"
            variant="standard"
            disableUnderline={true}
            fullWidth={true}
            onClose={() => setFocused(false)}
            renderValue={(value: string) => {
              if (value === "") {
                return (
                  <Typography color="text.disabled" component="i" variant="caption">
                    Not set
                  </Typography>
                );
              }

              const options = editable.options ? getCellOptions(editable.options, index) : undefined;
              const item = options?.find(({ value: v }) => v === value);
              return item?.label || value;
            }}
            onChange={(evt) => {
              setFocused(false);
              editable.onEdit({
                index,
                value: evt.target.value,
                data: datum,
              });
            }}
            autoWidth={true}
          >
            {displayOptions?.map((option) => (
              <MenuItem key={option.value} value={option.value} dense={true}>
                {option.label}
              </MenuItem>
            ))}
            {isClearable && (
              <MenuItem key="clear" value="" dense={true} sx={{ color: theme.palette.error.main }}>
                Clear
              </MenuItem>
            )}
          </EditSelectCell>
        );
      }
      case "date":
        return (
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DesktopDatePicker
              value={Dates.reformatDate(value)}
              InputProps={{
                disableUnderline: true,
                sx: {
                  maxWidth: column.maxWidth,
                  width: column.maxWidth,
                  px: 2,
                  minWidth: column.minWidth,
                  fontSize: 12,
                },
              }}
              renderInput={(params) => <TextField {...params} variant="standard" />}
              onClose={() => setFocused(false)}
              onChange={(date) => {
                if (date && isValid(date)) {
                  editable.onEdit({ index, value: date, data: datum });
                }
              }}
            />
          </LocalizationProvider>
        );
      default:
        return <EditInputCell autoFocus={true} defaultValue={value} onBlur={handleBlur} onKeyPress={handleKeyPress} />;
    }
  };

  const shouldAlwaysRenderInput =
    editable.dataType === "boolean" ||
    editable.dataType === "select" ||
    editable.dataType === "percentage" ||
    editable.dataType === "currency" ||
    editable.dataType === "date";

  return (
    <TableCell
      key={column.id}
      align={column.align}
      className={column.break ? "break" : undefined}
      onClick={() => setFocused(true)}
      tabIndex={0}
      onFocus={() => setFocused(true)}
      onBlur={() => setFocused(false)}
      sx={{
        maxWidth: column.maxWidth,
        width: column.maxWidth,
        px: 2,
        minWidth: column.minWidth,
        flex: column.flex ?? 1,
        color: theme.palette.text.primary,
        cursor: "pointer",
        ...tableCellSx,
        ...(variant === "warning" ? { backgroundColor: colors.orange[50] } : {}),
        "&:hover": {
          // lighter hover
          boxShadow: `inset 1px 1px ${theme.palette.grey[400]}, inset -1px -1px ${theme.palette.grey[400]}`,
        },
        ...(focused
          ? {
              boxShadow: `inset 1px 1px ${theme.palette.primary.main}, inset -1px -1px ${theme.palette.primary.main} !important`,
              zIndex: 2,
            }
          : {}),
      }}
    >
      <Stack direction="row" px={2} justifyContent="space-between" alignItems="center" minHeight="32px">
        {focused || shouldAlwaysRenderInput ? (
          renderInput()
        ) : (
          <SafeRender render={column.render} params={{ index, data: datum }} />
        )}
        {variant === "warning" && <Warning sx={{ fontSize: "12px" }} color="warning" className="warning-icon" />}
      </Stack>
    </TableCell>
  );
};

function getCellOptions(options: ColumnOptions, index: number) {
  return options.optionType === "PerColumn" ? options.items : options.items[index];
}
