import { z } from "zod";

export class TypeSchemas {
  public static LocationEntityReference = z.object({
    id: z.string(),
    label: z.string().optional(),
  });

  public static WcCodeReducedSchema = z.object({
    id: z.string(),
    code: z.string().optional(),
  });
}
