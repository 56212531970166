import { Add } from "@mui/icons-material";
import { Chip, Stack } from "@mui/material";
import React from "react";
import { useClobContext } from "../../../model/clobs/context";

interface Props {
  parentId: string | "root";
}

export const AddNewTreeItem: React.FC<Props> = ({ parentId }) => {
  const {
    actions: { addItem },
  } = useClobContext();

  return (
    <Stack direction="row" gap={2} py={1}>
      <Chip
        size="small"
        color="success"
        sx={{ opacity: 0.4, "&:hover": { opacity: 1 } }}
        icon={<Add />}
        label="Add section"
        onClick={() => addItem({ parentId, nodeType: "section" })}
      />
      {parentId !== "root" && (
        <Chip
          size="small"
          color="success"
          sx={{ opacity: 0.4, "&:hover": { opacity: 1 } }}
          icon={<Add />}
          label="Add question"
          onClick={() => addItem({ parentId, nodeType: "question" })}
        />
      )}
    </Stack>
  );
};
