import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import InputAdornment from "@mui/material/InputAdornment";

import { FormFieldNumberFormat, FormFieldNumberFormatProps } from "./FormFieldNumberFormat";

export const FormFieldFEIN: React.VFC<FormFieldNumberFormatProps> = (props) => {
  return (
    <FormFieldNumberFormat
      format="##-#######"
      extractValueAs="formatted-string"
      mask="_"
      {...props}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <BusinessCenterIcon />
          </InputAdornment>
        ),
      }}
    />
  );
};
