import { Close } from "@mui/icons-material";
import { Box, Button, IconButton, Stack, Typography } from "@mui/material";
import { OptionsObject, SnackbarKey, useSnackbar } from "notistack";
import { EstimateLinearProgress } from "./toast/EstimatedProgress";

/**
 * Wrapper around `useSnackbar()` with more opinionated defaults.
 */
export function useToaster() {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  return {
    toast: enqueueSnackbar,
    closeSnackbar,
    toastProgress: (message: string, secondsToComplete: number) => {
      return enqueueSnackbar(
        <Stack width="100%">
          <Typography variant="caption">{message}</Typography>
          <EstimateLinearProgress secondsToComplete={secondsToComplete} />
        </Stack>,
        {
          persist: true,
          action: (toastKey: SnackbarKey) => (
            <IconButton
              color="inherit"
              onClick={(evt) => {
                evt.stopPropagation();
                closeSnackbar(toastKey);
              }}
            >
              <Close />
            </IconButton>
          ),
        }
      );
    },
    toastUndo: (message: string, options: OptionsObject & { onUndo: () => void }) => {
      const { onUndo, ...restOptions } = options;
      return enqueueSnackbar(message, {
        ...restOptions,
        action: (toastKey: SnackbarKey) => (
          <Button
            variant="text"
            color="inherit"
            onClick={(evt) => {
              evt.stopPropagation();
              onUndo();
              closeSnackbar(toastKey);
            }}
          >
            Undo
          </Button>
        ),
      });
    },
    toastError: (error: Error | string) => {
      enqueueSnackbar(
        <Box component="pre" maxWidth="70vw" overflow="auto">
          {parseError(error)}
        </Box>,
        {
          variant: "error",
          action: (toastKey: SnackbarKey) => (
            <IconButton
              color="inherit"
              onClick={(evt) => {
                evt.stopPropagation();
                closeSnackbar(toastKey);
              }}
            >
              <Close />
            </IconButton>
          ),
        }
      );
    },
  };
}

export interface VerboseError extends Error {
  message: string;
  graphQLErrors?: ReadonlyArray<{
    message: string;
    extensions: {
      code?: string;
      response?: {
        statusCode: number;
        message: string[];
        error: string;
      };
    };
  }>;
}

function parseError(error: VerboseError | string) {
  if (typeof error === "string") {
    return error;
  }

  if (error.graphQLErrors && error.graphQLErrors.length > 0) {
    const { extensions, message } = error.graphQLErrors[0];
    if (!extensions.response) {
      return message;
    }
    const messages = Array.isArray(extensions.response.message)
      ? extensions.response.message
      : [extensions.response.message];
    return `${extensions.response.error || "Error"}: ${messages.join(", ")}`;
  }
  return error.message;
}
