import { Checkbox, FormControl, FormControlLabel, FormHelperText, Stack } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import { processFormFieldProps } from "../../form-fields/FormFieldProps";
import { getDefaultValue } from "../builders/getOptionsInternal";
import { AnyForm } from "../types";
import { renderForm } from "./renderForm";

interface Props {
  label?: string;
  helperText?: string | React.ReactNode;
  name: string;
  form: AnyForm<unknown>;
}

export function OptionalOf({ label, name, form, helperText }: Props) {
  const { control } = useFormContext();
  const { ControllerProps } = processFormFieldProps({
    pseudoRequire: false,
    label: "",
    name,
  });

  return (
    <Controller
      control={control}
      defaultValue=""
      {...ControllerProps}
      render={({ field, fieldState: { error } }) => {
        const checked = field.value != null;
        return (
          <FormControl variant="standard" fullWidth={true} error={!!error}>
            <FormHelperText>{error?.message}</FormHelperText>

            <Stack gap={2}>
              <FormControlLabel
                label={label}
                control={
                  <Checkbox
                    size="medium"
                    onChange={() => {
                      field.onChange(checked ? null : getDefaultValue(form));
                    }}
                    checked={checked}
                  />
                }
              />
              {helperText && <FormHelperText>{helperText}</FormHelperText>}
              {checked && renderForm(form, name)}
            </Stack>
          </FormControl>
        );
      }}
    />
  );
}
