import { Close } from "@mui/icons-material";
import { Button, Card, IconButton, Stack, Typography } from "@mui/material";
import { useFieldArray, useFormContext } from "react-hook-form";
import { getDefaultValue } from "../builders/getOptionsInternal";
import { AnyForm } from "../types";
import { join } from "./join";
import { renderForm } from "./renderForm";

interface Props {
  label?: string;
  itemName?: string;
  name: string;
  form: AnyForm<unknown>;
}

export function ArrayForm({ label, itemName, name, form }: Props) {
  const { control } = useFormContext();
  const { fields, append, remove } = useFieldArray({ control, name });

  return (
    <Card variant="outlined" sx={{ p: 2 }}>
      <Stack gap={3}>
        {label && (
          <Typography color="text.secondary" variant="h6">
            {label}
          </Typography>
        )}
        {fields.map((field, index) => {
          const fieldForm = renderForm(form, join(name, index.toString()));
          return (
            <Stack key={field.id}>
              <IconButton size="small" sx={{ alignSelf: "flex-end" }} color="error" onClick={() => remove(index)}>
                <Close fontSize="small" />
              </IconButton>
              {fieldForm}
            </Stack>
          );
        })}
        <Button
          variant="text"
          size="small"
          sx={{ alignSelf: "flex-start" }}
          onClick={() => append(getDefaultValue(form))}
        >
          + Add {itemName}
        </Button>
      </Stack>
    </Card>
  );
}
