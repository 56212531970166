import { usePopover } from "@cp/react-hooks";
import { MoreVert } from "@mui/icons-material";
import {
  Card as MuiCard,
  CardActions,
  CardContent,
  IconButton,
  MenuList,
  Popover,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import React, { PropsWithChildren } from "react";

interface Props {
  sx?: React.CSSProperties;
  title?: React.ReactNode;
  header?: React.ReactNode;
  actions?: React.ReactNode;
  headerActionMenu?: React.ReactNode;
  loading?: boolean;
}

/**
 * Opinionated card component
 */
export const Card: React.FC<PropsWithChildren<Props>> = ({
  sx,
  title,
  header,
  actions,
  headerActionMenu,
  loading,
  children,
}) => {
  if (loading) {
    return (
      <MuiCard sx={sx}>
        <CardContent sx={{ width: "200px", height: "130px" }}>
          <Skeleton variant="rectangular" width="30%" height="30%" />
          <Skeleton />
        </CardContent>
      </MuiCard>
    );
  }

  if (!children) {
    return null;
  }

  return (
    <MuiCard sx={sx}>
      <CardContent sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
        <Stack spacing={2} sx={{ flex: 1, minHeight: "0px" }}>
          {title && (
            <Typography
              variant="h4"
              m={0}
              color="text.secondary"
              fontWeight={600}
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              {title}
              {headerActionMenu && <CardHeaderActionMenu>{headerActionMenu}</CardHeaderActionMenu>}
            </Typography>
          )}
          {header}
          {children}
        </Stack>
      </CardContent>
      {actions && <CardActions>{actions}</CardActions>}
    </MuiCard>
  );
};

const CardHeaderActionMenu: React.FC<PropsWithChildren<{}>> = ({ children }) => {
  const { popoverProps, targetProps } = usePopover();

  return (
    <>
      <IconButton {...targetProps} size="small">
        <MoreVert fontSize="small" />
      </IconButton>
      <Popover {...popoverProps}>
        <MenuList dense={true}>{children}</MenuList>
      </Popover>
    </>
  );
};
