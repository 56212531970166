import { useModal } from "@cp/modals";
import { Box, Tooltip } from "@mui/material";
import { useRef } from "react";
import { useDrag } from "react-dnd";
import slugify from "slugify";
import {
  CarrierProjectDocument,
  CurationQuestionFragment,
  PortalAnnotationsDocument,
  useUpdateClobQuestionMutation,
} from "../../generated/graphql";
import { useClobContext } from "../../model/clobs/context";
import { EditableClobQuestion } from "../../model/clobs/types";
import { conditionToInput } from "../../model/conditions/conditionToInput";
import CreateCurationQuestionDialog from "../../pages/clobs/MapClob/CreateCurationQuestionDialog";
import { Dragable, DragTypes, DropResult } from "../../pages/curation/Dragables/DragTypes";
import { Handle } from "../sortables/components/Handle/Handle";

interface Props {
  clobQuestion: Pick<EditableClobQuestion, "id" | "text" | "options" | "componentType" | "clob">;
  renderDragHandle?: (ref: React.RefObject<HTMLButtonElement>) => React.ReactNode;
}

/**
 * This is a drag-handle that will create a curation question from a clob question when dropped,
 * and then insert it into the section it was dropped into.
 */
export function CreateCurationQuestionFromClobQuestionDragAndDrop({ clobQuestion, renderDragHandle }: Props) {
  const { actions } = useClobContext();
  const [updateClobQuestion] = useUpdateClobQuestionMutation({
    // Refetch for updated counts
    refetchQueries: [PortalAnnotationsDocument, CarrierProjectDocument],
  });

  const { openCustomModal } = useModal();

  const ref = useRef<HTMLButtonElement>(null);

  const [, drag] = useDrag<Dragable, DropResult, { isDragging: boolean }>(
    {
      type: DragTypes.CLOB_QUESTION,
      item: {
        id: clobQuestion.id,
        type: DragTypes.CLOB_QUESTION,
      },
      end: async (item, monitor) => {
        if (monitor.didDrop()) {
          const dropResult = monitor.getDropResult();
          if (dropResult?.type === "create-curation-question") {
            // Imperatively open the create curation question dialog
            const curationQuestion = await openCustomModal<CurationQuestionFragment | undefined>((onClose) => (
              <CreateCurationQuestionDialog
                open={true}
                handleClose={() => onClose(undefined)}
                onSuccess={(curationQuestion) => onClose(curationQuestion)}
                confirmText="Save and Map"
                clobQuestionId={clobQuestion.id}
                initialValues={{
                  key: toKey(clobQuestion),
                  text: clobQuestion.text,
                  options: clobQuestion.options ?? [],
                  componentType: clobQuestion.componentType,
                }}
              />
            ));
            // If the user cancelled the dialog, do nothing
            if (!curationQuestion) {
              return;
            }
            // Insert the curation question into the section
            dropResult.onInsert(curationQuestion.id);
            // Map the curation question to the PDF question
            await handleCreateCurationMapping(curationQuestion.id);
          }

          if (dropResult?.type === "connect-curation-question") {
            // Map the curation question to the PDF question
            await handleCreateCurationMapping(dropResult.curationQuestionId);
          }
        }
      },
    },
    [clobQuestion.id]
  );

  drag(ref);

  const handleCreateCurationMapping = (curationQuestionId: CurationQuestionFragment["id"]) => {
    return updateClobQuestion({
      variables: { clobQuestion: { id: clobQuestion.id, curationQuestionId: curationQuestionId } },
      onCompleted: (data) => {
        actions.updateQuestion({
          ...data.updateClobQuestion,
          visibility: data.updateClobQuestion.visibility.map(conditionToInput),
        });
      },
    });
  };

  if (renderDragHandle) {
    return <>{renderDragHandle(ref)}</>;
  }

  return (
    <Tooltip title="Drag to a section to create a new curation question and link it.">
      <Box sx={{ backgroundColor: "rgba(201, 242, 155,0.5)", borderRadius: 2 }}>
        <Handle ref={ref} />
      </Box>
    </Tooltip>
  );
}

const toKey = (clobQuestion: Props["clobQuestion"]) => {
  const { carrierSlug, lineOfBusiness } = clobQuestion.clob || {};
  const carrier = carrierSlug ? `${carrierSlug.trim()}_` : "";
  const lob = lineOfBusiness ? `${lineOfBusiness.trim()}_` : "";
  return `${carrier}${lob}${slugify(clobQuestion.text)}`;
};
