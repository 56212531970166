import { Autocomplete } from "@cp/forms";
import { useSearchQuery } from "@cp/react-hooks";
import { Add } from "@mui/icons-material";
import { CircularProgress, InputAdornment } from "@mui/material";
import { CurationQuestionFragment, useSearchCurationQuestionsQuery } from "../../generated/graphql";

interface Props {
  placeholderText: string;
  onChange: (id: string) => void;
}

export const CurationQuestionAutocomplete: React.FC<Props> = ({ placeholderText, onChange }) => {
  const search = useSearchQuery();
  const { data, previousData, loading } = useSearchCurationQuestionsQuery({
    variables: {
      searchTerm: search.debouncedValue.trim(),
      archived: false,
      pagination: {
        page: 1,
        rowsPerPage: 50,
      },
    },
    skip: !search.debouncedValue.trim(),
  });

  const questions = data?.curationQuestions ?? previousData?.curationQuestions ?? [];

  return (
    <Autocomplete
      value=""
      clearOnBlur={true}
      blurOnSelect={true}
      name="add-question"
      options={questions}
      label=""
      TextFieldProps={{
        InputProps: {
          ...search.inputProps,
          placeholder: placeholderText,
          startAdornment: (
            <InputAdornment position="start">
              <Add fontSize="small" />
            </InputAdornment>
          ),
          endAdornment: loading && (
            <InputAdornment position="end">
              <CircularProgress size={20} />
            </InputAdornment>
          ),
        },
        size: "small",
        variant: "outlined",
        color: "info",
      }}
      onChange={onChange}
      table={{
        headers: ["Key", "Question", "ID"],
        getRow: (question: CurationQuestionFragment) => [question.key, question.text, question.id],
      }}
      getOptionLabel={(question: CurationQuestionFragment) => question.text}
      getValue={(question: CurationQuestionFragment) => question.id}
    />
  );
};
