import { isEqual } from "lodash";
import { DropTargetMonitor, useDrop } from "react-dnd";
import { Dragable, DragSource, DragTypes, DropResult } from "../Dragables/DragTypes";
import { CurationQuestionId } from "../state/types";
import { useCurationActions } from "../state/useCurationActions";

/**
 * React-dnd hook to handle dropping a clob-question or pdf-annotation onto a curation-question.
 */
export function useCurationQuestionDropzoneForInsert(curationQuestionId: CurationQuestionId, source: DragSource) {
  const { moveToNewNode } = useCurationActions();

  return useDrop<Dragable, DropResult, { isOver: boolean }>(
    () => ({
      accept: [DragTypes.QUESTION],
      canDrop: (item: Dragable) => {
        // Can only drop CurationQuestion if not in the same source
        // For questions in the same source, that is handled by another dnd hook
        if (item.type === DragTypes.QUESTION) {
          return !isEqual(source, item.source);
        }
        return false;
      },
      drop: (dragable: Dragable, monitor: DropTargetMonitor<Dragable, DropResult>) => {
        if (monitor.didDrop()) {
          return undefined;
        }

        // Handle question drop
        // We move the question to the same section and right above the curation question
        if (dragable.type === DragTypes.QUESTION && source.type === "section") {
          moveToNewNode({
            toId: source.sectionId,
            fromId: dragable.source.type === "section" ? dragable.source.sectionId : undefined,
            nodeId: dragable.id,
            beforeId: curationQuestionId,
          });
        }

        return undefined;
      },
      collect: (monitor) => ({
        isOver: monitor.isOver() && monitor.canDrop(),
      }),
    }),
    [curationQuestionId]
  );
}
