import { AnyForm, FormyNestedForm, getResolver } from "@cp/forms";
import { useModal } from "@cp/modals";
import { useToaster } from "@cp/theme";
import { DeepPartial, useForm } from "react-hook-form";

interface Props<T> {
  title: string;
  message?: string;
  submitText: string;
  form: AnyForm<T>;
  defaultValues?: DeepPartial<T>;
  onSubmit: (data: T) => Promise<void>;
}

/**
 * Hook that connects useModal, useForm, and FormyForm to create a modal form imperatively
 */
export function useModalForm<State extends Record<string, any>>(props: Props<State>) {
  const { openForm } = useModal();
  const { toastError } = useToaster();
  const methods = useForm<State>({
    defaultValues: props.defaultValues,
    reValidateMode: "onChange",
    resolver: getResolver(props.form),
  });

  const openFormModal = async () => {
    const response = await openForm({
      title: props.title,
      message: props.message || "",
      form: <FormyNestedForm methods={methods} form={props.form} name="" />,
      submitText: props.submitText,
    });
    if (response === false || response === "cancelled") {
      return;
    }
    await methods.handleSubmit(props.onSubmit, (errors) =>
      toastError(
        Object.values(errors)
          .map((error) => error?.message)
          .join("\n")
      )
    )(response);
    methods.reset();
  };

  return {
    openFormModal,
    formMethods: methods,
  };
}
