import { Stack, Typography } from "@mui/material";
import { Column, createTableConfig, Table } from "@qw/table";
import { MuiMarkdown } from "mui-markdown";
import React, { useMemo } from "react";
import { InfoTextPopover } from "../form-fields/common/InfoTextPopover";
import { ApplicationConditions } from "../form-fields/conditions/ApplicationConditions";
import {
  ApplicationComponentType,
  EntityType,
  FormQuestionFragment,
  useApplicationClientEntitiesLazyQuery,
} from "../generated/operations";
import { DisabledCell } from "./common/DisabledCell";
import { formatCellValue } from "./common/formatCell";
import { TableWrapper } from "./common/TableWrapper";
import { getDataTypeForComponentType, getOptions } from "./common/utils";
import { RowTracker, SparseRow } from "./RowTracker";
import { parseDefaultValues } from "./useSetDefaultTableValues";

interface Props {
  title: string;
  allChildrenIds: string[];
  visibleFields: Array<Omit<FormQuestionFragment, "__typename">>;
  canAddRows: boolean;
  fixedColumns?: number;
  defaultValues?: string;
  readonly?: boolean;
  helperText?: string | null;
  getValues: (fieldId: string) => string | string[];
  onSaveValue?: (fieldId: string, value: unknown) => void;
  applicationId?: string;
}

export const FormTable: React.FC<Props> = ({
  title,
  allChildrenIds,
  visibleFields,
  canAddRows,
  defaultValues,
  helperText,
  fixedColumns = 0,
  readonly = false,
  getValues,
  onSaveValue,
  applicationId,
}) => {
  const [rowTracker, setRowTracker] = React.useState<RowTracker>(() => {
    return RowTracker.create(allChildrenIds, getValues).atLeast(1).atLeast(parseDefaultValues(defaultValues).length);
  });

  const [loadApplication] = useApplicationClientEntitiesLazyQuery();
  /**
   * Small helper to get the value of a field for a given row.
   * This handles the case where the field is a single value or an array of values.
   */
  const getValue = (fieldId: string, sparseRow: number) => {
    const columnValues = getValues(fieldId);
    if (Array.isArray(columnValues)) {
      return columnValues[sparseRow];
    }
    return columnValues;
  };

  const { columns } = useMemo(() => {
    let config = createTableConfig<SparseRow>();
    let colIdx = 0;
    for (const field of visibleFields) {
      const dataType = getDataTypeForComponentType(field.componentType);
      const editableConfig: Column<any>["editable"] = {
        required: field.isRequired,
        dataType: dataType,
        options: {
          optionType: "PerColumn",
          items: getOptions(field.componentType, field.options) || [],
        },
        getInitialValue: (sparseRow) => {
          return getValue(field.id, sparseRow);
        },
        getInitialOptions: async () => {
          if (field.componentType === ApplicationComponentType.LocationSelect && applicationId) {
            return loadApplication({
              variables: {
                input: { entityType: EntityType.Location, applicationId: applicationId },
              },
            }).then(({ data }) => {
              return data?.applicationClientEntities.map((o) => ({ label: o.label, value: o.label })) || [];
            });
          }
          return [];
        },

        onEdit: ({ data: sparseRow, value }) => {
          const key = `${field.id}.${sparseRow}`;
          onSaveValue?.(key, value);
        },
      };
      const isEditable = colIdx >= fixedColumns;
      config = config.addColumn({
        id: field.id,
        header: () => {
          return (
            <Stack direction="row" px={2}>
              {isEditable ? (
                <MuiMarkdown overrides={{ component: Typography }}>{field.label}</MuiMarkdown>
              ) : (
                <MuiMarkdown>{field.label}</MuiMarkdown>
              )}
              {field.isRequired && "*"}
              {field.infoText && <InfoTextPopover infoText={field.infoText} />}
            </Stack>
          );
        },
        flex: 1,
        minWidth: 150,
        render: ({ data: sparseRow, index }) => {
          const value = getValue(field.id, sparseRow);
          if (readonly) {
            return <>{formatCellValue(value, dataType)}</>;
          }

          return (
            <ApplicationConditions conditions={field.visibility} fallback={<DisabledCell />} row={index}>
              {formatCellValue(value, dataType)}
            </ApplicationConditions>
          );
        },
        editable: isEditable && !readonly ? editableConfig : undefined,
      });
      colIdx++;
    }
    return config.build();
  }, [visibleFields]);

  const handleAdd = () => setRowTracker((tracker) => tracker.addRow());
  helperText = helperText?.replaceAll("\n", "  \n");

  return (
    <TableWrapper
      title={title}
      helperText={<MuiMarkdown overrides={{ component: Typography }}>{helperText}</MuiMarkdown>}
    >
      <Table
        columns={columns}
        data={rowTracker.getRows()}
        loading={false}
        error={undefined}
        onAdd={canAddRows && !readonly ? handleAdd : undefined}
      />
    </TableWrapper>
  );
};
