import { Psychology, Search } from "@mui/icons-material";
import {
  Alert,
  Button,
  IconButton,
  InputAdornment,
  LinearProgress,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  MenuList,
  Skeleton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import {
  EntityType,
  useCurationEntitiesQuery,
  useMatchingCurationQuestionsLazyQuery,
} from "../../../generated/graphql";
import { useClobDetailsSearchCurationNodes } from "../../../pages/clobs/MapClob/components/ClobDetailsCurationNodeSearch";
import { ClobMappingDot } from "../../../pages/clobs/MapClob/components/ClobMappingDot";
import { SectionBreadcrumbs } from "../../../pages/curation/ApplicationLayout/SectionBreadcrumbs";
import { EntityChip } from "../../../pages/curation/Toolbar/EntityChip";
import { ViewCurationQuestionButton } from "../../buttons/curation-questions/ViewCurationQuestionButton";
import { Card } from "../../Card";
import { LinkCurationQuestion } from "../../curation-questions/LinkCurationQuestion";
import { useShowMore } from "../../lists/useShowMore";
import { Collapse } from "../../sortables/components/Collapse/Collapse";

type MatchBy =
  | {
      type: "clobQuestionId";
      clobQuestionId: string;
    }
  | {
      type: "pdfField";
      formFieldName: string;
      formIdentifier: string;
      pageNumber: number;
    };

interface Props {
  matchBy: MatchBy;
}

/**
 * Re-usable card component that shows a list of matching curation questions.
 */
export const MatchingCurationQuestionsCard: React.FC<Props> = ({ matchBy }) => {
  // Query to power the search fo matching curation questions
  const [searchQuery, setSearchQuery] = useState(() => {
    if (matchBy.type === "pdfField") {
      return matchBy.formFieldName;
    }
    return "";
  });

  const { onSearch } = useClobDetailsSearchCurationNodes();

  const getInput = () => {
    const trimmedSearchQuery = searchQuery.trim();
    if (matchBy.type === "clobQuestionId") {
      // if we have a search query, use it, otherwise use the clobQuestionId
      if (trimmedSearchQuery) {
        return { byText: [trimmedSearchQuery] };
      }
      return { byClobQuestionId: matchBy.clobQuestionId };
    }
    return { byText: [trimmedSearchQuery] };
  };

  const [load, { data, error, loading }] = useMatchingCurationQuestionsLazyQuery({
    fetchPolicy: "cache-and-network", // Ideally need cache ttl but no option in apollo.
    variables: {
      matchingCurationQuestionsInput: getInput(),
    },
  });

  const { data: entityData } = useCurationEntitiesQuery();

  const questionToEntityType = new Map<string, EntityType>();
  entityData?.curationEntities.forEach((e) => {
    e.questions.forEach((q) => questionToEntityType.set(q.id, e.type));
  });

  const entityQuestionIds = new Set(questionToEntityType.keys());

  const allItems = data?.matchingCurationQuestions.matchingQuestions ?? [];

  const [items, showMore] = useShowMore(allItems);
  const [isExpanded, expand] = useState<boolean>(true);

  // Prevent 0 being the max score
  const maxScore = Math.max(0.1, ...allItems.map(({ score }) => score ?? 0));

  if (error) {
    return <Alert severity="error">{error.message}</Alert>;
  }

  if (!data && !loading) {
    return (
      <Alert color="info" icon={false}>
        <Button startIcon={<Psychology />} variant="contained" size="small" onClick={() => load()} color="info">
          Load suggested curation questions
        </Button>
      </Alert>
    );
  }

  return (
    <Card
      sx={{
        border: "1px solid",
        borderColor: "info.light",
        display: "flex",
        flexDirection: "column",
      }}
      actions={
        isExpanded && (
          <Button onClick={showMore} disabled={!showMore} variant="text" size="small">
            Show more
          </Button>
        )
      }
      header={
        <Stack direction="row" spacing="5px">
          <Collapse onCollapse={() => expand(!isExpanded)} collapsed={!isExpanded} />
          <Stack direction="row">
            <Psychology color="info" />
            <Typography color="primary" fontWeight={500}>
              Suggestions
            </Typography>
          </Stack>
        </Stack>
      }
    >
      {isExpanded ? (
        <MenuList
          dense={true}
          subheader={
            isExpanded && (
              <TextField
                label="Search query"
                size="small"
                fullWidth={true}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Search fontSize="small" />
                    </InputAdornment>
                  ),
                }}
                defaultValue={searchQuery}
                onBlur={(e) => setSearchQuery(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    setSearchQuery((e.target as HTMLInputElement).value);
                  }
                }}
              />
            )
          }
        >
          {loading &&
            Array.from({ length: 7 }).map((_, i) => (
              <ListItem key={i} disableGutters={true}>
                <ListItemText
                  primary={<Skeleton variant="text" width="100%" />}
                  secondary={<Skeleton variant="text" width="80%" />}
                />
              </ListItem>
            ))}
          {isExpanded &&
            !loading &&
            items.map(({ curationQuestionId, questionText, questionKey, score }) => {
              score = score ?? 0;
              const normalizedScore = Math.round((1 - score / maxScore) * 100);
              return (
                <React.Fragment key={curationQuestionId}>
                  <ListItem key={curationQuestionId} disableGutters={true}>
                    <ListItemText
                      primary={questionText}
                      sx={{ pr: 15 }}
                      primaryTypographyProps={{
                        fontWeight: 500,
                      }}
                      secondaryTypographyProps={{
                        variant: "caption",
                        overflow: "hidden",
                      }}
                      secondary={
                        <Stack>
                          <span>{questionKey}</span>
                          <Stack direction="row" spacing={4} justifyContent="space-between" sx={{ paddingBottom: 1 }}>
                            <span>
                              <SectionBreadcrumbs nodeId={curationQuestionId} />
                            </span>
                            {entityQuestionIds.has(curationQuestionId) && (
                              <EntityChip entityType={questionToEntityType.get(curationQuestionId)} />
                            )}
                          </Stack>
                          <LinearProgress
                            sx={{ borderRadius: 3 }}
                            variant="determinate"
                            value={normalizedScore}
                            color={normalizedScore > 70 ? "success" : "warning"}
                          />
                        </Stack>
                      }
                    />
                    <ListItemSecondaryAction>
                      <ViewCurationQuestionButton curationQuestionId={curationQuestionId} />

                      <IconButton size="small">
                        <ClobMappingDot
                          id={matchBy.type === "clobQuestionId" ? matchBy.clobQuestionId : ""}
                          lineToId={curationQuestionId}
                          hideLine={true}
                          onClick={onSearch}
                        />
                      </IconButton>
                      {matchBy.type === "pdfField" ? null : (
                        <LinkCurationQuestion
                          curationQuestionId={curationQuestionId}
                          clobQuestionIds={[matchBy.clobQuestionId]}
                        />
                      )}
                    </ListItemSecondaryAction>
                  </ListItem>
                </React.Fragment>
              );
            })}
        </MenuList>
      ) : (
        <></>
      )}
    </Card>
  );
};
