import { useEvent } from "@cp/react-hooks";
import { useToaster } from "@cp/theme";
import DataLoader from "dataloader";
import { memo, PropsWithChildren, useMemo, useRef } from "react";
import {
  CurationApplicationEventInput,
  useCurationApplicationSubscription,
  useCurationQuestionChangedSubscription,
  useCurationQuestionsForApplicationQuery,
  useCurationSectionChangedSubscription,
  useSubmitCurationApplicationEventsMutation,
} from "../../../generated/graphql";
import { CurationActionsContext, CurationStateContext } from "./CurationContext";
import { deserializeCurationApplication } from "./serialize";
import { CurationContextActions, INITIAL_STATE, ROOT } from "./types";
import { useCurationActions } from "./useCurationActions";
import { useCurationState } from "./useCurationState";

/**
 * Intercepts the CurationProvider and adds realtime updates.
 */
export const RealtimeCurationProvider = memo(({ id, children }: PropsWithChildren<{ id: string }>) => {
  const { toast } = useToaster();
  const originalState = useCurationState();
  const originalActions = useCurationActions();

  // track what version we had before the last update so we can properly ignore our own updates
  // in the toaster
  const submittedAtVersion = useRef(0);

  // Pre-load the questions into the apollo state.
  const { loading } = useCurationQuestionsForApplicationQuery({
    variables: { id },
  });

  // Subscribe to tree/question/section changes. This will automatically update the cache.
  useCurationApplicationSubscription({
    variables: { id },
    onData: ({ data }) => {
      if (data.data?.curationApplication) {
        originalActions.setState(deserializeCurationApplication(data.data.curationApplication));
        if (submittedAtVersion.current + 1 !== data.data.curationApplication.version) {
          toast(`Application received updates. Version: ${data.data.curationApplication.version}`);
        }
      }
    },
  });
  useCurationQuestionChangedSubscription();
  useCurationSectionChangedSubscription();

  const [submitEvents] = useSubmitCurationApplicationEventsMutation();

  const handleSubmitEvent = useEvent(async (event: readonly CurationApplicationEventInput[]) => {
    submittedAtVersion.current = originalState.version;
    const previousState = { ...originalState };
    await submitEvents({
      variables: { id, events: [...event] },
      onError: (error) => {
        originalActions.setState(previousState);
      },
    });
  });

  // DataLoader provdes utility for batching and caching function calls reducing the number of individual calls made
  // The ".load()" function of DataLoader is used to load a single key. It takes a single argument,
  // the key to be loaded, and returns a promise that resolves to the value of the key.
  const submitEventsBatched = useMemo(
    () =>
      new DataLoader<CurationApplicationEventInput, void>(async (events) => {
        await handleSubmitEvent(events);
        // throws an error if Dataloader does not return an array with the same length as it was constructed with
        return Array.from({ length: events.length });
      }),
    [handleSubmitEvent]
  );

  const actions: CurationContextActions = {
    setState: useEvent((payload) => {
      originalActions.setState(payload);
    }),
    removeCurationSection: useEvent((payload) => {
      void submitEventsBatched.load({
        removeChild: { childId: payload.sectionId, parentId: payload.parentId },
      });
      originalActions.removeCurationSection(payload);
    }),
    moveToNewNode: useEvent((payload) => {
      const children = originalState.curationNodeMap.get(payload.toId)?.children ?? [];
      // default to end of list
      let position = children.length;
      if (payload.beforeId) {
        // If has children, insert at the end
        // If the beforeId is a child of the toId, update the position
        if (children.includes(payload.beforeId)) {
          position = children.indexOf(payload.beforeId) + 1;
        } else {
          console.error(
            `${payload.beforeId} is not a child of ${payload.toId}. Cannot find a precise position to insert the node.`
          );
        }
      }

      void submitEventsBatched.load({
        moveChild: {
          childId: payload.nodeId,
          fromParentId: payload.fromId === ROOT ? null : payload.fromId,
          toParentId: payload.toId,
          position: position,
        },
      });
      originalActions.moveToNewNode(payload);
    }),
    moveWithinNode: useEvent((payload) => {
      void submitEventsBatched.load({
        moveChild: {
          childId: payload.childId,
          toParentId: payload.parentId === ROOT ? null : payload.parentId,
          fromParentId: payload.parentId === ROOT ? null : payload.parentId,
          position: payload.toIndex,
        },
      });
      originalActions.moveWithinNode(payload);
    }),
    addNode: useEvent((payload) => {
      void submitEventsBatched.load({
        createSection: {
          id: payload.newId,
          title: "",
          parentId: payload.parentNodeId,
        },
      });
      originalActions.addNode(payload);
    }),
    removeNode: useEvent((payload) => {
      void submitEventsBatched.load({
        removeChild: { childId: payload.nodeId, parentId: payload.parentId },
      });
      originalActions.removeNode(payload);
    }),
    swapNode: useEvent(async (payload) => {
      await submitEventsBatched.load({
        swapChild: {
          insertChildId: payload.insertNodeId,
          removeChildId: payload.removeNodeId,
          parentId: payload.parentId,
        },
      });
      originalActions.swapNode(payload);
    }),
    makeConditionalQuestion: useEvent((payload) => {
      void submitEventsBatched.load({
        toggleConditionalQuestion: { id: payload.questionId },
      });
      originalActions.makeConditionalQuestion(payload);
    }),
    unmakeConditionalQuestion: useEvent((payload) => {
      void submitEventsBatched.load({
        toggleConditionalQuestion: { id: payload.questionId },
      });
      originalActions.unmakeConditionalQuestion(payload);
    }),
  };

  return (
    <CurationStateContext.Provider value={loading ? INITIAL_STATE : originalState}>
      <CurationActionsContext.Provider value={actions}>{children}</CurationActionsContext.Provider>
    </CurationStateContext.Provider>
  );
});

RealtimeCurationProvider.displayName = "RealtimeCurationProvider";
