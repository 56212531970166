import { FormLabel } from "@mui/material";
import { useFormRenderers } from "../context/FormRendererProvider";
import { FormFieldProps, processFormFieldProps } from "./FormFieldProps";

interface Props extends FormFieldProps {
  applicationId: string;
}

// Not a real 'form field', but a component that renders a file upload component
export const FileUpload: React.FC<Props> = (props: Props) => {
  const { label, applicationId } = processFormFieldProps(props);
  const { renderApplicationDocumentsUploadComponent } = useFormRenderers();

  if (!renderApplicationDocumentsUploadComponent) {
    return null;
  }

  return (
    <>
      <FormLabel className="question">{label}</FormLabel>
      {renderApplicationDocumentsUploadComponent({ applicationId })}
    </>
  );
};
