import { FormHelperText, useTheme } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import * as React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useSaveForm } from "./common/useSaveForm";
import { FormFieldProps, processFormFieldProps } from "./FormFieldProps";

interface Props extends FormFieldProps {
  options?: Array<{ value: string; label: React.ReactNode }>;
}

export const FormFieldRadioGroup: React.VFC<Props> = (props) => {
  const { control } = useFormContext();
  const theme = useTheme();
  const saveForm = useSaveForm();
  const { id, label, disabled, options = [], required, ControllerProps } = processFormFieldProps(props);

  return (
    <Controller
      control={control}
      {...ControllerProps}
      defaultValue=""
      rules={{
        ...ControllerProps.rules,
        // Historically, we've used 'null' to represent 'not answered' for boolean fields.
        // But this caused required-ness to pass. So we are not enforcing a real value when required.
        validate: (value) => (required ? value !== "null" : true),
      }}
      render={({ field, fieldState: { error } }) => (
        <FormControl component="fieldset" error={!!error} disabled={disabled} required={required}>
          <FormLabel component="legend" className="question">
            {label}
          </FormLabel>
          <RadioGroup
            id={id}
            {...field}
            sx={{ color: error ? theme.palette.error.main : undefined }}
            onChange={(e, val) => {
              field.onChange(e);
              saveForm(field.name, val);
            }}
          >
            {options.map((option) => (
              <FormControlLabel
                key={option.value}
                value={option.value}
                control={<Radio sx={{ color: error ? theme.palette.error.main : undefined }} />}
                label={option.label}
              />
            ))}
          </RadioGroup>
          <FormHelperText error={true}>{error?.message}</FormHelperText>
        </FormControl>
      )}
    />
  );
};
