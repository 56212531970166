import { Star } from "@mui/icons-material";
import { colors, Stack } from "@mui/material";

export const GoldenQuestionIcon: React.FC = () => {
  return (
    <Stack
      sx={{
        color: colors.common.white,
        borderRadius: "50%",
        backgroundColor: colors.orange[200],
        padding: 0.5,
      }}
    >
      <Star sx={{ fontSize: "14px" }} />
    </Stack>
  );
};
