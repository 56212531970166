import { FormContextProvider, FormRenderer } from "@cp/forms";
import { Container, EmptyState, LoadState } from "@cp/theme";
import { Refresh, SettingsApplications } from "@mui/icons-material";
import { Box, IconButton, Tooltip } from "@mui/material";
import React, { memo } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { usePreviewFormQuery } from "../../../generated/graphql";

interface Props {
  carrierSlug: string;
  lineOfBusiness: string;
}

export const AgencyFormPreview: React.FC<Props> = memo(({ carrierSlug, lineOfBusiness }) => {
  const methods = useForm();

  const { data, error, loading, refetch } = usePreviewFormQuery({
    variables: {
      input: {
        carrierSlugs: [carrierSlug],
        lineOfBusiness,
      },
    },
  });
  if (loading || error) {
    return <LoadState loading={loading} error={error} />;
  }

  if (!data || data.previewForm.tree.rootIds.length === 0) {
    return (
      <EmptyState
        title="No preview available"
        description="There are no questions linked for this Carrier and Line of Business."
        icon={<SettingsApplications />}
      />
    );
  }

  const { tree, containers, questions } = data.previewForm;

  return (
    <Container
      header={
        <Tooltip title="Refresh">
          <IconButton>
            <Refresh onClick={() => refetch()} />
          </IconButton>
        </Tooltip>
      }
      bodySx={{ backgroundColor: "white" }}
    >
      <Box maxWidth={800} mx="auto" px={4}>
        <FormProvider {...methods}>
          <FormContextProvider
            containers={containers}
            questions={questions}
            treeItems={tree.items}
            applicationId={undefined}
          >
            <FormRenderer rootIds={tree.rootIds} />
          </FormContextProvider>
        </FormProvider>
      </Box>
    </Container>
  );
});

AgencyFormPreview.displayName = "Preview";
