import { CellRenderer, CellRendererParams } from "../core/Column";

interface SafeRenderProps<T> {
  render: CellRenderer<T>;
  params: CellRendererParams<T>;
}

/* Wraps the render function in its own react component to correctly isolate hooks */
export function SafeRender<T>({ render, params }: SafeRenderProps<T>) {
  return <span>{render(params)}</span>;
}
