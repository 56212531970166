import { Boundary } from "@cp/async";
import { ThemeProvider } from "@cp/theme";
import * as Sentry from "@sentry/react";
import { Provider } from "jotai";
import { SnackbarProvider } from "notistack";
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "./index.css";

if (process.env.REACT_APP_SENTRY_DSN && process.env.NODE_ENV !== "development") {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: process.env.APP_ENV,
  });
}

ReactDOM.render(
  <React.StrictMode>
    <Provider>
      <ThemeProvider>
        <Boundary>
          <SnackbarProvider
            maxSnack={3}
            autoHideDuration={3000}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          >
            <App />
          </SnackbarProvider>
        </Boundary>
      </ThemeProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);
