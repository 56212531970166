import { useApolloClient } from "@apollo/client";
import { useModal } from "@cp/modals";
import { useToaster } from "@cp/theme";
import React from "react";
import { z } from "zod";
import { FileDropzone } from "../../../components/dnd/FileDropzone";
import {
  CarrierProjectDocument,
  CarrierProjectQuery,
  CarrierProjectQueryVariables,
  CarrierProjectsDocument,
  RecordingDocument,
  RecordingQuery,
  RecordingQueryVariables,
  RecordingsDocument,
  Scalars,
  useMoveCarrierRecordingsToProjectMutation,
} from "../../../generated/graphql";

interface Props {
  onSuccess?: () => void;
  projectId?: Scalars["CarrierProjectId"];
}

export const RecordingDropzone: React.FC<Props> = ({ onSuccess, projectId }) => {
  const { openConfirm, openAlert } = useModal();
  const { toast, toastError } = useToaster();
  const client = useApolloClient();
  const [move] = useMoveCarrierRecordingsToProjectMutation({
    refetchQueries: [CarrierProjectsDocument, CarrierProjectDocument],
  });

  const onSuccessfulDrop = () => {
    void client.refetchQueries({
      include: [RecordingsDocument],
    });
    onSuccess?.();
  };

  return (
    <FileDropzone
      accept={{
        "application/json": [".json"],
      }}
      multiple={true}
      onDrop={async (files) => {
        let successCount = 0;
        toast(`Uploading ${files.length} recording(s)...`);
        for (const file of files) {
          try {
            // Parse the file
            const contents = JSON.parse(await readFile(file));

            const scriptSchema = z.object({ url: z.string(), content: z.string() });
            const stringifiedSchema = z.string().transform((str) => {
              return z.array(scriptSchema).parse(JSON.parse(str));
            });

            // Validate the file
            const schema = z.object({
              recordingId: z.string(),
              origin: z.string(),
              href: z.string(),
              email: z.string().optional(),
              documentTitle: z.string().optional(),
              submissionId: z.string().optional(),
              html: z.string(),
              base64Image: z.string(),
              scripts: z.union([z.array(scriptSchema), stringifiedSchema]),
              styles: z.union([z.array(scriptSchema), stringifiedSchema]),
            });
            const parsed = schema.parse(contents);

            // Upload the file
            const response = await fetch("/api/recording", {
              method: "POST",
              headers: { "Content-Type": "application/json" },
              body: JSON.stringify({
                ...parsed,
                projectId,
              }),
            });

            // Get project title
            const getTitle = async (id: Scalars["CarrierProjectId"]) => {
              const project = await client.query<CarrierProjectQuery, CarrierProjectQueryVariables>({
                query: CarrierProjectDocument,
                variables: {
                  id,
                },
              });
              return project.data.carrierProject.name;
            };

            // If it was successful, continue
            if (response.ok) {
              successCount++;
              continue;
            }

            // It may error since the recording already exists.
            // So lets check if it exists and prompt to move to the new project.
            if (response.status === 409) {
              // Get the recording
              const recording = await client.query<RecordingQuery, RecordingQueryVariables>({
                query: RecordingDocument,
                variables: {
                  id: parsed.recordingId,
                },
              });

              const currentProjectId = recording.data.recording.carrierProjectId;
              // Case 1: No destination project and not in a project
              if (!projectId && !currentProjectId) {
                await openAlert({
                  title: "Recording already exists",
                  message: (
                    <div>
                      <p>
                        The recording <strong>{recording.data.recording.name}</strong> is already uploaded to{" "}
                        <strong>New Screen Captures</strong>.
                      </p>
                    </div>
                  ),
                });
                continue;
              }

              // Case 2: Has destination project and not in a project
              // We should auto-move
              if (projectId && !currentProjectId) {
                await move({
                  variables: {
                    input: {
                      recordingIds: [recording.data.recording.id],
                      projectId,
                    },
                  },
                });
                continue;
              }

              // Case 3: No destination project and in a project
              // Just alert
              if (!projectId && currentProjectId) {
                const currentProjectTitle = await getTitle(currentProjectId);
                await openAlert({
                  title: "Recording already exists",
                  message: (
                    <div>
                      <p>
                        The recording <strong>{recording.data.recording.name}</strong> is already uploaded to Project:{" "}
                        <strong>{currentProjectTitle}</strong>.
                      </p>
                    </div>
                  ),
                });
                continue;
              }

              // Case 4: Has destination project and in a project, and they are the same
              if (projectId && currentProjectId && projectId === currentProjectId) {
                await openAlert({
                  title: "Recording already exists",
                  message: (
                    <div>
                      <p>
                        The recording <strong>{recording.data.recording.name}</strong> is already uploaded to this
                        project.
                      </p>
                    </div>
                  ),
                });
                continue;
              }

              // Case 5: Has destination project and in a project, and they are different
              if (projectId && currentProjectId && projectId !== currentProjectId) {
                const currentProjectTitle = await getTitle(currentProjectId);
                const destinationProjectTitle = await getTitle(projectId);
                const confirmation = await openConfirm({
                  title: "Recording already exists",
                  message: (
                    <div>
                      <p>
                        The recording <strong>{recording.data.recording.name}</strong> already exists in the project{" "}
                        Project: <strong>{currentProjectTitle}</strong>.
                      </p>
                      <p>
                        Would you like to move it to the project <strong>{destinationProjectTitle}</strong>?
                      </p>
                    </div>
                  ),
                  confirmText: "Move",
                  cancelText: "Cancel",
                });
                if (confirmation) {
                  await move({
                    variables: {
                      input: {
                        recordingIds: [recording.data.recording.id],
                        projectId,
                      },
                    },
                  });
                }
                continue;
              }
            }

            throw new Error(`Server responded with ${response.status}`);
          } catch (error: any) {
            toastError(`Failed to send recording ${file.name}: ${error.message}`);
          }
        }
        toast(`Successfully uploaded ${successCount} recording(s)`);
        if (successCount > 0) {
          onSuccessfulDrop();
        }
      }}
    />
  );
};

function readFile(file: File): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      const data = event.target?.result;
      if (data) {
        resolve(data.toString());
      }
    };
    reader.onerror = (event) => {
      reject(event);
    };
    reader.readAsText(file);
  });
}
