import { useToaster } from "@cp/theme";
import { ContentPasteGo } from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";
import React from "react";
import { useCopyPastSection } from "../state/useCopyPasteSection";
import { useCurationActions } from "../state/useCurationActions";

interface Props {
  id: string;
}

export const SectionPaste: React.FC<Props> = ({ id }) => {
  const { toast } = useToaster();
  const { moveToNewNode } = useCurationActions();
  const [copiedId, setCopied] = useCopyPastSection();

  // No selection or is self
  if (!copiedId || copiedId.sectionId === id) {
    return null;
  }

  return (
    <Tooltip title="Move copied section">
      <IconButton
        size="small"
        color={"success"}
        onClick={() => {
          moveToNewNode({
            nodeId: copiedId.sectionId,
            toId: id,
            fromId: copiedId.parentId,
          });
          toast("Section moved");
          setCopied(undefined);
        }}
      >
        <ContentPasteGo />
      </IconButton>
    </Tooltip>
  );
};
