import { isDefined } from "@cp/toolkit";
import { Stack } from "@mui/material";
import { startCase } from "lodash";
import React from "react";
import { useFormContext } from "../context/FormContextProvider";
import { FormRendererContext } from "../context/FormRendererProvider";
import { ApplicationCondition, ApplicationConditions } from "../form-fields/conditions/ApplicationConditions";
import { FormFieldRenderer } from "../form-fields/FormFieldRenderer";
import {
  FormContainerFragment,
  FormContainerType,
  FormQuestionFragment,
  FormTreeItemFragment,
} from "../generated/operations";
import { EditableTable } from "../table/EditableTable";
import { PivotSectionTable } from "../table/pivot-section/PivotSectionTable";
import { ReadonlyFormTable } from "../table/ReadonlyFormTable";
import { EntitySection } from "./EntityItemCreatorDialog";
import { FormHeader } from "./FormHeader";
import { DefaultRenderers, Renderers } from "./Renderers";

interface Props {
  rootIds: string[];
  readonly?: boolean;
  renderers?: Partial<Renderers>;
}

/**
 * Renders a form. This is the main component for rendering a form.
 * It can be customized by providing a `renderers` prop.
 */
export const FormRenderer: React.FC<Props> = ({ rootIds, readonly = false, renderers: partialRenderers }) => {
  const { containerMap, questionMap, treeItemMap, applicationId } = useFormContext();
  const renderers: Renderers = { ...DefaultRenderers, ...partialRenderers };

  const renderQuestionInternal = (questionId: FormQuestionFragment["id"]) => {
    const question = questionMap.get(questionId);

    if (!question) {
      return;
    }

    const node = treeItemMap.get(questionId);
    const children = node?.children ?? [];

    const subsections = children
      .flatMap((child) => [renderQuestionInternal(child), renderContainerInternal(child, 1)])
      .filter(isDefined);

    const subsection =
      subsections.length > 0 ? (
        <Stack
          p="16px"
          ml="32px"
          mt="8px"
          gap="20px"
          sx={{
            backgroundColor: "background.alternate",
            border: "1px solid",
            borderColor: "divider",
            borderRadius: "5px",
            ":empty": { display: "none" },
          }}
        >
          {subsections}
        </Stack>
      ) : null;

    const field = renderers.renderQuestion(
      question,
      <FormFieldRenderer key={question.id} id={question.id} field={question} />
    );

    if (subsection) {
      return (
        <Stack key={question.id}>
          {field}
          {subsection}
        </Stack>
      );
    }

    return field;
  };

  const renderContainerInternal = (containerId: string, depth: number) => {
    const container = containerMap.get(containerId);

    if (!container) {
      return;
    }

    const node = treeItemMap.get(containerId);
    const children = node?.children ?? [];
    const childNodes = children
      .flatMap((child) => [renderQuestionInternal(child), renderContainerInternal(child, depth + 1)])
      .filter(isDefined);

    switch (container.type) {
      case FormContainerType.Entity: {
        let section: React.ReactNode = (
          <Stack id={containerId} key={containerId} gap="20px">
            <FormHeader depth={depth} helperText={container.helperText}>
              {container.label}
            </FormHeader>
            {renderers.renderEntitySection(
              container,
              <EntitySection key={containerId} title={startCase(container.entityType ?? "")} sectionId={containerId} />
            )}
          </Stack>
        );

        section = addConditions(section, containerId, container.visibility, readonly);
        section = renderers.renderContainer(container, section);
        return section;
      }
      case FormContainerType.Field:
      default: {
        if (childNodes.length === 0) {
          return;
        }

        if (container.pivotSubsectionsConfig?.enabled) {
          const childrenAsContainers = children
            .map((child) => containerMap.get(child))
            .filter<FormContainerFragment>(isDefined);
          const childrenAsTreeItems = children
            .map((child) => treeItemMap.get(child))
            .filter<FormTreeItemFragment>(isDefined);
          const nestedQuestions = childrenAsTreeItems
            .flatMap((item) => item.children)
            .map((child) => questionMap.get(child))
            .filter<FormQuestionFragment>(isDefined);

          if (childrenAsContainers.length > 0) {
            return (
              <PivotSectionTable
                key={containerId}
                title={container.label}
                helperText={container.helperText}
                readonly={readonly}
                useConditionalRows={!readonly}
                treeItems={childrenAsTreeItems}
                questions={nestedQuestions}
                containers={childrenAsContainers}
              />
            );
          }
        }

        if (container.tableConfig) {
          const questions = children.map((child) => questionMap.get(child)).filter<FormQuestionFragment>(isDefined);
          return (
            <Stack id={containerId} key={containerId} gap="20px">
              {readonly ? (
                <ReadonlyFormTable
                  title={container.label}
                  helperText={container.helperText}
                  allChildrenIds={children}
                  visibleFields={questions}
                  fixedColumns={container.tableConfig.fixedColumns}
                />
              ) : (
                <EditableTable
                  title={container.label}
                  helperText={container.helperText}
                  allChildrenIds={children}
                  visibleFields={questions}
                  canAddRows={container.tableConfig.canAddRemove}
                  defaultValues={container.tableConfig.defaultValues}
                  fixedColumns={container.tableConfig.fixedColumns}
                  applicationId={applicationId}
                />
              )}
            </Stack>
          );
        }
        let section: React.ReactNode = (
          <Stack id={containerId} key={containerId} gap="20px">
            {container.label && (
              <FormHeader depth={depth} helperText={readonly ? undefined : container.helperText}>
                {container.label}
              </FormHeader>
            )}
            {childNodes}
          </Stack>
        );
        if (!container.label && container.helperText) {
          section = (
            <Stack id={containerId} key={containerId} gap="20px">
              <FormHeader depth={depth} helperText={readonly ? undefined : container.helperText} />
              {childNodes}
            </Stack>
          );
        }

        section = addConditions(section, containerId, container.visibility, readonly);
        return renderers.renderContainer(container, section);
      }
    }
  };

  return (
    <FormRendererContext.Provider value={renderers}>
      <Stack gap="20px">
        {rootIds
          // Root ids can be a mix of containers and questions
          .flatMap((rootId) => [renderQuestionInternal(rootId), renderContainerInternal(rootId, 1)])
          .filter(isDefined)}
      </Stack>
    </FormRendererContext.Provider>
  );
};

function addConditions(
  element: React.ReactNode,
  id: string,
  conditions: ApplicationCondition[] | undefined,
  readonly: boolean
) {
  if (!conditions || conditions.length === 0 || readonly) {
    return element;
  }
  return (
    <ApplicationConditions key={id} conditions={conditions}>
      {element}
    </ApplicationConditions>
  );
}
