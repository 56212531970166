import { styled } from "@mui/material";
import React, { useRef } from "react";
import { useDrag } from "react-dnd";
import { ApplicationSection, ApplicationSectionProps } from "../ApplicationLayout/ApplicationSection";
import { DraggableWrapper } from "./DragableWapper";
import { Dragable, DragableItemProps, DragTypes, DropResult } from "./DragTypes";
import { useListItemDrop } from "./useListItemDrop";

const StyledApplicationSection = styled(ApplicationSection)<{ isDragging: boolean }>`
  opacity: ${({ isDragging }) => (isDragging ? 0.4 : 1)};
`;

export const DragableSection: React.VFC<Omit<DragableItemProps<ApplicationSectionProps>, "source">> = ({
  id,
  index,
  depth,
  move,
  parentId,
  disableSelection,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const { dropRef, isOver, direction } = useListItemDrop({
    id,
    ref,
    index,
    move,
    accept: [DragTypes.QUESTION, DragTypes.SECTION],
    source: { type: "section", sectionId: parentId },
  });

  const [{ isDragging }, drag, preview] = useDrag<Dragable, DropResult, { isDragging: boolean }>(
    {
      type: DragTypes.SECTION,
      item: {
        id,
        index,
        type: DragTypes.SECTION,
        source: { type: "section", sectionId: parentId },
      },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    },
    [id, index, parentId]
  );

  dropRef(ref);
  return (
    <DraggableWrapper ref={ref} isOver={isOver} direction={direction}>
      <StyledApplicationSection
        ref={preview}
        depth={depth}
        dragRef={drag}
        parentId={parentId}
        isDragging={isDragging}
        id={id}
        disableSelection={disableSelection}
      />
    </DraggableWrapper>
  );
};
