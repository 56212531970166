import { useSearchQuery } from "@cp/react-hooks";
import { Divider, Stack, Typography } from "@mui/material";
import React from "react";
import { ArchiveCurationQuestionToggle } from "../../../../components/ArchiveCurationQuestionToggle";
import { SearchInput } from "../../../../components/SearchInput";
import { useSearchCurationQuestionsQuery } from "../../../../generated/graphql";

export const ArchivedCurationQuestions: React.FC = () => {
  const search = useSearchQuery();

  const { data, loading } = useSearchCurationQuestionsQuery({
    variables: {
      searchTerm: search.debouncedValue.trim(),
      archived: true,
      pagination: {
        page: 1,
        rowsPerPage: 50,
      },
    },
    skip: !search.debouncedValue.trim(),
  });

  return (
    <Stack gap={1} maxWidth={800} width="100%" p={4}>
      <SearchInput {...search.inputProps} loading={loading} placeholder="Search archived questions" />
      {data?.curationQuestions.map((question) => (
        <Stack
          key={question.id}
          direction="row"
          sx={{ backgroundColor: "white" }}
          alignItems="center"
          boxShadow={1}
          width="100%"
          flex={1}
          p={1}
          divider={<Divider />}
          borderRadius={1}
        >
          <Stack flex={1} overflow="hidden">
            <Stack gap={1} direction="row" alignItems="center" className="hide-action-items" minHeight="40px" flex={1}>
              <Typography title={question.text} flex={1} variant="body2">
                {question.text}
              </Typography>
              <ArchiveCurationQuestionToggle id={question.id} archived={question.archived} />
            </Stack>
          </Stack>
        </Stack>
      ))}
    </Stack>
  );
};
