import { Link, LinkOff } from "@mui/icons-material";
import { ToggleButton, ToggleButtonGroup, Tooltip } from "@mui/material";
import { useMatch } from "@tanstack/react-location";
import React from "react";
import { LocationGenerics } from "../App";
import { useClobQuery } from "../generated/graphql";
import { useClobQuestionLobCarrierFilter } from "../pages/clobs/MapClob/atoms";

interface Props {
  value: undefined | "mapped" | "unmapped";
  handleClobFilter: (filter: undefined | "mapped" | "unmapped") => void;
}

export const ClobFilter: React.FC<Props> = ({ handleClobFilter, value }) => {
  const {
    params: { clobId },
  } = useMatch<LocationGenerics>();

  const handleChange = (_: React.MouseEvent<HTMLElement>, newFilter: "mapped" | "unmapped" | null) => {
    handleClobFilter(newFilter ?? undefined);
  };

  const { data: clobData } = useClobQuery({ variables: { id: clobId } });
  const [{ lineOfBusiness, carrierSlugs }] = useClobQuestionLobCarrierFilter();

  const clob = clobData?.clob;

  let mappedCount = 0;
  let unmappedCount = 0;
  clob?.questions
    ?.filter((question) => {
      if (!question.missingQuestion) {
        return true;
      }
      if (!lineOfBusiness && carrierSlugs.length === 0) {
        return true;
      }
      if (lineOfBusiness && carrierSlugs.length === 0) {
        return question?.missingQuestion?.lineOfBusiness === lineOfBusiness;
      }
      if (!lineOfBusiness && carrierSlugs.length > 0) {
        return carrierSlugs.includes(question?.missingQuestion?.carrierSlug ?? "");
      }

      if (lineOfBusiness && carrierSlugs.length > 0) {
        return (
          question?.missingQuestion?.lineOfBusiness === lineOfBusiness &&
          carrierSlugs.includes(question?.missingQuestion?.carrierSlug ?? "")
        );
      }
      return false;
    })
    .forEach((question) => {
      if (question.curationQuestion) {
        mappedCount++;
      } else {
        unmappedCount++;
      }
    });

  return (
    <ToggleButtonGroup size="small" value={value} exclusive={true} onChange={handleChange}>
      <Tooltip title="Filter to mapped">
        <ToggleButton value="mapped">
          <Link sx={{ mr: 1, color: "#7BC67E" }} />
          {mappedCount}
        </ToggleButton>
      </Tooltip>
      <Tooltip title="Filter to unmapped">
        <ToggleButton value="unmapped">
          <LinkOff sx={{ mr: 1, color: "#F88078" }} />
          {unmappedCount}
        </ToggleButton>
      </Tooltip>
    </ToggleButtonGroup>
  );
};
