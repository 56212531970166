import { identity } from "../functions";
import { TreeTraversalCallbacks } from "./traversers";
import { Tree } from "./Tree";

/**
 * Detects cycles in a tree.
 * Adapted from https://github.com/trekhleb/javascript-algorithms/blob/master/src/algorithms/graph/detect-cycle/detectDirectedCycle.js
 */
export function detectCycles(tree: Tree): Record<string, string> | null {
  let cycle: Record<string, string> | null = null;

  // Will store parents (previous vertices) for all visited nodes.
  // This will be needed in order to specify what path exactly is a cycle.
  const dfsParentMap = new Map<string, string>();

  // White set (UNVISITED) contains all the vertices that haven't been visited at all.
  const whiteSet = new Set<string>();

  // Gray set (VISITING) contains all the vertices that are being visited right now
  // (in current path).
  const graySet = new Set<string>();

  // Black set (VISITED) contains all the vertices that has been fully visited.
  // Meaning that all children of the vertex has been visited.
  const blackSet = new Set<string>();

  // If we encounter vertex in gray set it means that we've found a cycle.
  // Because when vertex in gray set it means that its neighbors or its neighbors
  // neighbors are still being explored.
  // Init white set and add all vertices to it.
  for (const [id] of tree.entries()) {
    whiteSet.add(id);
  }

  // Describe BFS callbacks.
  const callbacks: TreeTraversalCallbacks<string> = {
    onEnterNode: ({ currentNode, previousNode }) => {
      if (graySet.has(currentNode)) {
        // If current vertex already in grey set it means that cycle is detected.
        // Let's detect cycle path.
        cycle = {};

        let currentCycleVertex = currentNode;
        let previousCycleVertex = previousNode;

        if (!previousCycleVertex) {
          throw new Error("Previous node is undefined");
        }

        while (previousCycleVertex !== currentNode) {
          if (!previousCycleVertex) {
            break;
          }
          cycle[currentCycleVertex] = previousCycleVertex;
          currentCycleVertex = previousCycleVertex;
          previousCycleVertex = dfsParentMap.get(previousCycleVertex);
        }

        if (previousCycleVertex) {
          cycle[currentCycleVertex] = previousCycleVertex;
        }
      } else {
        // Otherwise let's add current vertex to gray set and remove it from white set.
        graySet.add(currentNode);
        whiteSet.delete(currentNode);

        // Update DFS parents list.
        if (previousNode) {
          dfsParentMap.set(currentNode, previousNode);
        }
      }
    },
    onLeaveNode: ({ currentNode }) => {
      // If all node's children has been visited let's remove it from gray set
      // and move it to the black set meaning that all its neighbors are visited.
      blackSet.add(currentNode);
      graySet.delete(currentNode);
    },
    allowTraversal: ({ nextNode }) => {
      // If cycle was detected we must forbid all further traversing since it will
      // cause infinite traversal loop.
      if (cycle) {
        return false;
      }

      // Allow traversal only for the vertices that are not in black set
      // since all black set vertices have been already visited.
      return !blackSet.has(nextNode);
    },
  };

  // Start exploring vertices.
  while (whiteSet.size > 0) {
    // Pick fist vertex to start BFS from.
    const firstWhiteKey = [...whiteSet.values()][0];
    const startVertex = firstWhiteKey;

    // Do Depth First Search.
    tree.depthFirstSearch(startVertex, callbacks);
  }

  return cycle;
}

export function printCycle(cycle: Record<string, string> | null, getLabel: (id: string) => string = identity): string {
  if (!cycle) {
    return "";
  }
  const startNode = Object.keys(cycle)[0];
  let nextNode = cycle[startNode];

  const cycleList = [startNode, nextNode];
  while (cycle[nextNode] !== startNode) {
    nextNode = cycle[nextNode];
    cycleList.push(nextNode);
  }
  cycleList.push(startNode);
  return cycleList.map(getLabel).join(" -> ");
}
