export const NOOP = () => {
  return;
};

export const ASYNC_NOOP = async () => {
  return;
};

export function identity<T>(value: T): T {
  return value;
}
