import { useState } from "react";

/**
 * Re-usable hook to wire popover + target.
 */
export function usePopover(opts: { trigger: "click" | "hover" } = { trigger: "click" }) {
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);

  const handleOpen = (event: React.MouseEvent) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  const open = Boolean(anchorEl);

  return {
    popoverProps: {
      anchorOrigin: {
        vertical: "top",
        horizontal: "center",
      } as const,
      transformOrigin: {
        vertical: "bottom",
        horizontal: "center",
      } as const,
      anchorEl,
      open,
      onClose: handleClose,
      // For hover, need to disable pointer events on the popover so that the target can receive hover events.
      sx: opts.trigger === "click" ? {} : { pointerEvents: "none" },
    },
    targetProps:
      opts.trigger === "click"
        ? {
            onClick: handleOpen,
          }
        : {
            onMouseEnter: handleOpen,
            onMouseLeave: handleClose,
          },
  };
}
