import type { Components, Theme } from "@mui/material/styles";

export function Pagination(theme: Theme): Components<Theme> {
  return {
    MuiPagination: {
      defaultProps: {
        color: "primary",
        shape: "rounded",
        showFirstButton: true,
        showLastButton: true,
        size: "small",
      },
    },
  };
}
