import { Stack } from "@mui/material";

/**
 * Small wrapper for a consistent way to combine helperText and error messages.
 */
export function combinedText(opts: { helperText?: React.ReactNode; error?: string }) {
  if (!opts.error) {
    return opts.helperText;
  }
  if (!opts.helperText) {
    return opts.error;
  }
  return (
    <Stack>
      <span>{opts.helperText}</span>
      <span>{opts.error}</span>
    </Stack>
  );
}
