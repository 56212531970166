import { EmptyState, LoadState } from "@cp/theme";
import { DriveFileRenameOutline, Filter, Movie, Photo } from "@mui/icons-material";
import { Box, Stack, Typography } from "@mui/material";
import { useMatch } from "@tanstack/react-location";
import React from "react";
import { LocationGenerics } from "../../App";
import { Card } from "../../components/Card";
import { useSelectedRecordingId } from "../../components/panels/atoms";
import { Panels } from "../../components/panels/Panels";
import { ScreenCaptureViewer } from "../../components/recordings/ScreenCaptureViewer";
import { useCarrierProjectQuery } from "../../generated/graphql";
import { RecordingDropzone } from "./recordings/RecordingDropzone";
import { RecordingList } from "./recordings/RecordingList";

export const RecordingsPage: React.FC = () => {
  const [selectedRecording, setSelectedRecording] = useSelectedRecordingId();
  const {
    params: { projectId },
  } = useMatch<LocationGenerics>();

  const { data, error, loading, refetch } = useCarrierProjectQuery({
    variables: { id: projectId },
    onCompleted: (data) => {
      !selectedRecording && setSelectedRecording(data.carrierProject.carrierRecordings[0].id);
    },
  });

  if (!data) {
    return <LoadState error={error} loading={loading} />;
  }
  const recordings = data.carrierProject.carrierRecordings;

  return (
    <>
      <Box sx={{ padding: "15px", backgroundColor: "grey.200" }}>
        <Typography>
          Compare the interactive portal screen to the screenshot to verify that all questions and answers were
          accurately captured. You may also rename, re-order and delete screens from the All Screen Captures panel at
          left.
        </Typography>
      </Box>
      <Panels
        id="projects"
        config={[
          {
            title: "Screen Captures",
            content: (
              <Card
                title={
                  <Stack sx={{ justifyContent: "flex-start", flexDirection: "row" }}>
                    <Filter sx={{ marginRight: "10px" }} />
                    All Screen Captures ({recordings.length})
                  </Stack>
                }
                loading={loading}
                sx={{ display: "flex", minHeight: "0px" }}
              >
                <RecordingList recordings={recordings} />
                <RecordingDropzone projectId={projectId} onSuccess={refetch} />
              </Card>
            ),
          },
          {
            title: "Interactive",
            content: (
              <>
                {selectedRecording ? (
                  <Card
                    title={
                      <Stack sx={{ justifyContent: "flex-start", flexDirection: "row" }}>
                        <DriveFileRenameOutline sx={{ marginRight: "10px" }} />
                        Interactive Portal Screen
                      </Stack>
                    }
                    loading={loading}
                    sx={{ display: "flex", flex: 1 }}
                  >
                    <ScreenCaptureViewer key={selectedRecording} recordingId={selectedRecording} mode={"edit"} />
                  </Card>
                ) : (
                  <EmptyState
                    title="No screen capture selected"
                    description="Select a screen capture from the left panel to view it here"
                    icon={<Movie fontSize="large" />}
                  />
                )}
              </>
            ),
          },
          {
            title: "Portal Screenshot",
            content: (
              <>
                {selectedRecording ? (
                  <Card
                    title={
                      <Stack sx={{ justifyContent: "flex-start", flexDirection: "row" }}>
                        <Photo sx={{ marginRight: "10px" }} />
                        Portal Screenshot
                      </Stack>
                    }
                    loading={loading}
                    sx={{ height: "100%" }}
                  >
                    <ScreenCaptureViewer key={selectedRecording} recordingId={selectedRecording} mode={"view"} />
                  </Card>
                ) : (
                  <EmptyState
                    title="No screen capture selected"
                    description="Select a screen capture from the left panel to view it here"
                    icon={<Movie fontSize="large" />}
                  />
                )}
              </>
            ),
          },
        ]}
      />
    </>
  );
};
