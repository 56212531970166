import { useSet } from "@cp/react-hooks";
import { useToaster } from "@cp/theme";
import { Maps } from "@cp/toolkit";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { Link } from "@tanstack/react-location";
import { uniq } from "lodash";
import React, { useCallback, useState } from "react";
import {
  ClobFragment,
  useCarrierProjectsQuery,
  useMappedClobQuestionsOnCurationQuestionQuery,
} from "../../generated/graphql";
import { CopyClipboard } from "../CopyClipboard";
import { BulkRemapClobQuestionsModal } from "./BulkRemapClobQuestionsModal";
import { RelatedMissingQuestionList } from "./RelatedMissingQuestionList";

interface Props {
  curationQuestionId: string;
  curationQuestionKey: string;
  disableEditTools?: boolean;
}

export const MappedClobQuestionList: React.FC<Props> = ({
  curationQuestionId,
  curationQuestionKey,
  disableEditTools = false,
}) => {
  const { toast } = useToaster();
  const [open, setOpen] = useState(false);
  const [selectedClobQuestions, selectionControl] = useSet<string>();
  const { data: projects, loading: projectsLoading } = useCarrierProjectsQuery();
  const { data } = useMappedClobQuestionsOnCurationQuestionQuery({
    variables: { id: curationQuestionId },
    skip: !curationQuestionId,
  });
  const mappedClobQuestions = data?.curationQuestion.mappedClobQuestions ?? [];

  const mappedMissingQuestions = mappedClobQuestions.filter((c) => c.missingQuestion != null);
  const isAcordMapped = mappedClobQuestions.map((q) => q?.acordELabelId).some((e) => !!e);
  const isPdfMapped = mappedClobQuestions.map((q) => q?.pdfQuestion?.id).some((e) => !!e);
  const warningLabel =
    isAcordMapped && isPdfMapped
      ? `ACORD AND PDF MAPPED`
      : isAcordMapped
      ? `ACORD-MAPPED`
      : isPdfMapped
      ? `PDF-MAPPED`
      : "";

  const projectsByClobId = Maps.keyBy(projects?.carrierProjects ?? [], (p) => p.clobId ?? "");
  const projectsByCarrierLOB = Maps.keyBy(
    projects?.carrierProjects ?? [],
    (p) => `${p.carrierSlug}::${p.lineOfBusiness}`
  );

  const ClobLink = useCallback(
    (clob: Pick<ClobFragment, "id" | "name" | "carrierSlug" | "lineOfBusiness">, clobQuestionId: string) => {
      if (projectsLoading) {
        return <CircularProgress />;
      }
      const matchingProject =
        projectsByClobId.get(clob.id) ?? projectsByCarrierLOB.get(`${clob.carrierSlug}::${clob.lineOfBusiness}`);
      return (
        <Link
          to={matchingProject ? `/project/${matchingProject.id}/transcribe` : `/clobs/map/${clob.id}`}
          search={{
            clobSearchQuery: curationQuestionKey,
            navigateToClobQuestion: clobQuestionId,
          }}
          target="_blank"
          rel="noopener noreferrer"
        >
          {clob.name}
        </Link>
      );
    },
    [projectsLoading, projectsByClobId, projectsByCarrierLOB]
  );

  if (mappedClobQuestions.length === 0) {
    return null;
  }

  return (
    <Stack direction="column" gap={1}>
      {(isAcordMapped || isPdfMapped) && (
        <Alert severity="warning">
          <AlertTitle>WARNING: {warningLabel} Question</AlertTitle>
          Editing this question might break PDF generation. Please work with a Product Manager or Curator if you need to
          edit.
        </Alert>
      )}

      <Typography variant="body2" color="text.secondary" fontWeight={700}>
        Mapped Clob Questions
      </Typography>
      <TableContainer component={Paper} sx={{ backgroundColor: "action.hover" }}>
        <Table sx={{ minWidth: 350 }}>
          <TableHead>
            <TableRow>
              <TableCell>
                {!disableEditTools && (
                  <Checkbox
                    checked={selectedClobQuestions.size === mappedClobQuestions.length}
                    onChange={(e) => {
                      e.target.checked
                        ? selectionControl.setMany(mappedClobQuestions.map((q) => q.id))
                        : selectionControl.deleteMany(mappedClobQuestions.map((q) => q.id));
                    }}
                  />
                )}
                Question
              </TableCell>
              <TableCell align="right">LOB</TableCell>
              <TableCell align="right">Options</TableCell>
              <TableCell align="right">File</TableCell>
              <TableCell align="right">Section</TableCell>
              <TableCell align="right">Conditions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {mappedClobQuestions.map((row) => (
              <TableRow key={row.id} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                <TableCell component="th" scope="row">
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    {!disableEditTools && (
                      <Checkbox
                        checked={selectedClobQuestions.has(row.id)}
                        onChange={(e) => {
                          e.target.checked ? selectionControl.set(row.id) : selectionControl.delete(row.id);
                        }}
                      />
                    )}
                    {row.text ?? row.id} {row.isRequired && <span style={{ color: "red" }}>*</span>}
                    {row.pdfQuestion?.id && (
                      <PictureAsPdfIcon fontSize="small" sx={{ marginLeft: "8px" }} color="warning" />
                    )}
                    {row.acordELabelId && (
                      <PictureAsPdfIcon fontSize="small" sx={{ marginLeft: "8px" }} color="error" />
                    )}
                  </Box>
                </TableCell>
                <TableCell>{row.clob?.lineOfBusiness}</TableCell>
                <TableCell sx={{ whiteSpace: "pre" }}>
                  {row.options?.join("\n") || "-"}
                  {row.options && row.options.length > 0 && (
                    <>
                      <br />
                      <CopyClipboard value={row.options.join("\n") ?? ""} />
                    </>
                  )}
                </TableCell>
                <TableCell align="right">{!!row.clob && ClobLink(row.clob, row.id)}</TableCell>
                <TableCell sx={{ whiteSpace: "pre" }}>{row.section.join(" \n> ")}</TableCell>
                <TableCell align="right">{row.conditions}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {!disableEditTools && (
        <>
          <Button
            size="small"
            onClick={() => {
              // combine, sort, and dedupe the options
              const options = uniq(mappedClobQuestions.flatMap((question) => question.options ?? []).sort());
              toast(`Copied to clipboard`);
              navigator.clipboard.writeText(options.join("\n"));
            }}
          >
            Copy all CLOB options to clipboard
          </Button>
          <Button size="small" onClick={() => selectedClobQuestions.size > 0 && setOpen(true)}>
            Re-map selected CLOB questions
          </Button>
        </>
      )}
      {mappedMissingQuestions.length > 0 && (
        <RelatedMissingQuestionList mappedMissingQuestions={mappedMissingQuestions} />
      )}
      <BulkRemapClobQuestionsModal
        clobQuestionIds={[...selectedClobQuestions]}
        curationQuestionIdForUndo={curationQuestionId}
        open={open}
        setOpen={setOpen}
      />
    </Stack>
  );
};
