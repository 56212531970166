import * as Apollo from "@apollo/client";
import { gql } from "@apollo/client";
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A ApplicationId as a UUID */
  ApplicationId: string & { __scalar: "ApplicationId" };
  /** A CurationQuestionId as a UUID */
  CurationQuestionId: any;
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  DateTime: any;
  /** An arbitrary precision decimal value. Often used for dollar amounts */
  Decimal: any;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: string | boolean | number | Record<string, any> | string[] | boolean[] | number[];
  /** A SubmissionDocumentId as a UUID */
  SubmissionDocumentId: any;
  /** A SubmissionId as a UUID */
  SubmissionId: any;
  /** A field whose value is a generic Universally Unique Identifier: https://en.wikipedia.org/wiki/Universally_unique_identifier. */
  UUID: string & { __scalar: "UUID" };
};

export enum ApplicationComponentType {
  BusinessClassification = "BusinessClassification",
  Checkbox = "Checkbox",
  CheckboxGroup = "CheckboxGroup",
  ContactSelect = "ContactSelect",
  Currency = "Currency",
  Date = "Date",
  DynamicBusinessClassification = "DynamicBusinessClassification",
  Email = "Email",
  Fein = "Fein",
  FileUpload = "FileUpload",
  FileUploadDrawerOpener = "FileUploadDrawerOpener",
  Hidden = "Hidden",
  LocationSelect = "LocationSelect",
  Number = "Number",
  Percentage = "Percentage",
  Phone = "Phone",
  Radio = "Radio",
  RadioBoolean = "RadioBoolean",
  Select = "Select",
  StateSelect = "StateSelect",
  Text = "Text",
  TextArea = "TextArea",
  WorkersCompClassification = "WorkersCompClassification",
  Year = "Year",
  Zip = "Zip",
}

export enum ApplicationConditionBooleanOperator {
  IsDefinedAndFalse = "IS_DEFINED_AND_FALSE",
  IsFalse = "IS_FALSE",
  IsTrue = "IS_TRUE",
}

export enum ApplicationConditionNumberOperator {
  Equals = "EQUALS",
  GreaterThan = "GREATER_THAN",
  GreaterThanOrEqualTo = "GREATER_THAN_OR_EQUAL_TO",
  LessThan = "LESS_THAN",
  LessThanOrEqualTo = "LESS_THAN_OR_EQUAL_TO",
}

export enum ApplicationConditionStringOperator {
  DefinedAndNotEqual = "DEFINED_AND_NOT_EQUAL",
  DoesNotEqual = "DOES_NOT_EQUAL",
  Equals = "EQUALS",
}

export enum ApplicationConditionStringSetOperator {
  IncludedIn = "INCLUDED_IN",
  NotIncludedIn = "NOT_INCLUDED_IN",
}

export enum EntityType {
  AdditionalInsured = "AdditionalInsured",
  AdditionalInterest = "AdditionalInterest",
  Aircraft = "Aircraft",
  Boat = "Boat",
  Building = "Building",
  DirectorsAndOfficers = "DirectorsAndOfficers",
  Driver = "Driver",
  Equipment = "Equipment",
  Hazard = "Hazard",
  Item = "Item",
  Job = "Job",
  Location = "Location",
  Loss = "Loss",
  Policy = "Policy",
  PriorPolicy = "PriorPolicy",
  ProductsAndOrCompletedOperations = "ProductsAndOrCompletedOperations",
  SpecificIndividual = "SpecificIndividual",
  Terminal = "Terminal",
  Trailer = "Trailer",
  Vehicle = "Vehicle",
  WorkersCompRateClass = "WorkersCompRateClass",
}

export type FindApplicationClientEntitiesInput = {
  applicationId: Scalars["String"];
  entityType: EntityType;
};

export type FindEntityInput = {
  clientId?: InputMaybe<Scalars["String"]>;
  id: Scalars["UUID"];
};

export enum FormContainerLayout {
  List = "LIST",
  Locked = "LOCKED",
  Modal = "MODAL",
  Paged = "PAGED",
  Table = "TABLE",
}

export enum FormContainerType {
  Entity = "ENTITY",
  Field = "FIELD",
}

export type ApplicationConditionStringFragment = {
  __typename?: "ApplicationConditionString";
  subject: string;
  stringValue: string;
  stringOperator: ApplicationConditionStringOperator;
};

export type ApplicationConditionStringSetFragment = {
  __typename?: "ApplicationConditionStringSet";
  subject: string;
  stringSetValue: Array<string>;
  stringSetOperator: ApplicationConditionStringSetOperator;
};

export type ApplicationConditionBooleanFragment = {
  __typename?: "ApplicationConditionBoolean";
  subject: string;
  booleanOperator: ApplicationConditionBooleanOperator;
};

export type ApplicationConditionNumberFragment = {
  __typename?: "ApplicationConditionNumber";
  subject: string;
  numberOperator: ApplicationConditionNumberOperator;
  numberValue: string;
};

type LeafApplicationCondition_ApplicationConditionBoolean_Fragment = {
  __typename: "ApplicationConditionBoolean";
  subject: string;
  booleanOperator: ApplicationConditionBooleanOperator;
};

type LeafApplicationCondition_ApplicationConditionMatchAll_Fragment = { __typename: "ApplicationConditionMatchAll" };

type LeafApplicationCondition_ApplicationConditionMatchAny_Fragment = { __typename: "ApplicationConditionMatchAny" };

type LeafApplicationCondition_ApplicationConditionNumber_Fragment = {
  __typename: "ApplicationConditionNumber";
  subject: string;
  numberOperator: ApplicationConditionNumberOperator;
  numberValue: string;
};

type LeafApplicationCondition_ApplicationConditionString_Fragment = {
  __typename: "ApplicationConditionString";
  subject: string;
  stringValue: string;
  stringOperator: ApplicationConditionStringOperator;
};

type LeafApplicationCondition_ApplicationConditionStringSet_Fragment = {
  __typename: "ApplicationConditionStringSet";
  subject: string;
  stringSetValue: Array<string>;
  stringSetOperator: ApplicationConditionStringSetOperator;
};

export type LeafApplicationConditionFragment =
  | LeafApplicationCondition_ApplicationConditionBoolean_Fragment
  | LeafApplicationCondition_ApplicationConditionMatchAll_Fragment
  | LeafApplicationCondition_ApplicationConditionMatchAny_Fragment
  | LeafApplicationCondition_ApplicationConditionNumber_Fragment
  | LeafApplicationCondition_ApplicationConditionString_Fragment
  | LeafApplicationCondition_ApplicationConditionStringSet_Fragment;

export type ApplicationConditionMatchAnyFragment = {
  __typename?: "ApplicationConditionMatchAny";
  matchAnyConditions: Array<
    | {
        __typename: "ApplicationConditionBoolean";
        subject: string;
        booleanOperator: ApplicationConditionBooleanOperator;
      }
    | { __typename: "ApplicationConditionMatchAll" }
    | { __typename: "ApplicationConditionMatchAny" }
    | {
        __typename: "ApplicationConditionNumber";
        subject: string;
        numberOperator: ApplicationConditionNumberOperator;
        numberValue: string;
      }
    | {
        __typename: "ApplicationConditionString";
        subject: string;
        stringValue: string;
        stringOperator: ApplicationConditionStringOperator;
      }
    | {
        __typename: "ApplicationConditionStringSet";
        subject: string;
        stringSetValue: Array<string>;
        stringSetOperator: ApplicationConditionStringSetOperator;
      }
  >;
};

export type ApplicationConditionMatchAllFragment = {
  __typename?: "ApplicationConditionMatchAll";
  matchAllConditions: Array<
    | {
        __typename: "ApplicationConditionBoolean";
        subject: string;
        booleanOperator: ApplicationConditionBooleanOperator;
      }
    | { __typename: "ApplicationConditionMatchAll" }
    | { __typename: "ApplicationConditionMatchAny" }
    | {
        __typename: "ApplicationConditionNumber";
        subject: string;
        numberOperator: ApplicationConditionNumberOperator;
        numberValue: string;
      }
    | {
        __typename: "ApplicationConditionString";
        subject: string;
        stringValue: string;
        stringOperator: ApplicationConditionStringOperator;
      }
    | {
        __typename: "ApplicationConditionStringSet";
        subject: string;
        stringSetValue: Array<string>;
        stringSetOperator: ApplicationConditionStringSetOperator;
      }
  >;
};

type ApplicationCondition_ApplicationConditionBoolean_Fragment = {
  __typename: "ApplicationConditionBoolean";
  subject: string;
  booleanOperator: ApplicationConditionBooleanOperator;
};

type ApplicationCondition_ApplicationConditionMatchAll_Fragment = {
  __typename: "ApplicationConditionMatchAll";
  matchAllConditions: Array<
    | {
        __typename: "ApplicationConditionBoolean";
        subject: string;
        booleanOperator: ApplicationConditionBooleanOperator;
      }
    | { __typename: "ApplicationConditionMatchAll" }
    | { __typename: "ApplicationConditionMatchAny" }
    | {
        __typename: "ApplicationConditionNumber";
        subject: string;
        numberOperator: ApplicationConditionNumberOperator;
        numberValue: string;
      }
    | {
        __typename: "ApplicationConditionString";
        subject: string;
        stringValue: string;
        stringOperator: ApplicationConditionStringOperator;
      }
    | {
        __typename: "ApplicationConditionStringSet";
        subject: string;
        stringSetValue: Array<string>;
        stringSetOperator: ApplicationConditionStringSetOperator;
      }
  >;
};

type ApplicationCondition_ApplicationConditionMatchAny_Fragment = {
  __typename: "ApplicationConditionMatchAny";
  matchAnyConditions: Array<
    | {
        __typename: "ApplicationConditionBoolean";
        subject: string;
        booleanOperator: ApplicationConditionBooleanOperator;
      }
    | { __typename: "ApplicationConditionMatchAll" }
    | { __typename: "ApplicationConditionMatchAny" }
    | {
        __typename: "ApplicationConditionNumber";
        subject: string;
        numberOperator: ApplicationConditionNumberOperator;
        numberValue: string;
      }
    | {
        __typename: "ApplicationConditionString";
        subject: string;
        stringValue: string;
        stringOperator: ApplicationConditionStringOperator;
      }
    | {
        __typename: "ApplicationConditionStringSet";
        subject: string;
        stringSetValue: Array<string>;
        stringSetOperator: ApplicationConditionStringSetOperator;
      }
  >;
};

type ApplicationCondition_ApplicationConditionNumber_Fragment = {
  __typename: "ApplicationConditionNumber";
  subject: string;
  numberOperator: ApplicationConditionNumberOperator;
  numberValue: string;
};

type ApplicationCondition_ApplicationConditionString_Fragment = {
  __typename: "ApplicationConditionString";
  subject: string;
  stringValue: string;
  stringOperator: ApplicationConditionStringOperator;
};

type ApplicationCondition_ApplicationConditionStringSet_Fragment = {
  __typename: "ApplicationConditionStringSet";
  subject: string;
  stringSetValue: Array<string>;
  stringSetOperator: ApplicationConditionStringSetOperator;
};

export type ApplicationConditionFragment =
  | ApplicationCondition_ApplicationConditionBoolean_Fragment
  | ApplicationCondition_ApplicationConditionMatchAll_Fragment
  | ApplicationCondition_ApplicationConditionMatchAny_Fragment
  | ApplicationCondition_ApplicationConditionNumber_Fragment
  | ApplicationCondition_ApplicationConditionString_Fragment
  | ApplicationCondition_ApplicationConditionStringSet_Fragment;

export type ContactFragment = {
  __typename?: "Contact";
  id: string;
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
  title?: string | null;
};

export type WorkersCompClassFragment = {
  __typename?: "WorkersCompClass";
  id: string;
  ncciCode?: string | null;
  isoCglCode?: string | null;
  naicsCode?: string | null;
  ncciDescription?: string | null;
  generalDescription?: string | null;
  isoBopDescription?: string | null;
  isoDescription?: string | null;
  naicsDescription?: string | null;
  caWcCode?: string | null;
  deWcCode?: string | null;
  miWcCode?: string | null;
  njWcCode?: string | null;
  nyWcCode?: string | null;
  paWcCode?: string | null;
  txWcCode?: string | null;
};

export type FormFragment = {
  __typename?: "Form";
  id: string;
  containers: Array<{
    __typename?: "FormContainer";
    id: string;
    helperText?: string | null;
    label: string;
    type: FormContainerType;
    layout?: FormContainerLayout | null;
    entityType?: EntityType | null;
    visibility: Array<
      | {
          __typename: "ApplicationConditionBoolean";
          subject: string;
          booleanOperator: ApplicationConditionBooleanOperator;
        }
      | {
          __typename: "ApplicationConditionMatchAll";
          matchAllConditions: Array<
            | {
                __typename: "ApplicationConditionBoolean";
                subject: string;
                booleanOperator: ApplicationConditionBooleanOperator;
              }
            | { __typename: "ApplicationConditionMatchAll" }
            | { __typename: "ApplicationConditionMatchAny" }
            | {
                __typename: "ApplicationConditionNumber";
                subject: string;
                numberOperator: ApplicationConditionNumberOperator;
                numberValue: string;
              }
            | {
                __typename: "ApplicationConditionString";
                subject: string;
                stringValue: string;
                stringOperator: ApplicationConditionStringOperator;
              }
            | {
                __typename: "ApplicationConditionStringSet";
                subject: string;
                stringSetValue: Array<string>;
                stringSetOperator: ApplicationConditionStringSetOperator;
              }
          >;
        }
      | {
          __typename: "ApplicationConditionMatchAny";
          matchAnyConditions: Array<
            | {
                __typename: "ApplicationConditionBoolean";
                subject: string;
                booleanOperator: ApplicationConditionBooleanOperator;
              }
            | { __typename: "ApplicationConditionMatchAll" }
            | { __typename: "ApplicationConditionMatchAny" }
            | {
                __typename: "ApplicationConditionNumber";
                subject: string;
                numberOperator: ApplicationConditionNumberOperator;
                numberValue: string;
              }
            | {
                __typename: "ApplicationConditionString";
                subject: string;
                stringValue: string;
                stringOperator: ApplicationConditionStringOperator;
              }
            | {
                __typename: "ApplicationConditionStringSet";
                subject: string;
                stringSetValue: Array<string>;
                stringSetOperator: ApplicationConditionStringSetOperator;
              }
          >;
        }
      | {
          __typename: "ApplicationConditionNumber";
          subject: string;
          numberOperator: ApplicationConditionNumberOperator;
          numberValue: string;
        }
      | {
          __typename: "ApplicationConditionString";
          subject: string;
          stringValue: string;
          stringOperator: ApplicationConditionStringOperator;
        }
      | {
          __typename: "ApplicationConditionStringSet";
          subject: string;
          stringSetValue: Array<string>;
          stringSetOperator: ApplicationConditionStringSetOperator;
        }
    >;
    tableConfig?: {
      __typename?: "TableSectionConfig";
      canAddRemove: boolean;
      defaultValues: string;
      fixedColumns: number;
    } | null;
    pivotSubsectionsConfig?: { __typename?: "PivotSubsectionsConfig"; enabled: boolean } | null;
  }>;
  questions: Array<{
    __typename?: "FormQuestion";
    id: string;
    componentType: ApplicationComponentType;
    defaultValue?: string | null;
    helperText?: string | null;
    infoText?: string | null;
    isRequired: boolean;
    label: string;
    options: Array<{ __typename?: "FormOption"; label: string; value: string }>;
    visibility: Array<
      | {
          __typename: "ApplicationConditionBoolean";
          subject: string;
          booleanOperator: ApplicationConditionBooleanOperator;
        }
      | {
          __typename: "ApplicationConditionMatchAll";
          matchAllConditions: Array<
            | {
                __typename: "ApplicationConditionBoolean";
                subject: string;
                booleanOperator: ApplicationConditionBooleanOperator;
              }
            | { __typename: "ApplicationConditionMatchAll" }
            | { __typename: "ApplicationConditionMatchAny" }
            | {
                __typename: "ApplicationConditionNumber";
                subject: string;
                numberOperator: ApplicationConditionNumberOperator;
                numberValue: string;
              }
            | {
                __typename: "ApplicationConditionString";
                subject: string;
                stringValue: string;
                stringOperator: ApplicationConditionStringOperator;
              }
            | {
                __typename: "ApplicationConditionStringSet";
                subject: string;
                stringSetValue: Array<string>;
                stringSetOperator: ApplicationConditionStringSetOperator;
              }
          >;
        }
      | {
          __typename: "ApplicationConditionMatchAny";
          matchAnyConditions: Array<
            | {
                __typename: "ApplicationConditionBoolean";
                subject: string;
                booleanOperator: ApplicationConditionBooleanOperator;
              }
            | { __typename: "ApplicationConditionMatchAll" }
            | { __typename: "ApplicationConditionMatchAny" }
            | {
                __typename: "ApplicationConditionNumber";
                subject: string;
                numberOperator: ApplicationConditionNumberOperator;
                numberValue: string;
              }
            | {
                __typename: "ApplicationConditionString";
                subject: string;
                stringValue: string;
                stringOperator: ApplicationConditionStringOperator;
              }
            | {
                __typename: "ApplicationConditionStringSet";
                subject: string;
                stringSetValue: Array<string>;
                stringSetOperator: ApplicationConditionStringSetOperator;
              }
          >;
        }
      | {
          __typename: "ApplicationConditionNumber";
          subject: string;
          numberOperator: ApplicationConditionNumberOperator;
          numberValue: string;
        }
      | {
          __typename: "ApplicationConditionString";
          subject: string;
          stringValue: string;
          stringOperator: ApplicationConditionStringOperator;
        }
      | {
          __typename: "ApplicationConditionStringSet";
          subject: string;
          stringSetValue: Array<string>;
          stringSetOperator: ApplicationConditionStringSetOperator;
        }
    >;
  }>;
  tree: {
    __typename?: "FormTree";
    rootIds: Array<string>;
    items: Array<{ __typename?: "FormTreeItem"; id: string; children: Array<string> }>;
  };
};

export type FormContainerFragment = {
  __typename?: "FormContainer";
  id: string;
  helperText?: string | null;
  label: string;
  type: FormContainerType;
  layout?: FormContainerLayout | null;
  entityType?: EntityType | null;
  visibility: Array<
    | {
        __typename: "ApplicationConditionBoolean";
        subject: string;
        booleanOperator: ApplicationConditionBooleanOperator;
      }
    | {
        __typename: "ApplicationConditionMatchAll";
        matchAllConditions: Array<
          | {
              __typename: "ApplicationConditionBoolean";
              subject: string;
              booleanOperator: ApplicationConditionBooleanOperator;
            }
          | { __typename: "ApplicationConditionMatchAll" }
          | { __typename: "ApplicationConditionMatchAny" }
          | {
              __typename: "ApplicationConditionNumber";
              subject: string;
              numberOperator: ApplicationConditionNumberOperator;
              numberValue: string;
            }
          | {
              __typename: "ApplicationConditionString";
              subject: string;
              stringValue: string;
              stringOperator: ApplicationConditionStringOperator;
            }
          | {
              __typename: "ApplicationConditionStringSet";
              subject: string;
              stringSetValue: Array<string>;
              stringSetOperator: ApplicationConditionStringSetOperator;
            }
        >;
      }
    | {
        __typename: "ApplicationConditionMatchAny";
        matchAnyConditions: Array<
          | {
              __typename: "ApplicationConditionBoolean";
              subject: string;
              booleanOperator: ApplicationConditionBooleanOperator;
            }
          | { __typename: "ApplicationConditionMatchAll" }
          | { __typename: "ApplicationConditionMatchAny" }
          | {
              __typename: "ApplicationConditionNumber";
              subject: string;
              numberOperator: ApplicationConditionNumberOperator;
              numberValue: string;
            }
          | {
              __typename: "ApplicationConditionString";
              subject: string;
              stringValue: string;
              stringOperator: ApplicationConditionStringOperator;
            }
          | {
              __typename: "ApplicationConditionStringSet";
              subject: string;
              stringSetValue: Array<string>;
              stringSetOperator: ApplicationConditionStringSetOperator;
            }
        >;
      }
    | {
        __typename: "ApplicationConditionNumber";
        subject: string;
        numberOperator: ApplicationConditionNumberOperator;
        numberValue: string;
      }
    | {
        __typename: "ApplicationConditionString";
        subject: string;
        stringValue: string;
        stringOperator: ApplicationConditionStringOperator;
      }
    | {
        __typename: "ApplicationConditionStringSet";
        subject: string;
        stringSetValue: Array<string>;
        stringSetOperator: ApplicationConditionStringSetOperator;
      }
  >;
  tableConfig?: {
    __typename?: "TableSectionConfig";
    canAddRemove: boolean;
    defaultValues: string;
    fixedColumns: number;
  } | null;
  pivotSubsectionsConfig?: { __typename?: "PivotSubsectionsConfig"; enabled: boolean } | null;
};

export type FormDataItemFragment = {
  __typename?: "FormDataItem";
  key: string;
  value?: string | null;
  rawValue?: string | boolean | number | Record<string, any> | string[] | boolean[] | number[] | null;
  row?: number | null;
};

export type FormEntityFragment = {
  __typename?: "FormEntity";
  id: string;
  type: EntityType;
  label: string;
  items: Array<{
    __typename?: "FormDataItem";
    key: string;
    value?: string | null;
    rawValue?: string | boolean | number | Record<string, any> | string[] | boolean[] | number[] | null;
    row?: number | null;
  }>;
};

export type FormQuestionFragment = {
  __typename?: "FormQuestion";
  id: string;
  componentType: ApplicationComponentType;
  defaultValue?: string | null;
  helperText?: string | null;
  infoText?: string | null;
  isRequired: boolean;
  label: string;
  options: Array<{ __typename?: "FormOption"; label: string; value: string }>;
  visibility: Array<
    | {
        __typename: "ApplicationConditionBoolean";
        subject: string;
        booleanOperator: ApplicationConditionBooleanOperator;
      }
    | {
        __typename: "ApplicationConditionMatchAll";
        matchAllConditions: Array<
          | {
              __typename: "ApplicationConditionBoolean";
              subject: string;
              booleanOperator: ApplicationConditionBooleanOperator;
            }
          | { __typename: "ApplicationConditionMatchAll" }
          | { __typename: "ApplicationConditionMatchAny" }
          | {
              __typename: "ApplicationConditionNumber";
              subject: string;
              numberOperator: ApplicationConditionNumberOperator;
              numberValue: string;
            }
          | {
              __typename: "ApplicationConditionString";
              subject: string;
              stringValue: string;
              stringOperator: ApplicationConditionStringOperator;
            }
          | {
              __typename: "ApplicationConditionStringSet";
              subject: string;
              stringSetValue: Array<string>;
              stringSetOperator: ApplicationConditionStringSetOperator;
            }
        >;
      }
    | {
        __typename: "ApplicationConditionMatchAny";
        matchAnyConditions: Array<
          | {
              __typename: "ApplicationConditionBoolean";
              subject: string;
              booleanOperator: ApplicationConditionBooleanOperator;
            }
          | { __typename: "ApplicationConditionMatchAll" }
          | { __typename: "ApplicationConditionMatchAny" }
          | {
              __typename: "ApplicationConditionNumber";
              subject: string;
              numberOperator: ApplicationConditionNumberOperator;
              numberValue: string;
            }
          | {
              __typename: "ApplicationConditionString";
              subject: string;
              stringValue: string;
              stringOperator: ApplicationConditionStringOperator;
            }
          | {
              __typename: "ApplicationConditionStringSet";
              subject: string;
              stringSetValue: Array<string>;
              stringSetOperator: ApplicationConditionStringSetOperator;
            }
        >;
      }
    | {
        __typename: "ApplicationConditionNumber";
        subject: string;
        numberOperator: ApplicationConditionNumberOperator;
        numberValue: string;
      }
    | {
        __typename: "ApplicationConditionString";
        subject: string;
        stringValue: string;
        stringOperator: ApplicationConditionStringOperator;
      }
    | {
        __typename: "ApplicationConditionStringSet";
        subject: string;
        stringSetValue: Array<string>;
        stringSetOperator: ApplicationConditionStringSetOperator;
      }
  >;
};

export type FormTreeItemFragment = { __typename?: "FormTreeItem"; id: string; children: Array<string> };

export type ApplicationClientEntitiesQueryVariables = Exact<{
  input: FindApplicationClientEntitiesInput;
}>;

export type ApplicationClientEntitiesQuery = {
  __typename?: "Query";
  applicationClientEntities: Array<{ __typename: "Entity"; id: string & { __scalar: "UUID" }; label: string }>;
};

export type ApplicationContactsQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type ApplicationContactsQuery = {
  __typename?: "Query";
  applicationContacts: Array<{
    __typename?: "Contact";
    id: string;
    firstName: string;
    lastName: string;
    phone: string;
    email: string;
    title?: string | null;
  }>;
};

export type EntityQueryVariables = Exact<{
  input: FindEntityInput;
}>;

export type EntityQuery = {
  __typename?: "Query";
  entity: { __typename: "Entity"; id: string & { __scalar: "UUID" }; label: string };
};

export type WorkersCompClassSearchQueryVariables = Exact<{
  queryString: Scalars["String"];
  stateCode?: InputMaybe<Scalars["String"]>;
}>;

export type WorkersCompClassSearchQuery = {
  __typename?: "Query";
  searchWorkersCompClassCodes: Array<{
    __typename?: "WorkersCompClass";
    id: string;
    ncciCode?: string | null;
    isoCglCode?: string | null;
    naicsCode?: string | null;
    ncciDescription?: string | null;
    generalDescription?: string | null;
    isoBopDescription?: string | null;
    isoDescription?: string | null;
    naicsDescription?: string | null;
    caWcCode?: string | null;
    deWcCode?: string | null;
    miWcCode?: string | null;
    njWcCode?: string | null;
    nyWcCode?: string | null;
    paWcCode?: string | null;
    txWcCode?: string | null;
  }>;
};

export const ContactFragmentDoc = gql`
  fragment Contact on Contact {
    id
    firstName
    lastName
    phone
    email
    title
  }
`;
export const WorkersCompClassFragmentDoc = gql`
  fragment WorkersCompClass on WorkersCompClass {
    id
    ncciCode
    isoCglCode
    naicsCode
    ncciDescription
    generalDescription
    isoBopDescription
    isoDescription
    naicsDescription
    caWcCode
    deWcCode
    miWcCode
    njWcCode
    nyWcCode
    paWcCode
    txWcCode
  }
`;
export const ApplicationConditionStringFragmentDoc = gql`
  fragment ApplicationConditionString on ApplicationConditionString {
    subject
    stringValue
    stringOperator
  }
`;
export const ApplicationConditionStringSetFragmentDoc = gql`
  fragment ApplicationConditionStringSet on ApplicationConditionStringSet {
    subject
    stringSetValue
    stringSetOperator
  }
`;
export const ApplicationConditionBooleanFragmentDoc = gql`
  fragment ApplicationConditionBoolean on ApplicationConditionBoolean {
    subject
    booleanOperator
  }
`;
export const ApplicationConditionNumberFragmentDoc = gql`
  fragment ApplicationConditionNumber on ApplicationConditionNumber {
    subject
    numberOperator
    numberValue
  }
`;
export const LeafApplicationConditionFragmentDoc = gql`
  fragment LeafApplicationCondition on ApplicationCondition {
    __typename
    ...ApplicationConditionString
    ...ApplicationConditionStringSet
    ...ApplicationConditionBoolean
    ...ApplicationConditionNumber
  }
`;
export const ApplicationConditionMatchAnyFragmentDoc = gql`
  fragment ApplicationConditionMatchAny on ApplicationConditionMatchAny {
    matchAnyConditions {
      ...LeafApplicationCondition
    }
  }
`;
export const ApplicationConditionMatchAllFragmentDoc = gql`
  fragment ApplicationConditionMatchAll on ApplicationConditionMatchAll {
    matchAllConditions {
      ...LeafApplicationCondition
    }
  }
`;
export const ApplicationConditionFragmentDoc = gql`
  fragment ApplicationCondition on ApplicationCondition {
    ...LeafApplicationCondition
    ...ApplicationConditionMatchAny
    ...ApplicationConditionMatchAll
  }
`;
export const FormContainerFragmentDoc = gql`
  fragment FormContainer on FormContainer {
    id
    helperText
    label
    type
    layout
    visibility {
      ...ApplicationCondition
    }
    tableConfig {
      canAddRemove
      defaultValues
      fixedColumns
    }
    pivotSubsectionsConfig {
      enabled
    }
    entityType
  }
`;
export const FormQuestionFragmentDoc = gql`
  fragment FormQuestion on FormQuestion {
    id
    componentType
    defaultValue
    helperText
    infoText
    isRequired
    label
    options {
      label
      value
    }
    visibility {
      ...ApplicationCondition
    }
  }
`;
export const FormTreeItemFragmentDoc = gql`
  fragment FormTreeItem on FormTreeItem {
    id
    children
  }
`;
export const FormFragmentDoc = gql`
  fragment Form on Form {
    id
    containers {
      ...FormContainer
    }
    questions {
      ...FormQuestion
    }
    tree {
      rootIds
      items {
        ...FormTreeItem
      }
    }
  }
`;
export const FormDataItemFragmentDoc = gql`
  fragment FormDataItem on FormDataItem {
    key
    value
    rawValue
    row
  }
`;
export const FormEntityFragmentDoc = gql`
  fragment FormEntity on FormEntity {
    id
    type
    items {
      ...FormDataItem
    }
    label
  }
`;
export const ApplicationClientEntitiesDocument = gql`
  query ApplicationClientEntities($input: FindApplicationClientEntitiesInput!) {
    applicationClientEntities(input: $input) {
      __typename
      id
      label
    }
  }
`;

/**
 * __useApplicationClientEntitiesQuery__
 *
 * To run a query within a React component, call `useApplicationClientEntitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useApplicationClientEntitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useApplicationClientEntitiesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useApplicationClientEntitiesQuery(
  baseOptions: Apollo.QueryHookOptions<ApplicationClientEntitiesQuery, ApplicationClientEntitiesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ApplicationClientEntitiesQuery, ApplicationClientEntitiesQueryVariables>(
    ApplicationClientEntitiesDocument,
    options
  );
}
export function useApplicationClientEntitiesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ApplicationClientEntitiesQuery, ApplicationClientEntitiesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ApplicationClientEntitiesQuery, ApplicationClientEntitiesQueryVariables>(
    ApplicationClientEntitiesDocument,
    options
  );
}
export type ApplicationClientEntitiesQueryHookResult = ReturnType<typeof useApplicationClientEntitiesQuery>;
export type ApplicationClientEntitiesLazyQueryHookResult = ReturnType<typeof useApplicationClientEntitiesLazyQuery>;
export type ApplicationClientEntitiesQueryResult = Apollo.QueryResult<
  ApplicationClientEntitiesQuery,
  ApplicationClientEntitiesQueryVariables
>;
export const ApplicationContactsDocument = gql`
  query ApplicationContacts($id: ID!) {
    applicationContacts(id: $id) {
      ...Contact
    }
  }
  ${ContactFragmentDoc}
`;

/**
 * __useApplicationContactsQuery__
 *
 * To run a query within a React component, call `useApplicationContactsQuery` and pass it any options that fit your needs.
 * When your component renders, `useApplicationContactsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useApplicationContactsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useApplicationContactsQuery(
  baseOptions: Apollo.QueryHookOptions<ApplicationContactsQuery, ApplicationContactsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ApplicationContactsQuery, ApplicationContactsQueryVariables>(
    ApplicationContactsDocument,
    options
  );
}
export function useApplicationContactsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ApplicationContactsQuery, ApplicationContactsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ApplicationContactsQuery, ApplicationContactsQueryVariables>(
    ApplicationContactsDocument,
    options
  );
}
export type ApplicationContactsQueryHookResult = ReturnType<typeof useApplicationContactsQuery>;
export type ApplicationContactsLazyQueryHookResult = ReturnType<typeof useApplicationContactsLazyQuery>;
export type ApplicationContactsQueryResult = Apollo.QueryResult<
  ApplicationContactsQuery,
  ApplicationContactsQueryVariables
>;
export const EntityDocument = gql`
  query Entity($input: FindEntityInput!) {
    entity(input: $input) {
      __typename
      id
      label
    }
  }
`;

/**
 * __useEntityQuery__
 *
 * To run a query within a React component, call `useEntityQuery` and pass it any options that fit your needs.
 * When your component renders, `useEntityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEntityQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEntityQuery(baseOptions: Apollo.QueryHookOptions<EntityQuery, EntityQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<EntityQuery, EntityQueryVariables>(EntityDocument, options);
}
export function useEntityLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EntityQuery, EntityQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<EntityQuery, EntityQueryVariables>(EntityDocument, options);
}
export type EntityQueryHookResult = ReturnType<typeof useEntityQuery>;
export type EntityLazyQueryHookResult = ReturnType<typeof useEntityLazyQuery>;
export type EntityQueryResult = Apollo.QueryResult<EntityQuery, EntityQueryVariables>;
export const WorkersCompClassSearchDocument = gql`
  query WorkersCompClassSearch($queryString: String!, $stateCode: String = "") {
    searchWorkersCompClassCodes(queryString: $queryString, stateCode: $stateCode) {
      ...WorkersCompClass
    }
  }
  ${WorkersCompClassFragmentDoc}
`;

/**
 * __useWorkersCompClassSearchQuery__
 *
 * To run a query within a React component, call `useWorkersCompClassSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useWorkersCompClassSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWorkersCompClassSearchQuery({
 *   variables: {
 *      queryString: // value for 'queryString'
 *      stateCode: // value for 'stateCode'
 *   },
 * });
 */
export function useWorkersCompClassSearchQuery(
  baseOptions: Apollo.QueryHookOptions<WorkersCompClassSearchQuery, WorkersCompClassSearchQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<WorkersCompClassSearchQuery, WorkersCompClassSearchQueryVariables>(
    WorkersCompClassSearchDocument,
    options
  );
}
export function useWorkersCompClassSearchLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<WorkersCompClassSearchQuery, WorkersCompClassSearchQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<WorkersCompClassSearchQuery, WorkersCompClassSearchQueryVariables>(
    WorkersCompClassSearchDocument,
    options
  );
}
export type WorkersCompClassSearchQueryHookResult = ReturnType<typeof useWorkersCompClassSearchQuery>;
export type WorkersCompClassSearchLazyQueryHookResult = ReturnType<typeof useWorkersCompClassSearchLazyQuery>;
export type WorkersCompClassSearchQueryResult = Apollo.QueryResult<
  WorkersCompClassSearchQuery,
  WorkersCompClassSearchQueryVariables
>;
