import styled from "@emotion/styled";
import { Box, Divider, Stack, Typography } from "@mui/material";
import { RouteTabs, RouteTabsProps } from "./RouteTabs";

const Root = styled(Stack)({
  height: "100%",
  width: "100%",
  flex: 1,
});

interface Props {
  title?: React.ReactNode;
  children: React.ReactNode;
  tabs?: RouteTabsProps["tabs"];
  actions?: React.ReactNode;
  noPadding?: boolean;
}

export const PageTemplate: React.FC<Props> = ({ title, children, tabs, actions, noPadding }) => {
  return (
    <Root>
      <Typography variant="h3" px={4} my={2}>
        {title}
      </Typography>
      <Divider />
      <Stack
        alignItems={"center"}
        direction={"row"}
        justifyContent={"space-between"}
        px={4}
        sx={{ borderBottom: "1px solid", borderColor: "divider" }}
      >
        {tabs && (
          <Box mt={1}>
            <RouteTabs tabs={tabs} />
          </Box>
        )}
        {actions}
      </Stack>
      <Stack flex={1} p={noPadding ? 0 : 4} overflow="auto">
        {children}
      </Stack>
    </Root>
  );
};
