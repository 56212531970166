import { CloudUpload } from "@mui/icons-material/";
import { Box, Typography } from "@mui/material";
import Dropzone, { Accept } from "react-dropzone";

interface FileDropZoneProps {
  accept: Accept;
  multiple: boolean;
  onDrop: (files: File[]) => void;
}

export function FileDropzone({ accept, onDrop, multiple }: FileDropZoneProps) {
  return (
    <Dropzone accept={accept} onDrop={(acceptedFiles) => onDrop(acceptedFiles)} multiple={multiple}>
      {({ getRootProps, getInputProps }) => (
        <Box sx={boxSx} {...getRootProps()}>
          <input {...getInputProps()} />
          <CloudUpload color="primary" fontSize="large" />
          <Typography sx={headerSx}>Click to import, or drag and drop them here.</Typography>
          <Typography sx={subheaderSx}>
            {Object.values(accept)
              .flat()
              .map((ext) => ext.replace(".", "").toUpperCase())
              .join(", ")}
          </Typography>
        </Box>
      )}
    </Dropzone>
  );
}

const boxSx = {
  display: "flex",
  cursor: "pointer",
  ":hover": {
    backgroundColor: "#F5F4F6",
  },
  flexShrink: 0,
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  padding: "48px",
  gap: "8px",
  background: "background.alternate",
  border: "2px solid",
  borderColor: "divider",
  borderRadius: "4px",
  height: "200px",
};

const headerSx = {
  fontWeight: 700,
  fontSize: "14px",
  lineHeight: "24px",
  letterSpacing: "-0.04em",
  color: "primary.main",
};

const subheaderSx = {
  fontWeight: 700,
  fontSize: "12px",
  lineHeight: "24px",
  letterSpacing: "-0.04em",
  color: "primary.main",
};
