import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import React, { useMemo } from "react";
import { IId } from "../types";
import { ListItem } from "./ListItem";

interface Props {
  id: string;
  renderItem: (item: IId, index?: number) => React.ReactNode;
  index?: number;
}

export const SortableListItem: React.FC<Props> = ({ id, renderItem, index }) => {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: id });

  const style = useMemo(
    () => ({
      transform: CSS.Transform.toString(transform),
      transition,
      cursor: "grab",
    }),
    [transform, transition]
  );

  return (
    <ListItem
      id={id}
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
      renderItem={renderItem}
      index={index}
    />
  );
};
