import { useSearchQuery } from "@cp/react-hooks";
import { Psychology } from "@mui/icons-material";
import {
  Alert,
  Divider,
  IconButton,
  LinearProgress,
  ListItem,
  ListItemText,
  MenuList,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useMatchingGoldenQuestionsQuery } from "../../../generated/graphql";
import { ClobMappingDot } from "../../../pages/clobs/MapClob/components/ClobMappingDot";
import { GoldenQuestionSwappingIcon } from "../../../pages/clobs/MapClob/components/GoldenQuestionSwappingIcon";
import { useSelectedCurationQuestions } from "../../../pages/clobs/MapClob/selection-atom";
import { SectionBreadcrumbs } from "../../../pages/curation/ApplicationLayout/SectionBreadcrumbs";
import { useSearchCurationNodes } from "../../../pages/curation/atoms";
import { useAllCurationQuestions } from "../../../pages/curation/state/useAllCurationQuestions";
import { useCurationState } from "../../../pages/curation/state/useCurationState";
import { ViewCurationQuestionButton } from "../../buttons/curation-questions/ViewCurationQuestionButton";
import { Card } from "../../Card";
import { SearchInput } from "../../SearchInput";
import { Collapse } from "../../sortables/components/Collapse/Collapse";

export const MatchingGoldenQuestionsCard: React.FC = () => {
  const [selectedCurationQuestionIds] = useSelectedCurationQuestions();
  const selectedCurationQuestionId = selectedCurationQuestionIds[0];

  const search = useSearchQuery();
  const getSearchText = () => {
    if (search.debouncedValue.trim().length === 0) {
      return null;
    }
    return [search.debouncedValue.trim()];
  };

  const [isExpanded, expand] = useState<boolean>(true);
  const { data, error, loading } = useMatchingGoldenQuestionsQuery({
    fetchPolicy: "cache-and-network", // Ideally need cache ttl but no option in apollo.
    variables: {
      matchingGoldenQuestionsInput: { curationQuestionId: selectedCurationQuestionId, byText: getSearchText() },
    },
    skip: !selectedCurationQuestionId && !getSearchText(),
  });

  const allItems = data?.matchingGoldenQuestions.matchingQuestions ?? [];

  // TODO: Experimenting with what we want the filter to be
  // If we pass this as part of topN to the backend call then we'll bring back less data
  const displayItems = allItems.slice(0, 5);

  // Prevent 0 being the max score
  const maxScore = Math.max(0.1, ...allItems.map(({ score }) => score ?? 0));

  if (error) {
    return <Alert severity="error">{error.message}</Alert>;
  }

  return (
    <Card
      header={
        <Stack justifyContent="space-between">
          <Stack mb={2}>
            <SearchInput {...search.inputProps} loading={loading} />
          </Stack>
          <Stack direction="row">
            <Psychology color="info" />
            <Typography color="primary" fontWeight={500}>
              Golden Question Suggestions
            </Typography>
            <Collapse onCollapse={() => expand(!isExpanded)} collapsed={!isExpanded} />
          </Stack>
        </Stack>
      }
    >
      {isExpanded ? (
        <MenuList dense={true}>
          {loading &&
            Array.from({ length: 7 }).map((_, i) => (
              <ListItem key={i} disableGutters={true}>
                <ListItemText
                  primary={<Skeleton variant="text" width="100%" />}
                  secondary={<Skeleton variant="text" width="80%" />}
                />
              </ListItem>
            ))}
          {!loading &&
            displayItems.map(({ curationQuestionId: matchingCurationQuestionId, questionText, questionKey, score }) => {
              score = score ?? 0;
              const normalizedScore = Math.round((1 - score / maxScore) * 100);
              return (
                <Stack key={matchingCurationQuestionId}>
                  <ListItem key={matchingCurationQuestionId} disableGutters={true}>
                    <Stack sx={{ flexDirection: "row", paddingRight: "10px" }}>
                      <GoldenQuestionSwappingIcon id={matchingCurationQuestionId} />
                      <GoldenQuestionMappingDot id={matchingCurationQuestionId} />
                      <ViewCurationQuestionButton curationQuestionId={matchingCurationQuestionId} />
                    </Stack>
                    <ListItemText
                      primary={questionText}
                      sx={{ pr: 15 }}
                      primaryTypographyProps={{
                        fontWeight: 500,
                      }}
                      secondaryTypographyProps={{
                        variant: "caption",
                        overflow: "hidden",
                      }}
                      secondary={
                        <Stack>
                          <span>{questionKey}</span>
                          <Stack direction="row" spacing={4} justifyContent="space-between" sx={{ paddingBottom: 1 }}>
                            <span>
                              <SectionBreadcrumbs nodeId={matchingCurationQuestionId} />
                            </span>
                          </Stack>
                          <LinearProgress
                            sx={{ borderRadius: 3 }}
                            variant="determinate"
                            value={normalizedScore}
                            color={normalizedScore > 70 ? "success" : "warning"}
                          />
                        </Stack>
                      }
                    />
                  </ListItem>
                  <Divider sx={{ borderBottomWidth: 5, color: "primary.main" }} />
                </Stack>
              );
            })}
        </MenuList>
      ) : (
        <></>
      )}
    </Card>
  );
};

interface MappingDotProps {
  id: string;
}

const GoldenQuestionMappingDot = ({ id }: MappingDotProps) => {
  const allQuestions = useAllCurationQuestions();
  const { curationNodeMap } = useCurationState();
  const { onSearch } = useSearchCurationNodes(curationNodeMap, allQuestions);

  return (
    <IconButton>
      {/* ClobMappingDot is being used here to snap to the suggested question in the Golden Question Library.*/}
      {/* It just happens to do what we need here. */}
      <ClobMappingDot id={id} lineToId={id} hideLine={true} onClick={onSearch} />
    </IconButton>
  );
};
