import { LinkOff } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Chip, Tooltip } from "@mui/material";
import { useUnlinkCurationQuestion } from "./useUnlinkCurationQuestion";

interface Props {
  curationQuestionId: string;
  clobQuestionId: string;
}

export function UnlinkCurationQuestionButton({ curationQuestionId, clobQuestionId }: Props) {
  const { unlink, loading } = useUnlinkCurationQuestion({
    curationQuestionId,
    clobQuestionId,
  });

  return (
    <Tooltip title="Unlink CLOB question from Curation question">
      <LoadingButton
        loading={loading}
        variant="text"
        color="warning"
        size="small"
        sx={{
          opacity: 0.5,
          "&:hover": { opacity: 1 },
        }}
        onClick={(evt) => {
          evt.stopPropagation();
          void unlink();
        }}
      >
        <LinkOff />
      </LoadingButton>
    </Tooltip>
  );
}

export function UnlinkCurationQuestionChip({ curationQuestionId, clobQuestionId }: Props) {
  const { unlink } = useUnlinkCurationQuestion({
    curationQuestionId,
    clobQuestionId,
  });

  return (
    <div>
      <Chip
        icon={<LinkOff />}
        label="Remove mapping"
        color="warning"
        sx={{ borderRadius: 1 }}
        variant="outlined"
        size="small"
        onClick={(evt) => {
          evt.stopPropagation();
          void unlink();
        }}
      />
    </div>
  );
}
