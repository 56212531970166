import { Link, Stack, Typography } from "@mui/material";
import React from "react";
import { CodeChip } from "../../business-classification/CodeChip";
import { formatCode } from "../../workers-comp-classification/WorkersCompUtils";

interface AlternateCodesGroup {
  title: string;
  selectedId?: string | null;
  codes: Array<{ id: string; descriptions: string[]; system: string }>;
}

interface ClassificationCardProps {
  code: string;
  description: string;
  broadDescription: string | undefined | null;
  definition: string | undefined | null;
  system: string;

  alternateCodes: AlternateCodesGroup[];
  onRemove?: () => void;
  onClick?: (code: { id: string; system: string }) => void;
}

export const ClassificationCard: React.FC<ClassificationCardProps> = ({
  code,
  description,
  broadDescription,
  definition,
  system,
  alternateCodes,
  onClick,
  onRemove,
}) => {
  return (
    <Stack border="2px solid" borderColor="divider" borderRadius="4px" width="100%">
      <Stack
        direction="row"
        px={3}
        py={2}
        gap={3}
        width={"100%"}
        justifyContent="space-between"
        alignItems="center"
        borderBottom="1px solid"
        borderColor="divider"
      >
        <Typography variant="h4">{description}</Typography>
        <CodeChip code={formatCode(code) || ""} system={system} selected={true} />
      </Stack>
      <Stack gap={3} p={3} sx={{ backgroundColor: "background.alternate" }}>
        <Typography>{definition ?? broadDescription}</Typography>
        {alternateCodes.map((group, idx) => {
          if (group.codes.length === 0) {
            return null;
          }

          return (
            <Stack gap={1} key={`${group.title}-${idx}`}>
              <Typography variant="caption" color="text.secondary" fontWeight={700}>
                {group.title}
              </Typography>
              {group.codes.map((code) => (
                <Stack key={formatCode(code.id)} direction="row" justifyContent="space-between" alignItems="center">
                  <Typography variant="body1" fontWeight={600}>
                    {code.descriptions.join(", ")}
                  </Typography>
                  <CodeChip
                    code={formatCode(code.id) || ""}
                    system={code.system}
                    onClick={onClick ? () => onClick(code) : undefined}
                    selected={code.id === group.selectedId}
                  />
                </Stack>
              ))}
            </Stack>
          );
        })}
        {onRemove && (
          <Link fontSize="small" onClick={onRemove} color="primary" sx={{ cursor: "pointer" }}>
            Remove classification
          </Link>
        )}
      </Stack>
    </Stack>
  );
};
