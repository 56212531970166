import React from "react";
import { ClobQuestionDetailsCard } from "../../../../components/cards/clob-questions/ClobQuestionDetailsCard";
import { formatConditionsInput } from "../../../../components/curation-questions/conditionalUtil";
import { useClobContext } from "../../../../model/clobs/context";
import { useCurationState } from "../../../curation/state/useCurationState";

interface Props {
  id: string;
  search: string;
}

export const ClobQuestionBody: React.FC<Props> = React.memo(({ id, search }) => {
  const { getClobQuestion } = useCurationState();
  const { state } = useClobContext();
  const question = state.questions.get(id);
  if (!question) {
    return null;
  }

  return (
    <ClobQuestionDetailsCard
      clobQuestion={question}
      visibility={formatConditionsInput(question.visibility, getClobQuestion)}
      search={search}
    />
  );
});

ClobQuestionBody.displayName = "ClobQuestionBody";
