import { FilterAlt } from "@mui/icons-material";
import { colors, Stack, Tooltip, Typography } from "@mui/material";
import React from "react";
import { formatConditions } from "../../../components/curation-questions/conditionalUtil";
import { ApplicationConditionFragment } from "../../../generated/graphql";
import { useCurationState } from "../state/useCurationState";

interface Props {
  conditions: ApplicationConditionFragment[] | null | undefined;
}

export const ConditionsWidget: React.FC<Props> = ({ conditions }) => {
  const { getQuestion } = useCurationState();

  if (!conditions || conditions.length === 0) {
    return null;
  }
  return (
    <Tooltip
      title={
        <Typography color="white" whiteSpace="pre-wrap" variant="subtitle1">
          {formatConditions(conditions, getQuestion) || ""}
        </Typography>
      }
    >
      <Stack
        sx={{
          color: colors.grey[200],
          borderRadius: "50%",
          backgroundColor: colors.teal[600],
          padding: 0.5,
        }}
      >
        <FilterAlt sx={{ fontSize: "14px" }} />
      </Stack>
    </Tooltip>
  );
};
