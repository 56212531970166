import { useEffect, useState } from "react";

/**
 * React-hook to debounce a value.
 *
 * @param value The changing value that should be debounced.
 * @param debounceMillis Number of milliseconds to debounce.
 * @return The debounced value. This starts equal to value and will take on the result of the last
 * thing value was set to after a delay of debounceMillis with no subsequent changes.
 */
export function useDebounce<T>(value: T, debounceMillis: number): T {
  const [debouncedValue, setDebouncedValue] = useState<T>(value);

  useEffect(() => {
    // Update debounced value after delay
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, debounceMillis);

    // Cancel the timeout if value changes
    return () => {
      clearTimeout(handler);
    };
  }, [value, debounceMillis]);

  return debouncedValue;
}
