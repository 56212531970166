import { useCallback, useState } from "react";

export function useSingleSelection<T>(initialValue: T) {
  const [state, setState] = useState<T | undefined>(initialValue);

  return [
    state,
    {
      set: useCallback((value: T | undefined) => setState(value), []),
      // Clear if the value is the same as the current state
      select: useCallback(
        (value: T | undefined) =>
          setState((prevState) => {
            return prevState === value ? undefined : value;
          }),
        []
      ),
      clear: useCallback(() => setState(undefined), []),
    },
  ] as const;
}
