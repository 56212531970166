import { atom } from "jotai";
import React from "react";
import { Deferred } from "./deferred";

export interface ModalAlertPayload {
  title: string;
  message: React.ReactNode;
  confirmText?: string;
}

export interface ModalConfirmPayload {
  title: string;
  message: React.ReactNode;
  confirmText?: string;
  cancelText: string;
}

export interface ModalPromptPayload {
  title: string;
  message: string;
  defaultValue?: string;
  confirmText: string;
}

interface HandleSubmit {
  <TArgs extends unknown[], TResult>(handler: (...args: TArgs) => TResult): (...args: TArgs) => TResult;
}
export type FormPayloadFn = (handleSubmit: HandleSubmit) => React.ReactNode;

export interface ModalFormPayload {
  title: string;
  message: string;
  cancelText?: string;
  submitText?: string;
  form: React.ReactNode | FormPayloadFn;
  showCloseButton?: boolean;
  id?: string;
  formTag?: "form" | "div";
}

export type ModalPayload =
  | {
      type: "alert";
      payload: ModalAlertPayload;
    }
  | {
      type: "prompt";
      payload: ModalPromptPayload;
    }
  | {
      type: "confirm";
      payload: ModalConfirmPayload;
    }
  | {
      type: "form";
      payload: ModalFormPayload;
    }
  | {
      type: "custom";
      payload: <T>(onClose: (value: T) => void) => React.ReactNode;
    };

export const modalAtom = atom<{
  activeModal: ModalPayload | undefined;
  promise: Deferred<any> | undefined;
}>({
  activeModal: undefined,
  promise: undefined,
});
