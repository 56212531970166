import { atom, useAtom, useSetAtom } from "jotai";
import { atomWithHash } from "jotai-location";
import { useContext } from "react";
import { MAIN_CURATION_APPLICATION_ID } from "../../constants";
import { Scalars } from "../../generated/graphql";
import { CurationAtomScopeContext } from "../../pages/curation/state/CurationAtomScopeProvider";

/**
 * Shared state among panels. This state gets stored in the URL hash and any panels and read/write to it.
 */

const defaultSerialization = <T extends string>() => ({
  serialize: (id: T | undefined) => id ?? ("" as T),
  deserialize: (id: string | null) => (id ? (id as T) : undefined),
});

// Selected Entity ID
const selectedEntityIdAtom = atomWithHash<string | undefined>("entityId", undefined, defaultSerialization());
export const useSelectedEntityId = () => useAtom(selectedEntityIdAtom);
export const useSetSelectedEntityId = () => useSetAtom(selectedEntityIdAtom);

export const curationQuestionsFilter = atom<{ carrierSlugs: string[]; lineOfBusiness: string }>({
  carrierSlugs: [],
  lineOfBusiness: "",
});
export const useCurationQuestionsFilter = () => useAtom(curationQuestionsFilter);

// Selected Curation Applciation ID
const selectedCurationApplicationIdAtom = atomWithHash<string>("curationId", MAIN_CURATION_APPLICATION_ID);
export const useSelectedCurationApplicationId = () => useAtom(selectedCurationApplicationIdAtom);
export const useSetSelectedCurationApplicationId = () => useSetAtom(selectedCurationApplicationIdAtom);

// Selected Recording ID
const selectedRecordingIdAtom = atomWithHash<Scalars["CarrierRecordingId"] | undefined>(
  "recordingId",
  undefined,
  defaultSerialization()
);
export const useSelectedRecordingId = () => useAtom(selectedRecordingIdAtom);
export const useSetSelectedRecordingId = () => useSetAtom(selectedRecordingIdAtom);

// Selected Portal Annotation ID
const selectedPortalAnnotationIdAtom = atomWithHash<Scalars["PortalAnnotationId"] | undefined>(
  "portalAnnotationId",
  undefined,
  defaultSerialization()
);
export const useSelectedPortalAnnotationId = () => useAtom(selectedPortalAnnotationIdAtom);
export const useSetSelectedPortalAnnotationId = () => useSetAtom(selectedPortalAnnotationIdAtom);

// Selected PDF ID
const selectedPdfIdAtom = atomWithHash<string | undefined>("pdfId", undefined, defaultSerialization());
export const useSelectedPdfId = () => useAtom(selectedPdfIdAtom);
export const useSetSelectedPdfId = () => useSetAtom(selectedPdfIdAtom);

export interface SelectedPdfField {
  fieldId: string;
  fieldOptions?: string[];
  pageNumber: number;
}

const selectedPdfFieldSerialization = <T extends SelectedPdfField>() => ({
  serialize: (field: T | undefined) =>
    field === undefined
      ? ""
      : new URLSearchParams({
          fieldId: field.fieldId,
          fieldOptions: (field.fieldOptions ?? []).join(","),
          pageNumber: field.pageNumber.toString(),
        }).toString(),
  deserialize: (field: string | null) => (field ? deserializeField(field) : undefined),
});

function deserializeField(field: string): SelectedPdfField {
  const value = new URLSearchParams(field);
  return {
    fieldId: value.get("fieldId") ?? "",
    fieldOptions: value.get("fieldOptions")?.split(","),
    pageNumber: value.get("pageNumber") ? Number.parseInt(value.get("pageNumber") ?? "") : 0,
  };
}

const selectedPdfFieldAtom = atomWithHash<SelectedPdfField | undefined>(
  "pdfField",
  undefined,
  selectedPdfFieldSerialization()
);
export const useSelectedPdfField = () => useAtom(selectedPdfFieldAtom);
export const useSetSelectedPdfField = () => useSetAtom(selectedPdfFieldAtom);

// Handle state for the golden question drawer
export const goldenQuestionDrawer = atom<boolean>(false);
export const useGoldenQuestionDrawer = () => useAtom(goldenQuestionDrawer);

// Handle state for the golden question drawer button since the SnackBar
// provider is outside of the scope of the react-location provider
export const showGoldenQuestionDrawerButtonInActionBar = atom<boolean>(false);
export const useShowGoldenQuestionDrawerButtonInActionBar = () => useAtom(showGoldenQuestionDrawerButtonInActionBar);

/**
 * State specific to a particular scope. Scope can be set on CurationAtomScopeProvider, and defaults
 * to DEFAULT_ATOM_SCOPE, if none is provided.
 */
export const useAtomScope = () => {
  return useContext(CurationAtomScopeContext);
};

// Selected Curation Node ID (section, question, etc)
const selectedCurationNodeIdAtom = atom<string | undefined>(undefined);
export const useSelectedCurationNodeId = () => useAtom(selectedCurationNodeIdAtom, useAtomScope());
export const useSetSelectedCurationNodeId = () => useSetAtom(selectedCurationNodeIdAtom, useAtomScope());

const selectedDrilldownIdAtom = atom<string | undefined>(undefined);
export const useSelectedDrilldownId = () => useAtom(selectedDrilldownIdAtom, useAtomScope());
export const useSetSelectedDrilldownId = () => useSetAtom(selectedDrilldownIdAtom, useAtomScope());
