import { useEffect } from "react";
import { useEvent } from "./useEvent";

export function useEventListener<K extends keyof WindowEventMap>(
  eventName: K,
  handler: (event: WindowEventMap[K]) => void,
  options?: boolean | AddEventListenerOptions
): void;
export function useEventListener<K extends keyof DocumentEventMap>(
  eventName: K,
  handler: (event: DocumentEventMap[K]) => void,
  options: boolean | AddEventListenerOptions,
  element: Document
): void;
export function useEventListener(
  eventName: string,
  handler: (event: Event) => void,
  options?: boolean | AddEventListenerOptions,
  element: EventTarget = window
): void {
  // Create consistent handler reference
  const savedHandler = useEvent(handler);

  useEffect(() => {
    // Make sure element supports addEventListener
    const isSupported = element && !!element.addEventListener;
    if (!isSupported) {
      return;
    }

    // Create event listener that calls handler function stored in ref
    const eventListener = (event: Event) => savedHandler && savedHandler(event);

    // Add event listener
    element.addEventListener(eventName, eventListener, options);

    // Remove event listener on cleanup
    return () => {
      element.removeEventListener(eventName, eventListener, options);
    };
  }, [eventName, element]);
}
