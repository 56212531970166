import React, { useContext } from "react";

export type OptionSets = Record<string, any[]>;

export const OptionsContext = React.createContext<OptionSets | undefined>({});

const EMPTY_OPTIONS: any[] = [];

export function useOptions<T>(key: string): T[] {
  const options = useContext(OptionsContext);
  if (options === undefined) {
    console.error("OptionsContext is not defined");
    return EMPTY_OPTIONS;
  }
  return options[key] || EMPTY_OPTIONS;
}
