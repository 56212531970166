import { Stack } from "@mui/material";
import React, { PropsWithChildren } from "react";
import { Collapse } from "../../../components/sortables/components/Collapse/Collapse";
import { Handle } from "../../../components/sortables/components/Handle/Handle";

interface Props {
  title?: React.ReactNode;
  actions?: React.ReactNode;
  dragRef?: React.Ref<HTMLButtonElement>;
  collapsed?: boolean;
  borderColor?: string;
  backgroundColor?: string;
  onCollapse?(): void;
}

export const DragableHeader: React.FC<PropsWithChildren<Props>> = ({
  dragRef,
  children,
  borderColor,
  onCollapse,
  collapsed,
  actions,
  backgroundColor,
}) => {
  return (
    <Stack
      className="hide-action-items"
      pr="2px"
      py={1}
      borderLeft={"6px solid"}
      borderColor={borderColor ?? "transparent"}
      direction="row"
      boxShadow="0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1)"
      borderRadius={1}
      alignItems="center"
      mb={"2px"}
      position={"relative"}
      sx={{ backgroundColor: backgroundColor ?? "white" }}
    >
      {dragRef && <Handle ref={dragRef} />}
      {onCollapse && <Collapse onCollapse={onCollapse} collapsed={collapsed} />}
      <Stack flex={1} direction="row" gap={1} alignItems="center">
        {children}
      </Stack>
      <Stack position={"absolute"} top={"4px"} right={"4px"} direction="row" alignItems="center">
        {actions}
      </Stack>
    </Stack>
  );
};
