import { styled } from "@mui/material";
import React, { PropsWithChildren, useRef } from "react";
import { useDrag } from "react-dnd";
import { InsertCurationQuestionIndicator } from "../Questions/InsertCurationQuestionIndicator";
import { QuestionItem, QuestionItemProps } from "../Questions/QuestionItem";
import { DraggableWrapper } from "./DragableWapper";
import { Dragable, DragableItemProps, DragSources, DragTypes } from "./DragTypes";
import { useListItemDrop } from "./useListItemDrop";

const StyledQuestionItem = styled(QuestionItem)<{ isDragging: boolean }>`
  opacity: ${({ isDragging }) => (isDragging ? 0.4 : 1)};
`;

export const DragableQuestion: React.FC<Omit<DragableItemProps<QuestionItemProps>, "source">> = React.memo(
  ({ index, move, depth, curationQuestion, parentId, canToggleConditionalQuestion, canRemove, disableSelection }) => {
    const source = parentId ? DragSources.section(parentId) : DragSources.uncurated;

    const [{ isDragging }, drag, preview] = useDrag<Dragable, unknown, { isDragging: boolean }>(
      {
        type: DragTypes.QUESTION,
        item: {
          id: curationQuestion.id,
          index,
          type: DragTypes.QUESTION,
          source,
        },
        collect: (monitor) => ({
          isDragging: monitor.isDragging(),
        }),
      },
      [curationQuestion.id, parentId, index]
    );

    const questionElement = (
      <StyledQuestionItem
        ref={preview}
        dragRef={drag}
        parentId={parentId}
        depth={depth}
        canToggleConditionalQuestion={canToggleConditionalQuestion}
        canRemove={canRemove}
        isDragging={isDragging}
        curationQuestion={curationQuestion}
        disableSelection={disableSelection}
      />
    );

    if (source.type === "uncurated") {
      return questionElement;
    }

    return (
      <InsertCurationQuestionIndicator parentId={parentId} curationId={curationQuestion.id}>
        <DraggableListQuestion index={index} move={move} id={curationQuestion.id} source={source}>
          {questionElement}
        </DraggableListQuestion>
      </InsertCurationQuestionIndicator>
    );
  }
);
DragableQuestion.displayName = "DragableQuestion";

const DraggableListQuestion: React.FC<PropsWithChildren<DragableItemProps<{ id: string }>>> = React.memo(
  ({ index, move, id, source, children }) => {
    const ref = useRef<HTMLDivElement>(null);

    const { isOver, dropRef, direction } = useListItemDrop({
      id: id,
      ref,
      index,
      move,
      accept: [DragTypes.QUESTION, DragTypes.SECTION],
      source,
    });

    dropRef(ref);
    return (
      <DraggableWrapper ref={ref} isOver={isOver} direction={direction}>
        {children}
      </DraggableWrapper>
    );
  }
);
DraggableListQuestion.displayName = "DraggableListQuestion";
