import { FormFieldAutocomplete } from "../../form-fields/FormFieldAutocomplete";
import { FormFieldProps } from "../../form-fields/FormFieldProps";
import { FormFieldSelect } from "../../form-fields/FormFieldSelect";
import { useOptions } from "./OptionsContext";

interface Props<T> extends FormFieldProps {
  optionsKey: string;
  getValue: (value: T) => string;
  getLabel: (value: T) => string;
  autocomplete?: {
    table?: {
      headers: string[];
      getRow: (value: T) => string[];
    };
  };
}

export function SelectForm<T>({ optionsKey, getValue, getLabel, autocomplete, ...rest }: Props<T>) {
  const options = useOptions<T>(optionsKey);

  if (autocomplete) {
    const { table } = autocomplete;
    return (
      <FormFieldAutocomplete {...rest} table={table} getOptionLabel={getLabel} getValue={getValue} options={options} />
    );
  }

  return (
    <FormFieldSelect
      {...rest}
      options={options.map((option) => ({
        value: getValue(option),
        label: getLabel(option),
      }))}
    />
  );
}
