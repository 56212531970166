import { FormBuilder, FormyForm } from "@cp/forms";
import { useModal } from "@cp/modals";
import { isDefined } from "@cp/toolkit";
import { Alert, AlertTitle, Stack, Typography } from "@mui/material";
import { BaseSyntheticEvent } from "react";
import { DeepMap, DeepPartial, FieldErrors } from "react-hook-form";
import { z } from "zod";
import { QUESTION_LINTER } from "../../../components/curation-lint/linters/linters";
import { getAllSubjects } from "../../../components/curation-questions/conditionalUtil";
import { CONDITIONS_FORM } from "../../../components/curation-questions/ConditionsEditor";
import { useCurationQuestionFormOptions } from "../../../components/curation-questions/useCurationQuestionFormOptions";
import { OptionsEditor } from "../../../components/OptionsEditor";
import {
  ApplicationComponentType,
  CurationQuestionFragment,
  useMappedPdfQuestionsQuery,
} from "../../../generated/graphql";
import { UpsertCurationQuestionInput } from "../../../model/curation-questions/deserialize";
import { NaicsSelector } from "./components/NaicsSelector";

interface CurationQuestionFormProps {
  formId: string;
  curationQuestion?: Partial<UpsertCurationQuestionInput>;
  onSubmit: (data: UpsertCurationQuestionInput) => void;
}

const FORM = FormBuilder.objectOf<UpsertCurationQuestionInput>({
  key: FormBuilder.string().options({
    label: "Key",
    helperText: "The key is used to identify the question in the application form",
  }),
  text: FormBuilder.string().options({
    label: "Question Text",
    helperText: "The question text is displayed to the user",
  }),
  componentType: FormBuilder.enum(Object.values(ApplicationComponentType)).options({
    label: "Component Type",
    helperText: "The component type determines how the question is displayed to the user",
  }),
  options: FormBuilder.custom(() => <OptionsEditor />),
  defaultValue: FormBuilder.string()
    .options({
      label: "Default Value",
      helperText: "The default value is used to pre-populate the question",
    })
    .optional(),
  notes: FormBuilder.string()
    .options({
      label: "Notes",
      helperText: "Notes are not visible to the user",
    })
    .optional(),
  helperText: FormBuilder.string()
    .options({
      label: "Helper Text",
      helperText: "Helper text is displayed below the question to the user",
    })
    .optional(),
  infoText: FormBuilder.string()
    .options({
      label: "Popover Text",
      helperText: "Popover text is displayed on the side of the question to the user",
      textArea: true,
    })
    .optional(),
  conditions: CONDITIONS_FORM.options({ label: "Conditions", itemName: "condition" }).optional(),
  naicsPrefixes: FormBuilder.custom<string[]>(({ name }) => <NaicsSelector name={name} />).optional(),
  golden: FormBuilder.boolean().options({
    label: "Golden Question",
  }),
}).options({
  superRefine: (obj, ctx) => {
    const lintResults = QUESTION_LINTER({
      id: obj.id,
      componentType: obj.componentType,
      options: obj.options?.filter((o) => !!o.trim()) || [],
      text: obj.text,
    });
    const lintErrors = lintResults.filter((r) => r.status === "error");

    for (const error of lintErrors) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: error.message,
        path: error.path,
      });
    }
  },
});

export default function CurationQuestionForm({ formId, curationQuestion, onSubmit }: CurationQuestionFormProps) {
  const optionSets = useCurationQuestionFormOptions();
  const { data } = useMappedPdfQuestionsQuery({
    variables: { id: curationQuestion?.id || "" },
    skip: !curationQuestion?.id,
  });
  const mappedClobQuestions = data?.curationQuestion.mappedClobQuestions ?? [];
  const isAcordMapped = mappedClobQuestions.map((q) => q?.acordELabelId).some((e) => !!e);
  const isPdfMapped = mappedClobQuestions.map((q) => q?.pdfQuestion?.id).some((e) => !!e);
  const { openConfirm, openAlert } = useModal();

  const onInvalidSubmission = async (errors: FieldErrors<UpsertCurationQuestionInput>) => {
    const messages = Object.entries(errors).map(([key, error]) => {
      return Array.isArray(error) ? error.join("\n") : error?.message || "";
    });
    await openAlert({
      title: "Validation error",
      message: (
        <Typography>
          <Alert severity="error">
            <AlertTitle>Validation Errors</AlertTitle>
            <ul>
              {messages.map((r) => (
                <li key={r}>{r}</li>
              ))}
            </ul>
          </Alert>
        </Typography>
      ),
    });
  };

  const onSubmitValid = async (
    data: UpsertCurationQuestionInput,
    opts: {
      dirtyFields?: Partial<Readonly<DeepMap<DeepPartial<UpsertCurationQuestionInput>, boolean>>>;
    },
    e?: BaseSyntheticEvent<object, any, any> | undefined
  ) => {
    e?.stopPropagation();
    e?.preventDefault();
    data.options = data.options?.map((o) => o.trim()).filter((o) => !!o && isDefined(o));

    const subjects = new Set(getAllSubjects(data.conditions || []));
    const questionsConditionalOn = optionSets.questions.filter((q) => subjects.has(q.id)).filter(isDefined);
    const { dirtyFields } = opts;

    const showPdfWarning = dirtyFields?.componentType || dirtyFields?.options;

    const { success: isValid, warnings } = validateData(
      data,
      questionsConditionalOn,
      showPdfWarning && (isAcordMapped || isPdfMapped)
    );
    if (isValid) {
      if (warnings.length === 0) {
        onSubmit(data);
      } else {
        const confirmed = await openConfirm({
          title: "There could be a few issues with this question",
          message: (
            <Typography>
              <Alert severity="warning">
                <AlertTitle>Warnings</AlertTitle>
                There could be a few issues with the question data.
                <ol type="1">
                  {warnings.map((r) => (
                    <li key={r}>{r}</li>
                  ))}
                </ol>
              </Alert>
            </Typography>
          ),
          cancelText: "Cancel",
        });
        if (!confirmed) {
          return;
        }

        onSubmit(data);
      }
    }
  };

  return (
    <Stack>
      <FormyForm
        key={curationQuestion?.id}
        optionSets={optionSets}
        id={formId}
        form={FORM}
        onSubmit={onSubmitValid}
        defaultValues={curationQuestion}
        onSubmitInvalid={onInvalidSubmission}
      />
    </Stack>
  );
}

const validateData: (
  obj: UpsertCurationQuestionInput,
  questionsConditionalOn: CurationQuestionFragment[],
  isPdfOrAcordMapped?: boolean
) => {
  success: boolean;
  errors: string[];
  warnings: string[];
} = (obj, questionsConditionalOn, isPdfOrAcordMapped) => {
  const lintResults = QUESTION_LINTER({
    id: obj.id,
    componentType: obj.componentType,
    options: obj.options?.filter((o) => !!o.trim()) || [],
    text: obj.text,
    questionsConditionalOn: questionsConditionalOn,
    isPdfOrAcordMapped: isPdfOrAcordMapped,
  });
  const lintErrors = lintResults.filter((r) => r.status === "error");
  const lintWarnings = lintResults.filter((r) => r.status === "warning");

  return {
    success: lintErrors.length === 0,
    errors: lintErrors.map((e) => e.message),
    warnings: lintWarnings.map((e) => e.message),
  };
};
