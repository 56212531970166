import { Maps } from "@cp/toolkit";
import {
  CurationApplicationFragment,
  CurationApplicationInput,
  CurationEntityFragment,
  CurationNodeType,
} from "../../../generated/graphql";
import { CurationContextState, CurationContextTreeState, CurationNode } from "./types";

export function deserializeCurationApplication(
  wire: Pick<CurationApplicationFragment, "id" | "version" | "lineOfBusiness" | "rootIds" | "nodes">
): CurationContextTreeState {
  return {
    id: wire.id,
    version: wire.version,
    lineOfBusiness: wire.lineOfBusiness,
    rootIds: wire.rootIds,
    curationNodeMap: Maps.keyBy(wire.nodes, (e) => e.id),
  };
}

export function collectIds(nodes: ReadonlyMap<string, CurationNode>): {
  questionIds: ReadonlySet<string>;
  sectionIds: ReadonlySet<string>;
} {
  const questionIds = new Set<string>();
  const sectionIds = new Set<string>();
  const add = (id: string) => {
    const node = nodes.get(id);
    // if a node exists and it's not a question, it's a section
    if (node && node.type !== CurationNodeType.CoverageQuestion) {
      sectionIds.add(id);
    } else {
      questionIds.add(id);
    }
  };

  for (const [nodeId, node] of nodes) {
    add(nodeId);
    node.children.forEach(add);
  }

  return { questionIds, sectionIds };
}

export function deserializeCurationEntity(wire: CurationEntityFragment): CurationContextTreeState {
  return {
    id: wire.id,
    version: wire.version,
    lineOfBusiness: "",
    rootIds: wire.rootIds,
    curationNodeMap: Maps.keyBy(wire.nodes, (e) => e.id),
  };
}

export function serializeCurationApplication(state: CurationContextState): CurationApplicationInput {
  return {
    id: state.id,
    nodes: [...state.curationNodeMap.values()],
    rootIds: state.rootIds,
  };
}
