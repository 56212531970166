import { useSearchQuery } from "@cp/react-hooks";
import { Stack } from "@mui/material";
import React from "react";
import { SearchInput } from "../SearchInput";

interface Props {
  searchValue: string;
  onChangeSearch: (value: string) => void;
  rightActions: React.ReactNode;
}

export function ListToolbar({ rightActions, searchValue, onChangeSearch }: Props) {
  const { inputProps } = useSearchQuery(searchValue);

  return (
    <Stack
      flexShrink={0}
      width="100%"
      direction="row"
      gap={4}
      alignItems="center"
      height="60px"
      justifyContent="space-between"
    >
      <SearchInput {...inputProps} onSubmit={onChangeSearch} />
      <Stack gap={4} direction="row">
        {rightActions}
      </Stack>
    </Stack>
  );
}
