import { useBoolean } from "@cp/react-hooks";
import { Add } from "@mui/icons-material";
import { Button, Stack } from "@mui/material";
import { QuestionMappingButton } from "../../../../components/QuestionMappingButton";
import CreateCurationQuestionDialog from "../CreateCurationQuestionDialog";

export const CurationHeader = () => {
  const [createCurationQuestionDialogIsOpen, createCurationQuestionDialogActions] = useBoolean(false);

  return (
    <Stack alignItems="center" direction="row" px={6} spacing={2}>
      <Button
        startIcon={<Add fontSize="small" />}
        size="small"
        variant="text"
        onClick={createCurationQuestionDialogActions.toggle}
      >
        Curation Question
      </Button>
      <CreateCurationQuestionDialog
        open={createCurationQuestionDialogIsOpen}
        handleClose={createCurationQuestionDialogActions.setFalse}
      />
      <QuestionMappingButton />
    </Stack>
  );
};
