import { DeveloperBoard, SettingsEthernet } from "@mui/icons-material";
import {
  Box,
  Button,
  ButtonGroup,
  Card,
  CardContent,
  Chip,
  colors,
  IconButton,
  Stack,
  styled,
  Tooltip,
  Typography,
} from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { Link } from "@tanstack/react-location";
import { groupBy } from "lodash";
import { useState } from "react";
import { TopBar } from "../../components/TopBar";
import { useClobsQuery } from "../../generated/graphql";

const RootDiv = styled(Box)({
  height: "100%",
  display: "flex",
  flex: "1",
  overflow: "hidden",
  flexDirection: "column",
  background: "#EEEEEE",
});

const Home = () => {
  const { data: clobs } = useClobsQuery();
  const [sort, setSort] = useState<"byCarrier" | "byLineOfBusiness">("byCarrier");

  const groupedClobs = groupBy(clobs?.clobs ?? [], (clob) =>
    sort === "byCarrier" ? clob.carrierSlug : clob.lineOfBusiness
  );

  return (
    <RootDiv>
      <TopBar
        leftToolbar={
          <Typography variant="h3" m={0}>
            Carrier Line of Business (CLOB) Applications
          </Typography>
        }
        rightToolbar={
          <ButtonGroup size="small">
            <Tooltip title="Sort by carrier">
              <Button variant={sort === "byCarrier" ? "contained" : "outlined"} onClick={() => setSort("byCarrier")}>
                Sort by Carrier
              </Button>
            </Tooltip>
            <Tooltip title="Sort by line of business">
              <Button
                variant={sort === "byLineOfBusiness" ? "contained" : "outlined"}
                onClick={() => setSort("byLineOfBusiness")}
              >
                Sort by Line of Business
              </Button>
            </Tooltip>
          </ButtonGroup>
        }
      />
      <Stack overflow="auto" pb={8}>
        {Object.entries(groupedClobs).map(([key, clobs]) => {
          return (
            <Stack key={key} px={3}>
              <Typography variant="h4" sx={{ py: 2 }}>
                {key}
              </Typography>
              <Grid2 container={true} spacing={2}>
                {clobs.map((clob) => (
                  <Grid2 xs={12} sm={6} md={4} lg={3} key={clob.id}>
                    <Card>
                      <Stack>
                        <CardContent sx={{ flex: "1 0 auto" }}>
                          <Typography component="div" variant="subtitle1">
                            {clob.name}
                          </Typography>
                          <Stack direction="row" spacing={2} pt={1}>
                            <Chip
                              color="info"
                              sx={{ borderRadius: 1, backgroundColor: colors.lightBlue[300] }}
                              size="small"
                              label={clob.lineOfBusiness}
                            />
                            <Chip
                              color="info"
                              sx={{ borderRadius: 1, backgroundColor: colors.lightBlue[300] }}
                              size="small"
                              label={clob.carrierSlug}
                            />
                          </Stack>
                        </CardContent>
                        <Box sx={{ display: "flex", alignItems: "center", pl: 1, pb: 1 }}>
                          <Tooltip title="Map to curation questions">
                            <span>
                              <IconButton component={Link} to={`/clobs/map/${clob.id}`}>
                                <SettingsEthernet />
                              </IconButton>
                            </span>
                          </Tooltip>
                          <Tooltip title="Edit CLOB application">
                            <span>
                              <IconButton component={Link} to={`/clobs/edit/${clob.id}`}>
                                <DeveloperBoard />
                              </IconButton>
                            </span>
                          </Tooltip>
                        </Box>
                      </Stack>
                    </Card>
                  </Grid2>
                ))}
              </Grid2>
            </Stack>
          );
        })}
      </Stack>
    </RootDiv>
  );
};

export default Home;
