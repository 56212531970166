import { formatISO } from "date-fns";

/**
 * Type-safe wrapper around `Object.entries`.
 */
export function entries<T extends {}>(obj: T): Array<[keyof T, T[keyof T]]> {
  return Object.entries(obj) as Array<[keyof T, T[keyof T]]>;
}

/**
 * Type-safe mapValues function.
 */
export function mapValues<O extends {}, V>(map: O, fn: (value: O[keyof O]) => V): Record<keyof O, V> {
  return Object.entries(map).reduce((acc, [key, value]) => {
    (acc as any)[key] = fn(value as O[keyof O]);
    return acc;
  }, {} as Record<keyof O, V>);
}

export function collectBy<T, K extends string, V>(
  items: T[],
  toKey: (value: T) => K,
  toValue: (value: T) => V
): Record<K, V> {
  const result = {} as Record<K, V>;
  items.forEach((item) => {
    result[toKey(item)] = toValue(item);
  });
  return result;
}

/**
 * Type-safe assign with same-shape objects.
 */
export function assign<T extends object>(target: T, ...source: T[]): T {
  return Object.assign(target, source);
}

/**
 * Type-safe keys function.
 */
export function keys<T extends object>(object: T): Array<keyof T> {
  return Object.keys(object) as Array<keyof T>;
}

/**
 * Remove undefined
 */
export function removeUndefined<T extends object>(object: T): T {
  return Object.fromEntries(Object.entries(object).filter(([, v]) => v !== undefined)) as T;
}

/**
 * Deep filter an object
 */
export function deepFilter<T>(obj: T, predicate: (value: unknown) => boolean): T {
  if (Array.isArray(obj)) {
    return obj.map((item) => deepFilter(item, predicate)) as unknown as T;
  }

  if (typeof obj === "object" && obj != null) {
    const newObject = filter(obj, predicate);
    return mapValues(newObject, (value) => deepFilter(value, predicate)) as unknown as T;
  }

  return obj;
}

/**
 * Remove empty/undefined/null, but keeps 0 and false
 */
export function removeEmpty<T>(object: T): T {
  return Object.fromEntries(Object.entries(object as {}).filter(([, v]) => v != null && v !== "")) as unknown as T;
}

export function inverseRecord<T extends PropertyKey, U extends PropertyKey>(input: Record<T, U>) {
  return Object.fromEntries(Object.entries(input).map(([key, value]) => [value, key])) as Record<U, T>;
}

export function removeFalsy<T>(from: { [key: string]: string | number | undefined }): T {
  return filter(from, Boolean);
}

export function filter<T, U = T>(from: T, predicate: (value: T[keyof T]) => boolean): U {
  return Object.fromEntries(Object.entries(from as any).filter(([_, o]) => predicate(o as any))) as unknown as U;
}

export function safeStringify(v: unknown) {
  if (v instanceof Date) {
    return formatISO(v, { representation: "date" });
  }

  try {
    return JSON.stringify(v);
  } catch {
    return "";
  }
}

export function isStringValidJson(stringValue: string) {
  try {
    const o = JSON.parse(stringValue);
    return o instanceof Object;
  } catch {
    return false;
  }
}
