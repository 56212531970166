import TextField from "@mui/material/TextField";
import { Controller, useFormContext } from "react-hook-form";

export const OptionsEditor = () => {
  const { control } = useFormContext();

  return (
    <Controller
      name={"options"}
      control={control}
      render={({ field: { onChange, onBlur, value, name, ref }, fieldState: { error } }) => {
        const stringValue = ((value ?? []) as string[]).join("\n");
        return (
          <TextField
            style={{ width: "100%" }}
            label={"Options"}
            name={name}
            inputRef={ref}
            multiline={true}
            onChange={(e) => onChange(e.target.value.split("\n"))}
            onBlur={onBlur}
            error={!!error}
            helperText={error?.message ? error.message : undefined}
            value={stringValue}
          />
        );
      }}
    />
  );
};
