import TextField, { TextFieldProps } from "@mui/material/TextField";
import { Controller, useFormContext } from "react-hook-form";
import { combinedText } from "./common/combineText";
import { parseIntOrNull } from "./common/parseIntOrNull";
import { FormFieldProps, processFormFieldProps } from "./FormFieldProps";

export type FormFieldTextProps = TextFieldProps & FormFieldProps;

export const FormFieldText: React.VFC<FormFieldTextProps> = (props) => {
  const { control } = useFormContext(); // retrieve all hook methods
  const { name, ControllerProps, helperText, infoText, updateOnBlur = false, ...rest } = processFormFieldProps(props);

  const isNumberType = rest.type === "number";

  return (
    <Controller
      control={control}
      defaultValue=""
      {...ControllerProps}
      render={({ field: { onChange, onBlur, value, name, ref }, fieldState: { error } }) => (
        <TextField
          {...rest}
          name={name}
          inputRef={ref}
          error={!!error}
          helperText={combinedText({
            helperText: helperText,
            error: error?.message,
          })}
          {...(updateOnBlur
            ? {
                defaultValue: value,
                onBlur: (evt) => {
                  onChange(isNumberType ? parseIntOrNull(evt.target.value) : evt.target.value);
                  onBlur();
                },
              }
            : {
                value: value,
                onChange: isNumberType ? (e) => onChange(parseIntOrNull(e.target.value)) : onChange,
                onBlur: onBlur,
              })}
          {...(isNumberType && {
            onWheel: (e) => e.target instanceof HTMLElement && e.target.blur(),
          })}
        />
      )}
    />
  );
};
