import { useModal } from "@cp/modals";
import { Edit } from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";
import React from "react";
import { Scalars, useUpdateCarrierRecordingMutation } from "../../../generated/graphql";

interface Props {
  name: string;
  recordingId: Scalars["CarrierRecordingId"];
}

/**
 * Button to rename a recording
 */
export const RenameRecording: React.FC<Props> = ({ name, recordingId }) => {
  const { openPrompt } = useModal();
  const [update] = useUpdateCarrierRecordingMutation();

  return (
    <>
      <Tooltip title="Rename recording">
        <IconButton
          size="small"
          onClick={async () => {
            const newName = await openPrompt({
              title: "Rename recording",
              message: "Enter a new name for this recording",
              defaultValue: name,
              confirmText: "Rename",
            });

            // no change
            if (!newName || newName === name) {
              return;
            }

            await update({
              variables: {
                input: { id: recordingId, name: newName },
              },
            });
          }}
        >
          <Edit fontSize="small" />
        </IconButton>
      </Tooltip>
    </>
  );
};
