import type { Components, Theme } from "@mui/material/styles";
import { darken } from "@mui/material/styles";

export function Button(theme: Theme): Components<Theme> {
  return {
    MuiButton: {
      defaultProps: {
        disableElevation: true,
        disableRipple: true,
        size: "large",
        variant: "contained",
      },
      styleOverrides: {
        root: {
          borderRadius: "5px",
          fontWeight: 600,
          letterSpacing: "-0.02em",
          textTransform: "none",
          "& svg": {
            fontSize: "inherit !important",
          },
        },
        iconSizeLarge: {
          fontSize: "20px",
        },
        iconSizeMedium: {
          fontSize: "16px",
        },
        iconSizeSmall: {
          fontSize: "14px",
        },
      },
      variants: [
        {
          props: { variant: "contained" },
          style: {
            "&:hover": {
              backgroundColor: darken(theme.palette.primary.main, 0.08),
            },
            "&:active": {
              backgroundColor: darken(theme.palette.primary.main, 0.16),
            },
          },
        },
        {
          props: { disabled: true },
          style: {
            backgroundImage: "none",
          },
        },
        {
          props: { variant: "inverted", color: "primary" },
          style: {
            backgroundColor: theme.palette.primary.light,
            color: theme.palette.primary.main,
            "&:hover": {
              backgroundColor: theme.palette.primary.shadow,
            },
          },
        },
        {
          props: { variant: "inverted", color: "secondary" },
          style: {
            backgroundColor: theme.palette.error.light,
            color: theme.palette.error.main,
            "&:hover": {
              backgroundColor: theme.palette.error.shadow,
            },
          },
        },
        {
          props: { size: "large" },
          style: {
            fontSize: "14px",
            gap: "5px",
            lineHeight: "20px",
            minHeight: "40px",
            padding: "0 20px",
          },
        },
        {
          props: { size: "medium" },
          style: {
            fontSize: "12px",
            gap: "2px",
            lineHeight: "16px",
            minHeight: "32px",
            padding: "0 16px",
          },
        },
        {
          props: { size: "small" },
          style: {
            fontSize: "10px",
            lineHeight: "12px",
            minHeight: "24px",
            padding: "0 12px",
          },
        },
      ],
    },
  };
}

declare module "@mui/material/Button" {
  interface ButtonPropsVariantOverrides {
    inverted: true;
  }
}
