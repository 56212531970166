import { FormDialog } from "@cp/modals";
import { useBoolean } from "@cp/react-hooks";
import { Button } from "@mui/material";
import { FormRendererStartingAt } from "./FormRendererStartingAt";

export interface EntitySectionProps {
  sectionId: string;
  title: string;
}

export const EntitySection: React.FC<EntitySectionProps> = ({ sectionId, title }) => {
  const [open, { toggle }] = useBoolean(false);

  return (
    <div>
      <Button variant="contained" color="primary" onClick={toggle}>
        Add {title}
      </Button>
      {open && (
        <FormDialog title={`Add ${title}`} onClose={toggle} open={open} onSubmit={toggle}>
          <FormRendererStartingAt id={sectionId} />
        </FormDialog>
      )}
    </div>
  );
};
