import { GlobalStyles, ThemeProvider as MaterialThemeProvider } from "@mui/material";
import type { PropsWithChildren } from "react";
import { useColorMode } from "./ColorModeProvider";
import { createTheme } from "./theme";

export const ThemeProvider: React.FC<PropsWithChildren<{}>> = ({ children }) => {
  const { mode } = useColorMode();

  return (
    <MaterialThemeProvider theme={createTheme(mode)}>
      <GlobalStyles
        styles={{
          // Small fix to make the snackbar look better
          ".SnackbarItem-message": {
            flex: 1,
          },
          // we only add this class, but never remove since we want the fade out, when removing form-highlight
          ".form-fade": {
            position: "relative",
            "&:before": {
              content: "''",
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: "transparent",
              boxShadow: "none",
              transition: "all .5s ease-in-out",
            },
          },
          ".form-highlight": {
            "&:before": {
              backgroundColor: "rgba(237, 108, 2, 0.1)",
              boxShadow: "0 0 20px 10px rgba(237, 108, 2, 0.1)",
            },
          },
          "[class*='MuiPaper-elevation']": {
            backgroundImage: "none !important",
          },
        }}
      />
      {children}
    </MaterialThemeProvider>
  );
};
