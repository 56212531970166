import { useEvent } from "@cp/react-hooks";
import { UnfoldLess, UnfoldMore } from "@mui/icons-material";
import { Button, ButtonGroup, Stack } from "@mui/material";
import React from "react";
import { CurationApplicationFilters } from "../../../components/CurationApplicationFilters";
import { LoadingStack } from "../../../components/loading/LoadingStack";
import { useSelectedDrilldownId } from "../../../components/panels/atoms";
import { useExpandedNodes } from "../atoms";
import { DragableSection } from "../Dragables/DragableSection";
import { MoveHandler } from "../Dragables/DragTypes";
import { DropArea } from "../Dragables/DropArea";
import { ROOT } from "../state/types";
import { useCurationActions } from "../state/useCurationActions";
import { useCurationState } from "../state/useCurationState";
import { AddEntityButton } from "../Toolbar/AddEntityButton";
import { Toolbar } from "../Toolbar/Toolbar";
import { ApplicationValidationBanner } from "./ApplicationValidationBanner";
import { SectionBreadcrumbs } from "./SectionBreadcrumbs";
import SectionQuestionList from "./SectionQuestionList";
import { useSectionDropzone } from "./useSectionDropzone";

interface ApplicationLayoutProps {
  disableCurationApplicationFilters?: boolean;
}

export const ApplicationLayout: React.FC<ApplicationLayoutProps> = ({ disableCurationApplicationFilters }) => {
  const { rootIds, curationNodeMap, getSection } = useCurationState();
  const [sectionDrilldownId] = useSelectedDrilldownId();
  const { moveWithinNode, moveToNewNode } = useCurationActions();
  const { expandAll, collapseAll, expandMany } = useExpandedNodes();

  const handleExpandAllSections = () => expandMany([...curationNodeMap.keys()].filter(getSection));

  const move: MoveHandler = useEvent(({ dragItemId, moveIndex }) => {
    moveWithinNode({
      childId: dragItemId,
      toIndex: moveIndex,
      parentId: ROOT,
    });
  });
  const [{ isOver, canDrop }, drop] = useSectionDropzone({ id: ROOT, children: rootIds }, moveToNewNode, ROOT);

  const renderSection = (id: string, index: number) => {
    const section = curationNodeMap.get(id);
    if (!section) {
      return null;
    }

    return <DragableSection key={section.id} index={index} depth={0} id={section.id} move={move} parentId={ROOT} />;
  };

  const renderBody = () => {
    // If we are drilled down to a section, only show that section's children
    if (sectionDrilldownId) {
      return (
        <Stack>
          <SectionBreadcrumbs nodeId={sectionDrilldownId} includeRoot={true} includeSelf={true} />
          <SectionQuestionList sectionId={sectionDrilldownId} depth={0} />
        </Stack>
      );
    }

    return <Stack>{rootIds.map(renderSection)}</Stack>;
  };

  return (
    <Stack gap={2}>
      <Toolbar
        right={
          <>
            {!disableCurationApplicationFilters && <CurationApplicationFilters />}

            <ButtonGroup size="small">
              <Button startIcon={<UnfoldLess />} onClick={collapseAll}>
                Collapse
              </Button>
              <Button startIcon={<UnfoldMore />} onClick={handleExpandAllSections}>
                Expand sections
              </Button>
              <Button startIcon={<UnfoldMore />} onClick={expandAll}>
                Expand all
              </Button>
            </ButtonGroup>
            <AddEntityButton parentNodeId={undefined} addType="category" />
          </>
        }
      />
      <ApplicationValidationBanner />
      {renderBody()}
      {/* Since it is never actually empty, empty means it is loading */}
      {rootIds.length === 0 && <LoadingStack />}
      <DropArea drop={drop} canDrop={canDrop} isOver={isOver} dropText="Drop to add to section">
        <div />
      </DropArea>
    </Stack>
  );
};

export default ApplicationLayout;
