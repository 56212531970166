import EmailIcon from "@mui/icons-material/Email";
import InputAdornment from "@mui/material/InputAdornment";
import { FormFieldText, FormFieldTextProps } from "./FormFieldText";

export const FormFieldEmail: React.VFC<FormFieldTextProps> = (props) => {
  return (
    <FormFieldText
      {...props}
      type="email"
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <EmailIcon />
          </InputAdornment>
        ),
      }}
    />
  );
};
