import { useEventListener } from "@cp/react-hooks";
import { Scalars } from "../generated/graphql";

/**
 * These are types of messages that can be across frames.
 */
export type Messages =
  | {
      type: "create-annotation";
    }
  | {
      type: "refetch-annotations";
    }
  | {
      type: "set-recording";
      recordingId: Scalars["CarrierRecordingId"];
    }
  | {
      type: "set-found-suggested-annotations";
      annotationIds: Array<Scalars["PortalAnnotationId"]>;
    }
  | {
      type: "ready";
    }
  | {
      type: "select-annotation";
      annotationId: Scalars["PortalAnnotationId"];
    }
  | {
      type: "hover-annotation";
      annotationId: Scalars["PortalAnnotationId"] | undefined;
    };

/**
 * This hook is used to communicate with the annotator iframe.
 */
export function useHandshake(opts: {
  onMessage: (message: Messages, response: (message: Messages) => void) => void;
  frame: Window | null | undefined;
}) {
  const { onMessage, frame } = opts;

  useEventListener("message", (event: MessageEvent) => {
    if (event.origin !== window.location.origin) {
      console.warn("Message from unknown origin");
      return;
    }

    onMessage(event.data, (message) => {
      event.source?.postMessage(message);
    });
  });

  return {
    postMessage(message: Messages) {
      if (!frame) {
        throw new Error("Frame has no content window");
      }
      frame.postMessage(message, "*");
    },
  };
}
