import { Check } from "@mui/icons-material";
import { Chip, Icon, Stack } from "@mui/material";
import React from "react";

export const CodeChip: React.FC<{
  system: string;
  code: string;
  selected?: boolean;
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}> = ({ system, code, selected, onClick }) => {
  return (
    <Chip
      size="small"
      sx={{ borderRadius: "3px", py: 0 }}
      icon={selected ? <Check /> : <Icon />}
      clickable={!!onClick}
      onClick={onClick}
      color={selected ? "info" : "default"}
      label={
        <pre>
          <Stack direction="row" gap={2}>
            {system}
            <b>{code}</b>
          </Stack>
        </pre>
      }
    />
  );
};
