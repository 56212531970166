import { Box, Stack } from "@mui/material";
import { createTableConfig, Table } from "@qw/table";
import { RecordingSessionsQuery } from "../../generated/graphql";
import { MoveRecordings } from "../../pages/recordings/projects/MoveRecordings";
import { CopyClipboard } from "../CopyClipboard";

type Session = RecordingSessionsQuery["recordingSessions"][0];

const config = createTableConfig<Session>()
  .addColumn({
    id: "thumbnail",
    header: "",
    render: ({ data }) => (
      <Box
        component="img"
        loading="lazy"
        alt="screenshot"
        src={`/api/recording/${data.recordings[0].id}/screenshot.png`}
        sx={{
          minWidth: "80px",
          minHeight: "80px",
          maxWidth: "80px",
          maxHeight: "80px",
          flexShrink: 0,
          objectFit: "fill",
          boxShadow: 1,
          borderRadius: 1,
          mr: 3,
        }}
      />
    ),
  })
  .addColumn({
    id: "recording",
    header: "Total Captures",
    align: "right",
    render: ({ data }) => data.recordings.length,
  })
  .addColumn({
    id: "recording",
    header: "Screen Capture",
    render: ({ data }) => (
      <Stack>
        {data.recordings.map((recording) => (
          <span key={recording.id}>{recording.name}</span>
        ))}
      </Stack>
    ),
  })
  .addColumn({
    id: "url",
    header: "URL",
    render: ({ data }) => (data.recordings[0].href ? new URL(data.recordings[0].href).hostname : ""),
  })
  .addColumn({
    id: "carrier",
    header: "Carrier Slug",
    render: ({ data }) => data?.recordings.find((recording) => recording.carrierSlug)?.carrierSlug || "",
  })
  .addColumn({
    id: "lineOfBusiness",
    header: "Line Of Business",
    render: ({ data }) => data?.recordings.find((recording) => recording.lineOfBusiness)?.lineOfBusiness || "",
  })
  .addColumn({
    id: "submissionId",
    header: "Submission ID",
    render: ({ data }) => {
      const submissionId = data?.recordings.find((recording) => recording.submissionId)?.submissionId || "";

      if (!submissionId) {
        return "";
      }
      return (
        <Stack direction={"row"} alignItems="center">
          <Stack px={2}>{submissionId}</Stack>
          <CopyClipboard value={submissionId} />
        </Stack>
      );
    },
  })
  .addColumn({
    id: "createdAt",
    header: "Created At",
    render: ({ data }) => `${new Date(data.date).toLocaleString("en-US", { timeZone: "America/Chicago" })} CST`,
  })
  .addColumn({
    id: "actions",
    header: "",
    render: ({ data }) => (
      <Stack direction="row" spacing={1}>
        <MoveRecordings recordingIds={data.recordings.map((recording) => recording.id)} />
      </Stack>
    ),
  })
  .build();

interface Props {
  loading: boolean;
  error?: Error | undefined;
  sessions: Session[];
}

export function SAPortalRecordingTable({ sessions, error, loading }: Props) {
  return (
    <Table
      {...config}
      data={sessions}
      emptyText="You don't have any screen captures yet."
      error={error}
      loading={loading}
    />
  );
}
