import { FormBuilder, FormyForm } from "@cp/forms";
import { useToaster } from "@cp/theme";
import React from "react";
import {
  CarrierProjectDocument,
  PortalAnnotationsDocument,
  useBulkUpdateClobQuestionMappingMutation,
} from "../generated/graphql";
import { EditableClobQuestion } from "../model/clobs/types";
import { conditionToInput } from "../model/conditions/conditionToInput";
import { useUpdateQuestions } from "../pages/clobs/MapClob/selection-atom";
import FormDialog from "./FormDialog";

const FORM_ID = "curation-question-form";

interface Props {
  open: boolean;
  onClose: () => void;
  clobQuestionIds: string[];
  curationQuestionId: string | undefined;
  notes?: string | null;
  onSuccess?: (questions: Array<Partial<EditableClobQuestion> & { id: string }>) => void;
}

interface QuestionMappingFormState {
  notes: string | undefined;
}

const FORM = FormBuilder.objectOf<QuestionMappingFormState>({
  notes: FormBuilder.string().options({ label: "Mapping notes" }).optional(),
});

export const QuestionMappingDialog: React.VFC<Props> = ({
  open,
  onClose,
  clobQuestionIds,
  curationQuestionId,
  notes,
  onSuccess = () => {
    return;
  },
}) => {
  const { toast } = useToaster();
  const [updateClobMappings, { loading }] = useBulkUpdateClobQuestionMappingMutation({
    // Refetch for updated counts
    refetchQueries: [PortalAnnotationsDocument, CarrierProjectDocument],
  });

  const [, setUpdateQuestions] = useUpdateQuestions();

  const handleSubmit = async (data: QuestionMappingFormState) => {
    if (clobQuestionIds.length > 0 && curationQuestionId !== undefined) {
      await updateClobMappings({
        variables: {
          mapping: {
            ids: clobQuestionIds,
            curationQuestionId: curationQuestionId,
            mappingNotes: data.notes,
          },
        },
        onCompleted: (newClobQuestions) => {
          // update internal state
          const updatedQuestions = newClobQuestions.bulkUpdateClobQuestionMapping.map((q) => {
            const updatedQuestion = {
              ...q,
              visibility: q.visibility.map(conditionToInput),
            };
            return updatedQuestion;
          });
          setUpdateQuestions(updatedQuestions);
          onSuccess(updatedQuestions);
          toast("CLOB question mapped to curation question");
          onClose();
        },
      });
    }
  };

  return (
    <FormDialog formId={FORM_ID} title="Add Notes For Mapping" loading={loading} open={open} onClose={onClose}>
      <FormyForm id={FORM_ID} form={FORM} onSubmit={handleSubmit} defaultValues={notes ? { notes } : {}} />
    </FormDialog>
  );
};
