import { BorderColor, InsertPhoto, NavigateBefore, NavigateNext } from "@mui/icons-material";
import { Box, Button, MenuItem, Select, Stack, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { useSelectedPortalAnnotationId, useSelectedRecordingId } from "../../../components/panels/atoms";
import { useNavigateItems } from "../../../components/recordings/useNavigateItems";
import { CarrierProjectQuery, Scalars } from "../../../generated/graphql";

type Data = CarrierProjectQuery["carrierProject"]["carrierRecordings"][0];

interface Props {
  recordings: Data[];
  viewerMode: "view" | "edit";
  setViewerMode: (mode: "view" | "edit") => void;
}

export const RecordingsNavbar: React.FC<Props> = ({ recordings, viewerMode, setViewerMode }) => {
  const [selectedRecording, setSelectedRecording] = useSelectedRecordingId();
  const [, setSelectedAnnotationId] = useSelectedPortalAnnotationId();

  const onChange = (selectedRecording: Scalars["CarrierRecordingId"]) => {
    setSelectedRecording(selectedRecording);
    setSelectedAnnotationId(undefined);
  };

  const { prev, current, next, canPrev, canNext } = useNavigateItems(
    recordings.map((r) => r.id),
    onChange
  );

  if (!selectedRecording) {
    return <></>;
  }

  return (
    <Stack
      sx={{
        justifyContent: "space-between",
        backgroundColor: "white",
        borderBottom: "1px solid",
        borderColor: "divider",
      }}
    >
      <Stack direction="row" sx={{ justifyContent: "space-between" }}>
        <Button
          variant="outlined"
          sx={NavButtonSx}
          disabled={!canPrev(selectedRecording)}
          onClick={() => prev(selectedRecording)}
        >
          <NavigateBefore />
        </Button>
        <Select
          value={selectedRecording ?? ""}
          SelectDisplayProps={{ style: { paddingTop: "8px", background: "none" } }}
          sx={{ background: "none", border: "none" }}
          onChange={(e) => onChange(e.target.value as Scalars["CarrierRecordingId"])}
        >
          {recordings.map((recording) => (
            <MenuItem key={recording.id} value={recording.id}>
              {recording.name} ({recording.annotationsCount - recording.unmappedAnnotationCount}/
              {recording.annotationsCount})
            </MenuItem>
          ))}
        </Select>
        <Button
          variant="outlined"
          sx={NavButtonSx}
          disabled={!canNext(selectedRecording)}
          onClick={() => next(selectedRecording)}
        >
          <NavigateNext />
        </Button>
      </Stack>
      <Stack
        direction="row"
        flex={1}
        sx={{ justifyContent: "space-between", alignItems: "center", marginTop: "18px", marginBottom: "8px" }}
      >
        <Stack>
          <ToggleButtonGroup
            value={viewerMode}
            exclusive={true}
            onChange={(_, value) => setViewerMode(value)}
            size="small"
            color="primary"
          >
            <ToggleButton value="edit">
              <BorderColor fontSize="small" />
            </ToggleButton>
            <ToggleButton value="view">
              <InsertPhoto fontSize="small" />
            </ToggleButton>
          </ToggleButtonGroup>
        </Stack>
        <Stack direction="row" flex={1} sx={{ justifyContent: "center", gap: 2 }}>
          <Box sx={SelectedHighlightSx}>Auto-selected</Box>
          <Box sx={TranscribedHighlightSx}>Transcribed</Box>
          <Box sx={MappedHighlightSx}>Mapped</Box>
        </Stack>
        {current(selectedRecording) + 1} of {recordings.length}
      </Stack>
    </Stack>
  );
};

const NavButtonSx = {
  backgroundColor: "primary.light",
  border: "none",
  padding: "0px",
  minWidth: "30px",
  width: "30px",
  height: "30px",
};

const HighlightSx = {
  borderRadius: "4px",
  display: "flex",
  alignItems: "center",
  paddingLeft: "5px",
  paddingRight: "5px",
  whiteSpace: "nowrap",
  overflow: "hidden",
};

const SelectedHighlightSx = {
  ...HighlightSx,
  border: "2px dotted red",
};

const TranscribedHighlightSx = {
  ...HighlightSx,
  backgroundColor: "rgba(52, 158, 235, 0.5)",
};

const MappedHighlightSx = {
  ...HighlightSx,
  backgroundColor: "rgba(52, 235, 82, 0.8)",
};
