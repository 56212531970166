import { Box, Stack } from "@mui/material";
import React from "react";
import { Scalars } from "../../generated/graphql";
import { Iframe } from "../Iframe";

interface Props {
  recordingId: Scalars["CarrierRecordingId"];
  toggleable?: boolean;
  mode: "view" | "edit";
}

export const ScreenCaptureViewer: React.FC<Props> = ({ recordingId, toggleable = true, mode }) => {
  return (
    <Stack flex={1}>
      {mode === "edit" && (
        <Iframe key={recordingId} title="Recording" id="recording-iframe" src={`/api/recording/${recordingId}/html`} />
      )}
      {mode === "view" && (
        <Box
          component="img"
          alt="screenshot"
          src={`/api/recording/${recordingId}/screenshot.png`}
          sx={{ width: "100%" }}
        />
      )}
    </Stack>
  );
};
