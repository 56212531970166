import { useToaster } from "@cp/theme";
import { Assignment, SvgIconComponent } from "@mui/icons-material";
import { Icon } from "@mui/material";
import React from "react";

interface Props {
  value: string;
  kind?: string;
  icon?: SvgIconComponent;
}

export function CopyClipboard({ value, kind, icon }: Props) {
  const { toast } = useToaster();

  if (!value) {
    return null;
  }

  const iconComponent = icon === undefined ? Assignment : icon;

  return (
    <Icon
      component={iconComponent}
      fontSize="inherit"
      sx={{
        cursor: "pointer",
        "&:hover": {
          transform: "scale(1.2)",
        },
      }}
      onClick={(evt: React.MouseEvent) => {
        evt.stopPropagation();
        toast(kind === undefined ? `Copied to clipboard` : `Copied ${kind} to clipboard`);
        navigator.clipboard.writeText(value);
      }}
    />
  );
}
