import { ArchiveAction, UnarchiveAction } from "./actions";
import { ActionItem, ItemType } from "./types";

const ALL_ACTIONS = [ArchiveAction, UnarchiveAction];

/**
 * Get all actions that accept the given items.
 */
export function getActions(items: Record<ItemType, string[]>): Array<ActionItem<any>> {
  const matches: Array<ActionItem<any>> = [];

  for (const action of ALL_ACTIONS) {
    let match = true;
    for (const [type, config] of Object.entries(action.accepts)) {
      const ids = items[type as ItemType];
      // No items of this type
      if (!ids) {
        match = false;
        break;
      }
      // Multiple items of this type, but action doesn't accept multiple
      if (!config.multi && ids.length > 1) {
        match = false;
        break;
      }
    }

    if (match) {
      matches.push(action);
    }
  }

  return matches;
}
