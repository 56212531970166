import React from "react";
import { CurationQuestionAutocomplete } from "../../../components/curation-questions/CurationQuestionAutocomplete";
import { useCurationActions } from "../state/useCurationActions";

interface Props {
  parentNodeId: string;
}

export const AddCurationQuestionField: React.FC<Props> = ({ parentNodeId }) => {
  const actions = useCurationActions();

  return (
    <CurationQuestionAutocomplete
      placeholderText="Add question"
      onChange={(id) => {
        if (id) {
          actions.moveToNewNode({
            fromId: undefined,
            toId: parentNodeId,
            nodeId: id,
          });
        }
      }}
    />
  );
};
