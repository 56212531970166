import { useToaster } from "@cp/theme";
import { useMemo } from "react";
import { useArchiveCurationQuestionMutation, useUnarchiveCurationQuestionMutation } from "../../../generated/graphql";
import { useSelectedCurationQuestions } from "../../clobs/MapClob/selection-atom";
import { useCurationActions } from "./useCurationActions";
import { useCurationState } from "./useCurationState";

export const useSwapCurationQuestions = (idToRemove: string) => {
  const { swapNode } = useCurationActions();
  const { id: curationApplicationId, getQuestion, curationNodeMap } = useCurationState();
  const [, setSelectedCurationQuestions] = useSelectedCurationQuestions();
  const [archiveCurationQuestion] = useArchiveCurationQuestionMutation();
  const [unarchiveCurationQuestion] = useUnarchiveCurationQuestionMutation();
  const { toast, toastUndo } = useToaster();

  // map to get parents of an entity
  const parentNodeMap = useMemo(() => {
    const map = new Map<string, string>();
    for (const [id, entity] of curationNodeMap) {
      for (const childId of entity.children) {
        map.set(childId, id);
      }
    }
    return map;
  }, [curationNodeMap]);

  const parentId = idToRemove ? parentNodeMap.get(idToRemove) : null;
  const isGolden = idToRemove ? getQuestion(idToRemove)?.golden : false;

  if (!parentId || !curationApplicationId) {
    return () => {
      toast("Something went wrong with question swapping", { variant: "error" });
    };
  }

  const swap = async (insertNodeId: string) => {
    await swapNode({
      insertNodeId,
      removeNodeId: idToRemove,
      parentId,
    });

    if (!isGolden) {
      // TODO: hard delete instead of archiving
      // TODO: ideally we only do if swapNode was successful
      await archiveCurationQuestion({ variables: { id: idToRemove } });
    }
    setSelectedCurationQuestions([]);
  };

  const undoSwap = async (insertNodeId: string) => {
    if (!isGolden) {
      await unarchiveCurationQuestion({ variables: { id: idToRemove } });
    }
    await swapNode({
      insertNodeId: idToRemove,
      removeNodeId: insertNodeId,
      parentId,
    });
    // Reset atoms to their previous state
    setSelectedCurationQuestions([idToRemove]);
  };

  const callback = async (insertNodeId: string) => {
    await swap(insertNodeId);

    toastUndo("Swap successful", {
      onUndo: () => undoSwap(insertNodeId),
      autoHideDuration: 6000,
    });
  };

  return callback;
};
