import { useEvent } from "@cp/react-hooks";
import {
  closestCenter,
  DndContext,
  DragOverlay,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { DragEndEvent, DragStartEvent } from "@dnd-kit/core/dist/types";
import { SortableContext, sortableKeyboardCoordinates, verticalListSortingStrategy } from "@dnd-kit/sortable";
import React, { useCallback, useEffect, useState } from "react";
import { ListItem } from "./components/ListItem";
import { SortableListItem } from "./components/SortableListItem";
import { createSortablePayloadByIndex, getBetweenRankAsc, sortByLexoRankAsc } from "./helpers";
import { IId, IListItemData } from "./types";

interface Props {
  initialItems: IListItemData[];
  renderItem: (item: IId, index?: number) => React.ReactNode;
  onUpdateRank: (item: IId, rank: string) => void;
}

export const LexorankList: React.FC<Props> = ({ initialItems, onUpdateRank, renderItem }) => {
  const [activeId, setActiveId] = useState<string | null>(null);
  const [items, setItems] = useState<IListItemData[]>(initialItems);
  useEffect(() => setItems(initialItems), [initialItems]);
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 8,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const handleDragStart = useCallback(
    (event: DragStartEvent) => {
      const { active } = event;
      setActiveId(active.id.toString());
    },
    [setActiveId]
  );

  const handleDragEnd = useEvent((event: DragEndEvent) => {
    const { active, over } = event;
    if (active.id !== over?.id) {
      setItems((oldItems) => {
        // find prev, current, next items
        const sortablePayload = createSortablePayloadByIndex(oldItems, event);
        // calculate new rank
        const newRank = getBetweenRankAsc(sortablePayload);
        const newItems = [...oldItems];
        const currIndex = oldItems.findIndex((x) => x.id === sortablePayload.entity.id);
        // replace current rank
        newItems[currIndex] = { ...newItems[currIndex], lexoRank: newRank.toString() } as IListItemData;
        // update rank in backend, non-blocking
        onUpdateRank(newItems[currIndex], newRank.toString());
        // sort by rank
        return newItems.sort(sortByLexoRankAsc);
      });
    }

    setActiveId(null);
  });

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
    >
      <SortableContext items={items} strategy={verticalListSortingStrategy}>
        {items.map((item, i) => (
          <SortableListItem key={item.id} id={item.id} renderItem={renderItem} index={i} />
        ))}
      </SortableContext>
      <DragOverlay>
        {activeId ? <ListItem className="dragOverlay" id={activeId} renderItem={renderItem} /> : null}
      </DragOverlay>
    </DndContext>
  );
};
