import { FormBuilder as FB } from "@cp/forms";
import {
  ApplicationConditionBooleanInput,
  ApplicationConditionBooleanOperator,
  ApplicationConditionInput,
  ApplicationConditionNumberInput,
  ApplicationConditionNumberOperator,
  ApplicationConditionStringInput,
  ApplicationConditionStringOperator,
  ApplicationConditionStringSetInput,
  ApplicationConditionStringSetOperator,
  CurationQuestionFragment,
} from "../../generated/graphql";
import { EditableClobQuestion } from "../../model/clobs/types";

export const QUESTIONS_OPTIONS_KEY = "questions";

const subject = FB.select<CurationQuestionFragment | EditableClobQuestion>({
  optionsKey: QUESTIONS_OPTIONS_KEY,
  getLabel: (question) => question.text,
  getValue: (question) => question.id,
  autocomplete: {
    table: {
      headers: ["Key", "Question"],
      getRow: (question) => {
        if (question.__typename === "ClobQuestion") {
          return [question.section.join(" > "), question.text];
        }
        return [question.key, question.text];
      },
    },
  },
}).options({ label: "Subject" });

const booleanCondition = FB.objectOf<ApplicationConditionBooleanInput>({
  subject: subject,
  booleanOperator: FB.enum(Object.values(ApplicationConditionBooleanOperator)).options({
    defaultValue: ApplicationConditionBooleanOperator.IsTrue,
  }),
}).options({ rowFlex: [2, 1, 1], alignItems: "center" });

const stringCondition = FB.objectOf<ApplicationConditionStringInput>({
  subject: subject,
  stringOperator: FB.enum(Object.values(ApplicationConditionStringOperator)).options({
    defaultValue: ApplicationConditionStringOperator.Equals,
  }),
  stringValue: FB.string().options({ label: "Value" }),
}).options({ rowFlex: [2, 1, 1], alignItems: "center" });

const stringSetCondition = FB.objectOf<ApplicationConditionStringSetInput>({
  subject: subject,
  stringSetOperator: FB.enum(Object.values(ApplicationConditionStringSetOperator)).options({
    defaultValue: ApplicationConditionStringSetOperator.IncludedIn,
  }),
  stringSetValue: FB.arrayOf(FB.string().options({ label: "Value" })),
}).options({ rowFlex: [2, 1, 1], alignItems: "center" });

const numberCondition = FB.objectOf<ApplicationConditionNumberInput>({
  subject: subject,
  numberOperator: FB.enum(Object.values(ApplicationConditionNumberOperator)).options({
    defaultValue: ApplicationConditionNumberOperator.GreaterThan,
  }),
  numberValue: FB.string().options({ label: "Value" }),
}).options({ rowFlex: [2, 1, 1], alignItems: "center" });

const titles = {
  booleanCondition: "Boolean condition",
  stringCondition: "String condition",
  stringSetCondition: "List of String condition",
  numberCondition: "Number condition",
  matchAnyCondition: "Match any condition",
  matchAllCondition: "Match all condition",
};

const leafCondition = FB.oneOf<ApplicationConditionInput>({
  booleanCondition: booleanCondition,
  stringCondition: stringCondition,
  stringSetCondition: stringSetCondition,
  numberCondition: numberCondition,
}).options({ titles });

export const condition = FB.oneOf<ApplicationConditionInput>({
  booleanCondition: booleanCondition,
  stringCondition: stringCondition,
  stringSetCondition: stringSetCondition,
  numberCondition: numberCondition,
  matchAnyCondition: FB.objectOf({
    matchAnyConditions: FB.arrayOf(leafCondition),
  }),
  matchAllCondition: FB.objectOf({
    matchAllConditions: FB.arrayOf(leafCondition),
  }),
}).options({ titles });

export const CONDITIONS_FORM = FB.arrayOf(condition);
