export const acordLabel = (eLabelId?: number | null, eLabelCounter?: string | null) => {
  if (eLabelId && eLabelCounter) {
    return `${eLabelId}_${eLabelCounter}`;
  }
  if (eLabelId && !eLabelCounter) {
    return `${eLabelId}_A`;
  }

  return null;
};
