import { useBoolean } from "@cp/react-hooks";
import { CloseFullscreen, ZoomOutMap } from "@mui/icons-material";
import { Dialog, DialogTitle, IconButton, Stack, Typography } from "@mui/material";
import React, { PropsWithChildren } from "react";

interface Props {
  title: string;
  helperText?: string | React.ReactNode | null;
}

/**
 * Wraps a table in a border and a title.
 * Also adds a button to toggle fullscreen mode.
 */
export const TableWrapper: React.FC<PropsWithChildren<Props>> = ({ title, helperText, children }) => {
  const [isFullScreen, { toggle: toggleFullScreen }] = useBoolean(false);

  return (
    <Stack
      borderRadius={1}
      boxShadow={0}
      border="2px solid"
      borderColor="divider"
      sx={{ backgroundColor: "background.alternate" }}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        px={2}
        height={48}
        borderBottom="1px solid"
        borderColor="divider"
      >
        <Typography variant="h4" color="text.primary" sx={{ m: 0 }}>
          {title}
        </Typography>
        <IconButton size="small" onClick={toggleFullScreen}>
          <ZoomOutMap fontSize="small" />
        </IconButton>
      </Stack>
      {children}
      {helperText && (
        <Typography variant="body2" color="grey.600" px={2} py={1}>
          {helperText}
        </Typography>
      )}
      <Dialog
        open={isFullScreen}
        onClose={toggleFullScreen}
        PaperProps={{ sx: { minWidth: "95vw", minHeight: "90vh" } }}
      >
        <DialogTitle display="flex" justifyContent="space-between">
          {title}
          <IconButton onClick={toggleFullScreen}>
            <CloseFullscreen />
          </IconButton>
        </DialogTitle>
        {children}
      </Dialog>
    </Stack>
  );
};
