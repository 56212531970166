import { Stack, Typography, TypographyProps } from "@mui/material";
import { MuiMarkdown } from "mui-markdown";

interface FormHeaderProps extends TypographyProps {
  depth: number;
  helperText?: string | null;
}

export function FormHeader({ children, depth, helperText, sx, ...rest }: FormHeaderProps) {
  const depthProps = depthToTypographyProps(depth);
  helperText = helperText?.replaceAll("\n", "  \n");
  return (
    <Stack gap="12px">
      {children && (
        <Typography {...rest} {...depthProps} sx={{ mt: "16px", mb: 0, ...sx }}>
          {children}
        </Typography>
      )}
      {helperText && (
        <Typography variant="body2">
          <MuiMarkdown>{helperText}</MuiMarkdown>
        </Typography>
      )}
    </Stack>
  );
}

const depthToTypographyProps = (depth: number): TypographyProps => {
  switch (depth) {
    case 1:
      return { variant: "h1" };
    case 2:
      return { variant: "h2" };
    case 3:
      return { variant: "h3" };
    default:
    case 4:
      return { variant: "h4" };
  }
};
