import { Chip, Stack } from "@mui/material";
import { uniq } from "lodash";
import React, { useState } from "react";
import { TagSelect } from "../../components/buttons/TagSelect";
import {
  QuestionlessCurationApplicationFragment,
  useClobQuestionMetadataSummaryQuery,
  useCurationQuestionsFilteredQuery,
} from "../../generated/graphql";
import { PreviewApplication } from "./PreviewApplication";

interface Props {
  curationApplication: QuestionlessCurationApplicationFragment;
}

export const FilterablePreviewApplication: React.VFC<Props> = React.memo(({ curationApplication }) => {
  const [carrierSlugs, setCarrierSlugs] = useState<string[]>([]);
  const [lineOfBusiness, setLineOfBusiness] = useState<string>();

  const { data: metadataSummary } = useClobQuestionMetadataSummaryQuery();
  const { data: previewApplicationData } = useCurationQuestionsFilteredQuery({
    variables: {
      filter: {
        carrierSlugs: carrierSlugs,
        lineOfBusiness: lineOfBusiness,
      },
    },
  });
  const previewApplication = previewApplicationData?.curationQuestionsWithRequiredness;

  const carrierSlugsOptions =
    metadataSummary?.clobQuestionMetadataSummary.carrierSlugs.map((slug) => ({
      label: slug,
      value: slug,
    })) ?? [];
  const lineOfBusinessesOptions =
    metadataSummary?.clobQuestionMetadataSummary.linesOfBusiness.map((lob) => ({
      label: lob,
      value: lob,
    })) ?? [];

  return (
    <Stack flex={1} sx={{ overflowY: "hidden" }}>
      <Stack
        flexWrap="wrap-reverse"
        flexShrink={0}
        direction="row"
        justifyContent="flex-end"
        gap={2}
        borderBottom="1px solid"
        borderColor="divider"
        p={2}
      >
        {carrierSlugs.map((slug) => (
          <Chip
            key={slug}
            color="info"
            variant="outlined"
            label={slug}
            size="small"
            sx={{ borderRadius: 1 }}
            onDelete={() => setCarrierSlugs((prev) => prev.filter((s) => s !== slug))}
          />
        ))}
        {lineOfBusiness && (
          <Chip
            key={lineOfBusiness}
            color="warning"
            variant="outlined"
            label={lineOfBusiness}
            size="small"
            sx={{ borderRadius: 1 }}
            onDelete={() => setLineOfBusiness(undefined)}
          />
        )}
        <TagSelect
          onAdd={(slug) => setCarrierSlugs((prev) => uniq([...prev, slug]))}
          label="Carrier"
          options={carrierSlugsOptions}
        />
        <TagSelect onAdd={(lob) => setLineOfBusiness(lob)} label="Line of Business" options={lineOfBusinessesOptions} />
      </Stack>
      {previewApplication && <PreviewApplication {...curationApplication} questions={previewApplication} />}
    </Stack>
  );
});

FilterablePreviewApplication.displayName = "FilterablePreviewApplication";
