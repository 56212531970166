import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import * as React from "react";
import { Events } from "../util/events";

interface FormDialogProps {
  formId: string;
  title: string;
  open: boolean;
  onClose: () => void;
  loading?: boolean;
  confirmText?: string;
  children?: React.ReactNode;
}

export default function FormDialog({
  formId,
  title,
  open,
  loading,
  onClose,
  children,
  confirmText = "Save",
}: FormDialogProps) {
  return (
    <Dialog
      data-test="form-dialog"
      open={open}
      onClose={onClose}
      onClick={Events.stopPropagation}
      sx={{ ".MuiDialog-paper": { width: "672px", maxWidth: "672px" } }}
    >
      <DialogTitle sx={{ boxShadow: "0px 1px 0px rgba(0, 0, 0, 0.05)" }}>{title}</DialogTitle>
      <DialogContent>{children}</DialogContent>
      <DialogActions sx={{ boxShadow: "0px -1px 0px rgba(0, 0, 0, 0.05)" }}>
        <Button variant="inverted" onClick={onClose}>
          Cancel
        </Button>
        <Box sx={{ flexGrow: 1 }} />
        <Button type="submit" form={formId} onClick={Events.stopPropagation} data-test="form-save-button">
          {confirmText}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
