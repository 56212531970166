import React from "react";
import { useFormContext } from "react-hook-form";
import { useSaveForm } from "../form-fields/common/useSaveForm";
import { FormQuestionFragment } from "../generated/operations";
import { FormTable } from "./FormTable";
import { useSetDefaultTableValues } from "./useSetDefaultTableValues";

interface Props {
  title: string;
  helperText?: string | null;
  allChildrenIds: string[];
  visibleFields: Array<Omit<FormQuestionFragment, "__typename">>;
  canAddRows: boolean;
  fixedColumns?: number;
  defaultValues?: string;
  applicationId?: string;
}

export const EditableTable: React.FC<Props> = ({
  title,
  allChildrenIds,
  visibleFields,
  canAddRows,
  helperText,
  defaultValues,
  fixedColumns,
  applicationId,
}) => {
  const methods = useFormContext();
  const saveValue = useSaveForm();

  useSetDefaultTableValues(defaultValues, allChildrenIds, saveValue);

  return (
    <FormTable
      title={title}
      helperText={helperText}
      allChildrenIds={allChildrenIds}
      visibleFields={visibleFields}
      canAddRows={canAddRows}
      fixedColumns={fixedColumns}
      defaultValues={defaultValues}
      readonly={false}
      getValues={methods.getValues}
      onSaveValue={(key, value) => {
        methods.setValue(key, value);
        saveValue(key, value);
      }}
      applicationId={applicationId}
    />
  );
};
