import { Boundary } from "@cp/async";
import { Divider, Stack } from "@mui/material";
import React from "react";
import RenderIfVisible from "react-render-if-visible";
import { formatConditions } from "../../../../components/curation-questions/conditionalUtil";
import { MappedClobQuestionList } from "../../../../components/curation-questions/MappedClobQuestionList";
import { PropertyList, PropertyPairs } from "../../../../components/properties/PropertyList";
import { CurationQuestionFragment } from "../../../../generated/graphql";
import { useCurationState } from "../../../curation/state/useCurationState";

interface Props {
  id: string;
  search: string;
  question: CurationQuestionFragment | undefined;
}

export const CurationQuestionBody: React.FC<Props> = React.memo(({ id, search, question }) => {
  const { getQuestion } = useCurationState();

  if (!question) {
    return null;
  }

  const { text, key, componentType, options, notes, conditions } = question;

  const properties: PropertyPairs = [
    ["Question", text],
    ["Key", key],
    ["Component Type", componentType],
    ["Options", options.join(", ")],
    ["Notes", notes],
    ["Conditions", formatConditions(conditions, getQuestion)],
    ["ID", id],
  ];

  return (
    <Stack flex={1}>
      <PropertyList searchQuery={search} pairs={properties} />
      <Divider sx={{ pt: 2, mb: 2 }} />
      <Boundary>
        <RenderIfVisible>
          <MappedClobQuestionList curationQuestionId={id} disableEditTools={true} curationQuestionKey={key} />
        </RenderIfVisible>
      </Boundary>
    </Stack>
  );
});

CurationQuestionBody.displayName = "CurationQuestionBody";
