import { useBoolean } from "@cp/react-hooks";
import { TextField, Typography } from "@mui/material";
import React from "react";

interface Props {
  value: string;
  onChange: (value: string) => void;
}

export const EditableText: React.VFC<Props> = ({ value, onChange }) => {
  const [isFocused, { setTrue, setFalse }] = useBoolean(false);

  if (isFocused) {
    return (
      <TextField
        autoFocus={true}
        fullWidth={true}
        size="small"
        defaultValue={value}
        onFocus={(e) => e.target.select()}
        onBlur={(evt) => {
          onChange(evt.target.value);
          setFalse();
        }}
      />
    );
  }

  return (
    <Typography
      component="div"
      onFocus={setTrue}
      tabIndex={0}
      flex={1}
      sx={{
        p: "8px",
        "&:hover": {
          cursor: "pointer",
          textDecoration: "underline",
        },
      }}
      onClick={setTrue}
    >
      {value}
    </Typography>
  );
};
