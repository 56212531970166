import { deferred } from "@cp/toolkit";

export function download(name: string, text: string) {
  const element = document.createElement("a");
  element.setAttribute("href", `data:text/plain;charset=utf-8,${encodeURIComponent(text)}`);
  element.setAttribute("download", name);

  element.style.display = "none";
  document.body.append(element);

  element.click();

  element.remove();
}

export function imperativeUpload(): Promise<string> {
  const input = document.createElement("input");
  input.type = "file";

  const promise = deferred<string>();

  input.onchange = (e) => {
    const file = (e.target as HTMLInputElement).files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const text = e.target?.result;
        if (text) {
          promise.resolve(text as string);
        }
      };
      reader.onerror = (err) => {
        promise.reject(err);
      };
      reader.readAsText(file);
    }
  };
  input.click();

  return promise.promise;
}
