import { Checkbox } from "@mui/material";
import React from "react";
import { useCurationFeature } from "../../../curation/state/useCurationFeature";
import { useSwapCurationQuestions } from "../../../curation/state/useSwapCurationQuestions";
import {
  useSelectedClobQuestions,
  useSelectedCurationQuestions,
  useSelectedCurationQuestionsSwapCallbacks,
} from "../selection-atom";
import { AnnotationMappingDot } from "./AnnotationMappingDot";
import { ClobMappingDot } from "./ClobMappingDot";

interface Props {
  id: string;
  disableSelection?: boolean;
  hideMappingIndicator?: boolean;
}

export const CurationQuestionMappingIndicator: React.FC<Props> = ({
  id,
  disableSelection,
  hideMappingIndicator = false,
}) => {
  const showAnnotationMappingDot = useCurationFeature("showAnnotationMappingDot");
  const showClobMappingDot = useCurationFeature("showClobMappingDot");
  const [selectedClobQuestions] = useSelectedClobQuestions();
  const [selectedCurationQuestions, setSelectedCurationQuestions] = useSelectedCurationQuestions();
  const [selectedCurationQuestionsSwapCallbacks] = useSelectedCurationQuestionsSwapCallbacks();
  const swapCurationQuestions = useSwapCurationQuestions(id);

  const shouldUnselect = selectedCurationQuestions?.includes(id);
  const shouldChangeSelection = selectedClobQuestions.length > 0;

  const renderMappingDot = () => {
    if (showAnnotationMappingDot) {
      return <AnnotationMappingDot id={id} />;
    }
    if (showClobMappingDot && selectedClobQuestions.length === 0 && !hideMappingIndicator) {
      return <ClobMappingDot id={id} />;
    }
    return null;
  };

  return (
    <>
      {renderMappingDot()}
      <Checkbox
        sx={{ p: "1px" }}
        disabled={disableSelection}
        checked={selectedCurationQuestions?.includes(id)}
        onClick={(evt) => {
          evt.stopPropagation();
          if (shouldUnselect) {
            setSelectedCurationQuestions(selectedCurationQuestions.filter((qId: string) => qId !== id));
          } else if (shouldChangeSelection) {
            setSelectedCurationQuestions([id]);
            selectedCurationQuestionsSwapCallbacks.set(id, swapCurationQuestions);
          } else {
            setSelectedCurationQuestions([...selectedCurationQuestions, id]);
            selectedCurationQuestionsSwapCallbacks.set(id, swapCurationQuestions);
          }
        }}
      />
    </>
  );
};
