import { InputBase, styled } from "@mui/material";

export const EditInputCell = styled(InputBase)(({ theme }) => ({
  ...theme.typography.body2,
  fontSize: "12px",
  mr: 3,
  "& input": {
    height: "100%",
  },
}));
