export enum DragTypes {
  QUESTION = "Question",
  CLOB_QUESTION = "ClobQuestion",
  PDF_ANNOTATION = "PdfAnnotation",
  SECTION = "Section",
}

export type DragSource = { type: "uncurated" } | { type: "section"; sectionId: string } | { type: "root" };
export const DragSources = {
  uncurated: { type: "uncurated" } as const,
  root: { type: "root" } as const,
  section: (sectionId: string) => ({ type: "section", sectionId } as const),
};

export type Dragable =
  | {
      type: DragTypes.PDF_ANNOTATION;
    }
  | {
      id: string;
      type: DragTypes.CLOB_QUESTION;
    }
  | {
      id: string;
      index: number;
      type: DragTypes.QUESTION;
      source: DragSource;
    }
  | {
      id: string;
      index: number;
      type: DragTypes.SECTION;
      source: DragSource;
    };

export type MoveHandler = (opts: { dragItemId: string; moveIndex: number }) => void;

export type DragableItemProps<T extends object> = Omit<T, "dragRef"> & {
  index: number;
  move: MoveHandler;
  source: DragSource;
};

export type DropResult =
  | {
      type: "create-curation-question";
      onInsert: (curationQuestionId: string) => void;
    }
  | {
      type: "connect-curation-question";
      curationQuestionId: string;
    }
  | {
      type: "inside-section-id";
      sectionId: string;
    }
  | {
      type: "unknown";
    };
