import { CurationNode } from "../pages/curation/state/types";

export function getAllNestedChildIds(
  nodeId: string,
  curationNodeMap: ReadonlyMap<string, CurationNode>,
  allChildIds = new Set<string>()
) {
  const node = curationNodeMap.get(nodeId);
  if (!node) {
    allChildIds.add(nodeId);
    return allChildIds;
  }

  allChildIds.add(node.id);
  node.children.forEach((childId) => {
    // Prevent infinite recursion
    if (allChildIds.has(childId)) {
      return;
    }
    getAllNestedChildIds(childId, curationNodeMap, allChildIds);
  });

  return allChildIds;
}
