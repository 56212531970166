import { Close, KeyboardArrowDown, KeyboardArrowUp, Search } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { CircularProgress, Divider, IconButton, InputBase, Paper, Stack, Tooltip, Typography } from "@mui/material";
import { useAtom } from "jotai";
import { useEffect, useState } from "react";
import { searchQueryAtom, searchResults, useSearchCurationNodes } from "../pages/curation/atoms";
import { useAllCurationQuestions } from "../pages/curation/state/useAllCurationQuestions";
import { useCurationState } from "../pages/curation/state/useCurationState";
import { useAtomScope } from "./panels/atoms";

export function CurationNodeSearch() {
  const questions = useAllCurationQuestions();
  const { curationNodeMap } = useCurationState();
  const { onSearch, scrollToNextSearchHit, scrollToPrevSearchHit, scrollToFirstSearchHit, index, total } =
    useSearchCurationNodes(curationNodeMap, questions);

  const [value, setValue] = useAtom(searchQueryAtom, useAtomScope());

  return (
    <CustomCurationNodeSearch
      value={value}
      setValue={setValue}
      onSearch={onSearch}
      scrollToFirstSearchHit={scrollToFirstSearchHit}
      scrollToNextSearchHit={scrollToNextSearchHit}
      scrollToPrevSearchHit={scrollToPrevSearchHit}
      index={index}
      total={total}
    />
  );
}

interface CustomCurationNodeSearchProps {
  value: string;
  setValue: (update: string) => void;
  onSearch: (query: string) => void;
  scrollToFirstSearchHit: () => void;
  scrollToNextSearchHit: () => void;
  scrollToPrevSearchHit: () => void;
  index: number;
  total: number;
}

export function CustomCurationNodeSearch({
  value,
  setValue,
  onSearch,
  scrollToFirstSearchHit,
  scrollToNextSearchHit,
  scrollToPrevSearchHit,
  index,
  total,
}: CustomCurationNodeSearchProps) {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  };

  const handleClear = () => {
    if (value !== "") {
      setValue("");
      onSearch("");
    }
  };

  const [showSpinner, setShowSpinner] = useState(false);

  const [results] = useAtom(searchResults, useAtomScope());

  useEffect(() => {
    async function onSearchComplete() {
      setShowSpinner(false);
      await new Promise((r) => setTimeout(r, 0));
      scrollToFirstSearchHit();
    }
    void onSearchComplete();
  }, [results]);

  return (
    <Stack direction="row" alignItems="flex-end">
      <Paper component="form" sx={{ p: "2px 4px", display: "flex", alignItems: "center" }}>
        <LoadingButton
          loading={showSpinner}
          loadingIndicator={<CircularProgress disableShrink={true} thickness={3} size="20px" color="primary" />}
          size="large"
          onClick={async () => {
            setShowSpinner(true);
            // Need it to display the spinner, otherwise eventloop only updates showSpinner only after the page is rendered.
            await new Promise((r) => setTimeout(r, 0));
            onSearch(value);
          }}
        >
          <Search />
        </LoadingButton>

        <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
        <InputBase
          value={value}
          onChange={handleChange}
          size="small"
          placeholder="Search"
          disabled={false}
          sx={{ p: "2px 2px 2px 8px" }}
          onKeyDown={async (e) => {
            if (e.code === "Enter") {
              // Search on enter.
              e.preventDefault(); // Refreshes current page if not.
              setShowSpinner(true);
              // Need it to display the spinner, otherwise eventloop only updates showSpinner only after the page is rendered.
              await new Promise((r) => setTimeout(r, 0));
              onSearch(value);
            } else if (e.ctrlKey && e.key === "n") {
              scrollToNextSearchHit();
            } else if (e.ctrlKey && e.key === "p") {
              scrollToPrevSearchHit();
            }
          }}
          endAdornment={<SearchCount value={value} index={index} total={total} />}
        />
        <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
        <IconButton type="button" size="small" sx={{ p: "10px" }} onClick={scrollToPrevSearchHit}>
          <Tooltip title="ctrl-p">
            <KeyboardArrowUp fontSize="small" />
          </Tooltip>
        </IconButton>
        <IconButton type="button" size="small" sx={{ p: "10px" }} onClick={scrollToNextSearchHit}>
          <Tooltip title="ctrl-n">
            <KeyboardArrowDown fontSize="small" />
          </Tooltip>
        </IconButton>
        <IconButton type="button" size="small" sx={{ p: "10px" }} onClick={handleClear}>
          <Close fontSize="small" />
        </IconButton>
      </Paper>

      {results !== "loading" && (
        <Typography fontStyle="italic" sx={{ fontSize: "11px" }} color="primary" align="right">
          {!showSpinner && `* ${results.length} Questions/Sections Matched`}
          {showSpinner && `Searching...`}
        </Typography>
      )}
    </Stack>
  );
}

function SearchCount({ value, index, total }: { value: string; index: number; total: number }) {
  if (value === "") {
    return null;
  }

  if (total === 0) {
    return null;
  }

  return (
    <Typography sx={{ fontSize: "10px", flexShrink: 0 }}>{`${total > 0 ? index + 1 : index} / ${total}`}</Typography>
  );
}
