import { Arrays, assertNever } from "@cp/toolkit";
import { uniq } from "lodash";
import type { Reducer } from "react";
import { v4 as uuidV4 } from "uuid";
import { CurationNodeType } from "../../../generated/graphql";
import { CurationContextState, ROOT } from "./types";

export type CurationTreeState = Omit<CurationContextState, "questionIds" | "sectionIds">;

export type CurationAction =
  | { type: "SET_STATE"; state: CurationTreeState }
  | { type: "REMOVE_CURATION_SECTION"; id: string }
  | { type: "MOVE_TO_NEW_NODE"; nodeId: string; fromId: string | undefined; toId: string; beforeId?: string }
  | { type: "MOVE_WITHIN_NODE"; parentId: string; toIndex: number; childId: string }
  | { type: "ADD_NODE"; parentNodeId: string | undefined; newId: string }
  | { type: "REMOVE_NODE"; nodeId: string }
  | { type: "SWAP_NODE"; insertNodeId: string; removeNodeId: string; parentId: string }
  | { type: "MAKE_CONDITIONAL_QUESTION"; questionId: string }
  | { type: "UNMAKE_CONDITIONAL_QUESTION"; questionId: string };

type CurationReducer = Reducer<CurationTreeState, CurationAction>;

export const curationReducer: CurationReducer = (previousState, action) => {
  switch (action.type) {
    case "SET_STATE":
      return action.state;

    case "REMOVE_CURATION_SECTION": {
      const { id } = action;
      const curationNodeMap = new Map(previousState.curationNodeMap);

      const maybeFieldSection = curationNodeMap.get(id);
      assertExists(maybeFieldSection);

      curationNodeMap.delete(id);
      curationNodeMap.forEach((node) => {
        if (node.children.includes(id)) {
          node = {
            ...node,
            children: node.children.filter((childId) => childId !== id),
          };
        }
      });

      return {
        ...previousState,
        curationNodeMap,
      };
    }

    case "MOVE_TO_NEW_NODE": {
      let nextState = previousState;
      const { nodeId, fromId, toId, beforeId } = action;

      // Remove from old parent
      nextState = updateParent(nextState, fromId, (children) => children.filter((id) => id !== nodeId));
      // Add to new parent
      nextState = updateParent(nextState, toId, (children) => {
        // If not defined, add to the end
        if (!beforeId) {
          return uniq([...children, nodeId]);
        }

        // Otherwise, add before the specified id
        const index = children.indexOf(beforeId);
        return Arrays.insertAtPosition(children, nodeId, index);
      });

      return {
        ...previousState,
        ...nextState,
      };
    }
    case "MOVE_WITHIN_NODE": {
      const { parentId, toIndex, childId } = action;

      // move from current index to new index
      const nextState = updateParent(previousState, parentId, (children) => moveTo(children, childId, toIndex));

      return {
        ...previousState,
        ...nextState,
      };
    }

    case "ADD_NODE": {
      let curationNodeMap = new Map(previousState.curationNodeMap);
      let rootIds = previousState.rootIds;
      const { parentNodeId, newId } = action;

      // Append to parent if exists
      if (parentNodeId) {
        const nextState = updateParent(previousState, parentNodeId, (children) => [newId, ...children]);
        curationNodeMap = new Map(nextState.curationNodeMap);
      } else {
        // Add as root category
        rootIds = [newId, ...rootIds];
      }

      curationNodeMap.set(newId, {
        id: newId,
        type: CurationNodeType.FieldSection,
        children: [],
      });

      return {
        ...previousState,
        rootIds,
        curationNodeMap,
      };
    }

    case "REMOVE_NODE": {
      const { nodeId } = action;
      const curationNodeMap = new Map(previousState.curationNodeMap);

      const maybeConditionalQuestion = curationNodeMap.get(nodeId);
      if (maybeConditionalQuestion?.type === CurationNodeType.CoverageQuestion) {
        throw new Error("Cannot remove coverage question");
      }

      for (const [parentNodeId, parentEntity] of curationNodeMap) {
        if (parentEntity.children.includes(nodeId)) {
          curationNodeMap.set(parentNodeId, {
            ...parentEntity,
            children: parentEntity.children.filter((id) => id !== nodeId),
          });
        }
      }

      return {
        ...previousState,
        curationNodeMap,
      };
    }

    case "SWAP_NODE":
      // Handled by backend; will not work without RealtimeCurationProvider
      return previousState;

    case "MAKE_CONDITIONAL_QUESTION": {
      const { questionId } = action;
      const curationNodeMap = new Map(previousState.curationNodeMap);

      // Create new wrapper section
      const newSectionId = uuidV4();
      curationNodeMap.set(newSectionId, {
        id: newSectionId,
        type: CurationNodeType.FieldSection,
        children: [],
      });

      curationNodeMap.set(questionId, {
        id: questionId,
        type: CurationNodeType.CoverageQuestion,
        children: [newSectionId],
      });

      return {
        ...previousState,
        curationNodeMap,
      };
    }

    case "UNMAKE_CONDITIONAL_QUESTION": {
      const { questionId } = action;
      const curationNodeMap = new Map(previousState.curationNodeMap);

      const conditionalQuestion = curationNodeMap.get(questionId);
      assertExists(conditionalQuestion);

      const subsectionIds = conditionalQuestion.children;
      for (const subsectionId of subsectionIds) {
        const subsection = curationNodeMap.get(subsectionId);
        assertExists(subsection);

        // Can only remove coverage section if section is empty
        if (subsection.children.length > 0) {
          throw new Error("Can only remove coverage section if section is empty");
        }

        curationNodeMap.delete(subsectionId);
      }
      curationNodeMap.delete(conditionalQuestion.id);

      return {
        ...previousState,
        curationNodeMap,
      };
    }

    default:
      return previousState;
  }
};

function assertExists<T>(thing: T | undefined | null): asserts thing is T {
  if (thing == null) {
    throw new Error("Expected non-nullable value");
  }
}

function moveTo<T>(array: T[], id: T, toIndex: number): T[] {
  const fromIndex = array.indexOf(id);
  const result = [...array];
  const [removed] = result.splice(fromIndex, 1);
  result.splice(toIndex, 0, removed);
  return result;
}

function updateParent(
  previousState: CurationTreeState,
  parentId: string | undefined,
  updater: (children: string[]) => string[]
): CurationTreeState {
  // Handle when parent no parent
  if (!parentId) {
    return previousState;
  }

  // Handle when parent is the root
  if (parentId === ROOT) {
    return {
      ...previousState,
      rootIds: updater(previousState.rootIds),
    };
  }

  // Handle when parent is from another entity
  const curationNodeMap = new Map(previousState.curationNodeMap);
  const parent = curationNodeMap.get(parentId);
  assertExists(parent);

  switch (parent.type) {
    case CurationNodeType.Category:
    case CurationNodeType.CollectionSection:
    case CurationNodeType.FieldSection:
      curationNodeMap.set(parentId, {
        ...parent,
        // Update and remove duplicates
        children: uniq(updater(parent.children)),
      });
      break;
    case CurationNodeType.CoverageQuestion:
      throw new Error("Cannot add to a coverage question");
    default:
      assertNever(parent.type);
  }

  return {
    ...previousState,
    curationNodeMap,
  };
}
