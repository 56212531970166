import {
  ContactFragment,
  FormContainerFragment,
  FormEntityFragment,
  FormQuestionFragment,
} from "../generated/operations";

/**
 * Renderers are used to customize the rendering of the form.
 * The default renderers are used if no custom renderers are provided.
 */
export interface Renderers {
  renderQuestion: (question: FormQuestionFragment, defaultComponent: React.ReactNode) => React.ReactNode;
  renderContainer: (section: FormContainerFragment, defaultComponent: React.ReactNode) => React.ReactNode;
  renderEntitySection: (section: FormContainerFragment, defaultComponent: React.ReactNode) => React.ReactNode;
  onCreateLocation?: () => Promise<Pick<FormEntityFragment, "id" | "label"> | undefined>;
  onCreateContact?: () => Promise<ContactFragment | undefined>;
  renderApplicationDocumentsUploadComponent?: ({ applicationId }: { applicationId: string }) => React.ReactNode;
  openApplicationDocumentsDrawer?: () => void;
}

export const DefaultRenderers: Renderers = {
  renderQuestion: (_, defaultComponent) => defaultComponent,
  renderContainer: (_, defaultComponent) => defaultComponent,
  renderEntitySection: (_, defaultComponent) => defaultComponent,
};
