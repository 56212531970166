import { useEvent, useHistory } from "@cp/react-hooks";
import { Functions, Maps } from "@cp/toolkit";
import { PropsWithChildren, useState } from "react";
import { ClobFragment, ClobQuestionFragment } from "../../generated/graphql";
import { conditionToInput } from "../conditions/conditionToInput";
import { ClobContext, ClobContextValue } from "./context";
import { deserializeClob } from "./deserialize";
import { Action, clobReducer, collapseAll, expandAll } from "./reducer";
import { ClobContextState } from "./types";

interface Props {
  clob: ClobFragment;
}

export function ClobEditorProvider({ children, clob }: PropsWithChildren<Props>) {
  // initial state as a function to avoid re-calculating on every render
  const [initialState] = useState(() => {
    return deserializeClob(clob);
  });

  const { state, set, undo, redo, ...undoRedo } = useHistory<ClobContextState>(initialState);

  const dispatch = useEvent((action: Action) => {
    set(clobReducer(state, action));
  });

  const actions: ClobContextValue["actions"] = {
    setState: useEvent((payload) => {
      undoRedo.clear(payload);
    }),
    setTree: useEvent((tree) => {
      dispatch({ type: "UPDATE_TREE", tree });
    }),
    updateState: useEvent((state) => {
      dispatch({ type: "UPDATE_STATE", state });
    }),
    collapseAll: useEvent(() => {
      actions.setTree(collapseAll(state.tree));
    }),
    expandAll: useEvent(() => {
      actions.setTree(expandAll(state.tree));
    }),
    addItem: useEvent((payload) => {
      dispatch({ type: "ADD_ITEM", ...payload });
    }),
    updateQuestion: useEvent((question) => {
      dispatch({ type: "UPDATE_CLOB_QUESTION", question });
    }),
    updateQuestions: useEvent((questions) => {
      dispatch({ type: "UPDATE_CLOB_QUESTIONS", questions });
    }),
    updateSection: useEvent((section) => {
      dispatch({ type: "UPDATE_CLOB_SECTION", section });
    }),
    undo: useEvent(() => {
      undo();
    }),
    redo: useEvent(() => {
      redo();
    }),
    ...undoRedo,
  };

  return <ClobContext.Provider value={{ state, actions }}>{children}</ClobContext.Provider>;
}

const SENTINEL_ID = "3fc19f7f-a0e5-4193-9c60-bdc56c45d0d6";
interface MissingQuestionEditorProviderProps {
  clobQuestions: ClobQuestionFragment[];
}
export function MissingQuestionEditorProvider({
  children,
  clobQuestions,
}: PropsWithChildren<MissingQuestionEditorProviderProps>) {
  // initial state as a function to avoid re-calculating on every render
  const [initialState] = useState<ClobContextState>(() => ({
    id: SENTINEL_ID,
    version: 0,
    name: "Sentinal",
    lineOfBusiness: "Sentinel",
    tree: [],
    questions: Maps.collectBy(
      clobQuestions,
      (q) => q.id,
      (q) => ({
        ...q,
        visibility: q.visibility?.map(conditionToInput) ?? [],
      })
    ),
    sections: new Map(),
  }));

  const { state, set, undo, redo, ...undoRedo } = useHistory<ClobContextState>(initialState);

  const dispatch = useEvent((action: Action) => {
    set(clobReducer(state, action));
  });

  const actions: ClobContextValue["actions"] = {
    setState: useEvent((payload) => {
      undoRedo.clear(payload);
    }),
    setTree: Functions.NOOP,
    updateState: useEvent((state) => {
      dispatch({ type: "UPDATE_STATE", state });
    }),
    collapseAll: Functions.NOOP,
    expandAll: Functions.NOOP,
    addItem: useEvent((payload) => {
      dispatch({ type: "ADD_ITEM", ...payload });
    }),
    updateQuestion: useEvent((question) => {
      dispatch({ type: "UPDATE_CLOB_QUESTION", question });
    }),
    updateQuestions: useEvent((questions) => {
      dispatch({ type: "UPDATE_CLOB_QUESTIONS", questions });
    }),
    updateSection: Functions.NOOP,
    undo: useEvent(() => {
      undo();
    }),
    redo: useEvent(() => {
      redo();
    }),
    ...undoRedo,
  };

  return <ClobContext.Provider value={{ state, actions }}>{children}</ClobContext.Provider>;
}
