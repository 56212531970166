import { Tab, Tabs } from "@mui/material";
import { Link, useLocation, useMatchRoute } from "@tanstack/react-location";

export interface RouteTabsProps {
  tabs: Array<{
    label: string;
    to: string;
    preserveAtomHashParams?: boolean;
  }>;
}

export function RouteTabs({ tabs }: RouteTabsProps) {
  const location = useLocation();
  const match = useMatchRoute();
  const selected = tabs.findIndex(({ to }) => match({ to }));

  return (
    <Tabs value={selected < 0 ? false : selected}>
      {tabs.map(({ label, to, preserveAtomHashParams }) => {
        const url = preserveAtomHashParams ? `${to}#${location.current.hash}` : to;
        return <Tab component={Link} key={label} label={label} to={url} />;
      })}
    </Tabs>
  );
}
