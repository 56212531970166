import { useSearchQuery, useSingleSelection } from "@cp/react-hooks";
import { EmptyState } from "@cp/theme";
import { Functions } from "@cp/toolkit";
import { Stack } from "@mui/material";
import React from "react";
import { CurationQuestionFilter } from "../../../components/CurationQuestionFilter";
import { SearchInput } from "../../../components/SearchInput";
import { CurationQuestionFragment, useSearchCurationQuestionsQuery } from "../../../generated/graphql";
import { DragableQuestion } from "../Dragables/DragableQuestion";
import { DragableItemProps, DragSources } from "../Dragables/DragTypes";
import { useCurationState } from "../state/useCurationState";
import { QuestionItemProps } from "./QuestionItem";

interface Props {
  CurationComponent?: React.ComponentType<DragableItemProps<QuestionItemProps>>;
}

export const CurationQuestionList: React.FC<Props> = React.memo(({ CurationComponent = DragableQuestion }: Props) => {
  const { curationNodeMap } = useCurationState();
  const [questionFilter, questionFilterActions] = useSingleSelection<"archived" | "unarchived">("unarchived");
  const search = useSearchQuery();

  // Used to determine if a result is already in the tree
  const childrenInTree = React.useMemo(() => {
    return new Set([...curationNodeMap.values()].flatMap((node) => node.children));
  }, [curationNodeMap]);

  const { data, previousData, loading } = useSearchCurationQuestionsQuery({
    variables: {
      searchTerm: search.debouncedValue.trim(),
      archived: questionFilter === "archived" ? true : questionFilter === "unarchived" ? false : undefined,
      pagination: {
        page: 1,
        rowsPerPage: 50,
      },
    },
    skip: !search.debouncedValue.trim(),
  });

  const questions = search.debouncedValue.trim()
    ? data?.curationQuestions || previousData?.curationQuestions || []
    : [];

  const renderQuestionKey = (question: CurationQuestionFragment, index: number) => {
    if (childrenInTree.has(question.id)) {
      return null;
    }

    return (
      <CurationComponent
        key={question.id}
        index={index}
        parentId={undefined}
        depth={0}
        canToggleConditionalQuestion={false}
        canRemove={false}
        curationQuestion={question}
        move={Functions.NOOP}
        source={DragSources.uncurated}
      />
    );
  };

  const renderBody = () => {
    const noSearch = !search.debouncedValue.trim();

    if (noSearch) {
      return (
        <EmptyState
          title="Search for a question"
          description="Search for curation questions not in this application."
        />
      );
    }

    if (questions.length === 0) {
      return <EmptyState title="No Results" description="No results found for your search." />;
    }

    return questions.map(renderQuestionKey);
  };

  return (
    <Stack gap={2}>
      <Stack gap={1}>
        <Stack direction="row" gap={4} alignItems="center" justifyContent="space-between">
          <SearchInput {...search.inputProps} loading={loading} />
          <CurationQuestionFilter value={questionFilter} handleFilter={questionFilterActions.set} />
        </Stack>
        {renderBody()}
      </Stack>
    </Stack>
  );
});

CurationQuestionList.displayName = "CurationQuestionList";
