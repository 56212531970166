import { Avatar, AvatarGroup, Tooltip } from "@mui/material";
import React from "react";
import { useMultiplayer } from "./useMultiplayer";

export const MultiplayerAvatars: React.VFC = () => {
  const players = useMultiplayer();

  return (
    <AvatarGroup>
      {players.map((player) => (
        <Tooltip title={player.name} key={player.id}>
          <Avatar key={player.id} {...{ ...stringAvatar(player.name) }} />
        </Tooltip>
      ))}
    </AvatarGroup>
  );
};

function stringToColor(string: string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

function stringAvatar(name: string) {
  const nameSplit = name.split(" ");
  const [firstName = "", lastName = ""] = nameSplit;
  return {
    sx: { bgcolor: stringToColor(name) },
    children: `${firstLetter(firstName)}${firstLetter(lastName)}`,
  };
}

function firstLetter(name: string) {
  return name[0] || "";
}
