import type { Components, Theme } from "@mui/material/styles";

export function Paper(theme: Theme): Components<Theme> {
  return {
    MuiPaper: {
      defaultProps: {},
      styleOverrides: {
        root: {
          borderColor: theme.palette.outline,
        },
        rounded: {
          borderRadius: "5px",
        },
      },
    },
  };
}
