import { createContext } from "react";
import {
  CurationContextActions,
  CurationContextFeature,
  CurationContextState,
  INITIAL_ACTIONS,
  INITIAL_STATE,
} from "./types";

export const CurationStateContext = createContext<CurationContextState>(INITIAL_STATE);
export const CurationActionsContext = createContext<CurationContextActions>(INITIAL_ACTIONS);
export const CurationFeatureContext = createContext<CurationContextFeature>(CurationContextFeature.DEFAULT);
