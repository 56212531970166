import React, { useCallback, useEffect, useRef, useState } from "react";

export function useResizer<T extends HTMLElement>(opts: {
  side: "left" | "right";
  initialWidth: number;
  maxWidth: number;
  minWidth: number;
}) {
  const sidebarRef = useRef<T | null>(null);
  const [isResizing, setIsResizing] = useState(false);
  const [sidebarWidth, setSidebarWidth] = useState(opts.initialWidth);

  const startResizing = useCallback((evt: React.MouseEvent) => {
    evt.preventDefault();
    setIsResizing(true);
  }, []);

  const stopResizing = useCallback(() => {
    setIsResizing(false);
  }, []);

  const resize = useCallback(
    (mouseMoveEvent: MouseEvent) => {
      if (isResizing && sidebarRef.current) {
        // difference between start and current mouse position
        let newWidth = mouseMoveEvent.clientX - sidebarRef.current.getBoundingClientRect()[opts.side];
        newWidth = opts.side === "left" ? newWidth : -newWidth;
        newWidth = clamp(newWidth, opts.minWidth, opts.maxWidth);
        setSidebarWidth(newWidth);
      }
    },
    [isResizing]
  );

  useEffect(() => {
    window.addEventListener("mousemove", resize);
    window.addEventListener("mouseup", stopResizing);
    return () => {
      window.removeEventListener("mousemove", resize);
      window.removeEventListener("mouseup", stopResizing);
    };
  }, [resize, stopResizing]);

  return {
    sidebarProps: {
      ref: sidebarRef,
      style: {
        display: "flex",
        position: "relative",
        flex: 1,
        width: sidebarWidth,
        minWidth: sidebarWidth,
        maxWidth: sidebarWidth,
      } as const,
    },
    sidebarResizer: {
      onMouseDown: startResizing,
      style: {
        position: "absolute",
        flexGrow: "0",
        flexShrink: "0",
        width: "3px",
        top: 0,
        bottom: 0,
        justifySelf: "flex-end",
        cursor: "col-resize",
        resize: "horizontal",
        ...(opts.side === "right"
          ? { left: 0, borderLeft: "2px solid #ccc" }
          : { right: 0, borderRight: "2px solid #ccc" }),
      } as const,
    },
  };
}

function clamp(value: number, min: number, max: number) {
  return Math.min(Math.max(value, min), max);
}
