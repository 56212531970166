import { Stack } from "@mui/material";
import React from "react";

export const LoginPage: React.FC = () => {
  return (
    <Stack p={10}>
      <form method="get" action="/api/auth/google">
        <button type="submit">Login with Google</button>
      </form>
    </Stack>
  );
};
