import { logNever } from "@cp/toolkit";
import { ApplicationConditionFragment, ApplicationConditionInput } from "../../generated/graphql";

export function conditionToInput(condition: ApplicationConditionFragment): ApplicationConditionInput {
  switch (condition.__typename) {
    case "ApplicationConditionBoolean": {
      const { __typename, ...rest } = condition;
      return { booleanCondition: rest };
    }
    case "ApplicationConditionString": {
      const { __typename, ...rest } = condition;
      return { stringCondition: rest };
    }
    case "ApplicationConditionStringSet": {
      const { __typename, ...rest } = condition;
      return { stringSetCondition: rest };
    }
    case "ApplicationConditionNumber": {
      const { __typename, ...rest } = condition;
      return { numberCondition: rest };
    }
    case "ApplicationConditionMatchAny": {
      const { __typename, ...rest } = condition;
      return {
        matchAnyCondition: {
          matchAnyConditions: rest.matchAnyConditions.map((condition) =>
            conditionToInput(condition as ApplicationConditionFragment)
          ),
        },
      };
    }
    case "ApplicationConditionMatchAll": {
      const { __typename, ...rest } = condition;
      return {
        matchAllCondition: {
          matchAllConditions: rest.matchAllConditions.map((condition) =>
            conditionToInput(condition as ApplicationConditionFragment)
          ),
        },
      };
    }
    default:
      logNever(condition);
      return {};
  }
}
