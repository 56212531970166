import { assertNever, coerceBoolean, logNever } from "@cp/toolkit";
import {
  ApplicationConditionBooleanFragment,
  ApplicationConditionBooleanOperator,
  ApplicationConditionNumberFragment,
  ApplicationConditionNumberOperator,
  ApplicationConditionStringFragment,
  ApplicationConditionStringOperator,
  ApplicationConditionStringSetFragment,
  ApplicationConditionStringSetOperator,
} from "../../generated/operations";
import { ApplicationCondition } from "./ApplicationConditions";

export const validateCondition = (
  condition: ApplicationCondition,
  getValue: (name: string) => string | string[] | unknown
): boolean => {
  // Handle composite conditions
  if (condition.__typename === "ApplicationConditionMatchAny") {
    return condition.matchAnyConditions?.some((subCondition) => validateCondition(subCondition, getValue)) ?? true;
  }
  if (condition.__typename === "ApplicationConditionMatchAll") {
    return condition.matchAllConditions?.every((subCondition) => validateCondition(subCondition, getValue)) ?? true;
  }

  // Get subject value
  const subject = getSubject(condition)[0];
  const fieldValue = getValue(subject);

  // If the field value is an array, any value in the array will be matched.
  // In future, we can add config to specify if any/all values must match.
  const fieldValueAsArray = Array.isArray(fieldValue) ? fieldValue : [fieldValue];

  switch (condition.__typename) {
    case "ApplicationConditionBoolean":
      return fieldValueAsArray.some((value) => validateBooleanCondition(condition, value));
    case "ApplicationConditionString":
      return fieldValueAsArray.some((value) => validateStringCondition(condition, value));
    case "ApplicationConditionStringSet":
      return fieldValueAsArray.some((value) => validateStringSetCondition(condition, value));
    case "ApplicationConditionNumber":
      return fieldValueAsArray.some((value) => validateNumberCondition(condition, value));
    case undefined:
      // TODO: Add nonOptionalTypenames to codegen.yml and remove this case (post type-operators)
      throw new Error("ApplicationCondition.__typename was undefined");
    default:
      throw assertNever(condition);
  }
};

export const getSubject = (condition: ApplicationCondition): string[] => {
  switch (condition.__typename) {
    case "ApplicationConditionBoolean":
      return [condition.subject];
    case "ApplicationConditionString":
      return [condition.subject];
    case "ApplicationConditionStringSet":
      return [condition.subject];
    case "ApplicationConditionNumber":
      return [condition.subject];
    case "ApplicationConditionMatchAny":
      return condition.matchAnyConditions?.flatMap(getSubject) ?? [];
    case "ApplicationConditionMatchAll":
      return condition.matchAllConditions?.flatMap(getSubject) ?? [];
    case undefined:
      return [];
    default:
      logNever(condition);
      return [];
  }
};

const validateBooleanCondition = ({ booleanOperator }: ApplicationConditionBooleanFragment, fieldValue: string) => {
  if (fieldValue === "") {
    return false;
  }
  const value = coerceBoolean(fieldValue);

  switch (booleanOperator) {
    case ApplicationConditionBooleanOperator.IsTrue:
      return value;
    case ApplicationConditionBooleanOperator.IsFalse:
      return !value;
    case ApplicationConditionBooleanOperator.IsDefinedAndFalse:
      return fieldValue && !value;
    default:
      throw assertNever(booleanOperator);
  }
};

const validateStringSetCondition = (
  { stringSetValue, stringSetOperator }: ApplicationConditionStringSetFragment,
  fieldValue: string
) => {
  switch (stringSetOperator) {
    case ApplicationConditionStringSetOperator.IncludedIn:
      return stringSetValue.includes(fieldValue);
    case ApplicationConditionStringSetOperator.NotIncludedIn:
      return !stringSetValue.includes(fieldValue);
    default:
      throw assertNever(stringSetOperator);
  }
};

const validateStringCondition = (
  { stringValue: expectedValue, stringOperator: operator }: ApplicationConditionStringFragment,
  fieldValue: string
) => {
  switch (operator) {
    case ApplicationConditionStringOperator.Equals:
      return expectedValue === fieldValue;
    case ApplicationConditionStringOperator.DoesNotEqual:
      return expectedValue !== fieldValue;
    case ApplicationConditionStringOperator.DefinedAndNotEqual:
      return fieldValue && expectedValue !== fieldValue;
    default:
      assertNever(operator);
      return;
  }
};

const validateNumberCondition = (
  { numberValue: expectedValue, numberOperator: operator }: ApplicationConditionNumberFragment,
  fieldValue: string
) => {
  const fieldNumber = Number.parseInt(fieldValue);
  const expectedNumber = Number.parseInt(expectedValue);

  if (isNaN(fieldNumber)) {
    return false;
  }

  if (isNaN(expectedNumber)) {
    return true;
  }

  switch (operator) {
    case ApplicationConditionNumberOperator.GreaterThan:
      return fieldNumber > expectedNumber;
    case ApplicationConditionNumberOperator.GreaterThanOrEqualTo:
      return fieldNumber >= expectedNumber;
    case ApplicationConditionNumberOperator.Equals:
      return fieldNumber === expectedNumber;
    case ApplicationConditionNumberOperator.LessThan:
      return fieldNumber < expectedNumber;
    case ApplicationConditionNumberOperator.LessThanOrEqualTo:
      return fieldNumber <= expectedNumber;
    default:
      assertNever(operator);
      return;
  }
};
