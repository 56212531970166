import { FormControlLabel, Switch } from "@mui/material";
import { useLocation, useMatch, useNavigate, useSearch } from "@tanstack/react-location";
import { LocationGenerics } from "../../../App";
import { ClobFilter } from "../../../components/ClobFilter";
import { ClobSelect } from "../../../components/ClobSelect";
import { ListToolbar } from "../../../components/lists/ListToolbar";
import { Panels } from "../../../components/panels/Panels";
import { TopBar } from "../../../components/TopBar";
import { useClobQuery } from "../../../generated/graphql";
import { ClobEditorProvider } from "../../../model/clobs/ClobEditorProvider";
import { useClobQuestionFilter, useMinimizedMode } from "./atoms";
import { ClobDetailsCurationNodeSearch } from "./components/ClobDetailsCurationNodeSearch";
import { ClobLobAndCarrierFilter } from "./components/ClobQuestionLobCarrierFilter";
import { CurationControls } from "./components/CurationControls";
import { CurationHeader } from "./components/CurationPanel";
import { MappableClobTree } from "./MappableClobTree";

const ClobDetails = () => {
  const {
    params: { clobId },
  } = useMatch();

  const [isMinimized, setIsMinimized] = useMinimizedMode();

  // Filters
  const [clobQuestionFilter, setClobFilter] = useClobQuestionFilter();
  const location = useLocation();
  const navigate = useNavigate();

  const { clobSearchQuery } = useSearch<LocationGenerics>();
  const setClobSearchQuery = (query: string) => {
    navigate({
      search: {
        ...location.current.search,
        clobSearchQuery: query,
      },
      replace: true,
    });
  };

  const { data: clobData } = useClobQuery({ variables: { id: clobId } });
  const clob = clobData?.clob;

  if (!clob) {
    return null;
  }

  const page = (
    <Panels
      id="clob-mapping"
      config={[
        {
          defaultSize: 25,
          title: "CLOB",
          header: <TopBar leftToolbar={<ClobSelect />} />,
          content: (
            <>
              <ListToolbar
                searchValue={clobSearchQuery ?? ""}
                onChangeSearch={setClobSearchQuery}
                rightActions={
                  <>
                    <FormControlLabel
                      control={
                        <Switch size="small" checked={isMinimized} onClick={() => setIsMinimized(!isMinimized)} />
                      }
                      sx={{ userSelect: "none" }}
                      componentsProps={{
                        typography: {
                          variant: "caption",
                        },
                      }}
                      label="Minimize"
                    />
                    <ClobLobAndCarrierFilter />
                    <ClobFilter value={clobQuestionFilter} handleClobFilter={setClobFilter} />
                  </>
                }
              />
              <MappableClobTree />
            </>
          ),
        },
        {
          title: "Application",
          header: <TopBar leftToolbar={<ClobDetailsCurationNodeSearch />} rightToolbar={<CurationHeader />} />,
          noPadding: true,
          content: <CurationControls />,
        },
      ]}
    />
  );

  return <ClobEditorProvider clob={clob}>{page}</ClobEditorProvider>;
};

export default ClobDetails;
