import { ApolloProvider } from "@apollo/client";
import { useToaster } from "@cp/theme";
import { Button, Grid } from "@mui/material";
import React from "react";
import { QuestionMappingButton } from "src/components/QuestionMappingButton";
import { SNACK_BAR_KEY } from "src/constants";
import { DynamicActionButtonGroup } from "../actions/components/DynamicActionButtonGroup";
import { ItemType } from "../actions/types";
import { client } from "../graphql/client";
import { useSelectedClobQuestions, useSelectedCurationQuestions } from "../pages/clobs/MapClob/selection-atom";
import { GoldenQuestionDrawerButton } from "./buttons/GoldenQuestionDrawerButton";

export const ConnectedToastActions: React.FC<{}> = () => {
  const [selectedClobQuestions, setSelectedClobQuestions] = useSelectedClobQuestions();
  const [selectedCurationQuestions, setSelectedCurationQuestions] = useSelectedCurationQuestions();
  const { closeSnackbar } = useToaster();

  React.useEffect(() => {
    if (selectedClobQuestions.length > 0 && selectedCurationQuestions.length > 1) {
      setSelectedCurationQuestions([]);
    }
  }, [selectedClobQuestions.length, selectedCurationQuestions.length]);

  const handleClear = () => {
    setSelectedClobQuestions([]);
    setSelectedCurationQuestions([]);
    closeSnackbar(SNACK_BAR_KEY);
  };
  const items = {
    [ItemType.CURATION_QUESTION]: selectedCurationQuestions,
    [ItemType.CLOB_QUESTION]: selectedClobQuestions,
  };

  return (
    <ApolloProvider client={client}>
      <Grid container={true} spacing={1} minWidth={"60vw"} justifyContent="space-between">
        <Grid item={true} lg={4}>
          {selectedClobQuestions.length > 0 && (
            <Grid item={true} lg={12}>
              {selectedClobQuestions.length} CLOB question(s) selected
            </Grid>
          )}
          {selectedCurationQuestions?.length > 0 && (
            <Grid item={true} lg={12}>
              {selectedCurationQuestions.length} Curation question(s) selected
            </Grid>
          )}
        </Grid>
        <Grid item={true} lg={8} display="flex" justifyContent="center" alignItems="center">
          <GoldenQuestionDrawerButton />
          <Grid spacing={2} container={true}>
            <Grid item={true} lg={3}>
              <QuestionMappingButton />
            </Grid>
            <Grid item={true} lg={6} display="flex" justifyContent="flex-end" alignItems="center">
              <DynamicActionButtonGroup items={items} />
            </Grid>
            <Grid item={true} lg={3} display="flex" justifyContent="flex-end" alignItems="center">
              <Button size="small" variant="contained" color="info" onClick={handleClear}>
                Clear
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </ApolloProvider>
  );
};
