import { Warning } from "@mui/icons-material";
import { CircularProgress } from "@mui/material";
import { EmptyState } from "./EmptyState";

interface Props {
  loading: boolean;
  error?: Error | null;
}

/**
 * A load state will consistently display a loading indicator or an error message.
 *
 * @example
 * if (loading || error) {
 *  return <LoadState loading={loading} error={error} />;
 * }
 */
export function LoadState({ loading, error }: Props) {
  if (loading) {
    return <EmptyState icon={<CircularProgress />} title="Loading..." />;
  }

  if (error) {
    return <EmptyState icon={<Warning fontSize="large" />} title="Error" description={error.message} />;
  }

  return null;
}
