import { useSet } from "./useSet";

export function useExpandCollapse() {
  const [collapsed, { toggle, setMany, reset }] = useSet<string>();

  return {
    isExpanded: (id: string) => !collapsed.has(id),
    toggle: toggle,
    expandAll: reset,
    collapseAll: setMany,
  };
}
