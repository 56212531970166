import { Maps } from "@cp/toolkit";
import { UniqueIdentifier } from "@dnd-kit/core";
import {
  ApplicationComponentType,
  ClobFragment,
  UpdateClobApplicationInput,
  UpdateClobQuestionInput,
} from "../../generated/graphql";
import { conditionToInput } from "../conditions/conditionToInput";
import { clobToTreeItems } from "./clobToTreeItems";
import { ClobContextState, ClobTreeItem, EditableClob } from "./types";

export function deserializeClob(
  clob: ClobFragment,
  itemsCollapseState?: Map<UniqueIdentifier, boolean>
): ClobContextState {
  return {
    id: clob.id,
    version: clob.version,
    name: clob.name,
    lineOfBusiness: clob.lineOfBusiness,
    tree: clobToTreeItems(clob, itemsCollapseState),
    questions: Maps.collectBy(
      clob.questions,
      (q) => q.id,
      (q) => ({
        ...q,
        visibility: q.visibility?.map(conditionToInput) ?? [],
      })
    ),
    sections: Maps.collectBy(
      clob.sections,
      (s) => s.id,
      (s) => ({
        ...s,
        visibility: s.visibility?.map(conditionToInput) ?? [],
      })
    ),
  };
}

function deserializeClobFromExport(clob: EditableClob): ClobContextState {
  return {
    id: clob.id,
    version: clob.version,
    name: clob.name,
    lineOfBusiness: clob.lineOfBusiness,
    tree: clobToTreeItems(clob),
    questions: Maps.keyBy(clob.questions, (q) => q.id),
    sections: Maps.keyBy(clob.sections, (s) => s.id),
  };
}

export function serializeClob(state: ClobContextState): EditableClob {
  let globalOrder = 0;

  // Collect visible node ids
  const validIds = new Set<string | number>();
  const idToOrder = new Map<string | number, number>();
  const idToParentId = new Map<string | number, string | undefined>();
  const visit = (node: ClobTreeItem, parentId: string | undefined) => {
    validIds.add(node.id);
    idToParentId.set(node.id, parentId);
    idToOrder.set(node.id, globalOrder);
    globalOrder++;

    if (node.children) {
      node.children.forEach((child) => visit(child, node.id.toString()));
    }
  };
  state.tree.forEach((node) => visit(node, undefined));

  return {
    id: state.id,
    version: state.version,
    name: state.name,
    lineOfBusiness: state.lineOfBusiness,
    sections: [...state.sections.values()]
      .filter((s) => validIds.has(s.id))
      .map((s) => ({
        // update order and parentId
        ...s,
        order: idToOrder.get(s.id) ?? s.order,
        parentId: idToParentId.get(s.id),
      })),
    questions: [...state.questions.values()]
      .filter((q) => validIds.has(q.id))
      .map((q) => ({
        // update order and parentId
        ...q,
        order: idToOrder.get(q.id) ?? q.order,
        clobSectionId: idToParentId.get(q.id)!,
      })),
  };
}

export function serializeClobForUpdate(state: ClobContextState): UpdateClobApplicationInput {
  const { sections, questions } = serializeClob(state);
  return {
    form: {
      sections: sections.map((s) => ({
        id: s.id,
        name: s.name,
        order: s.order,
        helperText: s.helperText,
        visibility: s.visibility ?? [],
        parentId: s.parentId,
      })),
      questions: questions.map(
        (q): UpdateClobQuestionInput => ({
          id: q.id,
          clobSectionId: q.clobSectionId,
          defaultValue: q.defaultValue,
          mappingNotes: q.mappingNotes,
          helperText: q.helperText,
          infoText: q.infoText,
          componentType: q?.componentType ?? ApplicationComponentType.Text,
          visibility: q.visibility ?? [],
          isRequired: q.isRequired,
          options: q.options,
          order: q.order,
          text: q.text,
        })
      ),
      id: state.id,
      name: state.name,
      lineOfBusiness: state.lineOfBusiness,
    },
    expectedVersion: state.version,
  };
}

export function stringifyClob(state: ClobContextState): string {
  return JSON.stringify(serializeClob(state));
}

export function deserializeClobFromString(str: string): ClobContextState {
  return deserializeClobFromExport(JSON.parse(str) as EditableClob);
}
