import React from "react";
import { EditableText } from "../../../components/properties/EditableText";
import { useClobContext } from "../../../model/clobs/context";

interface Props {
  id: string;
}

export const EditableClobSectionTitle: React.FC<Props> = ({ id }) => {
  const {
    state,
    actions: { updateSection },
  } = useClobContext();
  return <EditableText value={state.sections.get(id)?.name ?? ""} onChange={(name) => updateSection({ id, name })} />;
};
