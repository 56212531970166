import { Sets } from "@cp/toolkit";
import { atom, useAtomValue } from "jotai";
import {
  CurationQuestionsFilteredDocument,
  CurationQuestionsFilteredQuery,
  CurationQuestionsFilteredQueryVariables,
} from "../../generated/graphql";
import { client } from "../../graphql/client";
import { curationQuestionsFilter } from "./atoms";

const curationQuestionsFilterResults = atom(async (get) => {
  const filter = get(curationQuestionsFilter);
  const apolloClient = client;
  if (!filter) {
    return Sets.empty();
  }
  const isFilterEmpty = filter.carrierSlugs.length === 0 && filter.lineOfBusiness.length === 0;
  if (isFilterEmpty) {
    return Sets.empty();
  }

  const { data } = await apolloClient.query<CurationQuestionsFilteredQuery, CurationQuestionsFilteredQueryVariables>({
    query: CurationQuestionsFilteredDocument,
    variables: {
      filter: filter,
    },
  });
  const res = new Set(data.curationQuestionsWithRequiredness.map((q) => q.id));
  return res;
});

export const useCurationQuestionsFilterResults = () => useAtomValue(curationQuestionsFilterResults);
