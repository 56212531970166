import { usePopover } from "@cp/react-hooks";
import { Add } from "@mui/icons-material";
import { Chip, ChipProps, Menu, MenuItem } from "@mui/material";
import React from "react";

interface Props extends ChipProps {
  options: Array<{ label: string; value: string }>;
  onAdd: (tag: string) => void;
}

export const TagSelect: React.FC<Props> = ({ options, onAdd, ...rest }) => {
  const { targetProps, popoverProps } = usePopover();

  return (
    <>
      <Chip size="small" icon={<Add />} sx={{ borderRadius: 1 }} {...rest} {...targetProps} />
      <Menu {...popoverProps}>
        {options.map((option) => (
          <MenuItem key={option.value} onClick={() => onAdd(option.value)}>
            {option.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
