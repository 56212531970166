import type { Components, Theme } from "@mui/material/styles";

export function Table(theme: Theme): Components<Theme> {
  return {
    MuiTableCell: {
      styleOverrides: {
        root: {
          backgroundColor: theme.palette.background.default,
          borderColor: theme.palette.divider,
          lineHeight: "16px",
          padding: "8px 16px",
          "&.alignRight": {
            textAlign: "right",
          },
        },
        body: {
          fontSize: "12px",
          letterSpacing: "-0.02em",
          "&.break": {
            borderRight: "1px solid",
            borderColor: theme.palette.divider,
            paddingRight: "24px",
          },
          "&.break + &": {
            paddingLeft: "24px",
          },
        },
        head: {
          color: theme.palette.text.secondary,
          fontSize: "11px",
          fontWeight: 700,
          lineHeight: "12px",
          textTransform: "uppercase",
          "&.break": {
            paddingRight: "24px",
          },
          "&.break + &": {
            paddingLeft: "24px",
          },
        },
      },
    },
  };
}
