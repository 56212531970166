import ArchiveIcon from "@mui/icons-material/Archive";
import UnarchiveIcon from "@mui/icons-material/Unarchive";
import { IconButton, Tooltip } from "@mui/material";
import { useArchiveCurationQuestionMutation, useUnarchiveCurationQuestionMutation } from "../generated/graphql";

interface Props {
  id: string;
  archived: boolean;
}

export const ArchiveCurationQuestionToggle: React.VFC<Props> = ({ id, archived }) => {
  const [archiveCurationQuestion] = useArchiveCurationQuestionMutation();
  const [unarchiveCurationQuestion] = useUnarchiveCurationQuestionMutation();

  const handleArchiveCurationQuestion = () => {
    if (window.confirm("Are you sure you want to archive this curation question?")) {
      archiveCurationQuestion({
        variables: { id },
      });
    }
  };

  const handleUnarchiveCurationQuestion = () => {
    unarchiveCurationQuestion({
      variables: { id },
    });
  };

  return (
    <>
      {!archived && (
        <Tooltip title="Archive Question">
          <IconButton
            size="small"
            onClick={(evt) => {
              evt.stopPropagation();
              handleArchiveCurationQuestion();
            }}
          >
            <ArchiveIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      )}
      {archived && (
        <Tooltip title="Unarchive Question">
          <IconButton
            size="small"
            color="error"
            onClick={(evt) => {
              evt.stopPropagation();
              handleUnarchiveCurationQuestion();
            }}
          >
            <UnarchiveIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      )}
    </>
  );
};
