import { TableCell, TableCellProps } from "@mui/material";
import { Column } from "../../core/Column";
import { SafeRender } from "../SafeRender";

interface Props<T> {
  index: number;
  datum: T;
  column: Column<T>;
}

export function Cell<T>({ column, index, datum }: Props<T>) {
  return (
    <TableCell
      key={column.id}
      align={column.align}
      className={column.break ? "break" : undefined}
      sx={{
        maxWidth: column.maxWidth,
        width: column.maxWidth,
        minWidth: column.minWidth,
        flex: column.flex ?? 1,
        ...tableCellSx,
        backgroundColor: column.backgroundColor ? column.backgroundColor({ index, data: datum }) : "transparent",
      }}
    >
      <SafeRender render={column.render} params={{ index, data: datum }} />
    </TableCell>
  );
}

export const tableCellSx: TableCellProps["sx"] = {
  backgroundColor: "transparent",
  fontWeight: 500,
  fontSize: "12px",
  lineHeight: "16px",
  letterSpacing: "-0.02em",
  verticalAlign: "center",
};
