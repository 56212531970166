import { Maps } from "@cp/toolkit";
import { sum } from "lodash";
import { useMemo } from "react";
import { CurationApplicationFragment } from "../../generated/graphql";

export function useRequiredCountMap(application: CurationApplicationFragment) {
  /**
   * Map of ID to it's descendants required count.
   */
  const requiredCountMap = new Map<string, number>();
  const nodesById = useMemo(() => Maps.keyBy(application.nodes, (n) => n.id), [application.nodes]);
  const isRequiredById = useMemo(
    () =>
      Maps.collectBy(
        application.questions,
        (q) => q.id,
        (q) => (q as { isRequired?: boolean }).isRequired
      ),
    [application.questions]
  );

  // DFS to build the count map
  const traverse = (id: string) => {
    const node = nodesById.get(id);
    // Leaf nodes
    if (!node) {
      const count = isRequiredById.get(id) ? 1 : 0;
      requiredCountMap.set(id, count);
      return count;
    }

    const children = node.children ?? [];
    const count = sum(children.map(traverse));
    requiredCountMap.set(id, count);
    return count;
  };

  application.rootIds.forEach((id) => traverse(id));

  return requiredCountMap;
}
