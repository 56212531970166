import { usePrevious } from "@cp/react-hooks";
import React, { useEffect } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import {
  ApplicationConditionBooleanFragment,
  ApplicationConditionNumberFragment,
  ApplicationConditionStringFragment,
  ApplicationConditionStringSetFragment,
} from "../../generated/operations";
import { getSubject, validateCondition } from "./validateCondition";

export type ApplicationCondition =
  | ApplicationConditionBooleanFragment
  | ApplicationConditionStringFragment
  | ApplicationConditionStringSetFragment
  | ApplicationConditionNumberFragment
  | { __typename: "ApplicationConditionMatchAny"; matchAnyConditions?: ApplicationCondition[] }
  | { __typename: "ApplicationConditionMatchAll"; matchAllConditions?: ApplicationCondition[] };

interface Props {
  conditions: ApplicationCondition[];
  children?: React.ReactNode;
  fallback?: React.ReactElement | null;
  row?: number;
}

export const ApplicationConditions: React.VFC<Props> = ({ conditions, children, row, fallback = null }) => {
  const { trigger } = useFormContext();

  const subjects = conditions.flatMap(getSubject);

  const dependantValues: Array<string | string[]> = useWatch({
    name: subjects,
  });

  // AND all top-level conditions.
  const conditionsMet = conditions.every((condition) =>
    validateCondition(condition, (subject) => {
      const subjectIndex = subjects.indexOf(subject);
      const subjectValues = dependantValues[subjectIndex];
      if (row != null) {
        if (Array.isArray(subjectValues)) {
          return subjectValues[row];
        }
        return subjectValues;
      }
      return subjectValues;
    })
  );
  const prevConditionsMet = usePrevious(conditionsMet);

  // Retrigger form validation when the state of conditions changes
  useEffect(() => {
    if (prevConditionsMet === undefined) {
      // not set yet
      return;
    }
    // If we retrigger on mount, there is a cascading effect of re-rendering all fields
    if (conditionsMet !== prevConditionsMet) {
      trigger();
    }
  }, [conditionsMet, prevConditionsMet, trigger]);

  if (conditions.length === 0) {
    return <>{children}</>;
  }

  return conditionsMet ? <>{children}</> : fallback;
};
