import React, { PropsWithChildren } from "react";
import { AuthToken } from "../../util/auth";
import { LoginPage } from "./LoginPage";

/**
 * Checks if the user is logged in and redirects to the login page if not.
 * User is considered logged in if the cookie `access_token` is set.
 */
export const RouteGuard: React.FC<PropsWithChildren> = ({ children }) => {
  if (!AuthToken.hasToken()) {
    return <LoginPage />;
  }

  return <>{children}</>;
};
