import { useBoolean } from "@cp/react-hooks";
import { useToaster } from "@cp/theme";
import { Add } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Tooltip } from "@mui/material";
import {
  CarrierProjectDocument,
  CurationQuestionFragment,
  PortalAnnotationsDocument,
  useUpdateClobQuestionMutation,
} from "../../generated/graphql";
import { useClobContext } from "../../model/clobs/context";
import { EditableClobQuestion } from "../../model/clobs/types";
import { conditionToInput } from "../../model/conditions/conditionToInput";
import CreateCurationQuestionDialog from "../../pages/clobs/MapClob/CreateCurationQuestionDialog";

interface Props {
  clobQuestion: EditableClobQuestion;
}

export function CreateCurationQuestionFromClobQuestion({ clobQuestion }: Props) {
  const [isOpen, isOpenActions] = useBoolean(false);
  const { toastUndo } = useToaster();
  const { actions } = useClobContext();
  const [updateClobQuestion, { loading }] = useUpdateClobQuestionMutation({
    // Refetch for updated counts
    refetchQueries: [PortalAnnotationsDocument, CarrierProjectDocument],
  });

  const handleCreateCurationMapping = async (curationQuestion: CurationQuestionFragment) => {
    const handleUndo = async () => {
      await updateClobQuestion({
        variables: {
          clobQuestion: { id: clobQuestion.id, curationQuestionId: undefined },
        },
        onCompleted: (data) => {
          actions.updateQuestion({
            ...data.updateClobQuestion,
            componentType: data.updateClobQuestion.componentType || undefined, // convert empty string to undefined
            visibility: data.updateClobQuestion.visibility.map(conditionToInput),
          });
        },
      });
    };

    // Map the clob question to the curation question
    await updateClobQuestion({
      variables: { clobQuestion: { id: clobQuestion.id, curationQuestionId: curationQuestion.id } },
      onCompleted: (data) => {
        // Toast to allow undo-ing the mapping
        toastUndo(`Curation question linked to CLOB question ${curationQuestion.text}`, {
          onUndo: handleUndo,
        });
        actions.updateQuestion({
          ...data.updateClobQuestion,
          componentType: data.updateClobQuestion.componentType || undefined, // convert empty string to undefined
          visibility: data.updateClobQuestion.visibility.map(conditionToInput),
        });
      },
    });
  };

  return (
    <>
      <Tooltip title="Create curation question">
        <LoadingButton
          color="success"
          size="small"
          loading={loading}
          onClick={isOpenActions.toggle}
          sx={{
            opacity: 0.5,
            "&:hover": { opacity: 1 },
          }}
        >
          <Add />
        </LoadingButton>
      </Tooltip>
      {isOpen && (
        <CreateCurationQuestionDialog
          open={isOpen}
          handleClose={isOpenActions.setFalse}
          onSuccess={handleCreateCurationMapping}
          confirmText="Save and Map"
          initialValues={{
            text: clobQuestion.text,
            options: clobQuestion.options ?? [],
            componentType: clobQuestion.componentType,
          }}
        />
      )}
    </>
  );
}
