import { empty } from "../arrays";
import { NOOP } from "../functions";
import { collectBy } from "../maps";
import { TreeTraversalCallbacks } from "./traversers";

/**
 * A tree data structure to allow for easy traversal.
 *
 * Forms are a tree data structure, so we can use this to traverse the form.
 */
export class Tree {
  constructor(private nodes: ReadonlyMap<string, string[]>) {}

  static from(items: Array<{ id: string; children: string[] }>): Tree {
    return new Tree(
      collectBy(
        items,
        (item) => item.id,
        (item) => item.children
      )
    );
  }

  entries(): IterableIterator<[string, string[]]> {
    return this.nodes.entries();
  }

  getChildren(id: string): readonly string[] {
    return this.nodes.get(id) || empty();
  }

  depthFirstSearch(node: string, callbacks: Partial<TreeTraversalCallbacks<string>>): void {
    const seen = new Set<string>();
    this.depthFirstSearchRecursive(
      undefined,
      node,
      {
        allowTraversal: ({ nextNode }) => {
          if (seen.has(nextNode)) {
            return false;
          }
          seen.add(nextNode);
          return true;
        },
        onEnterNode: NOOP,
        onLeaveNode: NOOP,
        ...callbacks,
      },
      0
    );
  }

  private depthFirstSearchRecursive(
    previousNode: string | undefined,
    node: string,
    callbacks: TreeTraversalCallbacks<string>,
    depth: number
  ): void {
    callbacks.onEnterNode({ previousNode, currentNode: node, depth });
    for (const childNode of this.getChildren(node)) {
      if (callbacks.allowTraversal({ previousNode: node, nextNode: childNode, depth })) {
        this.depthFirstSearchRecursive(node, childNode, callbacks, depth + 1);
      }
    }
    callbacks.onLeaveNode({ currentNode: node, depth });
  }
}
