import type { Components, Theme } from "@mui/material/styles";

export function FormInput(theme: Theme): Components<Theme> {
  return {
    MuiFormControl: {
      defaultProps: {
        variant: "filled",
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: "filled",
        InputLabelProps: { sx: { paddingRight: 1 } },
      },
    },
    MuiSelect: {
      defaultProps: {
        variant: "filled",
      },
    },
    MuiFilledInput: {
      defaultProps: {
        disableUnderline: true,
      },
      styleOverrides: {
        root: {
          backgroundColor: theme.palette.background.alternate,
          borderRadius: 2,
          border: `1px solid ${theme.palette.divider}`,
          color: theme.palette.text.primary,
          fontWeight: 600,
          "&:hover": {
            backgroundColor: theme.palette.background.alternate,
          },
          "&.Mui-focused": {
            backgroundColor: theme.palette.background.paper,
            borderColor: theme.palette.info.main,
          },
          "&.Mui-error": {
            backgroundColor: theme.palette.background.alternate,
            borderColor: theme.palette.warning.main,
          },
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          color: theme.palette.text.primary,
          fontSize: "14px",
          fontWeight: 600,
          letterSpacing: "-0.02em",
        },
      },
    },
  };
}
