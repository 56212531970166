import { TypeSchemas } from "@cp/toolkit";
import { z } from "zod";
import { USState, US_STATES } from "../../../../toolkit/src/states/options";
import { WorkersCompClassFragment } from "../../generated/operations";

export type WCCodeClassificationQueryResult = WorkersCompClassFragment & { matchingCode: WorkersCompCode };

export enum WorkersCompCodeType {
  UNKNOWN = "",
  NCCI = "NCCI",
  CAWC = "CAWC",
  DEWC = "DEWC",
  MIWC = "MIWC",
  NJWC = "NJWC",
  NYWC = "NYWC",
  PAWC = "PAWC",
  TXWC = "TXWC",
}

const WorkersCompCodeSchema = TypeSchemas.WcCodeReducedSchema.extend({
  codeType: z.nativeEnum(WorkersCompCodeType),
  stateCode: z.string().optional() as z.ZodType<USState | undefined>,
});

export type WorkersCompCode = z.infer<typeof WorkersCompCodeSchema>;

export const DEFAULT_WORKERS_COMP_STATE_CODE: USState = "TX";

export const StateCodeToWorkersCompCodeType: Record<USState, WorkersCompCodeType> = {
  ...(Object.fromEntries(US_STATES.map((state) => [state, WorkersCompCodeType.NCCI])) as Record<
    USState,
    WorkersCompCodeType
  >),
  CA: WorkersCompCodeType.CAWC,
  DE: WorkersCompCodeType.DEWC,
  MI: WorkersCompCodeType.MIWC,
  NJ: WorkersCompCodeType.NJWC,
  NY: WorkersCompCodeType.NYWC,
  PA: WorkersCompCodeType.PAWC,
  TX: WorkersCompCodeType.TXWC,
};

export const getStateSpecificCode = (stateCode: USState, workersCompClassCode: WorkersCompClassFragment) => {
  switch (stateCode.toLocaleLowerCase()) {
    case "ca":
      return workersCompClassCode.caWcCode;
    case "de":
      return workersCompClassCode.deWcCode;
    case "mi":
      return workersCompClassCode.miWcCode;
    case "nj":
      return workersCompClassCode.njWcCode;
    case "ny":
      return workersCompClassCode.nyWcCode;
    case "pa":
      return workersCompClassCode.paWcCode;
    case "tx":
      return workersCompClassCode.txWcCode;
    case "other":
    default:
      return workersCompClassCode.ncciCode;
  }
};

export function addMatchingCode(
  workersCompClassCode: WorkersCompClassFragment[],
  query: string,
  stateCode: USState
): WCCodeClassificationQueryResult[] {
  return workersCompClassCode.map((result) => {
    return { ...result, matchingCode: getStateSpecificWorkersCompCode(result, stateCode) };
  });
}

export function workersCompComparator(code1: WCCodeClassificationQueryResult, code2: WCCodeClassificationQueryResult) {
  if (code1.matchingCode.codeType === code2.matchingCode.codeType) {
    return (code1.matchingCode.code ?? "").localeCompare(code2.matchingCode.code ?? "");
  }
  if (code1.matchingCode.codeType === WorkersCompCodeType.NCCI) {
    return -1; // We want to surface up the matches for NCCI Codes
  }
  if (code2.matchingCode.codeType === WorkersCompCodeType.NCCI) {
    return 1;
  }

  return code1.matchingCode.codeType.localeCompare(code2.matchingCode.codeType); // We don't care otherwise.
}

export function uniqueAndSorted(searchResults: WCCodeClassificationQueryResult[]): WCCodeClassificationQueryResult[] {
  searchResults.sort(workersCompComparator);
  const resultsHaveNcciCodeMatch = searchResults.length > 0 && !nonNcciCodeMatch(searchResults[0]);

  return searchResults.filter((value, index, array) => {
    if (index === 0) {
      return true;
    }

    // Filter out non-ncci codes when any ncci code result is available.
    if (resultsHaveNcciCodeMatch && nonNcciCodeMatch(value)) {
      return false;
    }

    // Remove duplicate matches.
    const previousValue = array[index - 1];
    if (previousValue) {
      return (
        previousValue.matchingCode.code !== value.matchingCode.code ||
        previousValue.matchingCode.codeType !== value.matchingCode.codeType
      );
    }
    return false;
  });
}

export function getStateSpecificWorkersCompCode(w: WorkersCompClassFragment, stateCode: USState): WorkersCompCode {
  return {
    id: w.id,
    code: getStateSpecificCode(stateCode, w) || "",
    codeType: StateCodeToWorkersCompCodeType[stateCode] || WorkersCompCodeType.NCCI,
    stateCode: stateCode,
  };
}

export function serializeWorkersCompCodeSafe(code: WorkersCompCode | string | null | undefined): string {
  if (code && typeof code === "string") {
    return code;
  }
  if (!code) {
    return "";
  }

  try {
    return JSON.stringify(code);
  } catch {
    return "";
  }
}

export function deSerializeWorkersCompCodeSafe(code: any | null | undefined): WorkersCompCode | undefined {
  if (!code) {
    return undefined;
  }

  if (code && typeof code !== "string" && isWorkersCompCode(code)) {
    return code;
  }

  if (code && typeof code === "string") {
    try {
      return WorkersCompCodeSchema.parse(JSON.parse(code));
    } catch {
      // Exception Parsing}
    }
  }

  // Is not numeric, return undefined
  if (code && typeof code === "string" && Number.isNaN(Number(code))) {
    return undefined;
  }

  return { id: "", code: code || "", codeType: WorkersCompCodeType.NCCI, stateCode: DEFAULT_WORKERS_COMP_STATE_CODE };
}

export function formatCode(code: string | null | undefined) {
  return code ? code.padStart(4, "0") : code;
}

function isWorkersCompCode(obj: any): obj is WorkersCompCode {
  return "id" in obj && "code" in obj && "codeType" in obj;
}

function nonNcciCodeMatch(value: WCCodeClassificationQueryResult): boolean {
  return value.matchingCode.codeType !== WorkersCompCodeType.NCCI;
}
