import { EmptyState, LoadState } from "@cp/theme";
import { Button, colors, Divider, Stack, Typography } from "@mui/material";
import React from "react";
import { ArchiveCurationQuestionToggle } from "../../../../components/ArchiveCurationQuestionToggle";
import { useUnmappedCurationQuestionsQuery } from "../../../../generated/graphql";
import { CurationQuestionBody } from "../../../clobs/MapClob/components/CurationQuestionBody";
import { CurationQuestionMappingIndicator } from "../../../clobs/MapClob/components/CurationQuestionMappingIndicator";
import { useSelectedCurationQuestions } from "../../../clobs/MapClob/selection-atom";
import { GoldenQuestionIcon } from "../../../curation/icons/GoldenQuestionIcon";

export const UnmappedCurationQuestions: React.FC = () => {
  const { data, loading, error } = useUnmappedCurationQuestionsQuery();
  const [, setSelectedCurationQuestions] = useSelectedCurationQuestions();

  const questions = data?.curationQuestionsWithoutClobQuestions || [];

  if (loading || error) {
    return <LoadState loading={loading} error={error} />;
  }

  if (questions.length === 0) {
    return <EmptyState title="No orphaned questions" description="Orphaned curation questions will appear here." />;
  }

  return (
    <Stack gap={1} maxWidth={800} width="100%" p={4}>
      <Button
        variant="outlined"
        size="small"
        onClick={() => {
          setSelectedCurationQuestions(questions.map((question) => question.id));
        }}
      >
        Select all
      </Button>
      {questions.map((question) => (
        <Stack
          key={question.id}
          direction="row"
          alignItems="center"
          boxShadow={1}
          width="100%"
          flex={1}
          p={1}
          divider={<Divider />}
          borderRadius={1}
          sx={{
            backgroundColor: question.golden ? colors.orange[50] : "white",
          }}
        >
          <Stack flex={1} overflow="hidden">
            <Stack gap={1} direction="row" alignItems="center" className="hide-action-items" minHeight="40px" flex={1}>
              <CurationQuestionMappingIndicator id={question.id} hideMappingIndicator={true} />
              <Typography title={question.text} flex={1} variant="body2">
                {question.text}
              </Typography>
              <ArchiveCurationQuestionToggle id={question.id} archived={question.archived} />
            </Stack>
            <CurationQuestionBody id={question.id} search="" question={question} />
          </Stack>
          {question.golden && <GoldenQuestionIcon />}
        </Stack>
      ))}
    </Stack>
  );
};
