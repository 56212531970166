import { useToaster } from "@cp/theme";
import FormDialog from "../../../components/FormDialog";
import {
  CarrierProjectDocument,
  CurationQuestionFragment,
  PortalAnnotationsDocument,
  useCreateCurationQuestionMutation,
} from "../../../generated/graphql";
import {
  curationQuestionFragmentToInput,
  UpsertCurationQuestionInput,
} from "../../../model/curation-questions/deserialize";
import CurationQuestionForm from "./CurationQuestionForm";

const FORM_ID = "curation-question-form";

export interface CreateCurationQuestionDialogProps {
  clobQuestionId?: string;
  initialValues?: Partial<CurationQuestionFragment>;
  open: boolean;
  confirmText?: string;
  handleClose: () => void;
  onSuccess?: (data: CurationQuestionFragment) => void;
}

export default function CreateCurationQuestionDialog({
  open,
  initialValues,
  handleClose,
  onSuccess,
  confirmText,
}: CreateCurationQuestionDialogProps) {
  const { toast } = useToaster();
  const [mutate, { loading }] = useCreateCurationQuestionMutation({
    // Refetch for updated counts
    refetchQueries: [PortalAnnotationsDocument, CarrierProjectDocument],
  });

  const handleSubmit = (data: UpsertCurationQuestionInput) => {
    mutate({
      variables: { input: data },
      refetchQueries: ["CurationQuestions"],
      onCompleted: (data) => {
        onSuccess?.(data.createCurationQuestion);
        toast("Curation question created successfully");
        handleClose();
      },
    });
  };

  return (
    <FormDialog
      formId={FORM_ID}
      title="Create Curation Question"
      loading={loading}
      open={open}
      onClose={handleClose}
      confirmText={confirmText}
    >
      <CurationQuestionForm
        formId={FORM_ID}
        onSubmit={handleSubmit}
        curationQuestion={curationQuestionFragmentToInput(initialValues)}
      />
    </FormDialog>
  );
}
