import { useState } from "react";
import { useDebounce } from "./useDebounce";

export function useSearchQuery(initialValue = "") {
  const [searchQuery, setSearchQuery] = useState(initialValue.toString());
  const debouncedValue = useDebounce(searchQuery, 250);

  return {
    value: searchQuery,
    setValue: setSearchQuery,
    debouncedValue: debouncedValue,
    inputProps: {
      value: searchQuery,
      onChange: <T extends { value: string }>(event: React.ChangeEvent<T>) => setSearchQuery(event.target.value),
    },
    isEmpty: searchQuery.trim() === "",
    clear: () => setSearchQuery(""),
  };
}
