import { useAtom } from "jotai";
import { CustomCurationNodeSearch } from "../../../../components/CurationNodeSearch";
import { useSearchCurationNodes } from "../../../curation/atoms";
import { useAllCurationQuestions } from "../../../curation/state/useAllCurationQuestions";
import { useCurationState } from "../../../curation/state/useCurationState";
import { curationQuestionSearchStringAtom } from "../atoms";

export function useClobDetailsSearchCurationNodes() {
  const allQuestions = useAllCurationQuestions();
  const { curationNodeMap } = useCurationState();
  const { onSearch, scrollToFirstSearchHit, scrollToNextSearchHit, scrollToPrevSearchHit, index, total } =
    useSearchCurationNodes(curationNodeMap, allQuestions);
  const [value, setValue] = useAtom(curationQuestionSearchStringAtom);

  /**
   * This should be used sparingly, as it will clear the search and collapse all nodes.
   * This may be disruptive to the curator.
   */
  const setSearchQuery = (query: string) => {
    onSearch(""); // Clear search and collapse all
    setValue(query);
    onSearch(query);
  };

  return {
    value,
    setValue,
    setSearchQuery,
    onSearch,
    scrollToFirstSearchHit,
    scrollToNextSearchHit,
    scrollToPrevSearchHit,
    index,
    total,
  };
}

export function ClobDetailsCurationNodeSearch() {
  const {
    value,
    setValue,
    onSearch,
    scrollToFirstSearchHit,
    scrollToNextSearchHit,
    scrollToPrevSearchHit,
    index,
    total,
  } = useClobDetailsSearchCurationNodes();
  return (
    <CustomCurationNodeSearch
      value={value}
      setValue={setValue}
      onSearch={onSearch}
      scrollToFirstSearchHit={scrollToFirstSearchHit}
      scrollToNextSearchHit={scrollToNextSearchHit}
      scrollToPrevSearchHit={scrollToPrevSearchHit}
      index={index}
      total={total}
    />
  );
}
