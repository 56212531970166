import { FormHelperText, Stack, SxProps, Theme, useTheme } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import FormLabel from "@mui/material/FormLabel";
import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useSaveForm } from "./common/useSaveForm";
import { FormFieldProps, processFormFieldProps } from "./FormFieldProps";

interface FormFieldCheckboxGroupProps extends FormFieldProps {
  renderEndAdornment?: (option: { label: React.ReactNode; value: string }) => React.ReactNode;
  optionSx?: SxProps<Theme>;
}

export const FormFieldCheckboxGroup: React.VFC<FormFieldCheckboxGroupProps> = (props) => {
  const { control, getFieldState } = useFormContext();
  const theme = useTheme();
  const { id, label, helperText, options = [], sx, ControllerProps, disabled, required } = processFormFieldProps(props);
  const { optionSx } = props;

  const { error } = getFieldState(id ?? "");
  const saveForm = useSaveForm();

  return (
    <FormControl
      component="fieldset"
      sx={{ gap: "0", width: "100%", ...sx }}
      disabled={disabled}
      required={required}
      onBlur={(e: React.FormEvent) => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      <FormLabel className="question" component="legend" required={required} error={!!error}>
        {label}
      </FormLabel>
      {!!helperText && <FormHelperText>{helperText}</FormHelperText>}
      <FormGroup>
        <Controller
          control={control}
          defaultValue={[]}
          {...ControllerProps}
          render={({ field }) => (
            <>
              {options.map((option) => (
                <Stack key={option.value} direction="row" alignItems="center" spacing={1}>
                  <FormControlLabel
                    {...field}
                    sx={{ ...optionSx, flex: 1 }}
                    label={option.label}
                    control={
                      <Checkbox
                        checked={field.value.includes(option.value)}
                        sx={{ color: error ? theme.palette.error.main : undefined }}
                        onChange={(_, checked) => {
                          // This question's component type may have changed and the value may have been already entered as a string,
                          // so we safely convert to an array
                          if (!Array.isArray(field.value)) {
                            throw new TypeError(`Expected field to be an array but instead it was ${field.value}`);
                          }

                          const saveableValue = option.value;

                          // We have gotten into situations with dupes before. Make sure at most one instance of this value is left in the saved value
                          const filteredFieldValue = field.value.filter((v) => v !== saveableValue);
                          const valueSet = checked ? [...filteredFieldValue, saveableValue] : filteredFieldValue;

                          field.onChange(valueSet);
                          saveForm(field.name, valueSet);
                        }}
                      />
                    }
                  />
                  {props.renderEndAdornment?.(option)}
                </Stack>
              ))}
            </>
          )}
        />
      </FormGroup>
    </FormControl>
  );
};
