import { useCallback, useRef, useState } from "react";
import { useEventListener } from "./useEventListener";

/**
 * Check if the user is scrolling anywhere on the page.
 */
export function useIsScrolling() {
  const [isScrolling, setIsScrolling] = useState(false);
  const timeoutRef = useRef<number>();

  const handleScroll = useCallback(() => {
    setIsScrolling(true);
    clearTimeout(timeoutRef.current);
    timeoutRef.current = window.setTimeout(() => {
      setIsScrolling(false);
    }, 100);
  }, []);

  useEventListener("scroll", handleScroll, true);

  return isScrolling;
}
