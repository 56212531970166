import { DropTargetMonitor, useDrop } from "react-dnd";
import { useLinkCurationQuestion } from "../../../components/curation-questions/useLinkCurationQuestion";
import { Dragable, DragTypes, DropResult } from "../Dragables/DragTypes";
import { CurationQuestionId } from "../state/types";

/**
 * React-dnd hook to handle dropping a clob-question or pdf-annotation onto a curation-question.
 */
export function useCurationQuestionDropzone(curationQuestionId: CurationQuestionId) {
  const [linkCurationQuestion] = useLinkCurationQuestion();

  return useDrop<Dragable, DropResult, { isOver: boolean; canDrop: boolean }>(
    () => ({
      accept: [DragTypes.CLOB_QUESTION, DragTypes.PDF_ANNOTATION],
      canDrop: (item: Dragable) => {
        return true;
      },
      drop: (dragable: Dragable, monitor: DropTargetMonitor<Dragable, DropResult>) => {
        if (monitor.didDrop()) {
          return undefined;
        }

        // Handle clob question drop
        // We link the curation question to the clob question
        if (dragable.type === DragTypes.CLOB_QUESTION) {
          void linkCurationQuestion({
            clobQuestionIds: [dragable.id],
            curationQuestionId,
          });
        }

        // Handle pdf annotation drop
        // We link the curation question to the pdf annotation
        if (dragable.type === DragTypes.PDF_ANNOTATION) {
          return {
            type: "connect-curation-question",
            curationQuestionId,
          };
        }

        return undefined;
      },
      collect: (monitor) => ({
        isOver: monitor.isOver({ shallow: true }) && monitor.canDrop(),
        canDrop: monitor.canDrop(),
      }),
    }),
    [curationQuestionId]
  );
}
