import {
  Autocomplete as MuiAutocomplete,
  autocompleteClasses,
  createFilterOptions,
  Popper,
  PopperProps,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  TextFieldProps,
} from "@mui/material";
import * as React from "react";
import { Controller, ControllerFieldState, RefCallBack, useFormContext } from "react-hook-form";
import { combinedText } from "./common/combineText";
import { FormFieldProps, processFormFieldProps } from "./FormFieldProps";

interface Props<T> extends Omit<FormFieldProps, "options"> {
  options: T[];
  getValue: (value: T) => string;
  getOptionLabel: (option: T) => string;
  table?: {
    headers: string[];
    getRow: (value: T) => string[];
  };
  multiple?: boolean;
}

const StyledAutocompletePopper = styled(Popper)({
  [`&.${autocompleteClasses.popper}`]: {
    width: "50vw !important",
  },
}) as any as React.JSXElementConstructor<PopperProps>;

export function FormFieldAutocomplete<T>({ options, ...props }: Props<T>) {
  const { label, sx, ControllerProps, helperText } = processFormFieldProps(props);
  const { control } = useFormContext(); // retrieve all hook methods

  return (
    <Controller
      control={control}
      {...ControllerProps}
      render={({ field, fieldState }) => (
        <Autocomplete
          {...props}
          {...field}
          {...fieldState}
          options={options}
          label={label}
          sx={sx}
          helperText={helperText}
        />
      )}
    />
  );
}

type AutocompleteProps<T> = Props<T> &
  Partial<ControllerFieldState> & {
    onBlur?: () => void;
    value: string;
    name: string;
    ref?: RefCallBack;
    clearOnBlur?: boolean;
    blurOnSelect?: boolean;
    helperText?: React.ReactNode;
    TextFieldProps?: TextFieldProps;
  } & ChangeHandlerArgs<T>;

type ChangeHandlerArgs<T> =
  | {
      multiple?: false;
      value: string;
      getValue: Props<T>["getValue"];
      onChange?: (id: string) => void;
    }
  | {
      multiple: true;
      value: string[];
      getValue: Props<T>["getValue"];
      onChange?: (ids: string[]) => void;
    };

function makeChangeHandler<T>(
  props: ChangeHandlerArgs<T>
): (e: React.SyntheticEvent<Element, Event>, data: T | T[] | null) => void {
  return (e, data) => {
    if (props.multiple) {
      props.onChange?.((data as T[]).map(props.getValue));
    } else {
      props.onChange?.(data ? props.getValue(data as T) : "");
    }
  };
}

export function Autocomplete<T>(props: AutocompleteProps<T>) {
  const {
    options,
    table,
    clearOnBlur,
    blurOnSelect,
    getValue,
    getOptionLabel,
    onChange,
    onBlur,
    value,
    name,
    ref,
    error,
    sx,
    label,
    helperText,
    defaultValue,
    TextFieldProps,
    multiple,
    ...autocompleteProps
  } = props;
  const filterOptions = createFilterOptions<T>({
    matchFrom: "any",
    stringify: (option: T) => {
      return table?.getRow(option).join(" ") || getOptionLabel(option);
    },
  });
  const processNaicsPrefix = (value: string): string => {
    if (["31", "32", "33"].includes(value)) {
      return "31-33";
    } else if (["44", "45"].includes(value)) {
      return "44-45";
    } else if (["48-49"].includes(value)) {
      return "48-49";
    }
    return value;
  };

  let componentValue = multiple
    ? options.filter((option) => value.includes(getValue(option)))
    : options.find((option) => getValue(option) === value) ?? null;

  if (name === "naicsPrefixes") {
    componentValue = multiple
      ? options.filter((option) => value.map(processNaicsPrefix).includes(getValue(option)))
      : options.find((option) => getValue(option) === processNaicsPrefix(value)) ?? null;
  }

  return (
    <MuiAutocomplete
      {...autocompleteProps}
      multiple={multiple}
      options={options}
      clearOnBlur={clearOnBlur}
      blurOnSelect={blurOnSelect}
      onChange={makeChangeHandler(props)}
      getOptionLabel={getOptionLabel}
      filterOptions={filterOptions}
      isOptionEqualToValue={(option, value) => {
        if (value) {
          return getValue(option) === getValue(value);
        }
        return false;
      }}
      onBlur={onBlur}
      ListboxComponent={({ children, className, ...props }) => (
        <div style={{ maxHeight: "40vh", overflow: "auto" }} {...props}>
          <Table>
            <TableHead sx={{ position: "sticky", top: 0 }}>
              <TableRow>
                {table?.headers.map((header) => (
                  <TableCell key={header} sx={{ color: "#FFFFFF", backgroundColor: "#424242" }}>
                    {header}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>{children}</TableBody>
          </Table>
        </div>
      )}
      PopperComponent={StyledAutocompletePopper}
      renderOption={(props, option) => renderOption(props, option, getValue, table?.getRow)}
      value={componentValue}
      sx={sx}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            name={name}
            inputRef={ref}
            label={label}
            error={!!error}
            helperText={combinedText({
              helperText: helperText,
              error: error?.message,
            })}
            {...TextFieldProps}
            InputProps={{
              ...params.InputProps,
              ...TextFieldProps?.InputProps,
            }}
          />
        );
      }}
    />
  );
}

function renderOption<T>(
  props: React.HTMLAttributes<HTMLElement>,
  option: T,
  getValue: (value: T) => string,
  getRow?: (value: T) => string[]
) {
  const rowValues = getRow?.(option);
  return (
    <TableRow sx={{ cursor: "pointer" }} {...props} key={getValue(option)}>
      {rowValues?.map((value, idx) => (
        <TableCell key={idx.toString() + value}>{value}</TableCell>
      ))}
    </TableRow>
  );
}
