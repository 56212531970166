import { FormHelperText, FormLabel } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import { useFormValue } from "../context/FormDataProvider";
import { ReadonlyFieldProps } from "./ReadonlyFieldProps";

export const YES_NO_OPTIONS = [
  {
    label: "Yes",
    value: "true",
  },
  {
    label: "No",
    value: "false",
  },
];

export const ReadonlyFieldBooleanRadio: React.VFC<ReadonlyFieldProps> = ({
  name,
  label,
  sx,
  required,
  helperText,
  defaultValue,
  ...rest
}) => {
  const value = useFormValue(name);

  return (
    <FormControl sx={sx} component="fieldset" required={required}>
      <FormLabel className="question">{label}</FormLabel>
      <FormHelperText sx={{ ml: 0 }}>{helperText}</FormHelperText>
      <RadioGroup name={name} row={true} {...rest} value={value} defaultValue={defaultValue}>
        {YES_NO_OPTIONS.map((option) => (
          <FormControlLabel
            key={option.label}
            value={option.value}
            control={<Radio disableRipple={true} readOnly={true} />}
            label={option.label}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
};
