import { useModal } from "@cp/modals";
import { Delete } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import React from "react";
import {
  CarrierProjectDocument,
  PortalAnnotationsDocument,
  Scalars,
  useRemovePortalAnnotationMutation,
} from "../../../generated/graphql";

interface Props {
  recordingId: Scalars["CarrierRecordingId"];
  annotationId: Scalars["PortalAnnotationId"];
  onRemove: () => void;
}

export const RemoveAnnotation: React.FC<Props> = ({ recordingId, annotationId, onRemove }) => {
  const { openConfirm } = useModal();
  const [removeAnnotation] = useRemovePortalAnnotationMutation({
    // Refetch for updated counts
    refetchQueries: [PortalAnnotationsDocument, CarrierProjectDocument],
    variables: {
      input: {
        annotationId,
        recordingId,
      },
    },
    onCompleted: () => {
      onRemove();
    },
  });

  return (
    <IconButton
      size="small"
      sx={{ opacity: 0.5, "&:hover": { opacity: 1 } }}
      color="error"
      onClick={async (evt) => {
        evt.stopPropagation();
        const confirmed = await openConfirm({
          title: "Remove annotation",
          message: "Are you sure you want to remove this annotation?",
          cancelText: "Cancel",
        });
        if (!confirmed) {
          return;
        }

        await removeAnnotation();
      }}
    >
      <Delete fontSize="small" />
    </IconButton>
  );
};
