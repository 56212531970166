import React from "react";
import { NumberFormatValues } from "react-number-format";
import { FormFieldNumberFormat, FormFieldNumberFormatProps } from "./FormFieldNumberFormat";

const MAX_VAL = 100;
const withValueLimit = ({ value }: NumberFormatValues) => value === "" || Number.parseInt(value) <= MAX_VAL;

export const FormFieldPercentage: React.VFC<FormFieldNumberFormatProps> = (props) => {
  return (
    <FormFieldNumberFormat suffix="%" decimalScale={0} allowNegative={false} isAllowed={withValueLimit} {...props} />
  );
};
