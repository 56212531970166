import type { Components, Theme } from "@mui/material/styles";

export function Avatar(theme: Theme): Components<Theme> {
  return {
    MuiAvatar: {
      styleOverrides: {
        root: {
          backgroundColor: theme.palette.grey[500],
          color: theme.palette.primary.contrastText,
          fontSize: "14px",
          fontWeight: 700,
          height: 32,
          lineHeight: "16px",
          width: 32,
          "&.large": {
            fontSize: "14px",
            height: 32,
            lineHeight: "16px",
            width: 32,
          },
          "&.small": {
            fontSize: "11px",
            height: 24,
            lineHeight: "12px",
            width: 24,
          },
        },
      },
    },
  };
}
