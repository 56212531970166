import { isUUID } from "../uuid";

export function words(s: string): string[] {
  // don't split on UUIDs
  if (isUUID(s)) {
    return [s];
  }

  // Split on whitespace and punctuation, with colon
  return s.split(/\s+|[!,.:;?_-]/).filter(Boolean);
}

export function smartMatch(needle: string, haystackOrHaystacks: string | Array<string | null | undefined>): boolean {
  // Note: .join treats nulls and undefineds as empty strings
  const haystack = typeof haystackOrHaystacks === "string" ? haystackOrHaystacks : haystackOrHaystacks.join(" ");
  const needles = words(needle.toLowerCase());
  const wordsInHaystack = words(haystack.toLowerCase());
  return needles.every((n) => wordsInHaystack.some((h) => h.startsWith(n)));
}

export function exactMatch(needle: string, haystackOrHaystacks: string | Array<string | null | undefined>): boolean {
  // Note: .join treats nulls and undefineds as empty strings
  const haystack = typeof haystackOrHaystacks === "string" ? haystackOrHaystacks : haystackOrHaystacks.join(" ");

  return haystack.toLowerCase().includes(needle.toLocaleLowerCase());
}
