import styled from "@emotion/styled";
import { Link } from "@tanstack/react-location";

export const TableLink = styled(Link)({
  fontSize: "13px",
  fontWeight: 700,
  lineHeight: "16px",
  textDecoration: "none",
  "&:hover": {
    textDecoration: "underline",
  },
});
