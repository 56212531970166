import type { Components, Theme } from "@mui/material/styles";

export function Dialog(theme: Theme): Components<Theme> {
  return {
    MuiDialog: {
      styleOverrides: {
        paper: {
          minWidth: "480px",
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          borderBottom: "1px solid",
          borderColor: theme.palette.divider,
          fontSize: "16px",
          fontWeight: 600,
          letterSpacing: "-0.04em",
          lineHeight: "24px",
          margin: 0,
          padding: "16px",
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          marginTop: "0px !important",
          padding: "16px",
          ".MuiDialogTitle-root + &": {
            paddingTop: "16px",
          },
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          borderTop: "1px solid",
          borderColor: theme.palette.divider,
          justifyContent: "space-between",
          padding: "16px",
        },
      },
    },
  };
}
