import { Search } from "@mui/icons-material";
import {
  AutocompleteRenderInputParams,
  Button,
  CircularProgress,
  InputAdornment,
  Stack,
  TextField,
  Theme,
  Typography,
} from "@mui/material";
import * as React from "react";
import { FieldError } from "react-hook-form";
import { combinedText } from "../combineText";
import { Omnibar } from "../Omnibar";

export function renderAutocompleteInputFn({
  name,
  ref,
  loading,
  startAdornment,
  error,
  helperText,
  placeholderText,
}: {
  name?: string;
  ref?: React.Ref<unknown> | undefined;
  loading: boolean;
  startAdornment?: React.ReactNode;
  error?: FieldError | undefined;
  helperText?: React.ReactNode;
  placeholderText: string;
}) {
  // eslint-disable-next-line react/display-name
  return (params: AutocompleteRenderInputParams) => {
    return (
      <TextField
        {...params}
        name={name}
        inputRef={ref}
        variant="outlined"
        placeholder={placeholderText}
        InputProps={{
          ...params.InputProps,
          sx: {
            backgroundColor: "background.paper",
            height: "68px",
            borderRadius: "4px",
          },
          autoFocus: true,
          startAdornment: startAdornment ?? (
            <InputAdornment position="start">
              <Search />
            </InputAdornment>
          ),
          endAdornment: (
            <React.Fragment>{loading ? <CircularProgress color="inherit" size={20} /> : null}</React.Fragment>
          ),
        }}
        helperText={combinedText({
          helperText: helperText,
          error: error?.message,
        })}
      />
    );
  };
}

export function renderSearchField(
  error: FieldError | undefined,
  theme: Theme,
  isOpenActions: {
    setTrue: () => void;
    setFalse: () => void;
  },
  isOpen: boolean,
  omnibar: JSX.Element
) {
  const defaultButton = (
    <Button variant="inverted" onClick={isOpenActions.setTrue}>
      Add Classification
    </Button>
  );

  return (
    <>
      {error?.type === "required" ? <SearchErrorButton theme={theme} onClick={isOpenActions.setTrue} /> : defaultButton}
      <Omnibar open={isOpen} onClose={isOpenActions.setFalse}>
        {omnibar}
      </Omnibar>
    </>
  );
}

export const SearchErrorButton = (props: ErrorButtonProps) => {
  return (
    <Stack
      border="2px solid"
      borderColor={props.theme.palette.divider}
      borderRadius="4px"
      py={2}
      px={3}
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      width={"100%"}
    >
      <Typography fontWeight={700}>Unclassified</Typography>
      <Button variant="inverted" color="secondary" onClick={props.onClick}>
        Add Classification
      </Button>
    </Stack>
  );
};

interface ErrorButtonProps {
  theme: Theme;
  onClick: React.MouseEventHandler<HTMLButtonElement> | undefined;
}
