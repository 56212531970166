import { styled, TableContainer, TableRow } from "@mui/material";

export const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  flex: 1,
  justifyContent: "space-between",
  overflowY: "scroll",
  position: "relative",
  td: {
    lineHeight: "32px",
    verticalAlign: "top",
    padding: "8px 16px",
  },
  th: {
    padding: "10px 16px",
  },

  "td:first-of-type, th:first-of-type": {
    paddingLeft: 0,
  },
  "td:last-of-type, th:last-of-type": {
    paddingRight: 0,
  },
  thead: {
    boxShadow: `0 1px 0 ${theme.palette.divider}`,
    position: "sticky",
    top: 0,
    zIndex: 1,
    th: {
      borderBottom: 0,
    },
  },
}));

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
}));
