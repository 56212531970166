import { Close } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
} from "@mui/material";
import * as React from "react";
import { FormPayloadFn } from "../atoms";
import { Deferred, deferred } from "../deferred";

interface FormDialogProps {
  id?: string;
  title: string;
  message?: string;
  open: boolean;
  onClose: () => void;
  onCancel?: () => void;
  onSubmit: (evt: React.FormEvent) => void;
  cancelText?: string;
  submitText?: string;
  children: React.ReactNode | FormPayloadFn;
  showCloseButton?: boolean;
  formTag?: "form" | "div";
}

const FORM_ID = "custom-form-dialog";

export function FormDialog({
  id = FORM_ID,
  title,
  open,
  message,
  onClose,
  onCancel,
  onSubmit,
  cancelText = "Cancel",
  submitText = "Save",
  children,
  showCloseButton = false,
  formTag = "form",
}: FormDialogProps) {
  const submissionPromise = React.useRef<Deferred<any>>();
  const registerSubmit = <TArgs extends unknown[], TResult>(handler: (...args: TArgs) => TResult) => {
    if (!submissionPromise.current) {
      // Register a deferred promise because DOM even bubbling is sync and react-hook-forms is async
      // There is an edge case where a FormDialog is re-used instead of re-mounted between forms, and the child is a function.
      // However, this is very unlikely and does not currently occur
      submissionPromise.current = deferred<TResult>();
    }
    return (...args: TArgs) => {
      const result = handler(...args);
      if (result instanceof Promise) {
        void result.then(submissionPromise.current?.resolve).catch(submissionPromise.current?.reject);
      }
      return result;
    };
  };

  const child = typeof children === "function" ? children(registerSubmit) : children;

  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const handleSubmit = async (event: React.FormEvent<Element>) => {
    setIsSubmitting(true);
    try {
      await submissionPromise.current?.promise;
      onSubmit(event);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} disableEscapeKeyDown={true}>
      <DialogTitle>
        {title}
        {showCloseButton && (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: "text.secondary",
            }}
          >
            <Close />
          </IconButton>
        )}
      </DialogTitle>
      <DialogContent sx={{ mt: 3 }}>
        {message && <DialogContentText pb={3}>{message}</DialogContentText>}
        {formTag === "div" ? (
          // HTML does not support nested forms
          <div onSubmit={handleSubmit}>{child}</div>
        ) : (
          <form id={id} onSubmit={handleSubmit}>
            {child}
          </form>
        )}
      </DialogContent>
      <DialogActions>
        <Button variant="inverted" onClick={onCancel ?? onClose} disabled={isSubmitting}>
          {cancelText}
        </Button>
        <Box sx={{ flexGrow: 1 }} />
        <LoadingButton type="submit" form={id} loading={isSubmitting}>
          {submitText}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
