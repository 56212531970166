import { Adjust } from "@mui/icons-material";
import { Tooltip } from "@mui/material";
import React from "react";
import { useSelectedPortalAnnotationId, useSelectedRecordingId } from "../../../../components/panels/atoms";
import { usePortalAnnotationsForCurationQuestionInApplicationLazyQuery } from "../../../../generated/graphql";
import { useCurationState } from "../../../curation/state/useCurationState";

interface Props {
  id: string;
}

export const AnnotationMappingDot: React.FC<Props> = ({ id }) => {
  const { id: curationApplicationId } = useCurationState();
  const [, setSelectedRecording] = useSelectedRecordingId();
  const [, setSelectedAnnotation] = useSelectedPortalAnnotationId();
  const [load] = usePortalAnnotationsForCurationQuestionInApplicationLazyQuery();

  const targetIcon = (
    <Adjust
      color="info"
      fontSize="small"
      id={id}
      sx={{ cursor: "pointer" }}
      onClick={(evt) => {
        evt.stopPropagation();
        void load({
          variables: {
            curationQuestionId: id,
            curationApplicationId,
          },
          onCompleted: (data) => {
            const { id: annotationId, primaryRecordingId } =
              data?.portalAnnotationsForCurationQuestionInApplication[0] || {};
            setSelectedRecording(primaryRecordingId ?? undefined);
            setSelectedAnnotation(annotationId);
          },
        });
      }}
    />
  );

  return <Tooltip title="Jump to annotation">{targetIcon}</Tooltip>;
};
