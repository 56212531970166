import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { useAtom } from "jotai";
import { PropsWithChildren } from "react";
import { modalAtom } from "./atoms";
import { FormDialog } from "./components/FormDialog";
import { PromptDialog } from "./components/PromptDialog";

export const ModalProvider = ({ children }: PropsWithChildren<{}>) => {
  const [{ activeModal, promise }, setModal] = useAtom(modalAtom);

  const closeModal = <T extends {}>(value?: T) => {
    promise?.resolve(value);
    setModal({ activeModal: undefined, promise: undefined });
  };

  const renderModal = () => {
    if (!activeModal) {
      return null;
    }

    if (activeModal.type === "alert") {
      return (
        <Dialog open={true} onClose={() => closeModal()} PaperProps={{ sx: { width: "100%", maxWidth: 300 } }}>
          <DialogTitle>{activeModal.payload.title}</DialogTitle>
          <DialogContent>
            <DialogContentText pt={4}>{activeModal.payload.message}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button fullWidth={true} sx={{ mx: 3 }} onClick={() => closeModal()} autoFocus={true}>
              {activeModal.payload.confirmText ?? "Ok"}
            </Button>
          </DialogActions>
        </Dialog>
      );
    }

    if (activeModal.type === "confirm") {
      return (
        <Dialog open={true} onClose={() => closeModal(false)}>
          <DialogTitle>{activeModal.payload.title}</DialogTitle>
          <DialogContent>
            <DialogContentText pt={4}>{activeModal.payload.message}</DialogContentText>
          </DialogContent>
          <DialogActions sx={{ justifyContent: "space-between" }}>
            <Button variant="inverted" onClick={() => closeModal(false)}>
              {activeModal.payload.cancelText ?? "Cancel"}
            </Button>
            <Button onClick={() => closeModal(true)} autoFocus={true}>
              {activeModal.payload.confirmText ?? "Confirm"}
            </Button>
          </DialogActions>
        </Dialog>
      );
    }

    if (activeModal.type === "prompt") {
      return <PromptDialog {...activeModal.payload} onClose={closeModal} />;
    }

    if (activeModal.type === "form") {
      return (
        <FormDialog
          open={true}
          onClose={() => closeModal(false)}
          onCancel={() => closeModal("cancelled")}
          onSubmit={(evt) => closeModal(evt)}
          id={activeModal.payload.id}
          title={activeModal.payload.title}
          message={activeModal.payload.message}
          cancelText={activeModal.payload.cancelText}
          submitText={activeModal.payload.submitText}
          showCloseButton={activeModal.payload.showCloseButton}
          formTag={activeModal.payload.formTag}
        >
          {activeModal.payload.form}
        </FormDialog>
      );
    }

    // Render custom modal
    if (activeModal.type === "custom") {
      // The payload is a function that returns a ReactNode
      // We pass the closeModal function to the payload so that it can close the modal once finished.
      return activeModal.payload<any>(closeModal);
    }

    return null;
  };

  return (
    <>
      {renderModal()}
      {children}
    </>
  );
};
