import { useIsScrolling } from "@cp/react-hooks";
import { Checkbox, colors, Stack, Tooltip } from "@mui/material";
import React, { useEffect } from "react";
import { CreateCurationQuestionFromClobQuestion } from "../../../../components/curation-questions/CreateCurationQuestionFromClobQuestion";
import { CreateCurationQuestionFromClobQuestionDragAndDrop } from "../../../../components/curation-questions/CreateCurationQuestionFromClobQuestionDragAndDrop";
import { UnlinkCurationQuestionButton } from "../../../../components/curation-questions/UnlinkCurationQuestion";
import { ViewScreenshot } from "../../../../components/curation-questions/ViewScreenshot";
import { Visibility } from "../../../../components/Visibility";
import { useClobContext } from "../../../../model/clobs/context";
import { useSelectedClobQuestions, useUpdateQuestions } from "../selection-atom";
import { ClobMappingDot } from "./ClobMappingDot";

interface Props {
  id: string;
  showScreenshotIcon?: boolean;
}

export const ClobActionItems: React.VFC<Props> = ({ id, showScreenshotIcon }) => {
  const {
    state,
    actions: { updateQuestion },
  } = useClobContext();
  const question = state.questions.get(id);
  const isScrolling = useIsScrolling();
  const [clobQuestions, setClobQuestion] = useSelectedClobQuestions();

  const [updatedQuestions, setUpdateQuestions] = useUpdateQuestions();

  useEffect(() => {
    if (updatedQuestions && updatedQuestions.length > 0) {
      const question = updatedQuestions.pop();
      if (question) {
        updateQuestion(question);
      }
    }
  }, [updatedQuestions, updateQuestion, setUpdateQuestions]);

  if (!question) {
    return null;
  }

  const { curationQuestion } = question;

  return (
    <Stack direction="row" flexShrink={0} alignItems="center">
      {showScreenshotIcon && (
        <Visibility>
          <ViewScreenshot fileUrl={question.missingQuestion?.fileUrl} />
        </Visibility>
      )}
      {curationQuestion && (
        <Visibility>
          <UnlinkCurationQuestionButton curationQuestionId={curationQuestion.id} clobQuestionId={id} />
        </Visibility>
      )}
      {!curationQuestion && (
        <Visibility>
          <CreateCurationQuestionFromClobQuestionDragAndDrop clobQuestion={question} />
          <CreateCurationQuestionFromClobQuestion clobQuestion={question} />
        </Visibility>
      )}
      {question.curationQuestion && (
        <ClobMappingDot id={id} lineToId={question.curationQuestion.id} hideLine={isScrolling} />
      )}
      {!question.curationQuestion && (
        <Tooltip title="Unmapped question">
          <Checkbox
            sx={{
              p: "1px",
              color: colors.yellow[800],
              "&.Mui-checked": {
                color: colors.yellow[800],
              },
            }}
            checked={clobQuestions.includes(id)}
            onClick={(evt) => {
              evt.stopPropagation();

              if (clobQuestions.includes(id)) {
                setClobQuestion(clobQuestions.filter((qId) => qId !== id));
              } else {
                setClobQuestion([...clobQuestions, id]);
              }
            }}
          />
        </Tooltip>
      )}
    </Stack>
  );
};
