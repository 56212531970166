/**
 * A mini-framework for defining actions that can be performed on items.
 * These actions can be rendered in different ways, such as a dropdown menu, toolbar, toast, omnibar, etc.
 */

export enum ItemType {
  CURATION_QUESTION = "curation_question",
  CLOB_QUESTION = "clob_question",
}

export interface ActionItem<TYPES extends ItemType> {
  /**
   * What types of items this action accepts and whether it accepts multiple items.
   * Must be true for all types.
   *
   * In future we can add a limit or optional.
   */
  accepts: Record<TYPES, { multi: true }>;
  id: string;
  label: string;
  intent?: "primary" | "secondary" | "error";
  description: string;
  /**
   * Accepts a map of item type to an array of item ids.
   * We can make this more strongly typed in future.
   */
  run: (args: Partial<Record<TYPES, string[]>>) => Promise<{ count: number; actionName: string } | undefined>;
}
