import { usePopover } from "@cp/react-hooks";
import { DriveFileMove } from "@mui/icons-material";
import { IconButton, ListItemText, MenuItem, MenuList, Popover, Tooltip } from "@mui/material";
import React from "react";
import {
  CarrierProjectDocument,
  CarrierProjectsDocument,
  Scalars,
  useCarrierProjectsQuery,
  useMoveCarrierRecordingsToProjectMutation,
} from "../../../generated/graphql";

interface Props {
  recordingIds: Array<Scalars["CarrierRecordingId"]>;
}

/**
 * Button to move a recording to a different carrier project
 */
export const MoveRecordings: React.FC<Props> = ({ recordingIds }) => {
  const { data } = useCarrierProjectsQuery();
  const { popoverProps, targetProps } = usePopover();
  const [move] = useMoveCarrierRecordingsToProjectMutation({
    refetchQueries: [CarrierProjectsDocument, CarrierProjectDocument],
  });

  return (
    <>
      <Popover {...popoverProps}>
        <MenuList dense={true}>
          {data?.carrierProjects.map((project) => (
            <MenuItem
              key={project.id}
              onClick={() => {
                move({ variables: { input: { recordingIds, projectId: project.id } } });
                popoverProps.onClose();
              }}
            >
              <ListItemText primary={project.name} />
            </MenuItem>
          ))}
        </MenuList>
      </Popover>
      <Tooltip title="Move to project">
        <IconButton {...targetProps} size="small">
          <DriveFileMove fontSize="small" />
        </IconButton>
      </Tooltip>
    </>
  );
};
