import { useCallback, useReducer } from "react";

/**
 * Adapted from https://usehooks.com/useHistory/
 */

export type HistoryActions<T> =
  | {
      type: "UNDO";
    }
  | {
      type: "REDO";
    }
  | {
      type: "SET";
      value: T;
    }
  | {
      type: "CLEAR";
      initial: T;
    };

export interface HistoryState<T> {
  past: T[];
  present: T;
  future: T[];
  nonce: number;
}

// Initial state that we pass into useReducer
const initialState = {
  // Array of previous state values updated each time we push a new state
  past: [],
  // Current state value
  present: null,
  // Will contain "future" state values if we undo (so we can redo)
  future: [],
};

const reducer =
  <T>() =>
  (state: HistoryState<T>, action: HistoryActions<T>): HistoryState<T> => {
    const { past, present, future } = state;
    switch (action.type) {
      case "UNDO": {
        const previous = past[past.length - 1];
        const newPast = past.slice(0, -1);
        return {
          past: newPast,
          present: previous,
          future: [present, ...future],
          nonce: state.nonce + 1,
        };
      }
      case "REDO": {
        const next = future[0];
        const newFuture = future.slice(1);
        return {
          past: [...past, present],
          present: next,
          future: newFuture,
          nonce: state.nonce + 1,
        };
      }
      case "SET": {
        const { value } = action;
        if (value === present) {
          return state;
        }
        return {
          past: [...past, present],
          present: value,
          future: [],
          // Don't update nonce if we're just setting the value
          nonce: state.nonce,
        };
      }
      case "CLEAR": {
        const { initial } = action;
        return {
          ...initialState,
          present: initial,
          nonce: 0,
        };
      }
    }
  };

export interface UndoRedoActions<T> {
  undo: () => void;
  redo: () => void;
  clear: (initialState?: T) => void;
  canUndo: boolean;
  canRedo: boolean;
  /**
   * A nonce that changes every time the history changes.
   * Useful for forcing a re-render when the history changes.
   */
  nonce: number;
}

export interface UseHistoryResponse<T> extends UndoRedoActions<T> {
  state: T;
  set: (value: T) => void;
}

export function useHistory<T>(initial: T): UseHistoryResponse<T> {
  const [state, dispatch] = useReducer(reducer<T>(), {
    ...initialState,
    present: initial,
    nonce: 0,
  });

  const canUndo = state.past.length > 0;
  const canRedo = state.future.length > 0;

  const undo = useCallback(() => {
    if (canUndo) {
      dispatch({ type: "UNDO" });
    }
  }, [canUndo, dispatch]);
  const redo = useCallback(() => {
    if (canRedo) {
      dispatch({ type: "REDO" });
    }
  }, [canRedo, dispatch]);

  const set = useCallback((value: T) => dispatch({ type: "SET", value }), [dispatch]);
  const clear = useCallback((value?: T) => dispatch({ type: "CLEAR", initial: value ?? initial }), [dispatch, initial]);

  return { state: state.present, set, undo, redo, clear, canUndo, canRedo, nonce: state.nonce };
}
