import { Arrays } from "@cp/toolkit";
import { Add } from "@mui/icons-material";
import { colors, Divider, Stack } from "@mui/material";
import React, { useState } from "react";
import { Page } from "../../components/column/Page";
import { useSelectedCurationApplicationId } from "../../components/panels/atoms";
import { useCurationApplicationByIdQuery, useCurationQuestionsForApplicationQuery } from "../../generated/graphql";
import { FilterablePreviewApplication } from "./FilterablePreviewApplication";
import { PreviewApplication } from "./PreviewApplication";

export const PreviewPage: React.FC = () => {
  const [selectedCurationApp] = useSelectedCurationApplicationId();
  const { data: curationApplicationData, loading } = useCurationApplicationByIdQuery({
    variables: { id: selectedCurationApp },
  });
  const { data: questionsData } = useCurationQuestionsForApplicationQuery({
    variables: { id: selectedCurationApp },
  });
  const curationApplication = curationApplicationData?.curationApplicationById;
  const questions = questionsData?.curationApplicationById.questions ?? Arrays.empty();
  const [numPreviewApplications, setNumPreviewApplications] = useState(1);

  if (!curationApplication) {
    return null;
  }

  return (
    <Page>
      <Stack direction="row" overflow="hidden" flex={1}>
        <Stack flex={1} sx={{ overflowY: "hidden" }}>
          <Divider sx={{ mt: "48px" }} />
          <PreviewApplication {...curationApplication} hideRequiredCount={true} questions={questions} />
        </Stack>
        {Array.from({ length: numPreviewApplications }).map((_, i) => (
          <FilterablePreviewApplication key={i} curationApplication={curationApplication} />
        ))}
        <Stack
          width="30px"
          alignItems={"center"}
          justifyContent={"center"}
          boxShadow={2}
          sx={{
            backgroundColor: colors.grey[100],
            cursor: "pointer",
            ":hover": { backgroundColor: colors.grey[200] },
          }}
          onClick={() => setNumPreviewApplications((prev) => prev + 1)}
        >
          <Add fontSize="small" />
        </Stack>
      </Stack>
    </Page>
  );
};
