import { Boundary } from "@cp/async";
import { Stack } from "@mui/material";
import React from "react";
import { TopBar } from "../TopBar";
import { ResizingSidebar } from "./ResizingSidebar";

interface Props {
  flex: number;
  resize?: {
    side: "left" | "right";
    initialWidth: number;
  };
  title?: React.ReactNode;
  rightToolbar?: React.ReactNode;
  header?: React.ReactNode;
  children: React.ReactNode;
}

export const PageColumn: React.FC<Props> = ({ flex, title, header, rightToolbar, children, resize }) => {
  const content = (
    <Stack flexShrink={0} flex={flex} height="100%" width="100%">
      {(title || rightToolbar) && <TopBar leftToolbar={title} rightToolbar={rightToolbar} />}
      <Stack overflow="hidden" flex={1}>
        <Boundary>
          {header}
          <Stack overflow="auto" p={3} flex={1}>
            {children}
          </Stack>
        </Boundary>
      </Stack>
    </Stack>
  );

  if (resize) {
    return <ResizingSidebar {...resize}>{content}</ResizingSidebar>;
  }

  return content;
};
