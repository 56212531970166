import { FormProvider, UseFormReturn } from "react-hook-form";
import { AnyForm } from "../types";
import { OptionsContext, OptionSets } from "./OptionsContext";
import { renderForm } from "./renderForm";

interface Props<T> {
  name: string;
  form: AnyForm<T>;
  optionSets?: OptionSets;
  methods: UseFormReturn<any, any>;
}

/**
 * Used when nested inside another FormProvider.
 */
export function FormyNestedForm<T>({ form, name, methods, optionSets }: Props<T>) {
  return (
    <OptionsContext.Provider value={optionSets}>
      <FormProvider {...methods}>{renderForm(form, name)}</FormProvider>
      {/* <DevTool control={methods.control} /> */}
    </OptionsContext.Provider>
  );
}
