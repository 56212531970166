import { KeyboardEvent, useState } from "react";
import { useWatch } from "react-hook-form";
import { FormFieldNumberFormat, FormFieldNumberFormatProps } from "./FormFieldNumberFormat";

export const FormFieldZip: React.VFC<FormFieldNumberFormatProps> = (props) => {
  const [isNineDigit, setIsNineDigit] = useState(false);

  const maskedZip = useWatch({ name: props.name });
  const enteredZip = `${maskedZip}`.replaceAll(/[_-]/g, "");

  const onKeyDown = (e: KeyboardEvent<any>) => {
    if (/[\d-]/.test(e.key) && enteredZip.length === 5) {
      setIsNineDigit(true);
    } else if (enteredZip.length <= 5) {
      setIsNineDigit(false);
    }
  };

  const onKeyUp = (e: KeyboardEvent<any>) => {
    if (e.code === "Backspace" && enteredZip.length === 5) {
      setIsNineDigit(false);
    }
  };

  return (
    <FormFieldNumberFormat
      onKeyUp={onKeyUp}
      onKeyDown={onKeyDown}
      format={isNineDigit ? "#####-####" : "#####"}
      extractValueAs="formatted-string"
      mask="_"
      {...props}
    />
  );
};
