import { useBoolean } from "@cp/react-hooks";
import { Edit, UnfoldLess, UnfoldMore } from "@mui/icons-material";
import { IconButton, Stack } from "@mui/material";
import React from "react";
import { Visibility } from "../../../../components/Visibility";
import { CurationQuestionFragment } from "../../../../generated/graphql";
import EditCurationQuestionDialog from "../EditCurationQuestionDialog";

interface Props {
  question: CurationQuestionFragment;
  isMinimized: boolean;
  toggleMinimize: () => void;
}

export const CurationActionItems: React.VFC<Props> = ({ question, isMinimized, toggleMinimize }) => {
  const [isOpen, { toggle }] = useBoolean(false);

  return (
    <Stack direction="row" flexShrink={0} alignItems="center">
      <Visibility>
        <IconButton
          size="small"
          onClick={(evt) => {
            evt.stopPropagation();
            toggle();
          }}
        >
          <Edit fontSize="small" />
        </IconButton>
        <IconButton
          size="small"
          onClick={(evt) => {
            evt.stopPropagation();
            toggleMinimize();
          }}
        >
          {isMinimized ? <UnfoldMore fontSize="small" /> : <UnfoldLess fontSize="small" />}
        </IconButton>
      </Visibility>
      {isOpen && <EditCurationQuestionDialog open={isOpen} handleClose={toggle} curationQuestion={question} />}
    </Stack>
  );
};
