import { TimeAgo } from "@cp/theme";
import { Edit, Key, RemoveCircle } from "@mui/icons-material";
import { Box, BoxProps, Chip, colors, Divider, IconButton, Stack, Tooltip, Typography } from "@mui/material";
import { omit } from "lodash";
import React from "react";
import { ArchiveCurationQuestionToggle } from "../../../components/ArchiveCurationQuestionToggle";
import { CopyClipboard } from "../../../components/CopyClipboard";
import { formatConditions } from "../../../components/curation-questions/conditionalUtil";
import { useSetSelectedCurationNodeId } from "../../../components/panels/atoms";
import { PropertyList, PropertyPairs } from "../../../components/properties/PropertyList";
import { QuestionPaste } from "../../../components/QuestionPaste";
import { Visibility } from "../../../components/Visibility";
import { CurationNodeType, CurationQuestionFragment } from "../../../generated/graphql";
import { CurationQuestionMappingIndicator } from "../../clobs/MapClob/components/CurationQuestionMappingIndicator";
import { GoldenQuestionSwappingIcon } from "../../clobs/MapClob/components/GoldenQuestionSwappingIcon";
import { useSelectedCurationQuestions } from "../../clobs/MapClob/selection-atom";
import { ApplicationSection } from "../ApplicationLayout/ApplicationSection";
import { CurationQuestionDropArea } from "../ApplicationLayout/CurationQuestionDropArea";
import { useExpandEntity } from "../atoms";
import { DragableHeader } from "../Dragables/DragableHeader";
import { GoldenQuestionIcon } from "../icons/GoldenQuestionIcon";
import { useCurationActions } from "../state/useCurationActions";
import { useCurationFeature } from "../state/useCurationFeature";
import { useCurationState } from "../state/useCurationState";
import { ConditionalQuestionToggle } from "../Toolbar/ConditionalQuestionToggle";
import { ConditionsWidget } from "../widgets/ConditionsWidget";

export type QuestionItemProps = {
  curationQuestion: CurationQuestionFragment;
  canToggleConditionalQuestion: boolean;
  canRemove: boolean;
  parentId: string | undefined;
  depth: number;
  dragRef: React.Ref<HTMLButtonElement>;
  disableSelection?: boolean;
} & BoxProps;

export const QuestionItem = React.memo(
  React.forwardRef<HTMLDivElement, QuestionItemProps>(function QuestionKeyComponent(props, ref) {
    const {
      curationQuestion,
      parentId,
      canToggleConditionalQuestion,
      depth,
      canRemove,
      dragRef,
      disableSelection,
      ...boxProps
    } = props;
    const { id, text, key, archived } = curationQuestion;
    const showCurationQuestionIndicator = useCurationFeature("showCurationQuestionMappingIndicator");
    const showGoldenQuestionSwappingIcon = useCurationFeature("showGoldenQuestionSwappingIcon");
    const { curationNodeMap, getQuestion } = useCurationState();
    const actions = useCurationActions();
    const maybeConditionalQuestion = curationNodeMap.get(id);
    const [isExpanded, toggleExpanded, searchQuery] = useExpandEntity(id);
    const [selectedCurationQuestions] = useSelectedCurationQuestions();

    const isConditionalQuestion = maybeConditionalQuestion?.type === CurationNodeType.CoverageQuestion;
    const isSelfSelected = selectedCurationQuestions.includes(id);
    const setSelectedCurationNodeId = useSetSelectedCurationNodeId();

    // HACK: This is a hack show the mapping of clob question to curation question
    const isCurationPage = window.location.pathname.includes("/curations");

    const properties: PropertyPairs = [
      ["Question", curationQuestion.text],
      ["Helper Text", curationQuestion.helperText],
      ["Key", curationQuestion.key],
      ["Component Type", curationQuestion.componentType],
      ["Default Value", curationQuestion.defaultValue],
      ["Options", curationQuestion.options.join(", ")],
      ["Notes", curationQuestion.notes],
      ["Conditions", formatConditions(curationQuestion.conditions, getQuestion)],
      ["Created", curationQuestion.createdAt ? <TimeAgo date={curationQuestion.createdAt} /> : undefined],
      ["Last Edited", curationQuestion.updatedAt ? <TimeAgo date={curationQuestion.updatedAt} /> : undefined],
      ["ID", curationQuestion.id],
    ];

    return (
      <Box id={id} ref={ref} {...omit(boxProps, ["isDragging"])}>
        <Stack>
          <DragableHeader
            dragRef={dragRef}
            collapsed={!isExpanded}
            borderColor={
              curationQuestion.golden
                ? colors.orange[200]
                : curationQuestion.archived
                ? colors.amber[900]
                : colors.teal[500]
            }
            backgroundColor={curationQuestion.golden ? colors.orange[50] : undefined}
            onCollapse={toggleExpanded}
            actions={
              <>
                <Visibility>
                  <QuestionPaste parentId={parentId} nodeId={id} />
                  {canToggleConditionalQuestion && <ConditionalQuestionToggle questionId={id} />}
                  <Tooltip title="Edit curation question">
                    <IconButton
                      size="small"
                      onClick={(evt) => {
                        evt.stopPropagation();
                        setSelectedCurationNodeId(id);
                      }}
                    >
                      <Edit fontSize="small" />
                    </IconButton>
                  </Tooltip>
                  <ArchiveCurationQuestionToggle id={id} archived={archived} />
                  {canRemove && parentId && (
                    <Tooltip
                      title={
                        isConditionalQuestion ? "Must remove as coverage section first" : "Remove curation question"
                      }
                    >
                      <span>
                        <IconButton
                          size="small"
                          disabled={isConditionalQuestion}
                          onClick={(evt) => {
                            evt.stopPropagation();
                            if (
                              window.confirm(
                                "Are you sure you want to remove this curation question? It will go back to the left-hand side of the application."
                              )
                            ) {
                              actions.removeNode({ nodeId: id, parentId });
                            }
                          }}
                        >
                          <RemoveCircle fontSize="small" />
                        </IconButton>
                      </span>
                    </Tooltip>
                  )}
                </Visibility>
                {isConditionalQuestion && (
                  <Chip
                    sx={{ borderRadius: 1, backgroundColor: colors.teal[100] }}
                    size="small"
                    label="Conditional Q"
                  />
                )}
                <ConditionsWidget conditions={curationQuestion.conditions} />
                {curationQuestion.golden && <GoldenQuestionIcon />}
              </>
            }
          >
            {!isCurationPage &&
              (showCurationQuestionIndicator ? (
                <CurationQuestionMappingIndicator id={id} disableSelection={disableSelection && !isSelfSelected} />
              ) : showGoldenQuestionSwappingIcon ? (
                <GoldenQuestionSwappingIcon id={id} />
              ) : (
                <></>
              ))}
            <Tooltip title="Copy ID">
              <CopyClipboard value={curationQuestion.id} kind="ID" icon={Key} />
            </Tooltip>
            <Typography
              color="text.primary"
              fontWeight={500}
              display="flex"
              fontSize={"14px"}
              minWidth={"200px"}
              alignItems="center"
              flex={1}
              sx={{ wordBreak: "break-word" }}
            >
              {text || key}
            </Typography>
            <CurationQuestionDropArea curationQuestionId={id} />
          </DragableHeader>
          {isExpanded && (
            <Stack
              gap={2}
              p={2}
              mt="-2px"
              ml={2}
              mr={1}
              sx={{ boxShadow: 1, backgroundColor: "white", borderBottomLeftRadius: 2, borderBottomRightRadius: 2 }}
            >
              <PropertyList searchQuery={searchQuery} pairs={properties} />
              {maybeConditionalQuestion?.children.map((subsectionId) => (
                <React.Fragment key={subsectionId}>
                  <Divider />
                  <ApplicationSection
                    parentId={id}
                    depth={depth}
                    hideDelete={true}
                    autoExpand={true}
                    id={subsectionId}
                    key={subsectionId}
                    disableSelection={isSelfSelected}
                  />
                </React.Fragment>
              ))}
            </Stack>
          )}
        </Stack>
      </Box>
    );
  })
);

QuestionItem.displayName = "QuestionItem";
