import { assertNever } from "@cp/toolkit";
import { Stack } from "@mui/material";
import { startCase } from "lodash";
import { FormFieldCheckbox } from "../../form-fields/FormFieldCheckbox";
import { FormFieldNumberFormat } from "../../form-fields/FormFieldNumberFormat";
import { FormFieldSelect } from "../../form-fields/FormFieldSelect";
import { FormFieldText } from "../../form-fields/FormFieldText";
import { FormFieldTextArea } from "../../form-fields/FormFieldTextArea";
import { getInternalOptions } from "../builders/getOptionsInternal";
import {
  AnyForm,
  ArrayFormOpts,
  BooleanFormOpts,
  CustomFormOpts,
  EnumFormOpts,
  NumberFormOpts,
  ObjectFormOpts,
  OptionalOfFormOpts,
  SelectFormOpts,
  StringFormOpts,
  TypeUnionFormOpts,
} from "../types";
import { ArrayForm } from "./ArrayForm";
import { CustomForm } from "./CustomForm";
import { join } from "./join";
import { OneOfForm } from "./OneOfForm";
import { OptionalOf } from "./OptionalOf";
import { SelectForm } from "./SelectForm";

export function renderForm<T>(form: AnyForm<T>, name: string) {
  switch (form.type) {
    case "string": {
      const options = getInternalOptions<StringFormOpts<string>>(form);
      if (options.textArea) {
        return (
          <FormFieldTextArea
            name={name}
            label={options.label ?? ""}
            updateOnBlur={options.updateOnBlur}
            placeholder={options.placeholder}
            helperText={options.helperText}
            required={options.isRequired}
            InputProps={{
              readOnly: options.disabled ?? false,
            }}
          />
        );
      }

      return (
        <FormFieldText
          label={options.label ?? ""}
          type={options.type}
          name={name}
          required={options.isRequired}
          helperText={options.helperText}
          placeholder={options.placeholder}
          updateOnBlur={options.updateOnBlur}
          InputProps={{
            readOnly: options.disabled ?? false,
          }}
        />
      );
    }
    case "number": {
      const options = getInternalOptions<NumberFormOpts<number>>(form);
      return (
        <FormFieldNumberFormat
          label={options.label ?? ""}
          name={name}
          required={options.isRequired}
          helperText={options.helperText}
          placeholder={options.placeholder}
          min={options.min}
          max={options.max}
        />
      );
    }
    case "object": {
      const options = getInternalOptions<ObjectFormOpts<any>>(form);
      const shouldFlexRows = !!options.rowFlex;
      const totalFlex = options.rowFlex?.reduce((acc, flex) => acc + flex, 0);
      return (
        <Stack
          gap={2}
          direction={shouldFlexRows ? "row" : "column"}
          alignItems={shouldFlexRows ? "flex-end" : undefined}
          width={shouldFlexRows ? "100%" : undefined}
        >
          {Object.entries(options.fields).map(([fieldName, field], index) => {
            const fieldForm = renderForm(field, join(name, fieldName));
            const rowFlex = options.rowFlex?.[index];
            return (
              <Stack
                sx={{ maxWidth: totalFlex && rowFlex ? `${(rowFlex / totalFlex) * 100}%` : undefined }}
                flex={rowFlex}
                flexShrink={0}
                key={fieldName}
              >
                {fieldForm}
              </Stack>
            );
          })}
        </Stack>
      );
    }
    case "oneOf": {
      const options = getInternalOptions<TypeUnionFormOpts<any>>(form);
      return <OneOfForm name={name} fields={options.fields} titles={options.titles} label={options.label} />;
    }
    case "boolean": {
      const options = getInternalOptions<BooleanFormOpts<any>>(form);
      return <FormFieldCheckbox name={name} label={options.label ?? ""} />;
    }
    case "select": {
      const options = getInternalOptions<SelectFormOpts<any>>(form);
      return <SelectForm name={name} label={options.label ?? ""} required={options.isRequired} {...options} />;
    }
    case "enum": {
      const options = getInternalOptions<EnumFormOpts<any>>(form);
      return (
        <FormFieldSelect
          name={name}
          sx={{ minWidth: 150 }}
          label={options.label ?? ""}
          required={options.isRequired}
          placeholder={options.placeholder}
          helperText={options.helperText}
          options={options.enums.map((option) => ({ label: startCase(option), value: option }))}
        />
      );
    }
    case "array": {
      const options = getInternalOptions<ArrayFormOpts<any>>(form);
      return <ArrayForm name={name} form={options.field} label={options.label} itemName={options.itemName} />;
    }
    case "optionalOf": {
      const options = getInternalOptions<OptionalOfFormOpts<any>>(form);
      return <OptionalOf name={name} form={options.field} label={options.label} helperText={options.helperText} />;
    }
    case "custom": {
      const options = getInternalOptions<CustomFormOpts<any>>(form);
      return <CustomForm name={name} render={options.render} />;
    }
    default:
      return assertNever(form.type);
  }
}
