import * as React from "react";
import { FormFieldNumberFormat, FormFieldNumberFormatProps } from "./FormFieldNumberFormat";

export const FormFieldCurrency: React.VFC<FormFieldNumberFormatProps> = (props) => {
  return (
    <FormFieldNumberFormat
      prefix="$"
      thousandsGroupStyle="thousand"
      thousandSeparator={true}
      decimalScale={2}
      decimalSeparator="."
      fixedDecimalScale={true}
      {...props}
    />
  );
};
