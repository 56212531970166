import { useEffect } from "react";
import { useFormContext } from "react-hook-form";

export function useSetDefaultTableValues(
  defaultValues: string | undefined,
  columnIds: string[],
  onSave?: (key: string, value: unknown) => void
) {
  const { setValue, getValues } = useFormContext();

  useEffect(() => {
    const rows = parseDefaultValues(defaultValues);
    if (rows.length === 0) {
      return;
    }

    // Iterate over the rows
    for (const [i, row] of rows.entries()) {
      // Iterate over each column in the row
      for (const [j, cell] of row.entries()) {
        // Get the default value for the cell
        // Get the field id for the column
        const fieldId = columnIds[j];

        // Empty cells are ignored
        if (cell == null || cell === "") {
          continue;
        }
        // Missing columns are ignored
        if (!fieldId) {
          console.warn(`No field id found for column ${j}`);
          continue;
        }
        const currentValue = getValues(`${fieldId}.${i}`);
        // If the cell is already set, don't overwrite it
        if (currentValue != null && currentValue !== "") {
          continue;
        }
        // Set the value
        onSave?.(`${fieldId}.${i}`, cell);
        setValue(`${fieldId}.${i}`, cell);
      }
    }
  }, [defaultValues]); // only run when defaultValues changes
}

/**
 * Parses the default values string into an array of arrays.
 * e.g.
 * a,b,c
 * d,e,f
 * => [["a", "b", "c"], ["d", "e", "f"]]
 */
export function parseDefaultValues(defaultValues: string | undefined): string[][] {
  if (!defaultValues) {
    return [];
  }

  return defaultValues.split("\n").map((row) =>
    row
      .trim()
      .split(",")
      .map((cell) => cell.trim())
  );
}
