import slugify from "slugify";
import { CreateCurationQuestionInput, CurationQuestionFragment } from "../../generated/graphql";
import { conditionToInput } from "../conditions/conditionToInput";

// Handle both create and update with similar log
export interface UpsertCurationQuestionInput extends CreateCurationQuestionInput {
  id?: string;
}

export function curationQuestionFragmentToInput(
  question: Partial<CurationQuestionFragment> = {}
): UpsertCurationQuestionInput {
  // We don't spread here since we will otherwise get unwanted fields
  return {
    id: question.id,
    key: question.key ? question.key : question.text ? slugify(question.text) : "",
    text: question.text ?? "",
    componentType: question.componentType,
    defaultValue: question.defaultValue,
    helperText: question.helperText,
    infoText: question.infoText,
    notes: question.notes,
    options: question.options ?? [],
    naicsPrefixes: question.naicsPrefixes ?? [],
    conditions: question.conditions?.map(conditionToInput) ?? [],
    golden: question.golden,
  };
}
