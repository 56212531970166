import { useFormContext } from "../context/FormContextProvider";
import { useFormRenderers } from "../context/FormRendererProvider";
import { FormRenderer } from "./FormRenderer";

/**
 * Render a form starting at a specific sectionId.
 */
export function FormRendererStartingAt(props: { id: string; readonly?: boolean }) {
  const treeItem = useFormContext().treeItemMap.get(props.id);
  const renderers = useFormRenderers();

  if (treeItem) {
    return <FormRenderer rootIds={treeItem.children} renderers={renderers} readonly={props.readonly} />;
  }
  return null;
}
