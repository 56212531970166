import { Maps } from "@cp/toolkit";
import { createContext, FC, PropsWithChildren, useContext, useMemo } from "react";
import { EntityType, FormDataItemFragment, FormEntityFragment } from "../generated/operations";

export interface FormDataContextValue {
  dataMap: ReadonlyMap<string, string | null | undefined | string[]>;
  entityMap: ReadonlyMap<string, FormEntityFragment>;
  entityTypeMap: ReadonlyMap<EntityType, FormEntityFragment[]>;
}

export const FormDataContext = createContext<FormDataContextValue>({
  dataMap: Maps.EMPTY,
  entityMap: Maps.EMPTY,
  entityTypeMap: Maps.EMPTY,
});

interface FormDataContextProps {
  dataItems: FormDataItemFragment[];
  formEntities?: FormEntityFragment[];
}

/**
 * React-context provider for the form data. This contains the form data and the form entities.
 */
export const FormDataContextProvider: FC<PropsWithChildren<FormDataContextProps>> = ({
  children,
  dataItems,
  formEntities,
}) => {
  const value = useMemo(() => {
    const dataMap = new Map(Object.entries(deserializeFormData(dataItems)));
    return formEntities
      ? {
          dataMap,
          entityMap: Maps.keyBy(formEntities, (e) => e.id),
          entityTypeMap: Maps.multiMap(
            formEntities,
            (e) => e.type,
            (e) => e
          ),
        }
      : {
          dataMap,
          entityMap: Maps.EMPTY,
          entityTypeMap: Maps.EMPTY,
        };
  }, [formEntities, dataItems]);

  return <FormDataContext.Provider value={value}>{children}</FormDataContext.Provider>;
};

export function useFormDataContext() {
  return useContext(FormDataContext);
}

/**
 * Hook to get the value of a form data item
 */
export function useFormValue(key: string) {
  const { dataMap } = useFormDataContext();
  const value = dataMap.get(key);
  if (Array.isArray(value)) {
    console.warn("Expected a single value, but got an array", value);
    if (value.length > 0) {
      return value[0];
    }
    return null;
  }
  return value;
}

/**
 * Hook to get the value of a form data item
 */
export function useFormValues(keys: string[]) {
  const { dataMap } = useFormDataContext();
  return Maps.collectBy(
    keys,
    (id) => id,
    (id) => dataMap.get(id)
  );
}

/**
 * Hook to get the value of an entity
 */
export function useFormEntity(id: string) {
  const { entityMap } = useFormDataContext();
  return entityMap.get(id);
}

/**
 * Hook to get all entity of a type
 */
export function useFormEntities(typeId: EntityType) {
  const { entityTypeMap } = useFormDataContext();
  return entityTypeMap.get(typeId);
}

function deserializeFormData(data: FormDataItemFragment[]) {
  // Could have many rows for the same question, so we need to group them
  const dataById = Maps.multiMap(data, (d) => d.key);
  const deserializedData: Record<string, string | null | undefined | string[]> = {};

  for (const [fieldId, dataItems] of dataById) {
    if (dataItems.length === 1 && dataItems[0].row == null) {
      // If there is only one value, and it's not a row, then we can just set it
      deserializedData[fieldId] = dataItems[0].value;
      continue;
    }

    // Populate multiple values
    for (const item of dataItems) {
      deserializedData[fieldId] = deserializedData[fieldId] ?? [];
      if (item.value != null && item.row != null) {
        (deserializedData[fieldId] as any)[item.row] = item.value;
      } else if (item.value !== null) {
        (deserializedData[fieldId] as any[]).push(item.value);
      }
    }

    // NB: we should consider deduping here, but for now won't because we might actually want
    // to preserve the values across rows
  }

  return deserializedData;
}
