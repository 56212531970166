import { Maps } from "@cp/toolkit";
import { UniqueIdentifier } from "@dnd-kit/core";
import { ClobQuestionFragment, ClobSectionFragment } from "../../generated/graphql";
import { ClobTreeItem } from "./types";

type TraversableClobQuestion = Pick<ClobQuestionFragment, "id" | "text" | "clobSectionId" | "order">;
type TraversableClobSection = Pick<ClobSectionFragment, "id" | "name" | "parentId" | "order">;

export function clobToTreeItems(
  clob: {
    questions: TraversableClobQuestion[];
    sections: TraversableClobSection[];
  },
  itemsCollapseState?: Map<UniqueIdentifier, boolean>
): ClobTreeItem[] {
  const { sections, questions } = clob;

  // Sort sections by order
  const sortedSections = [...sections].sort((a, b) => a.order - b.order);
  // Sort questions by order
  const sortedQuestions = [...questions].sort((a, b) => a.order - b.order);

  // Create map of sections by parent ID
  const sectionsByParentId = Maps.multiMap(
    sortedSections,
    (s) => s.parentId ?? "root",
    (s) => s
  );

  // Create map of questions by section ID
  const questionsBySectionId = Maps.multiMap(
    sortedQuestions,
    (q) => q.clobSectionId,
    (q) => q
  );

  // Create ClobTreeItem
  const createSectionTreeItem = (section: TraversableClobSection): ClobTreeItem => {
    const { id, name } = section;
    const childrenSection = sectionsByParentId.get(id) ?? [];
    const childrenQuestion = questionsBySectionId.get(id) ?? [];

    return {
      type: "section",
      id,
      collapsed: itemsCollapseState?.get(id),
      title: name,
      canHaveChildren: true,
      children: [...childrenQuestion.map(createQuestionTreeItem), ...childrenSection.map(createSectionTreeItem)],
    };
  };

  const createQuestionTreeItem = (question: TraversableClobQuestion): ClobTreeItem => {
    const { id, text } = question;
    return {
      type: "question",
      id,
      title: text,
      canHaveChildren: false,
    };
  };

  // Traverse starting by root sections
  const rootSections = sortedSections.filter((s) => s.parentId == null);
  return rootSections.map(createSectionTreeItem);
}
