import { Box, Typography } from "@mui/material";

interface Props {
  emptyText: string;
  emptyAction?: React.ReactNode;
}

export function EmptyTable({ emptyText, emptyAction }: Props) {
  return (
    <Box
      sx={{
        alignItems: "center",
        backgroundColor: "grey.50",
        borderRadius: "6px",
        boxShadow: "inset 0 0 0 1px rgba(63, 81, 181, 0.1)",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        padding: "64px",
      }}
    >
      <Typography variant="h2">{emptyText}</Typography>
      {emptyAction}
    </Box>
  );
}
