import { MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { useMatch, useNavigate } from "@tanstack/react-location";
import React from "react";
import { LocationGenerics } from "../App";
import { useClobsQuery } from "../generated/graphql";

export const ClobSelect: React.VFC = () => {
  const {
    params: { clobId },
  } = useMatch<LocationGenerics>();
  const navigate = useNavigate();
  const [selectedClob, setSelectedClob] = React.useState(clobId);
  const { data } = useClobsQuery();

  const handleClobChange = (event: SelectChangeEvent<string>) => {
    setSelectedClob(event.target.value);
    navigate({ to: `/clobs/map/${event.target.value}` });
  };

  return (
    <Select onChange={handleClobChange} value={selectedClob} variant="outlined">
      {data?.clobs.map((clob) => (
        <MenuItem key={clob.id} value={clob.id}>
          {clob.name}
        </MenuItem>
      ))}
    </Select>
  );
};
