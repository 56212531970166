import {
  BulkArchiveCurationQuestionsDocument,
  BulkArchiveCurationQuestionsMutation,
  BulkArchiveCurationQuestionsMutationVariables,
  BulkUnarchiveCurationQuestionsDocument,
  BulkUnarchiveCurationQuestionsMutation,
  BulkUnarchiveCurationQuestionsMutationVariables,
} from "../generated/graphql";
import { client } from "../graphql/client";
import { ActionItem, ItemType } from "./types";

export const ArchiveAction: ActionItem<ItemType.CURATION_QUESTION> = {
  accepts: {
    [ItemType.CURATION_QUESTION]: { multi: true },
  },
  id: "archive",
  label: "Archive",
  intent: "error",
  description: "Archive the selected curation questions.",
  run: async (args) => {
    const ids = args[ItemType.CURATION_QUESTION];
    if (!ids) {
      throw new Error("No curation questions provided.");
    }
    const confirm = window.confirm(
      `Are you sure you want to archive ${ids.length} curation question(s)? This will delete all connected PDF questions and un-map any CLOB questions.`
    );
    if (!confirm) {
      return undefined;
    }
    await client.mutate<BulkArchiveCurationQuestionsMutation, BulkArchiveCurationQuestionsMutationVariables>({
      mutation: BulkArchiveCurationQuestionsDocument,
      variables: { ids },
    });
    return { count: ids.length, actionName: "Archive" };
  },
};

export const UnarchiveAction: ActionItem<ItemType.CURATION_QUESTION> = {
  accepts: {
    [ItemType.CURATION_QUESTION]: { multi: true },
  },
  id: "unarchive",
  label: "Unarchive",
  description: "Unarchive the selected curation questions.",
  run: async (args) => {
    const ids = args[ItemType.CURATION_QUESTION];
    if (!ids) {
      throw new Error("No curation questions provided.");
    }
    await client.mutate<BulkUnarchiveCurationQuestionsMutation, BulkUnarchiveCurationQuestionsMutationVariables>({
      mutation: BulkUnarchiveCurationQuestionsDocument,
      variables: { ids },
    });
    return { count: ids.length, actionName: "Unarchived" };
  },
};
