import { useExpandCollapse } from "@cp/react-hooks";
import { UnfoldLess, UnfoldMore } from "@mui/icons-material";
import { Box, Button, ButtonGroup, Chip, IconButton, Stack, Typography } from "@mui/material";
import { startCase } from "lodash";
import React from "react";
import {
  CurationApplicationFragment,
  CurationNodeType,
  CurationQuestionFragment,
  CurationSectionFragment,
} from "../../generated/graphql";
import { COMPONENT_TYPE_ICONS } from "../clobs/MapClob/COMPONENT_TYPE_ICONS";
import { CurationNode } from "../curation/state/types";
import { CurationApplicationRenderer } from "./CurationApplicationRenderer";
import { useRequiredCountMap } from "./useRequiredCountMap";

interface Props extends CurationApplicationFragment {
  hideRequiredCount?: boolean;
}

export const PreviewApplication: React.VFC<Props> = React.memo(({ hideRequiredCount = false, ...rest }) => {
  const { expandAll, collapseAll, isExpanded, toggle } = useExpandCollapse();
  const requiredCountById = useRequiredCountMap(rest);

  const renderElement = (opts: {
    nodeId: string;
    depth: number;
    question: (CurationQuestionFragment & { isRequired?: boolean }) | undefined;
    section: CurationSectionFragment | undefined;
    curationNode: CurationNode | undefined;
    renderChild: (id: string) => React.ReactNode | undefined;
  }) => {
    const { nodeId, depth, question, section, curationNode, renderChild } = opts;

    if (question) {
      return (
        <Stack key={nodeId} spacing={2}>
          <Stack direction="row" spacing={2}>
            <Box sx={{ width: "100px" }}>
              <Chip
                size="small"
                icon={question.componentType ? COMPONENT_TYPE_ICONS[question.componentType] : undefined}
                color="info"
                label={startCase(question.componentType ?? "")}
                sx={{ borderRadius: "3px", opacity: 0.7 }}
              />
            </Box>
            <Typography key={question.id} pl={2}>
              {question.text} {question.isRequired && <span style={{ color: "red" }}>*</span>}
            </Typography>
          </Stack>
          {curationNode?.type === CurationNodeType.CoverageQuestion && (
            <Stack mt={2} pl={2}>
              {curationNode.children.map(renderChild)}
            </Stack>
          )}
        </Stack>
      );
    }

    const children = curationNode?.children?.map(renderChild).filter(Boolean);
    const title = section?.title;

    if (children && children.length > 0 && curationNode) {
      return (
        <Stack
          px={2}
          py={2}
          spacing={2}
          key={nodeId}
          sx={{
            backgroundColor: depth === 0 ? "grey.50" : depth === 1 ? "grey.100" : depth < 4 ? "grey.200" : "grey.300",
            borderRadius: "3px",
            boxShadow: "0px 0px 1px 1px rgba(0,0,0,0.1)",
          }}
        >
          {title && (
            <Stack direction="row" spacing={2} justifyContent="space-between">
              <Typography key={nodeId} pl={2} pt={1}>
                <Typography variant="h3" fontWeight={600} component="span" color="text.disabled" pr={2}>
                  H{depth + 1}
                </Typography>
                <Typography variant="h3" fontWeight={600} component="span" color="text.secondary">
                  {title}
                </Typography>
              </Typography>
              <Stack direction="row" alignItems="center" spacing={1}>
                {!hideRequiredCount && (
                  <Typography variant="caption" color="text.secondary">
                    REQ: {requiredCountById.get(curationNode.id) ?? 0}
                  </Typography>
                )}
                <IconButton size="small" onClick={() => toggle(nodeId)}>
                  {isExpanded(nodeId) ? <UnfoldMore fontSize="small" /> : <UnfoldLess fontSize="small" />}
                </IconButton>
              </Stack>
            </Stack>
          )}
          <Stack pl={2} spacing={2} display={isExpanded(nodeId) ? "block" : "none"}>
            {children}
          </Stack>
        </Stack>
      );
    }

    return null;
  };

  return (
    <Stack flex={1} sx={{ overflowY: "auto" }} p={2} spacing={3}>
      <Stack direction="row" justifyContent="flex-end" spacing={2}>
        <ButtonGroup size="small">
          <Button
            startIcon={<UnfoldLess />}
            onClick={() => {
              const allIds = rest.nodes.flatMap((n) => n.children ?? []);
              collapseAll([...allIds, ...rest.rootIds]);
            }}
          >
            Collapse
          </Button>
          <Button startIcon={<UnfoldMore />} onClick={() => expandAll()}>
            Expand
          </Button>
        </ButtonGroup>
      </Stack>
      <CurationApplicationRenderer {...rest} render={renderElement} />
    </Stack>
  );
});

PreviewApplication.displayName = "PreviewApplication";
