import {
  Box,
  ListItemSecondaryAction,
  ListItemText,
  MenuItem,
  MenuItemProps,
  MenuList,
  Typography,
} from "@mui/material";
import { isNil } from "lodash";
import React from "react";
import { LexorankList } from "../../../components/dnd/lexorank/LexorankList";
import { useSelectedRecordingId } from "../../../components/panels/atoms";
import { Visibility } from "../../../components/Visibility";
import { CarrierProjectQuery, Scalars, useUpdateCarrierRecordingMutation } from "../../../generated/graphql";
import { MoveRecordings } from "../projects/MoveRecordings";
import { RenameRecording } from "../projects/RenameRecording";

type Data = CarrierProjectQuery["carrierProject"]["carrierRecordings"][0];

interface Props {
  recordings: Data[];
  disableMove?: boolean;
  MenuItemProps?: MenuItemProps;
  onClick?: (recording: Data) => void;
}

export const RecordingList: React.FC<Props> = ({ recordings, disableMove = false, MenuItemProps, onClick }) => {
  const [selectedRecording, setSelectedRecording] = useSelectedRecordingId();
  const [updateRank] = useUpdateCarrierRecordingMutation();

  return (
    <MenuList component="div" disablePadding={true} sx={{ overflowY: "scroll", overflowX: "hidden" }}>
      <LexorankList
        initialItems={recordings}
        onUpdateRank={(item, lexoRank) => {
          void updateRank({
            variables: { input: { id: item.id as Scalars["CarrierRecordingId"], lexoRank } },
          });
        }}
        renderItem={({ id }, index?: number) => {
          const recording = recordings.find((record) => record.id === id);
          if (!recording) {
            return null;
          }
          return (
            <MenuItem
              disableRipple={true}
              disableTouchRipple={true}
              className="hide-action-items"
              key={recording.id}
              {...MenuItemProps}
              sx={{
                paddingLeft: "0px",
                borderRadius: 2,
                backgroundColor: "background.default",
                ":hover": {
                  backgroundColor: "background.default",
                },
                whiteSpace: "unset",
              }}
              onClick={() => {
                onClick?.(recording);
                setSelectedRecording(recording.id);
              }}
              selected={recording.id === selectedRecording}
            >
              {!isNil(index) && <Typography sx={{ padding: "10px", fontWeight: "bold" }}>{index + 1}</Typography>}
              <Box
                component="img"
                loading="lazy"
                alt="screenshot"
                src={`/api/recording/${recording.id}/screenshot.png`}
                sx={{
                  width: "80px",
                  height: "80px",
                  flexShrink: 0,
                  objectFit: "fill",
                  boxShadow: 1,
                  borderRadius: 1,
                  mr: 3,
                }}
              />
              <ListItemText
                primaryTypographyProps={{
                  fontWeight: 600,
                  variant: "body2",
                }}
                secondaryTypographyProps={{
                  variant: "caption",
                  color:
                    recording.unmappedAnnotationCount > 0 || recording.annotationsCount === 0
                      ? "warning.main"
                      : "text.secondary",
                }}
                primary={recording.name}
                secondary={
                  recording.annotationsCount === 0 ? (
                    <span>No annotations</span>
                  ) : (
                    <span>
                      {recording.annotationsCount - recording.unmappedAnnotationCount} of {recording.annotationsCount}{" "}
                      annotations mapped
                    </span>
                  )
                }
              />
              {!disableMove && (
                <ListItemSecondaryAction onClick={(e) => e.stopPropagation()}>
                  <Visibility>
                    <RenameRecording recordingId={recording.id} name={recording.name} />
                    <MoveRecordings recordingIds={[recording.id]} />
                  </Visibility>
                </ListItemSecondaryAction>
              )}
            </MenuItem>
          );
        }}
      />

      {recordings.length === 0 && (
        <MenuItem disabled={true} {...MenuItemProps}>
          <ListItemText primary="No recordings" />
        </MenuItem>
      )}
    </MenuList>
  );
};
