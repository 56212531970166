import { Column } from "./Column";
import { Row } from "./Row";

export interface TableConfig {
  row?: Row<any>;
  columns: Array<Column<any>>;
  /**
   * @default false
   */
  flex?: boolean;
}

export function createTableConfig<T>(opts?: TableOptions): TableConfigBuilder<T> {
  return new TableConfigBuilder(opts);
}

export interface TableOptions {
  flex?: boolean;
  row?: Row<any>;
}
/**
 * An immutable builder that allows re-using base configuration
 */
class TableConfigBuilder<T> {
  constructor(
    private readonly opts?: TableOptions,
    // private readonly row: Row<T> = { getRowStyles: () => ({}) },
    private readonly columns: Array<Column<T>> = []
  ) {}

  addColumn(column: Column<T>): TableConfigBuilder<T> {
    return new TableConfigBuilder(this.opts, [...this.columns, column]);
  }

  build(): TableConfig {
    return {
      columns: this.columns,
      flex: this.opts?.flex,
      row: this.opts?.row,
    };
  }
}
