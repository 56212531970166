import Checkbox, { CheckboxProps } from "@mui/material/Checkbox";
import FormControlLabel, { FormControlLabelProps } from "@mui/material/FormControlLabel";
import * as React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useSaveForm } from "./common/useSaveForm";
import { FormFieldProps, processFormFieldProps } from "./FormFieldProps";

type Props = Omit<FormControlLabelProps, "control"> &
  FormFieldProps & {
    checkboxProps?: CheckboxProps;
  };

export const FormFieldCheckbox: React.VFC<Props> = (props) => {
  const { control } = useFormContext();
  const { checkboxProps, ControllerProps, fullWidth, helperText, infoText, ...rest } = processFormFieldProps(props);
  const saveForm = useSaveForm();

  const convertToBool = (value: string | boolean): boolean => {
    if (typeof value === "string") {
      return value === "true" ? true : false;
    }
    return value;
  };

  return (
    <Controller
      control={control}
      defaultValue={props.defaultValue ?? false}
      {...ControllerProps}
      render={({ field: { onChange, onBlur, value, ref, name } }) => (
        <FormControlLabel
          className="question"
          {...rest}
          control={
            <Checkbox
              size="medium"
              {...checkboxProps}
              name={name}
              onChange={(event, checked) => {
                onChange(event);
                saveForm(name, checked);
              }}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  const nextValue = !value;
                  onChange(nextValue);
                  saveForm(name, nextValue);
                }
              }}
              onBlur={onBlur}
              checked={convertToBool(value)}
              inputRef={ref}
            />
          }
        />
      )}
    />
  );
};
