import styled from "@emotion/styled";
import { Stack } from "@mui/material";
import { PropsWithChildren } from "react";

const Root = styled(Stack)({
  height: "100%",
  width: "100%",
  flex: 1,
  background: "#EEEEEE",
});

export function Page(props: PropsWithChildren<{}>) {
  return <Root direction="row">{props.children}</Root>;
}
