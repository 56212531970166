import { atom, useAtom } from "jotai";

const minimizedMode = atom(true);
export function useMinimizedMode() {
  return useAtom(minimizedMode);
}

const curationSearchQuery = atom("");
export function useCurationSearchQuery() {
  return useAtom(curationSearchQuery);
}

const clobQuestionFilter = atom<undefined | "mapped" | "unmapped">(undefined);
export function useClobQuestionFilter() {
  const [value, setValue] = useAtom(clobQuestionFilter);
  return [
    value,
    (next: undefined | "mapped" | "unmapped") => {
      if (value === next) {
        setValue(undefined);
      } else {
        setValue(next);
      }
    },
  ] as const;
}

export const clobQuestionLobCarrierFilter = atom<{ carrierSlugs: string[]; lineOfBusiness: string }>({
  carrierSlugs: [],
  lineOfBusiness: "",
});
export const useClobQuestionLobCarrierFilter = () => useAtom(clobQuestionLobCarrierFilter);

export const curationQuestionSearchStringAtom = atom<string>("");
